import isEmpty from 'lodash/isEmpty';
import { generatePath, useParams } from 'react-router-dom';
import { useHomeWithStatsQuery } from '../../gql/generated';
import LoadingOverlay from '../../components/common/LoadingOverlay';
import ItemTabs from '../../components/itemDetails/ItemTabs';
import { useMemo } from 'react';
import { AppPaths } from '../../routes/paths';
import { useTranslation } from 'react-i18next';

const ItemDetails = () => {
  const { t } = useTranslation();
  const { id = '', itemId = '' } = useParams();

  const { data, loading } = useHomeWithStatsQuery({ variables: { homeId: id }, fetchPolicy: 'cache-and-network' });

  const tabs = useMemo(
    () => [
      { label: t('home_details.info'), path: generatePath(AppPaths.ITEM_DETAILS_INFO, { id, itemId }) },
      {
        label: t('home_details.maintenance_plan'),
        path: generatePath(AppPaths.ITEM_DETAILS_MAINTENANCE_PLAN, { id, itemId }),
      },
    ],
    [id, itemId, t],
  );

  if (loading) return <LoadingOverlay />;
  if (isEmpty(data)) return null;
  return <ItemTabs tabs={tabs} itemDetailsPath={generatePath(AppPaths.ITEM_DETAILS, { id, itemId })} />;
};

export default ItemDetails;

import { Box, BoxProps, Link, Text } from '@chakra-ui/react';
import { DataTable } from '../../components/common/DataTable';
import { Row, createColumnHelper } from '@tanstack/react-table';
import UserInfo from '../../components/users/UserInfo';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { HomeFragment, Location, User } from '../../gql/generated';
import HomeMateColumn from '../common/HomeMateColumn';
import generateGooglePlaceUrl from '../../utils/generateGooglePlaceUrl';
import HomeActions from './HomeActions';
import TableTooltipLabel from '../common/TableTooltipLabel';
import { useNavigate } from 'react-router-dom';

type UserInfo = Pick<User, 'id' | 'profile' | 'email' | 'isVerified'>;

export type HomeData = {
  owner: UserInfo;
  homemates: UserInfo[];
  location: Location;
  zoneCount: number;
  dwelinkTasks: number;
  totalTasks: number;
  phone: string;
  id: string;
};

const columnHelper = createColumnHelper<HomeData>();

type Props = {
  isLoading?: boolean;
  homes: HomeFragment[];
  boxProps?: BoxProps;
  getNavigationPath?: (row: Row<HomeData>) => string;
  disableRow?: (row: Row<HomeData>) => boolean;
};

export default function HomeTable({ homes, getNavigationPath, disableRow, boxProps, isLoading }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      columnHelper.accessor('location', {
        cell: (info) => {
          const location = info.getValue();
          const placeUrl = generateGooglePlaceUrl(location);
          const value = `${String(location.street1)} ${String(location.city)}, ${String(location.state)}`;
          return (
            <Box maxW='300'>
              <Link href={placeUrl} isExternal>
                <TableTooltipLabel text={value} maxW='300' />
              </Link>
            </Box>
          );
        },
        header: t('home_table.header.address'),
      }),
      columnHelper.accessor('homemates', {
        cell: (info) => {
          const homemates = info.getValue();
          return <HomeMateColumn homemates={homemates} />;
        },
        header: t('home_table.header.homemates'),
      }),
      columnHelper.accessor('zoneCount', {
        cell: (info) => info.getValue(),
        header: t('home_table.header.zones'),
      }),
      columnHelper.accessor('totalTasks', {
        cell: (info) => info.getValue(),
        header: t('home_table.header.tasks'),
      }),
      columnHelper.accessor('owner', {
        cell: (info) => <UserInfo {...info.getValue()} />,
        header: t('home_table.header.owner'),
      }),
      columnHelper.accessor('phone', {
        cell: (info) => {
          const phone = info.getValue();
          if (!phone)
            return (
              <Text color='primary.main' fontWeight='semibold'>
                N/A
              </Text>
            );
          return info.getValue();
        },
        header: t('home_table.header.phone'),
      }),
      columnHelper.accessor('id', {
        cell: (info) =>
          disableRow?.(info.row) ? null : <HomeActions navigationPath={getNavigationPath?.(info.row) || ''} />,
        header: '',
      }),
    ],
    [disableRow, getNavigationPath, t],
  );
  const handlerOnClickRow = useCallback(
    (row: Row<HomeData>) => getNavigationPath && navigate(getNavigationPath(row)),
    [getNavigationPath, navigate],
  );

  const tableData = homes.map((home) => {
    const [totalTasks, dwelinkTasks] = home.taskCount;
    const [zoneCount] = home.zoneCount;
    return { ...home, phone: home.owner.profile?.phone || '', dwelinkTasks, totalTasks, zoneCount };
  }, []);

  return (
    <DataTable
      columns={columns}
      data={tableData}
      loading={isLoading}
      disableRow={disableRow}
      {...(getNavigationPath && { onClickRow: handlerOnClickRow })}
      boxProps={{ minHeight: 650, ...boxProps }}
      emptyTitle={t('home_table.empty.title')}
      emptyDescription={t('home_table.empty.description')}
    />
  );
}

import React, { forwardRef } from 'react';
import { Box, BoxProps, Divider, Flex, SimpleGrid } from '@chakra-ui/react';
import { FormProvider, UseFormReset, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { EJobPerformer, TaskFragment } from '../../gql/generated';
import { CRITICALITY_SELECT_OPTIONS, LEVEL_OF_SKILL_SELECT_OPTIONS } from '../../constants/presets';
import FormDatePicker from '../common/form/FormDatePicker';
import getTaskFormSchema from './getTaskFormSchema';
import SelectJobPerformer from './SelectJobPerformer';
import addMonths from 'date-fns/addMonths';
import addDays from 'date-fns/addDays';
import { getImportanceLevel, getLevelOfSkill } from '../../utils/helpers';
import FormMenu from '../common/form/FormMenu';
import FormInput from '../common/form/FormInput';
import FormNumberInput from '../common/form/FormNumberInput';
import FormPrompt from '../common/form/FormPrompt';
import { getRecommendedDate } from '../../utils/date';

export interface ICreateTaskForm {
  name?: string;
  importance?: number;
  levelOfSkill?: number;
  jobPerformer?: EJobPerformer;
  jobPerformerName?: string;
  recommendedDate?: Date;
  scheduledDate?: Date | null;
  costEstimate?: number | null;
  description: string;
  riskOfNotDoing?: string;
  zone?: string;
  inventoryItem?: string;
}

export interface ITaskFormForwardRef {
  onSubmitForm: (() => unknown) | null | undefined;
  reset: UseFormReset<ICreateTaskForm>;
}
interface ITaskFormProps {
  boxProps?: BoxProps;
  initialValues?: Partial<ICreateTaskForm>;
  disableEdit?: boolean;
  onSubmitJobPerformerSelect?: (jobPerformer: EJobPerformer, jobPerformerName?: string) => void;
  onSubmitForm: (values: ICreateTaskForm) => Promise<TaskFragment | undefined>;
  isCreate?: boolean;
}

const TaskForm = forwardRef<ITaskFormForwardRef, ITaskFormProps>(
  ({ boxProps, isCreate, onSubmitJobPerformerSelect, disableEdit, initialValues, onSubmitForm }, ref) => {
    const methods = useForm({
      resolver: yupResolver(getTaskFormSchema()),
      defaultValues: { ...initialValues },
    });

    const onSubmit = methods.handleSubmit(async (values) => {
      const newTask = await onSubmitForm({ ...values, jobPerformer: values.jobPerformer as EJobPerformer });
      if (!newTask) return;
      methods.reset({
        costEstimate: newTask.costEstimate,
        description: newTask.description || ' ',
        importance: newTask.importance || 1,
        levelOfSkill: newTask.levelOfSkill || 1,
        jobPerformerName: newTask.jobPerformerName || '',
        inventoryItem: newTask.item.name || '',
        zone: newTask.item.zone.name || '',
        jobPerformer: newTask.jobPerformer,
        name: newTask.name || '',
        recommendedDate: newTask.recommendedDate ? getRecommendedDate(newTask.recommendedDate) : undefined,
        riskOfNotDoing: newTask.riskOfNotDoing || '',
        scheduledDate: newTask.scheduledDate ? new Date(newTask.scheduledDate) : null,
      });
    });

    React.useImperativeHandle(ref, () => ({
      onSubmitForm: onSubmit,
      reset: methods.reset as UseFormReset<ICreateTaskForm>,
    }));

    const importancePlaceholder = methods.getValues('importance')
      ? getImportanceLevel(methods.getValues('importance'))?.title
      : 'undefined';

    const levelOfSkillPlaceholder = methods.getValues('levelOfSkill')
      ? getLevelOfSkill(methods.getValues('levelOfSkill'))?.title
      : 'undefined';

    return (
      <Box {...boxProps} bg='white' p='5' rounded='xl' shadow='md'>
        <FormProvider {...methods}>
          <form onSubmit={onSubmit}>
            {!isCreate && <FormPrompt unwatchFields={['jobPerformerName', 'jobPerformer']} />}
            <Flex>
              <Box h='fit-content' flex={1} overflow='hidden'>
                <SimpleGrid columnGap='5' columns={{ base: 1, lg: 2 }}>
                  <FormInput
                    name='name'
                    label='task_form.name'
                    isRequired
                    placeholder='undefined'
                    disabled={disableEdit}
                  />
                  <FormInput name='zone' label='task_form.zone' placeholder='undefined' isEdit={false} />
                </SimpleGrid>
                <Divider my='1' />
                <SimpleGrid columnGap='5' columns={{ base: 1, lg: 2 }}>
                  <FormMenu
                    isRequired
                    name='importance'
                    label='task_form.criticality'
                    options={CRITICALITY_SELECT_OPTIONS}
                    placeholder={importancePlaceholder}
                    disabled={disableEdit}
                  />
                  <FormInput
                    name='inventoryItem'
                    label='task_form.inventory_item'
                    placeholder='undefined'
                    isEdit={false}
                  />
                </SimpleGrid>
                <Divider my='1' />
                <SimpleGrid columnGap='5' alignItems='center' columns={{ base: 1, lg: 2 }}>
                  <Box flex='1'>
                    <FormMenu
                      isRequired
                      name='levelOfSkill'
                      label='task_form.difficulty_level'
                      titleColor='black'
                      options={LEVEL_OF_SKILL_SELECT_OPTIONS}
                      placeholder={levelOfSkillPlaceholder}
                      disabled={disableEdit}
                    />
                  </Box>
                  <Box flex='1'>
                    <SelectJobPerformer
                      label='task_form.job_performer'
                      placeholder='undefined'
                      onSubmit={onSubmitJobPerformerSelect}
                      disabled={disableEdit}
                    />
                  </Box>
                </SimpleGrid>
                <Divider my='1' />
                <SimpleGrid columnGap='5' alignItems='center' columns={{ base: 1, lg: 2 }}>
                  <Box width='100%'>
                    <FormDatePicker
                      minDate={addMonths(new Date(), 1)}
                      showMonthYearPicker
                      isRequired
                      name='recommendedDate'
                      label='task_form.recommended_date'
                      disabled={disableEdit}
                    />
                    <FormDatePicker
                      minDate={addDays(new Date(), 1)}
                      name='scheduledDate'
                      label='task_form.scheduled_date'
                      disabled={disableEdit || methods.getValues('jobPerformer') !== EJobPerformer.DWELINK}
                      pickerPosition='top'
                    />
                  </Box>
                  <FormNumberInput
                    name='costEstimate'
                    label='task_form.cost_estimate'
                    placeholder='undefined'
                    disabled={disableEdit}
                    min={0}
                  />
                </SimpleGrid>
                <Divider my='1' />
                <SimpleGrid columnGap='5' columns={{ base: 1, lg: 2 }}>
                  <FormInput
                    isRequired
                    name='description'
                    label='task_form.description'
                    isTextarea
                    placeholder='undefined'
                    disabled={disableEdit}
                  />
                  <FormInput
                    name='riskOfNotDoing'
                    label='task_form.risk_of_not_doing'
                    isRequired
                    isTextarea
                    placeholder='undefined'
                    disabled={disableEdit}
                  />
                </SimpleGrid>
              </Box>
            </Flex>
            <button type='submit' style={{ display: 'none' }} />
          </form>
        </FormProvider>
      </Box>
    );
  },
);

export default TaskForm;

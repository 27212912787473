import {
  Card,
  CardBody,
  CardHeader,
  Code,
  Divider,
  Flex,
  Heading,
  ListItem,
  OrderedList,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export default function JobberHubCard() {
  const { t } = useTranslation();

  return (
    <Card size='md'>
      <CardHeader>
        <Heading size='lg'>{t('jobber_hub_card.header')}</Heading>
      </CardHeader>
      <CardBody pt='0'>
        <Flex direction='column'>
          <Text>{t('jobber_hub_card.body.description')}</Text>
          <Divider my='4' />
          <Text>{t('jobber_hub_card.body.list.title')}</Text>
          <OrderedList>
            <ListItem>{t('jobber_hub_card.body.list.step_1')}</ListItem>
            <ListItem>{t('jobber_hub_card.body.list.step_2')}</ListItem>
            <ListItem>{t('jobber_hub_card.body.list.step_3')}</ListItem>
            <ListItem>{t('jobber_hub_card.body.list.step_4')}</ListItem>
          </OrderedList>
          <Divider my='4' />
          <Flex direction='column' gap={2}>
            <Text>{t('jobber_hub_card.body.sample_url_title')}</Text>
            <Code>
              <Text>https://clienthub.getjobber.com/client_hubs/6db7bbc4-ac6d-4985-8772-fce755573e4f</Text>
            </Code>
          </Flex>
        </Flex>
      </CardBody>
    </Card>
  );
}

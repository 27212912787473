import isEmpty from 'lodash/isEmpty';
import { useParams } from 'react-router-dom';
import { Box, Card, CardBody, SimpleGrid, Spinner, Text, useToast } from '@chakra-ui/react';
import { Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { createRef, useCallback, useMemo } from 'react';
import {
  EDoItRequestStatus,
  useDoitRequestImagesQuery,
  useDoitRequestQuery,
  useUpdateDoitRequestMutation,
} from '../../gql/generated';
import { Gallery as GalleryIcon } from '../../icons/dwelink-icons';
import LoadingOverlay from '../../components/common/LoadingOverlay';
import { formatGraphQLErrors } from '../../gql/apollo/helpers';
import DoItDetailsInfo from '../../components/doitDetails/DoItDetailsInfo';
import DoItDetailsNotes, {
  IDoItNotesFormRef,
  IDoItNotesFormValues,
} from '../../components/doitDetails/DoItDetailsNotes';
import DoItDetailsButtonsBar from '../../components/doitDetails/DoItDetailsButtonsBar';
import Gallery from '../../components/common/images/Gallery';
import PageError from '../../components/common/PageError';

const DoItRequestDetails = () => {
  const { doitRequestId = '' } = useParams();
  const toast = useToast();
  const { t } = useTranslation();
  const formRef = createRef<IDoItNotesFormRef>();

  const { data, error, loading } = useDoitRequestQuery({
    variables: { doitRequestId },
    skip: !doitRequestId,
    fetchPolicy: 'cache-and-network',
  });

  const { data: imagesData, loading: doitRequestImagesLoading } = useDoitRequestImagesQuery({
    variables: { doitRequestId },
    skip: !doitRequestId,
    fetchPolicy: 'cache-and-network',
  });

  const [updateDoitRequest, { loading: updateDoitRequestLoading }] = useUpdateDoitRequestMutation({
    onError: (e) => toast({ description: formatGraphQLErrors(e).message, status: 'error' }),
    onCompleted: () => {
      toast({
        description: t('doit_request_details.toast.update_doit_request'),
        status: 'success',
      });
    },
  });

  const handlerOnSubmitUpdateDoItRequest = useCallback(
    async (values?: IDoItNotesFormValues) => {
      if (data?.doitRequest.note === values?.note) return;

      const updateDoItRequest = {
        doitRequestId,
        ...values,
      };
      await updateDoitRequest({ variables: { input: updateDoItRequest } });
    },
    [data?.doitRequest.note, doitRequestId, updateDoitRequest],
  );

  const handlerOnSave = useCallback(() => {
    formRef.current?.onSubmit?.();
  }, [formRef]);

  const initialValues: IDoItNotesFormValues = useMemo(
    () => ({ note: data?.doitRequest.note || '' }),
    [data?.doitRequest.note],
  );

  const resetForm = useCallback(() => {
    formRef.current?.reset();
  }, [formRef]);
  if (loading && isEmpty(data)) return <LoadingOverlay />;

  if (error?.message && isEmpty(data)) return <PageError error={error} />;

  return (
    <Box>
      {data?.doitRequest && (
        <DoItDetailsButtonsBar
          resetForm={resetForm}
          onSaveLoading={updateDoitRequestLoading}
          onSave={handlerOnSave}
          doitRequest={data?.doitRequest}
        />
      )}
      <SimpleGrid gap={4} columns={{ base: 1, lg: 2 }}>
        <Flex gap='4' flexDirection='column'>
          <Card>
            <CardBody p={4}>{data?.doitRequest && <DoItDetailsInfo doitRequest={data?.doitRequest} />}</CardBody>
          </Card>
          <Card>
            <CardBody p={4}>
              <DoItDetailsNotes
                disabled={data?.doitRequest.status === EDoItRequestStatus.FINISHED}
                ref={formRef}
                initialValues={initialValues}
                onSubmit={handlerOnSubmitUpdateDoItRequest}
              />
            </CardBody>
          </Card>
        </Flex>
        <Card>
          <CardBody p={4}>
            {doitRequestImagesLoading ? (
              <Flex py='20' flexDirection='column' alignItems='center' gap={6}>
                <Spinner size='xl' color='orange' />
              </Flex>
            ) : imagesData?.doitRequestImages.length ? (
              <Gallery images={imagesData.doitRequestImages} showCarrousel />
            ) : (
              <Flex py='20' flexDirection='column' alignItems='center' justifyContent='center'>
                <GalleryIcon boxSize={20} />
                <Text color='darkgray' fontSize='lg' p='4' textAlign='center'>
                  {t('doit_request_details.images.empty_state')}
                </Text>
              </Flex>
            )}
          </CardBody>
        </Card>
      </SimpleGrid>
    </Box>
  );
};

export default DoItRequestDetails;

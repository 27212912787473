import { RouteObject } from 'react-router-dom';
import AuthLayout from '../components/layouts/AuthLayout/AuthLayout';
import AcceptInvitation from '../pages/auth/AcceptInvitation';
import GoogleRedirect from '../pages/auth/GoogleRedirect';
import Login from '../pages/auth/Login';
import SetPassword from '../pages/auth/SetPassword';
import VerifyEmail from '../pages/auth/VerifyEmail';
import { AuthPaths } from './paths';

export default {
  element: <AuthLayout />,
  children: [
    { path: AuthPaths.LOGIN, element: <Login /> },
    { path: AuthPaths.FORGOT, element: <SetPassword isForgot /> },
    { path: AuthPaths.SET_PASSWORD, element: <SetPassword /> },
    { path: AuthPaths.VERIFY_EMAIL, element: <VerifyEmail /> },
    { path: AuthPaths.GOOGLE_REDIRECT, element: <GoogleRedirect /> },
    { path: AuthPaths.ACCEPT_INVITATION, element: <AcceptInvitation /> },
  ],
} as RouteObject;

import { Flex, FormControl, FormLabel, Select, Switch, useToast, chakra, Box } from '@chakra-ui/react';
import { Row } from '@tanstack/react-table';
import isEmpty from 'lodash/isEmpty';
import { formatGraphQLErrors } from '../../gql/apollo/helpers';
import { EJobPerformer, ETaskStatus, useTasksQuery } from '../../gql/generated';
import TaskTable, { TaskData } from '../../components/homeDetails/Tasks/TaskTable';
import { ChangeEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Pagination from '../../components/common/Pagination';
import { generatePath } from 'react-router-dom';
import { AppPaths } from '../../routes/paths';
import endOfMonth from 'date-fns/endOfMonth';
import startOfMonth from 'date-fns/startOfMonth';
import DatePicker from '../../components/common/DatePicker';
import { FILTER_TASKS_BY_JOB_PERFORMER_SELECT } from '../../constants/presets';
import PageError from '../../components/common/PageError';

const TASKS_PER_PAGE = 40;

const Tasks = () => {
  const toast = useToast();
  const [currentPage, setCurrentPage] = useState(1);
  const [showIsArchived, setShowIsArchived] = useState(false);
  const [status, setStatus] = useState(ETaskStatus.ACTIVE);

  const [executor, setExecutor] = useState<EJobPerformer | undefined>(undefined);

  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
  const [startDate, endDate] = dateRange;

  const timeRange = useMemo(
    () =>
      !!startDate && !!endDate
        ? { timeRange: { start: startOfMonth(startDate).toISOString(), end: endOfMonth(endDate).toISOString() } }
        : undefined,
    [endDate, startDate],
  );

  const jobPerformer = useMemo(
    () => (executor && Object.keys(EJobPerformer).includes(executor) ? [executor] : undefined),
    [executor],
  );

  const { data, error, loading } = useTasksQuery({
    variables: {
      filter: { status: [status], isArchived: showIsArchived, ...timeRange, jobPerformer },
      limit: TASKS_PER_PAGE,
      offset: (currentPage - 1) * TASKS_PER_PAGE,
    },
    onError: (e) => toast({ description: formatGraphQLErrors(e).message, status: 'error' }),
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });

  const { t } = useTranslation();

  const handleIsArchivedChange = () => {
    setShowIsArchived((prev) => {
      setCurrentPage(1);
      return !prev;
    });
  };

  const handleExecutorChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setExecutor(() => {
      setCurrentPage(1);
      return e.target.value as EJobPerformer;
    });
  };

  const handleStatusChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setStatus(() => {
      setCurrentPage(1);
      return e.target.value as ETaskStatus;
    });
  };

  const handleGetNavigationPath = (row: Row<TaskData>) => generatePath(AppPaths.EDIT_TASK, { taskId: row.original.id });

  const disableEdit = status === ETaskStatus.FINISHED || showIsArchived;

  const tasks = (data?.tasks.tasks || []).map(({ name, item, recommendedDate, jobPerformer, id, item: { home } }) => ({
    name,
    item: item.name,
    zone: item.zone.name,
    recommendedDate,
    owner: home.owner,
    relatedHome: home.location,
    jobPerformer,
    id,
    homeId: item.homeId,
  }));

  if (error?.message && isEmpty(data)) return <PageError error={error} />;

  return (
    <Box>
      <Flex py='2' justifyContent='space-between'>
        <Box width='300px'>
          <DatePicker<string, true>
            selectsRange
            showMonthYearPicker
            placeholderText='MM/DD/YYYY - MM/DD/YYYY'
            startDate={startDate}
            endDate={endDate}
            onChange={(update) => {
              setDateRange(update);
            }}
            isClearable={true}
          />
        </Box>
        <Flex gap={5} alignItems='center'>
          <Box>
            <FormControl display='flex' alignItems='center'>
              <FormLabel htmlFor='show_archived' mb='0' fontWeight='normal'>
                {t('task_show_archived')}
              </FormLabel>
              <Switch
                id='show_archived'
                colorScheme='primary'
                onChange={handleIsArchivedChange}
                isChecked={showIsArchived}
              />
            </FormControl>
          </Box>
          <Box>
            <Select value={executor} onChange={handleExecutorChange} variant='outlined'>
              {FILTER_TASKS_BY_JOB_PERFORMER_SELECT.map((key) => (
                <chakra.option value={key} key={key}>
                  {t(`task_select_executor.${key.toLowerCase()}`)}
                </chakra.option>
              ))}
            </Select>
          </Box>
          <Box>
            <Select value={status} onChange={handleStatusChange} variant='outlined'>
              {Object.keys(ETaskStatus).map((key) => (
                <chakra.option value={key} key={key}>
                  {t(`task_select_status.${key.toLowerCase()}`)}
                </chakra.option>
              ))}
            </Select>
          </Box>
        </Flex>
      </Flex>
      <Box width='100%'>
        <TaskTable
          getNavigationPath={handleGetNavigationPath}
          tasks={tasks}
          isLoading={loading && isEmpty(tasks)}
          disableEdit={disableEdit}
          withHomeInfo
        />
        {data?.tasks && (
          <Pagination
            total={data.tasks.total}
            current={currentPage}
            defaultPageSize={TASKS_PER_PAGE}
            onChange={(page) => setCurrentPage(page)}
          />
        )}
      </Box>
    </Box>
  );
};

export default Tasks;

import { useInvitationQuery } from '../../gql/generated';
import { Box, useToast } from '@chakra-ui/react';
import { useDeferredValue, useEffect, useState } from 'react';
import Pagination from '../../components/common/Pagination';
import { formatGraphQLErrors } from '../../gql/apollo/helpers';
import InvitationTable from './InvitationTable';
import Header from './Header';
import { isEmpty } from 'lodash';
import PageError from '../common/PageError';

const INVITATIONS_PER_PAGE = 40;

export default function Invitations() {
  const [search, setSearch] = useState('');
  const toast = useToast();
  const deferredSearch = useDeferredValue(search.trim());
  const { data, loading, error, refetch } = useInvitationQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      filter: { search: deferredSearch, isUsed: false },
      limit: INVITATIONS_PER_PAGE,
    },
    onError: (e) => toast({ description: formatGraphQLErrors(e).message, status: 'error' }),
    notifyOnNetworkStatusChange: true,
  });
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    refetch({
      offset: (currentPage - 1) * INVITATIONS_PER_PAGE,
      limit: INVITATIONS_PER_PAGE,
    });
  }, [currentPage, refetch]);

  useEffect(() => {
    setCurrentPage(1);
  }, [deferredSearch]);

  if (error?.message && isEmpty(data)) return <PageError error={error} />;

  return (
    <Box>
      <Header search={search} onChange={(e) => setSearch(e.target.value)} refetch={refetch} />
      <InvitationTable
        isLoading={loading}
        search={deferredSearch}
        refetch={refetch}
        invitations={
          data?.invitations
            ? data.invitations.invitations.map(({ profile, email, role, expiresAt, inviter, id }) => ({
                id,
                email,
                role,
                expirationDate: expiresAt,
                invitee: {
                  email,
                  profile,
                },
                inviter: {
                  id: inviter.id,
                  email: inviter.email,
                  profile: {
                    firstName: inviter.profile?.firstName || '',
                    lastName: inviter.profile?.lastName || '',
                  },
                  isVerified: inviter.isVerified,
                },
              }))
            : []
        }
      />
      {data?.invitations && (
        <Pagination
          total={data.invitations.total}
          current={currentPage}
          defaultPageSize={INVITATIONS_PER_PAGE}
          onChange={(page) => setCurrentPage(page)}
        />
      )}
    </Box>
  );
}

import CustomerTable, { CustomerData } from '../../components/users/CustomerTable';
import { Input, InputGroup, InputLeftElement, Box, useToast } from '@chakra-ui/react';
import { useDeferredValue, useEffect, useState } from 'react';
import { ERole, useUsersQuery } from '../../gql/generated';
import Pagination from '../../components/common/Pagination';
import { Search } from '../../icons';
import { formatGraphQLErrors } from '../../gql/apollo/helpers';
import { useTranslation } from 'react-i18next';
import { Row } from '@tanstack/react-table';
import { generatePath } from 'react-router-dom';
import { AppPaths } from '../../routes/paths';
import { isEmpty } from 'lodash';
import PageError from '../../components/common/PageError';

const CUSTOMERS_PER_PAGE = 40;

export default function Customers() {
  const [search, setSearch] = useState('');
  const toast = useToast();
  const { t } = useTranslation();

  const deferredSearch = useDeferredValue(search.trim());

  const { data, error, loading, refetch } = useUsersQuery({
    variables: {
      filter: { search: deferredSearch, role: [ERole.USER] },
      limit: CUSTOMERS_PER_PAGE,
    },
    onError: (e) => toast({ description: formatGraphQLErrors(e).message, status: 'error' }),
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });
  const [currentPage, setCurrentPage] = useState(1);

  const handleGetNavigationPath = (row: Row<CustomerData>) =>
    generatePath(AppPaths.CUSTOMER_PROFILE, { userId: row.original.id });

  useEffect(() => {
    refetch({
      offset: (currentPage - 1) * CUSTOMERS_PER_PAGE,
      limit: CUSTOMERS_PER_PAGE,
    });
  }, [currentPage, refetch]);

  useEffect(() => {
    setCurrentPage(1);
  }, [deferredSearch]);

  if (error?.message && isEmpty(data)) return <PageError error={error} />;

  return (
    <Box>
      <InputGroup mb='4'>
        <InputLeftElement pointerEvents='none'>
          <Search />
        </InputLeftElement>
        <Input
          placeholder={t('search')}
          background='white'
          maxW={{ base: 'none', md: '260px' }}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </InputGroup>
      <CustomerTable
        getNavigationPath={handleGetNavigationPath}
        isLoading={loading}
        search={deferredSearch}
        customers={
          data?.users
            ? data.users.users.map((customer) => ({
                id: customer.id,
                user: customer,
                phone: customer.profile?.phone,
                homes: customer.numberOfHomes,
                createdAt: new Date(customer.createdAt),
                authServices: customer.authServices,
              }))
            : []
        }
      />
      {data?.users && (
        <Pagination
          total={data.users.total}
          current={currentPage}
          defaultPageSize={CUSTOMERS_PER_PAGE}
          onChange={(page) => setCurrentPage(page)}
        />
      )}
    </Box>
  );
}

import { Box, Divider, Flex, FlexProps, SimpleGrid, useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import format from 'date-fns/format';
import {
  CurrentUserFragment,
  ELoginWithPasswordAbility,
  useLoginWithPasswordAbilityQuery,
} from '../../../gql/generated';
import { useCallback, useMemo } from 'react';
import ItemWithEditAction from './ItemWithEditAction';
import ChangeNameModal from '../ChangeNameModal';
import ChangePhoneNumberModal from '../ChangePhoneNumberModal';
import ChangePasswordModal from '../ChangePasswordModal';
import CreatePasswordModal from '../CreatePasswordModal';

const DIVIDER_MARGIN = 2;

interface IUserProfileInfoProps {
  containerProps?: FlexProps;
  user: CurrentUserFragment;
  countOfHomes: number;
}

const UserProfileInfo = ({ countOfHomes, containerProps, user }: IUserProfileInfoProps) => {
  const { t } = useTranslation();

  const {
    isOpen: isOpenChangePasswordModal,
    onOpen: onOpenChangePasswordModal,
    onClose: onCloseChangePasswordModal,
  } = useDisclosure();

  const {
    isOpen: isOpenChangeNameModal,
    onOpen: onOpenChangeNameModal,
    onClose: onCloseChangeNameModal,
  } = useDisclosure();

  const {
    isOpen: isOpenChangePhoneModal,
    onOpen: onOpenChangePhoneModal,
    onClose: onCloseChangePhoneModal,
  } = useDisclosure();

  const {
    isOpen: isOpenCreatePasswordModal,
    onOpen: onOpenCreatePasswordModal,
    onClose: onCloseCreatePasswordModal,
  } = useDisclosure();

  const yearRegistered = useMemo(
    () => (user.createdAt ? format(new Date(user.createdAt), 'LLL dd, yyyy') : ''),
    [user.createdAt],
  );

  const name = `${user.profile?.firstName || ''} ${user.profile?.lastName || ''}`;

  const { data, loading: loadingAbility } = useLoginWithPasswordAbilityQuery({
    variables: { email: user.email },
    fetchPolicy: 'cache-and-network',
  });

  const handleOnOpenChangePhoneModal = useCallback(() => {
    if (loadingAbility) return null;
    switch (data?.loginWithPasswordAbility) {
      case ELoginWithPasswordAbility.HAS_ACCOUNT_NO_PASSWORD:
        return onOpenCreatePasswordModal();
      default:
        return onOpenChangePasswordModal();
    }
  }, [data?.loginWithPasswordAbility, loadingAbility, onOpenChangePasswordModal, onOpenCreatePasswordModal]);

  return (
    <Flex my='50' {...containerProps} justifyContent='space-between'>
      <CreatePasswordModal user={user} isOpen={isOpenCreatePasswordModal} onClose={onCloseCreatePasswordModal} />
      <ChangePasswordModal isOpen={isOpenChangePasswordModal} onClose={onCloseChangePasswordModal} />
      <ChangeNameModal user={user} isOpen={isOpenChangeNameModal} onClose={onCloseChangeNameModal} />
      <ChangePhoneNumberModal user={user} isOpen={isOpenChangePhoneModal} onClose={onCloseChangePhoneModal} />
      <SimpleGrid
        w='100%'
        columnGap={10}
        rowGap={5}
        columns={{
          base: 1,
          lg: 2,
        }}
      >
        <Box flex='1' borderColor='gray.200' p='5' borderWidth={1} borderRadius='12'>
          <ItemWithEditAction label={t('user_profile_info.name')} value={name} onEdit={onOpenChangeNameModal} />
          <Divider my={DIVIDER_MARGIN} />
          <ItemWithEditAction
            label={t('user_profile_info.phone')}
            value={user.profile?.phone || ''}
            onEdit={onOpenChangePhoneModal}
          />
          <Divider my={DIVIDER_MARGIN} />
          <ItemWithEditAction
            label={t('user_profile_info.password')}
            value={
              data?.loginWithPasswordAbility === ELoginWithPasswordAbility.HAS_ACCOUNT_PASSWORD
                ? t('user_profile_info.password_placeholder')
                : ''
            }
            onEdit={handleOnOpenChangePhoneModal}
          />
        </Box>
        <Box flex='1' borderColor='gray.200' p='5' borderWidth={1} borderRadius='12'>
          <ItemWithEditAction
            label={t('user_profile_info.role')}
            value={t(`roles.${user.role.toLowerCase()}`)}
            disableEdit
          />
          <Divider my={DIVIDER_MARGIN} />
          <ItemWithEditAction label={t('user_profile_info.homes')} value={countOfHomes} disableEdit />
          <Divider my={DIVIDER_MARGIN} />
          <ItemWithEditAction label={t('user_profile_info.registered_on')} value={yearRegistered} disableEdit />
        </Box>
      </SimpleGrid>
    </Flex>
  );
};

export default UserProfileInfo;

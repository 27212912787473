import { Navigate, useSearchParams } from 'react-router-dom';
import LoadingOverlay from '../../components/common/LoadingOverlay';
import { AuthPaths } from '../../routes/paths';
import { useEffect, useMemo, useRef } from 'react';
import useLoginWithGoogle from '../../hooks/auth/useLoginWithGoogle';

export default function GoogleRedirect() {
  const wasRequested = useRef(false); //  avoid double request in strict mode

  const [search] = useSearchParams();
  const [login] = useLoginWithGoogle();
  const code = useMemo(() => search.get('code'), [search]);

  useEffect(() => {
    if (code && !wasRequested.current) {
      void login({ variables: { code } });
      wasRequested.current = true;
    }
  }, [login, code]);

  if (!code) {
    return <Navigate to={AuthPaths.LOGIN} replace />;
  }

  return <LoadingOverlay />;
}

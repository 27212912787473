import { Box, useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Row } from '@tanstack/react-table';
import { generatePath } from 'react-router-dom';
import { HomeFragment, useHomesQuery } from '../../gql/generated';
import Pagination from '../../components/common/Pagination';
import { formatGraphQLErrors } from '../../gql/apollo/helpers';
import HomeTable, { HomeData } from '../../components/homes/HomeTable';
import { AppPaths } from '../../routes/paths';
import { isEmpty } from 'lodash';
import PageError from '../../components/common/PageError';

const HOMES_PER_PAGE = 40;

export default function Homes() {
  const [currentPage, setCurrentPage] = useState(1);
  const toast = useToast();

  const { data, loading, error, refetch } = useHomesQuery({
    variables: {
      limit: HOMES_PER_PAGE,
    },
    onError: (e) => toast({ description: formatGraphQLErrors(e).message, status: 'error' }),
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });

  const handleGetNavigationPath = (row: Row<HomeData>) => generatePath(AppPaths.HOME_DETAILS, { id: row.original.id });

  useEffect(() => {
    refetch({
      offset: (currentPage - 1) * HOMES_PER_PAGE,
      limit: HOMES_PER_PAGE,
    });
  }, [currentPage, refetch]);

  if (error?.message && isEmpty(data)) return <PageError error={error} />;

  return (
    <Box>
      <HomeTable
        getNavigationPath={handleGetNavigationPath}
        isLoading={loading}
        homes={(data?.homes.homes as HomeFragment[]) || []}
      />
      {data?.homes && (
        <Pagination
          total={data.homes.total}
          current={currentPage}
          defaultPageSize={HOMES_PER_PAGE}
          onChange={(page) => setCurrentPage(page)}
        />
      )}
    </Box>
  );
}

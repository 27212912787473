import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { User, useSetProfileMutation } from '../../gql/generated';
import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import i18next from 'i18next';
import Button, { EButtonVariant } from '../common/Button';
import { formatGraphQLErrors } from '../../gql/apollo/helpers';

interface IChangeNameModalProps {
  isOpen: boolean;
  onClose: () => void;
  user: Pick<User, 'profile' | 'email' | 'isVerified' | 'role' | 'id'>;
}

const getSchema = () =>
  yup.object().shape({
    firstName: yup
      .string()
      .required(i18next.t('form_errors.field_required_error_message'))
      .max(35, i18next.t('form_errors.max_chars_error', { count: 35 })),
    lastName: yup
      .string()
      .required(i18next.t('form_errors.field_required_error_message'))
      .max(35, i18next.t('form_errors.max_chars_error', { count: 35 })),
  });

const ChangeNameModal = ({ isOpen, onClose, user }: IChangeNameModalProps) => {
  const { t } = useTranslation();

  const initialValues = useMemo(
    () => ({ firstName: user?.profile?.firstName || '', lastName: user?.profile?.lastName || '' }),
    [user?.profile],
  );

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(getSchema()),
    defaultValues: { ...initialValues },
  });

  const toast = useToast();

  const [setUserProfile, { loading }] = useSetProfileMutation({
    onError: (error) => toast({ description: formatGraphQLErrors(error).message, status: 'error' }),
  });

  const handleOnClose = useCallback(() => {
    onClose();
    reset();
  }, [onClose, reset]);

  const onSubmit = handleSubmit((data) => {
    if (data.firstName && data.lastName)
      setUserProfile({
        variables: {
          input: { firstName: data.firstName.trim(), lastName: data.lastName.trim(), phone: user.profile?.phone },
        },
        onCompleted: (data) => {
          handleOnClose();
          reset({ lastName: data.setProfile.profile?.lastName, firstName: data.setProfile.profile?.firstName });
          toast({ description: t('change_name_modal.success_message'), status: 'success' });
        },
      });
  });
  return (
    <Modal isOpen={isOpen} onClose={handleOnClose}>
      <ModalOverlay />
      <ModalContent maxWidth='40%'>
        <ModalHeader>{t('change_name_modal.title')}</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={onSubmit}>
          <ModalBody>
            <FormControl isInvalid={!!errors.firstName}>
              <FormLabel>{t('change_name_modal.first_name_label')}</FormLabel>
              <Input
                placeholder={t('change_name_modal.first_name_placeholder')}
                {...register('firstName')}
                focusBorderColor='primary.main'
                pr='60px'
              />
              <FormErrorMessage>{errors.firstName?.message}</FormErrorMessage>
            </FormControl>
            <FormControl pt='7' isInvalid={!!errors.lastName}>
              <FormLabel>{t('change_name_modal.last_name_label')}</FormLabel>
              <Input
                placeholder={t('change_name_modal.last_name_placeholder')}
                {...register('lastName')}
                focusBorderColor='primary.main'
                pr='60px'
              />
              <FormErrorMessage>{errors.lastName?.message}</FormErrorMessage>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button mr={2} styleVariant={EButtonVariant.gray} onClick={handleOnClose} variant='solid'>
              {t('cancel')}
            </Button>
            <Button isDisabled={!isValid} isLoading={loading} type='submit' styleVariant={EButtonVariant.default}>
              {t('save')}
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default ChangeNameModal;

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  useToast,
} from '@chakra-ui/react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useChangePasswordMutation } from '../../gql/generated';
import { useCallback } from 'react';
import PasswordFormControl from '../common/form/PasswordFormControl';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { passwordRegex } from '../../constants/passwordRequirements';
import Button, { EButtonVariant } from '../common/Button';
import { formatGraphQLErrors } from '../../gql/apollo/helpers';
import i18next from 'i18next';

interface ISetPasswordModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const getSchema = () =>
  yup.object().shape({
    currentPassword: yup.string().required(i18next.t('form_errors.field_required_error_message')),
    newPassword: yup
      .string()
      .label(i18next.t('password'))
      .matches(passwordRegex, i18next.t('password_regex'))
      .notOneOf([yup.ref('currentPassword'), null], i18next.t('change_password_modal.same_passwords_error'))
      .required(i18next.t('form_errors.field_required_error_message')),
  });

const ChangePasswordModal = ({ isOpen, onClose }: ISetPasswordModalProps) => {
  const { t } = useTranslation();

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(getSchema()),
  });

  const handleOnClose = useCallback(() => {
    onClose();
    reset();
  }, [onClose, reset]);
  const toast = useToast();

  const [changePassword, { loading }] = useChangePasswordMutation({
    onError: (error) => toast({ description: formatGraphQLErrors(error).message, status: 'error' }),
  });

  const onSubmit = handleSubmit((values) => {
    changePassword({
      variables: { input: values },
      onCompleted: () => {
        handleOnClose();
        toast({ description: t('change_password_modal.success_message'), status: 'success' });
      },
    });
  });
  return (
    <Modal isOpen={isOpen} onClose={handleOnClose}>
      <ModalOverlay />
      <ModalContent maxWidth='40%'>
        <ModalHeader>{t('change_password_modal.title')}</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={onSubmit}>
          <ModalBody>
            <PasswordFormControl
              isInvalid={!!errors.currentPassword}
              errorMessage={errors.currentPassword?.message}
              label={t('change_password_modal.current_password_label')}
              register={() => register('currentPassword')}
              placeholder={t('change_password_modal.current_password_placeholder')}
            />
            <Spacer h='5' />
            <PasswordFormControl
              isInvalid={!!errors.newPassword}
              errorMessage={errors.newPassword?.message}
              label={t('change_password_modal.new_password_label')}
              register={() => register('newPassword')}
              placeholder={t('change_password_modal.new_password_placeholder')}
            />
          </ModalBody>
          <ModalFooter>
            <Button mr={2} styleVariant={EButtonVariant.gray} onClick={handleOnClose} variant='solid'>
              {t('cancel')}
            </Button>
            <Button isDisabled={!isValid} isLoading={loading} type='submit' styleVariant={EButtonVariant.default}>
              {t('save')}
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default ChangePasswordModal;

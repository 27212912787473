import { Box, Flex, Text, Tooltip, WrapItem } from '@chakra-ui/react';
import pluralize from 'pluralize';
import { useTranslation } from 'react-i18next';
import { User } from '../../gql/generated';
import { Info } from '../../icons';
import UserInfo from '../users/UserInfo';

interface IHomeMateColumnProps {
  homemates: Pick<User, 'id' | 'profile' | 'email' | 'isVerified'>[];
}

const HomeMateColumn = ({ homemates }: IHomeMateColumnProps) => {
  const { t } = useTranslation();
  if (!homemates.length) {
    return (
      <Text color='primary.main' fontWeight='semibold'>
        N/A
      </Text>
    );
  }

  if (homemates.length === 1) {
    return <UserInfo {...homemates[0]} />;
  }

  return (
    <Flex gap={2}>
      <Text>{pluralize(t('user'), homemates.length, true)}</Text>
      <WrapItem>
        <Tooltip
          label={homemates.map(({ profile, email }) => (
            <Text key={email}>{profile ? `${profile.firstName} ${profile.lastName}` : email}</Text>
          ))}
          placement='bottom'
          arrowSize={15}
          hasArrow
          width='fit-content'
        >
          <Box>
            <Info fill='gray.300' />
          </Box>
        </Tooltip>
      </WrapItem>
    </Flex>
  );
};

export default HomeMateColumn;

import { useToast } from '@chakra-ui/react';
import useCurrentUser from '../../../hooks/auth/useCurrentUser';
import useVerifyEmail from '../../../hooks/auth/useVerifyEmail';
import CodeForm from './CodeForm';
import { useSendVerificationCodeMutation } from '../../../gql/generated';

export default function OtpVerifyEmailForm() {
  const [user] = useCurrentUser();
  const [verify, { loading }] = useVerifyEmail();
  const toast = useToast();
  const handleError = (e: Error) => {
    toast({
      description: e.message,
      status: 'error',
    });
  };
  const [sendVerificationCode, { loading: resendLoading }] = useSendVerificationCodeMutation({
    onError: handleError,
  });

  const onSubmit = async ({ code }: { code: string }) => {
    await verify({
      variables: {
        code,
      },
    });
  };

  const onResend = async () => {
    await sendVerificationCode();
  };

  return (
    <CodeForm
      email={user?.email || ''}
      onSubmit={onSubmit}
      onResend={onResend}
      isLoading={loading}
      isDisabled={resendLoading}
    />
  );
}

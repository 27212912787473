import { Flex, Tooltip, WrapItem } from '@chakra-ui/react';
import { Eye } from '../../../icons';
import { useTranslation } from 'react-i18next';
import Button, { EButtonVariant } from '../../common/Button';
import { Link } from 'react-router-dom';

const ICON_SIZE = 5;

interface IDoItRequestsActionsProps {
  navigationPath: string;
}

const DoItRequestsActions = ({ navigationPath }: IDoItRequestsActionsProps) => {
  const { t } = useTranslation();

  return (
    <Flex>
      <Tooltip label={t('doit_requests_table.actions.view')}>
        <WrapItem>
          <Link to={navigationPath}>
            <Button styleVariant={EButtonVariant.ghost}>
              <Eye fill='gray.300' w={ICON_SIZE} h={ICON_SIZE} />
            </Button>
          </Link>
        </WrapItem>
      </Tooltip>
    </Flex>
  );
};

export default DoItRequestsActions;

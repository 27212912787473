import isEmpty from 'lodash/isEmpty';
import { generatePath, useParams } from 'react-router-dom';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHomeWithStatsQuery } from '../../../gql/generated';
import { AppPaths } from '../../../routes/paths';
import LoadingOverlay from '../../common/LoadingOverlay';
import ItemTabs from '../../itemDetails/ItemTabs';

const ZoneItemDetails = () => {
  const { t } = useTranslation();
  const { id = '', itemId = '', zoneId = '' } = useParams();

  const { data, loading } = useHomeWithStatsQuery({ variables: { homeId: id }, fetchPolicy: 'cache-and-network' });

  const tabs = useMemo(
    () => [
      { label: t('home_details.info'), path: generatePath(AppPaths.ZONE_ITEM_DETAILS_INFO, { id, zoneId, itemId }) },
      {
        label: t('home_details.maintenance_plan'),
        path: generatePath(AppPaths.ZONE_ITEM_DETAILS_MAINTENANCE_PLAN, { id, zoneId, itemId }),
      },
    ],
    [id, itemId, t, zoneId],
  );

  if (loading) return <LoadingOverlay />;
  if (isEmpty(data)) return null;
  return <ItemTabs tabs={tabs} itemDetailsPath={generatePath(AppPaths.ZONE_ITEM_DETAILS, { id, zoneId, itemId })} />;
};

export default ZoneItemDetails;

import { AspectRatio, Box, Flex, IconButton, Image, SimpleGrid, Spinner } from '@chakra-ui/react';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import Carousel from './Carousel';
import ImageCard from './ImageCard';
import UploadingFile from '../../../../classes/UploadingFile';
import { Cross } from '../../../../icons';

type Image = {
  id: string;
  url: string;
  isMain?: boolean;
};

type Props = {
  images: Image[];
  showCarrousel?: boolean;
  uploadingImages?: UploadingFile[];
  deleteImage?: (fileId: string) => Promise<void> | void;
  setMainImage?: (fileId: string) => Promise<void> | void;
  AddButton?: ReactNode;
};

export default function Gallery({
  images,
  uploadingImages,
  showCarrousel,
  setMainImage,
  deleteImage,
  AddButton,
}: Props) {
  const [selectedImage, setSelectedImage] = useState(0);
  const [selectedMainImage, setSelectedMainImage] = useState(images.find((i) => i.isMain)?.id);

  const onSelectMainImage = useCallback(
    (fileId: string) => {
      setMainImage?.(fileId);
      setSelectedMainImage(fileId);
    },
    [setMainImage],
  );
  useEffect(() => setSelectedImage(0), [images]);

  return (
    <Flex direction='column' gap={4}>
      {showCarrousel && (
        <Carousel
          images={images}
          mainImage={selectedMainImage}
          selectedItem={selectedImage}
          onChange={(ind) => setSelectedImage(ind)}
          {...(setMainImage && { onSetMainImage: onSelectMainImage })}
        />
      )}
      <SimpleGrid
        columns={{
          base: 1,
          sm: 2,
          md: 3,
          lg: 4,
          xl: 5,
        }}
        gap={3}
      >
        {AddButton}
        {uploadingImages?.map((upload) => (
          <Box position='relative' key={upload.file.name}>
            <AspectRatio ratio={1}>
              <Image src={URL.createObjectURL(upload.file)} />
            </AspectRatio>
            <Spinner
              size='xl'
              colorScheme='orange'
              position='absolute'
              top='0'
              bottom='0'
              left='0'
              right='0'
              margin='auto'
              color='gray.50'
            />
            <IconButton
              position='absolute'
              top='0'
              bottom='0'
              left='0'
              right='0'
              margin='auto'
              variant='ghost'
              aria-label='cancel'
              size='lg'
              rounded='full'
              width='min-content'
              bg='rgba(0,0,0,0.3)'
              _hover={{
                bg: 'rgba(0,0,0,0.5)',
              }}
              display={upload.isCancelable() ? 'block' : 'none'}
              onClick={upload.cancel}
              fill='gray.300'
              icon={<Cross />}
            />
          </Box>
        ))}
        {images.map(({ id, url }, ind) => (
          <ImageCard
            key={id}
            isClickable={showCarrousel}
            id={id}
            url={url}
            isSelected={!!showCarrousel && selectedImage === ind}
            onClick={() => setSelectedImage(ind)}
            isMain={selectedMainImage === id}
            onSetMainImage={setMainImage && !showCarrousel ? () => onSelectMainImage(id) : undefined}
            onDelete={deleteImage ? () => deleteImage?.(id) : undefined}
          />
        ))}
      </SimpleGrid>
    </Flex>
  );
}

import RcPagination, { PaginationProps } from 'rc-pagination';
import { AngleSmallLeft, AngleSmallRight } from '../../icons';
import { Button } from '@chakra-ui/react';

type Props = PaginationProps & {
  current: number;
  defaultPageSize: number;
  total: number;
};

export default function Pagination({ current, total, defaultPageSize, ...rest }: Props) {
  const hasMore = current * defaultPageSize < total;
  return (
    <RcPagination
      locale={{
        prev_page: '',
        next_page: '',
        prev_5: '',
        next_5: '',
        prev_3: '',
        next_3: '',
      }}
      total={total}
      current={current}
      defaultPageSize={defaultPageSize}
      showLessItems
      hideOnSinglePage
      prevIcon={<AngleSmallLeft />}
      nextIcon={<AngleSmallRight />}
      style={{
        display: 'flex',
        gap: '8px',
        paddingTop: '12px',
        margin: '0 auto',
        width: 'fit-content',
        listStyleType: 'none',
      }}
      itemRender={(page, type, element) => (
        <Button
          aria-label='type'
          size='xs'
          bgColor='gray.100'
          borderRadius='lg'
          shadow='lg'
          isDisabled={(type === 'prev' && page === 0) || (type === 'next' && !hasMore)}
          isActive={page === current && type === 'page'}
          _disabled={{
            bgColor: 'gray.100',
            color: 'gray.400',
            cursor: 'not-allowed',
          }}
          _active={{
            bgColor: '#002859',
            color: 'white',
          }}
        >
          {type === 'page' ? page : type === 'jump-next' || type === 'jump-prev' ? '...' : element}
        </Button>
      )}
      {...rest}
    />
  );
}

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  useBoolean,
} from '@chakra-ui/react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Eye, EyeCrossed } from '../../../icons';
import Button, { EButtonVariant } from '../Button';

type Props = {
  register: () => UseFormRegisterReturn<string>;
  errorMessage?: string;
  isDisabled?: boolean;
  isInvalid?: boolean;
  label?: string;
  placeholder?: string;
};

export default function PasswordFormControl({
  isInvalid,
  isDisabled,
  register,
  errorMessage,
  label,
  placeholder,
}: Props) {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useBoolean(false);

  return (
    <FormControl isInvalid={isInvalid}>
      <FormLabel>{label || t('password')}</FormLabel>
      <InputGroup>
        <Input
          type={showPassword ? 'text' : 'password'}
          placeholder={placeholder || t('password_placeholder')}
          {...register()}
          disabled={isDisabled}
          focusBorderColor='primary.main'
          pr='60px'
        />
        <InputRightElement mr='5px'>
          <Button styleVariant={EButtonVariant.grayOutline} rounded='full' h='30px' onClick={setShowPassword.toggle}>
            {showPassword ? <EyeCrossed /> : <Eye />}
          </Button>
        </InputRightElement>
      </InputGroup>
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
}

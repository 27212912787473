import { TokenRefreshLink } from 'apollo-link-token-refresh';
import { afterLogout } from '../../../hooks/auth/useLogout';
import client from '../client';
import RefreshTokenService from '../../../services/tokens/RefreshTokenService';
import { LogoutDocument } from '../../generated';

const createRefreshTokenLink = new TokenRefreshLink({
  accessTokenField: 'accessToken',

  isTokenValidOrUndefined: async () => {
    return RefreshTokenService.isTokenValidOrUndefined();
  },

  fetchAccessToken: async () => {
    console.debug('Fetching new access token');
    return RefreshTokenService.fetchToken();
  },

  handleFetch: () => {
    console.debug('Refreshed the access token');
  },

  handleResponse: () => async (response: Response) => {
    console.debug('Parsing server response');
    return RefreshTokenService.handleFetchTokenResponse(response);
  },
  
  handleError: () => {
    client
      .mutate({
        mutation: LogoutDocument,
      })
      .catch((e) => console.log('Error while logout', e))
      .finally(() => {
        afterLogout(client);
      });
  },
});

export default createRefreshTokenLink;

import { useCallback, useState } from 'react';
import {
  Divider,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalFooter,
  VStack,
  Box,
  Text,
  HStack,
  Tag,
  TagLabel,
  TagCloseButton,
  ModalBody,
  Flex,
  Spinner,
} from '@chakra-ui/react';
import uniqueId from 'lodash/uniqueId';
import concat from 'lodash/concat';
import { useTranslation } from 'react-i18next';
import Button, { EButtonVariant } from '../../common/Button';
import { Check } from '../../../icons';
import {
  HomeWithStatsDocument,
  ItemTemplate,
  ZoneFragment,
  ZonesDocument,
  ZonesQuery,
  useAddItemsMutation,
  useItemTemplatesQuery,
} from '../../../gql/generated';
import { useParams } from 'react-router-dom';
import pluralize from 'pluralize';
import { ZONES_PER_PAGE } from '.';

interface IAddInventoryItemsModal {
  isOpen: boolean;
  onClose: () => void;
  zoneName: string;
  zoneId: string;
  zoneTemplateId: string;
  items: { id: string; name: string; itemTemplateId: string }[];
}

export interface IItemProps {
  id: string;
  name: string;
  itemTemplateId: string;
}

const getIsAvailableTemplate = (
  items: IAddInventoryItemsModal['items'],
  selectedItems: IItemProps[],
  template: ItemTemplate,
) => {
  const general = concat(items, selectedItems);
  const filteredItems = general.filter((item) => item.itemTemplateId === template.id);
  const isAvailable = !(filteredItems.length >= template.maxQuantity);
  return isAvailable;
};

const AddInventoryItemsModal = ({
  items,
  zoneName,
  zoneTemplateId,
  zoneId,
  isOpen,
  onClose,
}: IAddInventoryItemsModal) => {
  const { id: homeId } = useParams();
  const { t } = useTranslation();
  const [newItems, setNewItems] = useState<IItemProps[]>([]);
  const { data, loading: loadingTemplates } = useItemTemplatesQuery({
    variables: { zoneTemplateId },
    fetchPolicy: 'cache-and-network',
    skip: !isOpen,
  });
  const [addItems, { loading: loadingMutation }] = useAddItemsMutation();

  const handleOnClose = useCallback(() => {
    setNewItems([]);
    onClose();
  }, [onClose]);
  const handleOnAddItem = useCallback(
    (itemTemplate: ItemTemplate) => {
      const countItems =
        newItems.concat(items || []).filter((i) => i.itemTemplateId === itemTemplate.id).length + 1 || 1;
      const name = itemTemplate.maxQuantity === 1 ? itemTemplate.name : `${itemTemplate.name} #${countItems}`;
      const newItem = {
        id: uniqueId(itemTemplate.id),
        itemTemplateId: itemTemplate.id,
        name,
      };
      setNewItems((prevState) => concat(prevState, newItem));
    },
    [items, newItems],
  );

  const handleSubmit = useCallback(() => {
    const selectedItems = newItems.map((item) => ({ itemTemplateId: item.itemTemplateId, name: item.name }));
    if (!homeId) return null;
    const addItemsInput = {
      zoneId,
      items: selectedItems,
    };
    addItems({
      variables: { input: addItemsInput },
      update(cache, { data }) {
        const queryParams = {
          query: ZonesDocument,
          variables: { homeId, isArchivedZone: false, isArchivedItem: false, limit: ZONES_PER_PAGE },
        };
        const queryData = cache.readQuery(queryParams) as ZonesQuery;
        const result = queryData?.zones?.zones?.map((zone: ZoneFragment) => {
          const newItems = data?.addItems.map(({ id, name, itemTemplateId }) => ({ id, name, itemTemplateId }));
          const totalItems = concat(zone.items.items, newItems);
          if (zone.id === zoneId) return { ...zone, items: { total: totalItems.length, items: totalItems } };
          return zone;
        });
        cache.writeQuery({
          ...queryParams,
          data: { ...queryData, zones: { ...queryData.zones, zones: result } },
        });
      },
      refetchQueries: [{ query: HomeWithStatsDocument, variables: { homeId } }],
      onCompleted: handleOnClose,
    });
  }, [addItems, handleOnClose, homeId, newItems, zoneId]);
  const handleRemoveItem = useCallback((templateId: string) => {
    setNewItems((prev) => prev.filter(({ id }) => id !== templateId));
  }, []);

  if (!isOpen) return null;
  return (
    <Modal isOpen={isOpen} onClose={handleOnClose} size='lg' closeOnOverlayClick={false} scrollBehavior='inside'>
      <ModalOverlay bg='blackAlpha.300' />
      <ModalContent>
        <ModalHeader>{t('home_details.add_items_modal_title', { zone: zoneName })}</ModalHeader>
        <ModalCloseButton />
        <Divider />
        {loadingTemplates ? (
          <Flex h='25vh' alignItems='center' justifyContent='center'>
            <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='primary.main' size='xl' />
          </Flex>
        ) : (
          <ModalBody>
            <VStack gap='20px'>
              <Box>
                <Text fontSize='md' fontWeight='medium'>
                  {t('home_details.select_item')}
                </Text>
                <HStack flexWrap='wrap' mt='2'>
                  {data?.itemTemplates.map((template) => {
                    const isAvailable = getIsAvailableTemplate(items, newItems, template);
                    return (
                      <Tag
                        key={template.id}
                        size='lg'
                        variant='subtle'
                        colorScheme={isAvailable ? 'blue' : 'gray'}
                        borderRadius='16'
                        cursor={isAvailable ? 'pointer' : 'not-allowed'}
                        _hover={isAvailable ? { bg: 'blue.500', color: 'white' } : {}}
                        onClick={isAvailable ? () => handleOnAddItem(template) : undefined}
                      >
                        <TagLabel userSelect='none'>{template.name}</TagLabel>
                      </Tag>
                    );
                  })}
                </HStack>
              </Box>
              <Box width='full'>
                <Text fontSize='md' fontWeight='medium'>
                  {t(pluralize('home_details.added_item', newItems.length), { count: newItems.length })}
                </Text>
                <HStack flexWrap='wrap' mt='2'>
                  {newItems.map(({ id, name }) => (
                    <Tag key={id} size='lg' variant='outline' colorScheme='gray' borderRadius='16'>
                      <TagLabel>{name}</TagLabel>
                      {newItems.find((zone) => zone.id === id) && (
                        <TagCloseButton onClick={() => handleRemoveItem(id)} />
                      )}
                    </Tag>
                  ))}
                </HStack>
              </Box>
            </VStack>
          </ModalBody>
        )}
        <Divider />
        <ModalFooter>
          <Button styleVariant={EButtonVariant.gray} onClick={handleOnClose}>
            {t('add_zone_modal.close')}
          </Button>
          <Button
            ml={3}
            isLoading={loadingMutation}
            isDisabled={newItems.length === 0}
            onClick={handleSubmit}
            rightIcon={<Check fill='white' boxSize='3' stroke='white' strokeWidth={2} />}
          >
            {t('add_zone_modal.submit')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddInventoryItemsModal;

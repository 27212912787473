import {
  Box,
  CloseButton,
  Flex,
  Drawer,
  DrawerContent,
  useDisclosure,
  BoxProps,
  Image,
  Divider,
  HStack,
  Avatar,
  VStack,
  Text,
} from '@chakra-ui/react';
import SidebarItem from './SidebarItem';
import Header from './Header';
import logo from '../../../../assets/full-logo-primary.svg';
import { AppPaths } from '../../../../routes/paths';
import { ReactElement, useMemo } from 'react';
import useLogout from '../../../../hooks/auth/useLogout';
import { useTranslation } from 'react-i18next';
import useCurrentUser from '../../../../hooks/auth/useCurrentUser';
import { CommentUser, HomeLocation, IdBadge, ListCheck, Settings, SignOut, User } from '../../../../icons';
import { ERole } from '../../../../gql/generated';
import { NavLink, generatePath } from 'react-router-dom';

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  const { t } = useTranslation();
  const [user] = useCurrentUser();
  const logout = useLogout();

  const ADMIN_ROUTES = useMemo(
    () => [
      { name: t('sidebar.dwelink_users'), to: AppPaths.DWELINK_USERS, Icon: IdBadge },
      { name: t('sidebar.settings'), to: AppPaths.SETTINGS, Icon: Settings },
    ],
    [t],
  );

  const COMMON_ROUTES = useMemo(
    () => [
      { name: t('sidebar.customers'), to: AppPaths.CUSTOMERS, Icon: User },
      { name: t('sidebar.homes'), to: AppPaths.HOMES, Icon: HomeLocation },
      { name: t('sidebar.tasks'), to: AppPaths.TASKS, Icon: ListCheck },
      { name: t('sidebar.doit_requests'), to: AppPaths.DOIT_REQUESTS, Icon: CommentUser },
    ],
    [t],
  );
  const profilePath = useMemo(() => generatePath(AppPaths.USER_PROFILE, { userId: user?.id || '' }), [user?.id]);

  const avatarName = user?.profile ? `${user.profile.firstName} ${user.profile.lastName}` : user?.email;
  const firstName = user?.profile ? user.profile.firstName : user?.email;
  const lastName = user?.profile?.lastName;

  const isAdmin = user?.role === ERole.DWELINK_ADMIN;

  return (
    <Box transition='3s ease' w={{ base: 'full', md: '245px' }} pos='fixed' h='full' {...rest} p='4' bg='#f8f9fa'>
      <Flex h='80%' direction='column'>
        <Flex alignItems='center' p='4' justifyContent='space-between'>
          <Image src={logo} />
          <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
        </Flex>
        <Divider my='1' />
        {/* // COMMON ROUTES */}
        <Flex py='4' direction='column' gap='1'>
          {COMMON_ROUTES.map(({ name, to, Icon }) => (
            <SidebarItem onClick={onClose} key={name} Icon={Icon} name={name} to={to} />
          ))}
          <Divider my='1' display={{ base: 'none', md: 'flex' }} />
        </Flex>
        {/* // ADMIN ROUTES */}
        <Flex hidden={!isAdmin} py='4' direction='column' flex='1' justifyContent='flex-end' gap='1'>
          {ADMIN_ROUTES.map(({ name, to, Icon }) => (
            <SidebarItem onClick={onClose} key={name} Icon={Icon} name={name} to={to} />
          ))}
        </Flex>
      </Flex>
      <Divider my='1' display={{ base: 'none', md: 'flex' }} />
      <Box my='4'>
        {/* // PROFILE BUTTON */}
        <NavLink to={profilePath} style={{ textDecoration: 'none' }}>
          {({ isActive }) => (
            <HStack
              display={{ base: 'none', md: 'flex' }}
              px='4'
              py='3'
              _hover={{ bg: 'white', shadow: 'sm' }}
              borderRadius='2xl'
              cursor='pointer'
              alignSelf='flex-end'
              bg={isActive ? '#FFF' : 'transparent'}
            >
              <Flex width='100%' alignItems={'center'}>
                <HStack>
                  <Avatar size={'sm'} name={avatarName} backgroundColor='blue.300' color='black' />
                  <VStack alignItems='flex-start' spacing='1px' ml='2'>
                    <Text
                      maxW='140'
                      textOverflow='ellipsis'
                      whiteSpace='nowrap'
                      overflow='hidden'
                      fontSize='sm'
                      fontWeight='bold'
                    >
                      {firstName}
                    </Text>
                    <Text
                      maxW='140'
                      textOverflow='ellipsis'
                      whiteSpace='nowrap'
                      overflow='hidden'
                      fontSize='sm'
                      fontWeight='bold'
                    >
                      {lastName}
                    </Text>
                    <Text fontSize='xs' color='gray.600'>
                      {user?.role && t(`sidebar.user_roles.${user.role.toLowerCase()}`)}
                    </Text>
                  </VStack>
                </HStack>
              </Flex>
            </HStack>
          )}
        </NavLink>
        {/* // LOGOUT BUTTON */}
        <Flex
          fontSize='sm'
          fontWeight='bold'
          align='center'
          px='4'
          py='3'
          borderRadius='2xl'
          role='group'
          cursor='pointer'
          _hover={{
            shadow: 'sm',
            bg: '#FFF',
          }}
          display={{ base: 'none', md: 'flex' }}
          onClick={() => logout()}
        >
          <Box
            mr='4'
            w='32px'
            h='32px'
            borderRadius='full'
            display='flex'
            justifyContent='center'
            alignItems='center'
            bg='white'
            fill='primary.main'
            _groupHover={{
              fill: 'white',
              bg: 'primary.main',
            }}
          >
            <SignOut />
          </Box>
          {t('logout')}
        </Flex>
      </Box>
    </Box>
  );
};

export default function Sidebar({ children }: { children: ReactElement }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box minH='100dvh' bg='#f8f9fa'>
      <SidebarContent onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
      <Drawer
        isOpen={isOpen}
        placement='left'
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size='full'
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      <Header onOpen={onOpen} />

      <Box ml={{ base: 0, md: 60 }} p='4'>
        {children}
      </Box>
    </Box>
  );
}

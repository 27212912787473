import { Box, Divider, Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { DoItRequestFragment } from '../../gql/generated';
import { useMemo } from 'react';
import format from 'date-fns/format';
import { getItemFromPreset } from '../../utils/helpers';
import { DO_IT_REQUEST_STATUS_PRESETS, TIMING_PRESETS } from '../../constants/presets';

const DIVIDER_MARGIN = 4;

interface IDoItDetailsInfoProps {
  doitRequest: DoItRequestFragment;
}

const DoItDetailsInfo = ({ doitRequest }: IDoItDetailsInfoProps) => {
  const { t } = useTranslation();

  const sentDate = useMemo(
    () => (doitRequest.sentDate ? format(new Date(doitRequest.sentDate), 'LLL dd, yyyy') : undefined),
    [doitRequest.sentDate],
  );
  const { color, title = '' } = getItemFromPreset(DO_IT_REQUEST_STATUS_PRESETS, doitRequest.status || '');
  const { title: timing = '' } = getItemFromPreset(TIMING_PRESETS, doitRequest.timing || '');

  return (
    <Box mr='5' p='5'>
      <Flex>
        <Text flex='1'>{t('doit_request_details.status')}</Text>
        <Text flex='1' color={color}>
          {t(title)}
        </Text>
      </Flex>
      <Divider my={DIVIDER_MARGIN} />
      <Flex>
        <Text flex='1'>{t('doit_request_details.urgency')}</Text>
        <Text flex='1'>{t(timing)}</Text>
      </Flex>
      <Divider my={DIVIDER_MARGIN} />
      <Flex>
        <Text flex='1'>{t('doit_request_details.sent_on')}</Text>
        <Text flex='1'>{sentDate}</Text>
      </Flex>
      <Divider my={DIVIDER_MARGIN} />
      <Flex flexDirection='column'>
        <Text flex='1' pb='2' fontWeight='700' color='gray.700'>
          {t('doit_request_details.description')}
        </Text>
        <Text flex='1'>{doitRequest.description}</Text>
      </Flex>
    </Box>
  );
};

export default DoItDetailsInfo;

import { useCallback, useState } from 'react';
import {
  Box,
  Divider,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Button as CButton,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import Button from '../../common/Button';
import { useUpdateZoneMutation } from '../../../gql/generated';
import TextInput from '../../common/TextInput';

interface IRenameModalProps {
  isOpen: boolean;
  onClose: () => void;
  zoneName: string;
  zoneId: string;
}

const RenameZoneModal = ({ zoneName, zoneId, isOpen, onClose }: IRenameModalProps) => {
  const { t } = useTranslation();
  const [newZoneName, setZoneName] = useState<string>(zoneName);
  const [updateZone, { loading, error }] = useUpdateZoneMutation();

  const handleClose = useCallback(() => {
    onClose();
    setZoneName('');
  }, [onClose]);
  const handleOnSave = useCallback(() => {
    updateZone({
      variables: { input: { id: zoneId, name: newZoneName } },
      onCompleted: handleClose,
    });
  }, [updateZone, zoneId, newZoneName, handleClose]);

  const disabledSubmitButton = newZoneName === zoneName || !newZoneName;

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size='lg' closeOnOverlayClick={false}>
      <ModalOverlay bg='blackAlpha.300' />
      <ModalContent>
        <ModalHeader>{t('home_details.rename_zone_modal_title')}</ModalHeader>
        <ModalCloseButton />
        <Divider />
        <Box my='5' mx='5'>
          <TextInput
            error={error?.message}
            maxLength={30}
            minLength={1}
            onChange={(event) => setZoneName(event.target.value)}
            defaultValue={zoneName}
            placeholder='Zone name'
            size='lg'
          />
          <Button isDisabled={disabledSubmitButton} onClick={handleOnSave} isLoading={loading} my='3' float='right'>
            {t('save')}
          </Button>
          <CButton onClick={handleClose} my='3' marginRight='2' float='right'>
            {t('cancel')}
          </CButton>
        </Box>
      </ModalContent>
    </Modal>
  );
};

export default RenameZoneModal;

import { useGoogleLogin } from '@react-oauth/google';
import { useTranslation } from 'react-i18next';
import { AuthPaths } from '../../routes/paths';
import { Google } from '../../icons/social-icons';
import Button, { EButtonVariant } from '../common/Button';

const getGoogleRedirectUri = () => {
  const currentLocation = window.location;
  const dynamicDomain = `${currentLocation.protocol}//${currentLocation.host}`;
  return `${dynamicDomain}${AuthPaths.GOOGLE_REDIRECT}`;
};

export default function GoogleButton() {
  const { t } = useTranslation();

  const login = useGoogleLogin({
    ux_mode: 'redirect',
    flow: 'auth-code',
    redirect_uri: getGoogleRedirectUri(),
  });

  return (
    <Button w='100%' styleVariant={EButtonVariant.grayOutline} onClick={() => login()} leftIcon={<Google />}>
      {t('google_continue')}
    </Button>
  );
}

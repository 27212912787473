import {
  Avatar,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { User, useSetUserPasswordMutation } from '../../gql/generated';
import UserInfo from './UserInfo';
import { useCallback, useMemo } from 'react';
import PasswordFormControl from '../common/form/PasswordFormControl';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import i18next from 'i18next';
import { passwordRegex } from '../../constants/passwordRequirements';
import Button, { EButtonVariant } from '../common/Button';
import { formatGraphQLErrors } from '../../gql/apollo/helpers';

interface ISetPasswordModalProps {
  isOpen: boolean;
  onClose: () => void;
  user: Pick<User, 'profile' | 'email' | 'isVerified' | 'role' | 'id'>;
  onCompleted?: ({ userId, password }: { userId: string; password: string }) => void;
}

const getSchema = () =>
  yup.object().shape({
    newPassword: yup
      .string()
      .label(i18next.t('password'))
      .matches(passwordRegex, i18next.t('password_regex'))
      .required()
      .label(i18next.t('field_required_error_message')),
  });

const SetPasswordModal = ({ isOpen, onClose, user, onCompleted }: ISetPasswordModalProps) => {
  const { t } = useTranslation();

  const name = useMemo(
    () => (user.profile ? `${user.profile.firstName} ${user.profile.lastName}` : 'N/A'),
    [user.profile],
  );
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(getSchema()),
  });

  const handleOnClose = useCallback(() => {
    onClose();
    reset();
  }, [onClose, reset]);

  const toast = useToast();

  const [setUserPassword, { loading }] = useSetUserPasswordMutation({
    onError: (error) => toast({ description: formatGraphQLErrors(error).message, status: 'error' }),
  });

  const onSubmit = handleSubmit((data) => {
    if (!data.newPassword) return;
    setUserPassword({
      variables: { input: { userId: user.id, password: data.newPassword } },
      onCompleted: () => {
        handleOnClose();
        toast({ description: t('set_password_modal.success_message'), status: 'success' });
        onCompleted?.({ userId: user.id, password: data.newPassword });
      },
    });
  });
  return (
    <Modal isOpen={isOpen} onClose={handleOnClose}>
      <ModalOverlay />
      <ModalContent maxWidth='45%'>
        <ModalHeader>{t('set_password_modal.title')}</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={onSubmit}>
          <ModalBody>
            <HStack gap='3' pb='5'>
              <Avatar size={'md'} name={name || ''} backgroundColor='blue.300' color='black' />
              <UserInfo {...user} />
            </HStack>
            <PasswordFormControl
              isInvalid={!!errors.newPassword}
              errorMessage={errors.newPassword?.message}
              label={t('set_password_modal.new_password')}
              register={() => register('newPassword')}
            />
          </ModalBody>
          <ModalFooter>
            <Button mr={2} styleVariant={EButtonVariant.gray} onClick={handleOnClose} variant='solid'>
              {t('cancel')}
            </Button>
            <Button isDisabled={!isValid} isLoading={loading} type='submit' styleVariant={EButtonVariant.default}>
              {t('save')}
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default SetPasswordModal;

import * as yup from 'yup';
// import { EJobPerformer } from '../../gql/generated';
import i18next from 'i18next';

const getTaskFormSchema = () =>
  yup.object().shape({
    name: yup
      .string()
      .max(100, i18next.t('form_errors.max_chars_error', { count: 100 }))
      .required(i18next.t('form_errors.field_required_error_message')),
    importance: yup.number().required(i18next.t('form_errors.field_required_error_message')),
    levelOfSkill: yup.number().required(i18next.t('form_errors.field_required_error_message')),
    jobPerformer: yup.string(),
    jobPerformerName: yup.string(),
    // .test('', i18next.t('field_required_error_message'), (_value, testContext) => {
    //   // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    //   if (testContext.parent.jobPerformer === EJobPerformer.THIRD_PARTY && !_value) return false;
    //   else return true;
    // }),
    recommendedDate: yup
      .date()
      .min(new Date(), i18next.t('form_errors.date_later_today'))
      .required(i18next.t('form_errors.field_required_error_message')),
    scheduledDate: yup.date().nullable(),
    costEstimate: yup
      .number()
      .nullable()
      .transform((value: number) => (Number.isNaN(value) ? null : value))
      .max(10000000, i18next.t('form_errors.max_chars_error', { count: 10000000 }))
      .min(0, i18next.t('form_errors.min_chars_error', { count: 0 })),
    description: yup
      .string()
      .max(1000, i18next.t('form_errors.max_chars_error', { count: 1000 }))
      .required(i18next.t('form_errors.field_required_error_message')),
    riskOfNotDoing: yup
      .string()
      .max(300, i18next.t('form_errors.max_chars_error', { count: 300 }))
      .required(i18next.t('form_errors.field_required_error_message')),
    zone: yup.string(),
    inventoryItem: yup.string(),
  });

export default getTaskFormSchema;

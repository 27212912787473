import { EDoItRequestStatus, EDoItRequestTiming, EItemCondition, EJobPerformer } from '../gql/generated';
import { User, Dwelink, Briefcase } from '../icons';

export const JOB_PERFORMER_SELECT_OPTIONS = [
  {
    title: 'task_form.job_performer_options.dwelink',
    value: EJobPerformer.DWELINK,
    Icon: Dwelink,
  },
  {
    title: 'task_form.job_performer_options.homeowner',
    value: EJobPerformer.USER,
    Icon: User,
  },
  { title: 'task_form.job_performer_options.third_party', value: EJobPerformer.THIRD_PARTY, Icon: Briefcase },
];

export enum ECriticalityVariants {
  low = 'Low',
  medium = 'Medium',
  high = 'High',
}

export const CRITICALITY_SELECT_OPTIONS = [
  { title: 'task_form.criticality_options.low', value: 2, color: 'green.500' },
  { title: 'task_form.criticality_options.medium', value: 4, color: 'yellow.500' },
  { title: 'task_form.criticality_options.high', value: 6, color: 'red.500' },
];

export const CONDITION_SELECT_OPTIONS = [
  {
    title: 'home_details.inventory_table.condition_options.excellent',
    value: EItemCondition.EXCELLENT,
    color: 'green.500',
  },
  { title: 'home_details.inventory_table.condition_options.good', value: EItemCondition.GOOD, color: 'green.600' },
  { title: 'home_details.inventory_table.condition_options.normal', value: EItemCondition.NORMAL, color: 'yellow.500' },
  {
    title: 'home_details.inventory_table.condition_options.average',
    value: EItemCondition.AVERAGE,
    color: 'primary.main',
  },
  { title: 'home_details.inventory_table.condition_options.bad', value: EItemCondition.BAD, color: 'red.500' },
  {
    title: 'home_details.inventory_table.condition_options.very_bad',
    value: EItemCondition.VERY_BAD,
    color: 'red.600',
  },
];

export const LEVEL_OF_SKILL_SELECT_OPTIONS = [
  { title: 'task_form.level_of_skill_options.easy', value: 3 },
  { title: 'task_form.level_of_skill_options.average', value: 6 },
  { title: 'task_form.level_of_skill_options.hard', value: 10 },
];

export const DO_IT_REQUEST_STATUS_PRESETS = [
  {
    title: 'doit_request_details.doit_request_status.in_review',
    color: 'blue.200',
    key: EDoItRequestStatus.ACTIVE,
    translate: true,
  },
  {
    title: 'doit_request_details.doit_request_status.processed',
    color: 'primary.main',
    key: EDoItRequestStatus.FINISHED,
    translate: true,
  },
];

export const TIMING_PRESETS = [
  { title: 'doit_request_details.doit_request_timing.immediate', key: EDoItRequestTiming.IMMEDIATE },
  { title: 'doit_request_details.doit_request_timing.while_you_are_here', key: EDoItRequestTiming.WHILE_YOU_ARE_HERE },
  { title: 'doit_request_details.doit_request_timing.within_a_week', key: EDoItRequestTiming.WITHIN_WEEK },
  { title: 'doit_request_details.doit_request_timing.within_a_month', key: EDoItRequestTiming.WITHIN_MONTH },
];

export const FILTER_TASKS_BY_JOB_PERFORMER_SELECT = [
  'all',
  EJobPerformer.DWELINK,
  EJobPerformer.THIRD_PARTY,
  EJobPerformer.USER,
];

export const FILTER_DO_IT_REQUEST_BY_URGENCY_SELECT = [
  'all',
  EDoItRequestTiming.IMMEDIATE,
  EDoItRequestTiming.WHILE_YOU_ARE_HERE,
  EDoItRequestTiming.WITHIN_MONTH,
  EDoItRequestTiming.WITHIN_WEEK,
];

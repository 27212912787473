import AccessTokenService from './AccessTokenService';
import { BaseTokenService } from './BaseTokenService';

type ResponseType = {
  data?: {
    token: {
      accessToken: string;
      refreshToken?: string;
    };
  };
};

class RefreshTokenService extends BaseTokenService {
  constructor() {
    super('refreshToken');
  }

  isTokenValidOrUndefined = () => {
    const tokenExp = AccessTokenService.getTokenExp();
    const refreshToken = this.getToken();

    if (!tokenExp || !refreshToken) {
      return true;
    }

    const isExpired = tokenExp * 1000 < Date.now();
    return !isExpired;
  };

  fetchToken = async () => {
    return fetch('/graphql', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query: `
          mutation RefreshToken($refreshToken: String!) {
            token(refreshToken: $refreshToken) {
              accessToken
            }
          }
        `,
        variables: {
          refreshToken: this.getToken(),
        },
      }),
    });
  };

  handleFetchTokenResponse = async (response: Response) => {
    const refreshResponse = (await response.json()) as ResponseType;
    if (!refreshResponse?.data?.token?.accessToken) {
      throw Error('Failed to fetch fresh access token');
    }

    const { refreshToken, accessToken } = refreshResponse.data.token;

    AccessTokenService.setToken(accessToken);
    if (refreshToken) {
      this.setToken(refreshToken);
    }

    return { accessToken };
  };
}

export default new RefreshTokenService();

import { useCallback, useState } from 'react';
import {
  Divider,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Center,
  Spinner,
  Box,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import MultiSelect, { Option } from '../../common/MultiSelect';
import { ERole, HomeWithStatsDocument, User, useAssignHomematesMutation, useUsersQuery } from '../../../gql/generated';
import Button from '../../common/Button';

type TUser = Pick<User, 'id' | 'profile' | 'email' | 'isVerified' | 'role'>;

interface IAddHomeMateModal {
  isOpen: boolean;
  onClose: () => void;
  assignHomemates?: TUser[];
  ownerId: string;
}

const AddHomeMateModal = ({ ownerId, assignHomemates, isOpen, onClose }: IAddHomeMateModal) => {
  const { id } = useParams();
  const [selectedValue, setSelectedValue] = useState<Option[]>();
  const { data, loading } = useUsersQuery({
    variables: { filter: { role: [ERole.DWELINK_HOMEMATE, ERole.DWELINK_ADMIN] }, limit: 100 },
    fetchPolicy: 'cache-and-network',
    skip: !isOpen,
  });

  const filteredUsers = data?.users.users.filter(({ id }) => ownerId !== id);

  const [assignHomates, { loading: mutationLoading }] = useAssignHomematesMutation();

  const { t } = useTranslation();
  const onChange = useCallback((newValue: Option[]) => {
    setSelectedValue(newValue);
  }, []);

  const handleApply = useCallback(() => {
    const assignedHomemateIds = assignHomemates?.map((assignHomemate) => assignHomemate.id);
    const homemateIds = selectedValue
      ?.filter((value) => !assignedHomemateIds?.includes(value?.value))
      .map(({ value }) => value);

    if (!id || !homemateIds?.length) return;

    const input = { homeId: id, homemateIds: homemateIds };
    assignHomates({
      variables: { input },
      onCompleted: onClose,
      refetchQueries: [{ query: HomeWithStatsDocument, variables: { homeId: id } }],
    });
  }, [assignHomates, assignHomemates, id, selectedValue, onClose]);

  const handleOnClose = useCallback(() => {
    setSelectedValue([]);
    onClose();
  }, [onClose]);
  const options =
    filteredUsers?.map(({ id, email, profile }) => ({
      value: id,
      label: `${profile?.firstName || ''} ${profile?.lastName || ''} (${email})`,
      isFixed: !!assignHomemates?.find((assignHomemate) => assignHomemate.id === id),
    })) || [];

  const defaultValue = assignHomemates?.map((assignHomemate) => ({
    value: assignHomemate.id,
    label: `${assignHomemate.profile?.firstName || ''} ${assignHomemate.profile?.lastName || ''} (${
      assignHomemate.email
    })`,
    isFixed: true,
  }));
  return (
    <Modal isOpen={isOpen} onClose={handleOnClose}>
      <ModalOverlay />
      <ModalContent maxWidth='45%'>
        <ModalHeader>{t('home_details.assign_home_mate_modal_title')}</ModalHeader>
        <ModalCloseButton />
        <Divider />
        <Box px='5' py='6'>
          {loading ? (
            <Center width='100%' left={0} top='200'>
              <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='primary.main' size='xl' />
            </Center>
          ) : (
            <MultiSelect onChange={onChange} options={options} defaultValue={defaultValue} />
          )}
          <Button
            isDisabled={!selectedValue?.length}
            isLoading={mutationLoading}
            onClick={handleApply}
            marginTop='5'
            float='right'
          >
            {t('assign')}
          </Button>
        </Box>
      </ModalContent>
    </Modal>
  );
};

export default AddHomeMateModal;

import { IconButton, Menu, MenuButton, MenuItem, MenuList, useDisclosure } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { MenuDots } from '../../icons';
import ChangeRoleModal from './ChangeRoleModal';
import { User } from '../../gql/generated';
import { useTranslation } from 'react-i18next';
import SetPasswordModal from './SetPasswordModal';
import { MouseEventHandler, useCallback } from 'react';

type Props = {
  user: Pick<User, 'id' | 'role' | 'profile' | 'email' | 'isVerified'>;
  withView?: boolean;
  navigationPath?: string;
};

export function UserActions({ user, navigationPath, withView }: Props) {
  const { t } = useTranslation();

  const {
    isOpen: isOpenChangeRoleModal,
    onOpen: onOpenChangeRoleModal,
    onClose: onCloseChangeRoleModal,
  } = useDisclosure();

  const {
    isOpen: isOpenSetPasswordModal,
    onOpen: onOpenSetPasswordModal,
    onClose: onCloseSetPasswordModal,
  } = useDisclosure();

  const onSetPassword: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.stopPropagation();
      onOpenSetPasswordModal();
    },
    [onOpenSetPasswordModal],
  );

  const onChangeRole: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.stopPropagation();
      onOpenChangeRoleModal();
    },
    [onOpenChangeRoleModal],
  );

  return (
    <>
      <SetPasswordModal user={user} isOpen={isOpenSetPasswordModal} onClose={onCloseSetPasswordModal} />
      <ChangeRoleModal isOpen={isOpenChangeRoleModal} onClose={onCloseChangeRoleModal} user={user} />
      <Menu strategy='fixed' placement='auto-start'>
        <MenuButton
          as={IconButton}
          onClick={(e) => e.stopPropagation()}
          variant='ghost'
          transform='rotate(90deg)'
          icon={<MenuDots fill='gray.400' />}
        />
        <MenuList>
          {withView && (
            <MenuItem as={Link} to={navigationPath}>
              {t('user_table.actions.view')}
            </MenuItem>
          )}
          <MenuItem onClick={onChangeRole}>{t('user_table.actions.change_role')}</MenuItem>
          <MenuItem onClick={onSetPassword}>{t('user_table.actions.set_password')}</MenuItem>
        </MenuList>
      </Menu>
    </>
  );
}

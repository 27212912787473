import { Box, Divider, Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import ActiveUsers from '../../components/dwelinkUsers/ActiveUsers';
import Invitations from '../../components/dwelinkUsers/Invitations';

const DwelinkUsers = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <Tabs position='relative' variant='unstyled' isLazy>
        <TabList>
          <Tab fontWeight='500' _selected={{ color: 'primary.main' }}>
            {t('dwelink_users.active_users')}
          </Tab>
          <Tab fontWeight='500' _selected={{ color: 'primary.main' }}>
            {t('dwelink_users.invitations')}
          </Tab>
        </TabList>
        <TabIndicator height='2px' bg='primary.main' borderRadius='1px' />
        <Divider my='0.5' />
        <TabPanels>
          <TabPanel px={0}>
            <ActiveUsers />
          </TabPanel>
          <TabPanel px={0}>
            <Invitations />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default DwelinkUsers;

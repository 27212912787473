import {
  Box,
  IconProps,
  Flex,
  PopoverContent,
  Popover,
  useDisclosure,
  Input,
  Spinner,
  PopoverTrigger,
  Text,
  FormControl,
  FormErrorMessage,
  FlexProps,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import Button, { EButtonVariant } from '../common/Button';
import { Check } from '../../icons';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { EJobPerformer } from '../../gql/generated';
import { JOB_PERFORMER_SELECT_OPTIONS } from '../../constants/presets';

interface ISelectJobPerformerPresetProps {
  Icon: (props: IconProps) => JSX.Element;
  title: string;
  value: EJobPerformer;
}

interface IFormSelectJobPerformerProps {
  placeholder: string;
  disabled?: boolean;
  label: string;
  containerProps?: FlexProps;
  onClose?: () => void;
  onSubmit?: (jobPerformer: EJobPerformer, jobPerformerName?: string) => void;
  loading?: boolean;
}

const ICON_SIZE = 5;

const SelectJobPerformer = (props: IFormSelectJobPerformerProps) => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const { placeholder, onSubmit, label, loading, disabled, containerProps } = props;
  const [selectedItem, setSelectedItem] = React.useState<ISelectJobPerformerPresetProps>();
  const { onOpen, onClose: closePopover, isOpen } = useDisclosure();
  const { t } = useTranslation();

  const { setValue, getValues, register, trigger, formState } = useFormContext();

  const onSave = React.useCallback(() => {
    setSubmitLoading(true);
    if (!selectedItem?.value) return;
    setValue('jobPerformer', selectedItem.value);
    if (selectedItem?.value !== EJobPerformer.DWELINK) setValue('scheduledDate', null);
    trigger();

    const jobPerformerName =
      selectedItem?.value === EJobPerformer.THIRD_PARTY ? (getValues('jobPerformerName') as string) || ' ' : undefined;

    closePopover();
    onSubmit?.(selectedItem?.value, jobPerformerName);
    setSubmitLoading(false);
  }, [getValues, selectedItem?.value, setValue, trigger, closePopover, onSubmit]);

  const jobPerformerInputVisible = selectedItem?.value === EJobPerformer.THIRD_PARTY;
  const jobPerformerNameVisible = getValues('jobPerformer') === EJobPerformer.THIRD_PARTY;

  const title = JOB_PERFORMER_SELECT_OPTIONS.find((i) => i.value === getValues('jobPerformer'))?.title;

  const isDisabledSubmitButton = !selectedItem && !!formState.errors.jobPerformerName;

  return (
    <Popover
      onOpen={() => setSelectedItem(undefined)}
      isOpen={isOpen}
      onClose={closePopover}
      strategy='fixed'
      placement='auto-start'
    >
      <Flex {...containerProps} alignItems='center' py='2' flexDirection='row'>
        <Text width='40%' fontWeight='500'>
          {t(label)}
        </Text>
        <Flex flex='1' pl='7' alignItems='center' justifyContent='space-between'>
          <Box>
            {t(title || placeholder)}
            <Box pt='2' hidden={!jobPerformerNameVisible}>
              {getValues('jobPerformerName')}
            </Box>
          </Box>
          <PopoverContent py='2' px='4'>
            {JOB_PERFORMER_SELECT_OPTIONS.map((item, index) => (
              <Box
                key={index}
                fontWeight={item.title === selectedItem?.title ? '600' : '400'}
                onClick={() => setSelectedItem(item)}
                justifyContent='space-between'
                minH='48px'
                display='flex'
                cursor='pointer'
              >
                {t(item.title)}
                <Box pl='3'> {item.Icon && <item.Icon width={ICON_SIZE} height={ICON_SIZE} />}</Box>
              </Box>
            ))}
            <Flex hidden={!jobPerformerInputVisible}>
              <FormControl width='100%' isRequired isInvalid={!!formState.errors.jobPerformerName} mb='2'>
                <Input {...register('jobPerformerName')} flex={1} w='100%' />
                <FormErrorMessage>{formState.errors.jobPerformerName?.message as string}</FormErrorMessage>
              </FormControl>
            </Flex>
            <Flex justifyContent='flex-end'>
              <Button styleVariant={EButtonVariant.gray} onClick={closePopover}>
                {t('close')}
              </Button>
              <Button
                ml={3}
                isLoading={submitLoading}
                isDisabled={isDisabledSubmitButton}
                onClick={onSave}
                rightIcon={<Check fill='white' boxSize='3' stroke='white' strokeWidth={2} />}
              >
                {t('save')}
              </Button>
            </Flex>
          </PopoverContent>
          <PopoverTrigger>
            <Box onClick={() => !disabled && onOpen()} p='2' hidden={disabled} cursor='pointer'>
              {loading ? (
                <Spinner thickness='1px' speed='0.65s' emptyColor='gray.200' color='gray.400' size='sm' />
              ) : (
                <IconCarbonEdit />
              )}
            </Box>
          </PopoverTrigger>
        </Flex>
      </Flex>
    </Popover>
  );
};

export default SelectJobPerformer;

import { CellContext, Row, createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { ItemFragment } from '../../../gql/generated';
import { DataTable } from '../../common/DataTable';
import { MouseEvent, useCallback, useMemo } from 'react';
import conditionVariants from '../../../constants/conditions';
import { Flex, Text } from '@chakra-ui/react';
import InventoryItemActions from './InventoryItemActions';
import { generatePath, useNavigate } from 'react-router-dom';
import { AppPaths } from '../../../routes/paths';

export type InventoryItemsData = ItemFragment & {
  dwelinkTasks: number;
  totalTasks: number;
};

interface InventoryItemsTable {
  items?: ItemFragment[];
  isLoading?: boolean;
  archiveCb?: () => void;
  getNavigationPath?: (row: Row<ItemFragment>) => string;
  getTaskCountNavigationPath?: (info: CellContext<InventoryItemsData, number>) => string;
}

const columnHelper = createColumnHelper<InventoryItemsData>();

const InventoryItemsTable = ({
  items,
  isLoading,
  getNavigationPath,
  getTaskCountNavigationPath,
  archiveCb,
}: InventoryItemsTable) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleOnClickCountOfTasks = useCallback(
    (
      e: MouseEvent<HTMLParagraphElement, globalThis.MouseEvent>,
      info: CellContext<InventoryItemsData, number>,
      showDwelinkTasks?: boolean,
    ) => {
      if (getTaskCountNavigationPath) {
        e.stopPropagation();
        navigate(getTaskCountNavigationPath(info), { state: { showDwelinkTasks } });
      }
    },
    [getTaskCountNavigationPath, navigate],
  );

  const handlerOnClickRow = useCallback(
    (row: Row<ItemFragment>) => getNavigationPath && navigate(getNavigationPath(row)),
    [getNavigationPath, navigate],
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        cell: (info) => info.getValue(),
        header: t('home_details.inventory_table.item_name'),
      }),
      columnHelper.accessor('zone', {
        cell: (info) => {
          const zone = info.getValue();
          return zone.name;
        },
        header: t('home_details.inventory_table.related_zone'),
      }),
      columnHelper.accessor('condition', {
        cell: (info) => {
          const condition = info.getValue();
          if (!condition) return <Text color='gray.300'>UNDEFINED</Text>;
          const { color } = conditionVariants[condition];
          return <Text color={color}>{condition}</Text>;
        },
        header: t('home_details.inventory_table.condition'),
      }),
      columnHelper.accessor('totalTasks', {
        cell: (info) => (
          <Flex>
            <Text padding='10px' zIndex={1} onClick={(e) => handleOnClickCountOfTasks(e, info)}>
              {info.getValue()}
            </Text>
          </Flex>
        ),
        header: t('home_details.inventory_table.total_tasks'),
      }),
      columnHelper.accessor('dwelinkTasks', {
        cell: (info) => (
          <Flex>
            <Text padding='10px' zIndex={1} onClick={(e) => handleOnClickCountOfTasks(e, info, true)}>
              {info.getValue()}
            </Text>
          </Flex>
        ),
        header: t('home_details.inventory_table.dwelink_tasks'),
      }),
      columnHelper.accessor('id', {
        cell: (info) => {
          const path = generatePath(AppPaths.ITEM_DETAILS, {
            itemId: info.getValue(),
            id: info.row.original.homeId,
          });
          return <InventoryItemActions navigationPath={path} item={info.row.original} archiveCb={archiveCb} />;
        },
        header: '',
      }),
    ],
    [archiveCb, handleOnClickCountOfTasks, t],
  );
  const tableData = (items || []).map((item) => {
    const [totalTasks, dwelinkTasks] = item.taskCount;
    return { ...item, dwelinkTasks, totalTasks };
  }, []);

  return (
    <DataTable
      columns={columns}
      data={tableData}
      {...(getNavigationPath && { onClickRow: handlerOnClickRow })}
      loading={isLoading}
      boxProps={{ minHeight: 550 }}
      emptyTitle={t('home_details.inventory_table.empty_inventory_items_table_title')}
      emptyDescription={t('home_details.inventory_table.empty_inventory_items_table_description')}
    />
  );
};

export default InventoryItemsTable;

import { Box, Flex, Link } from '@chakra-ui/react';
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import { Row, createColumnHelper } from '@tanstack/react-table';
import { useCallback, useMemo } from 'react';
import { DataTable } from '../../common/DataTable';
import { Location, User } from '../../../gql/generated';
import { useTranslation } from 'react-i18next';
import TaskActions from './TaskActions';
import TableTooltipLabel from '../../common/TableTooltipLabel';
import { useNavigate } from 'react-router-dom';
import generateGooglePlaceUrl from '../../../utils/generateGooglePlaceUrl';
import UserInfo from '../../users/UserInfo';

type UserInfo = Pick<User, 'id' | 'profile' | 'email' | 'isVerified'>;

export type TaskData = {
  name: string;
  item: string;
  owner: UserInfo;
  relatedHome?: Location;
  zone: string;
  recommendedDate: string;
  jobPerformer: string;
  id: string;
  homeId?: string;
};

const columnHelper = createColumnHelper<TaskData>();

type Props = {
  isLoading?: boolean;
  tasks: TaskData[];
  withHomeInfo?: boolean;
  disableEdit?: boolean;
  getNavigationPath?: (row: Row<TaskData>) => string;
};

export default function TaskTable({ getNavigationPath, withHomeInfo, tasks, isLoading, disableEdit }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        cell: (info) => <TableTooltipLabel text={info.getValue()} />,
        header: t('task_table.header.name'),
      }),
      columnHelper.accessor('item', {
        cell: (info) => info.getValue(),
        header: t('task_table.header.item'),
      }),
      columnHelper.accessor('recommendedDate', {
        cell: (info) => {
          const date = info.getValue().split('T')[0];
          return formatInTimeZone(date, 'UTC', 'LLLL yyyy');
        },
        header: t('task_table.header.recommended_date'),
      }),
      ...(withHomeInfo
        ? [
            columnHelper.accessor('owner', {
              cell: (info) => <UserInfo {...info.getValue()} />,
              header: t('home_table.header.owner'),
            }),
            columnHelper.accessor('relatedHome', {
              cell: (info) => {
                const location = info.getValue();
                const value = `${String(location?.street1)} ${String(location?.city)}, ${String(location?.state)}`;
                const placeUrl = location && generateGooglePlaceUrl(location);
                return (
                  <Box maxW='280'>
                    <Link href={placeUrl} isExternal>
                      <TableTooltipLabel text={value} maxW='280' />
                    </Link>
                  </Box>
                );
              },
              header: t('task_table.header.related_home'),
            }),
          ]
        : []),
      ...(withHomeInfo
        ? []
        : [
            columnHelper.accessor('zone', {
              cell: (info) => info.getValue(),
              header: t('task_table.header.zone'),
            }),
          ]),
      columnHelper.accessor('jobPerformer', {
        cell: (info) => {
          const title = t(`job_performer.${info.getValue().toLowerCase()}`);
          return title;
        },
        header: t('task_table.header.job_performer'),
      }),
      columnHelper.accessor('id', {
        cell: (info) =>
          disableEdit ? null : (
            <Flex justifyContent='center'>
              <TaskActions navigationPath={getNavigationPath?.(info.row) || ''} taskId={info.getValue()} />
            </Flex>
          ),
        header: () => null,
      }),
    ],
    [disableEdit, getNavigationPath, t, withHomeInfo],
  );

  const handlerOnClickRow = useCallback(
    (row: Row<TaskData>) => getNavigationPath && navigate(getNavigationPath(row)),
    [getNavigationPath, navigate],
  );

  return (
    <DataTable
      columns={columns}
      data={tasks}
      boxProps={{ minHeight: 650 }}
      {...(getNavigationPath && { onClickRow: handlerOnClickRow })}
      loading={isLoading}
      emptyTitle={t('task_table.empty.title')}
      emptyDescription={t('task_table.empty.description')}
    />
  );
}

type ObjectEntry<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T];

export function filterObject<T extends object>(
  obj: T,
  fn: (entry: ObjectEntry<T>, i: number, arr: ObjectEntry<T>[]) => boolean,
) {
  return Object.fromEntries((Object.entries(obj) as ObjectEntry<T>[]).filter(fn)) as T;
}

export function mapObject<T extends object>(
  obj: T,
  fn: (entry: ObjectEntry<T>, i: number, arr: ObjectEntry<T>[]) => unknown[],
) {
  return Object.fromEntries((Object.entries(obj) as ObjectEntry<T>[]).map(fn)) as T;
}

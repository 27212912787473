import { Modal, ModalCloseButton, ModalContent, ModalOverlay } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { User } from '../../gql/generated';
import { useCallback } from 'react';
import AuthCard from '../auth/AuthCard';
import { useNavigate } from 'react-router-dom';
import SetPasswordSteps from '../auth/SetPasswordSteps';

interface ISetPasswordModalProps {
  isOpen: boolean;
  onClose: () => void;
  user: Pick<User, 'profile' | 'email' | 'isVerified' | 'role' | 'id'>;
}

const CreatePasswordModal = ({ isOpen, onClose, user }: ISetPasswordModalProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleOnClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const onSuccess = () => {
    handleOnClose();
    navigate(0);
  };

  return (
    <Modal isOpen={isOpen} onClose={handleOnClose} isCentered>
      <ModalOverlay />
      <ModalContent maxWidth='40%'>
        <AuthCard title={t('set_password_header')}>
          <ModalCloseButton />
          <SetPasswordSteps
            skipEmailStep
            cancelButtonTitle={t('close')}
            onCancel={handleOnClose}
            onLastStepSuccess={onSuccess}
            initialEmail={user.email}
            hideLoginToast
          />
        </AuthCard>
      </ModalContent>
    </Modal>
  );
};

export default CreatePasswordModal;

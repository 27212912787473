import { Text, VStack } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { DataTable } from '../../common/DataTable';
import { ZoneActions } from './ZoneActions';
import { useTranslation } from 'react-i18next';
import { ZoneFragment, ZoneTemplateFragment, ZonesQuery } from '../../../gql/generated';
import { ApolloQueryResult } from '@apollo/client';
import { NavLink, generatePath, useParams } from 'react-router-dom';
import { AppPaths } from '../../../routes/paths';

export type ZoneData = {
  name: string;
  score?: number | null;
  id: string;
  totalTasks: number;
  dwelinkTasks: number;
  template: ZoneTemplateFragment;
  items: { total: number; items: { id: string; name: string; itemTemplateId: string }[] };
};

const columnHelper = createColumnHelper<ZoneData>();

export type TZoneTableProps = {
  isLoading?: boolean;
  archiveCb?: () => Promise<ApolloQueryResult<ZonesQuery>>;
  zones: ZoneFragment[];
};

export default function ZoneTable({ zones, isLoading, archiveCb }: TZoneTableProps) {
  const { t } = useTranslation();
  const { id: homeId = '' } = useParams();

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        cell: (info) => (
          <VStack maxW='250' align='flex-start'>
            <NavLink to={generatePath(AppPaths.ZONE_ITEMS, { id: homeId, zoneId: info.row.original.id })}>
              {info.getValue()}
            </NavLink>
          </VStack>
        ),
        header: t('zone_table.header.name'),
      }),
      columnHelper.accessor('score', {
        cell: (info) => {
          const score = info.getValue();
          return typeof score === 'number' ? (
            <Text>
              {score.toFixed(2)}
              {'%'}
            </Text>
          ) : (
            <Text color='primary.main' fontWeight='semibold'>
              N/A
            </Text>
          );
        },
        header: t('zone_table.header.score'),
      }),
      columnHelper.accessor('items', {
        cell: (info) => info.getValue().total,
        header: t('zone_table.header.number_of_items'),
      }),
      columnHelper.accessor('totalTasks', {
        cell: (info) => (
          <VStack maxW='250' align='flex-start'>
            <NavLink
              style={{ padding: 10 }}
              to={generatePath(AppPaths.ZONE_TASKS, { id: homeId, zoneId: info.row.original.id })}
            >
              {info.getValue()}
            </NavLink>
          </VStack>
        ),
        header: t('zone_table.header.total_tasks'),
      }),
      columnHelper.accessor('dwelinkTasks', {
        cell: (info) => (
          <VStack maxW='250' align='flex-start'>
            <NavLink
              style={{ padding: 10 }}
              to={generatePath(AppPaths.ZONE_TASKS, { id: homeId, zoneId: info.row.original.id })}
              state={{ showDwelinkTasks: true }}
            >
              {info.getValue()}
            </NavLink>
          </VStack>
        ),
        header: t('zone_table.header.dwelink_tasks'),
      }),

      columnHelper.accessor('id', {
        cell: (info) => <ZoneActions zone={info.row.original} archiveCb={archiveCb} />,
        header: () => null,
      }),
    ],
    [archiveCb, homeId, t],
  );

  const tableData = zones.map((zone) => {
    const [totalTasks, dwelinkTasks] = zone.taskCount;
    return { ...zone, totalTasks, dwelinkTasks };
  }, []);

  return (
    <DataTable
      columns={columns}
      data={tableData}
      loading={isLoading}
      boxProps={{ minHeight: 550 }}
      emptyTitle={t('zone_table.empty.title')}
      emptyDescription={t('zone_table.empty.description')}
    />
  );
}

import {
  Button as CButton,
  Checkbox,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useBoolean,
} from '@chakra-ui/react';
import { useDeleteTaskImagesMutation } from '../../../gql/generated';
import { ApolloError } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import Button from '../../common/Button';
import { useEffect } from 'react';

type Props = {
  isOpen: boolean;
  taskId: string;
  fileId: string;
  onClose: () => void;
  onError: (e: ApolloError) => void;
};

export default function DeleteTaskImageModal({ taskId, fileId, isOpen, onClose, onError }: Props) {
  const { t } = useTranslation();
  const [deleteFromItem, { toggle, off }] = useBoolean(false);
  const [deleteImage, { loading }] = useDeleteTaskImagesMutation({
    variables: {
      input: {
        parentId: taskId,
        fileIds: [fileId],
        deleteFromItem,
      },
    },
    update: (cache) => {
      cache.evict({ id: `TaskImage:${fileId}` });
    },
    onError,
    onCompleted: onClose,
  });

  const handleConfirm = () => {
    deleteImage();
  };

  useEffect(() => {
    off();
  }, [isOpen, off]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('delete_task_image_modal.header')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb='10px'>
              {deleteFromItem
                ? t('delete_task_image_modal.description_with_item')
                : t('delete_task_image_modal.description')}
            </Text>
            <Checkbox defaultChecked={deleteFromItem} onChange={toggle} size='md'>
              {t('delete_task_image_modal.checkbox')}
            </Checkbox>
          </ModalBody>
          <ModalFooter>
            <CButton mr={3} onClick={onClose}>
              {t('cancel')}
            </CButton>
            <Button isLoading={loading} color='white' bg='primary.main' onClick={handleConfirm}>
              {t('confirm')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

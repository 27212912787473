import { Box, Flex, FlexProps, IconProps } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';

interface SidebarItemProps extends FlexProps {
  Icon: (props: IconProps) => JSX.Element;
  to?: string;
  name: string;
}

export default function SidebarItem({ Icon, to, name, ...rest }: SidebarItemProps) {
  return (
    <NavLink to={to || ''} style={{ textDecoration: 'none' }}>
      {({ isActive }) => (
        <Flex
          fontSize='sm'
          fontWeight='bold'
          align='center'
          px='4'
          py='3'
          borderRadius='2xl'
          role='group'
          cursor='pointer'
          bg={isActive ? '#FFF' : 'transparent'}
          _hover={{
            shadow: 'sm',
            bg: '#FFF',
          }}
          shadow={isActive ? 'sm' : 'none'}
          {...rest}
        >
          <Box
            mr='4'
            w='32px'
            h='32px'
            borderRadius='full'
            display='flex'
            justifyContent='center'
            alignItems='center'
            bg={isActive ? 'primary.main' : 'white'}
            fill={isActive ? 'white' : 'primary.main'}
            _groupHover={{
              fill: 'white',
              bg: 'primary.main',
            }}
          >
            <Icon />
          </Box>
          {name}
        </Flex>
      )}
    </NavLink>
  );
}

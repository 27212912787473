import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import Button, { EButtonVariant, IButtonProps } from './Button';

interface IConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  description?: string | JSX.Element;
  title: string | JSX.Element;
  onSubmit: () => void;
  loading?: boolean;
  submitButtonProps?: IButtonProps;
}

const ConfirmationModal = ({
  onSubmit,
  loading,
  description,
  isOpen,
  onClose,
  title,
  submitButtonProps,
}: IConfirmationModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal motionPreset='slideInBottom' isOpen={isOpen} onClose={onClose}>
      <ModalOverlay bg='blackAlpha.300' />
      <ModalContent>
        <ModalHeader mr='1'>{title}</ModalHeader>
        <ModalCloseButton />
        {description ? <ModalBody>{description}</ModalBody> : null}

        <ModalFooter>
          <Button styleVariant={EButtonVariant.gray} mr={3} onClick={onClose}>
            {t('cancel')}
          </Button>
          <Button isLoading={loading} title={t('confirm')} onClick={onSubmit} {...submitButtonProps} />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmationModal;

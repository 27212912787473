import { IconProps, Icon } from '@chakra-ui/icon';
export const Apple = (props: IconProps) => (
  <Icon viewBox='0 0 24 24' {...props}>
    <path
      d='M18.9163 8.18242C18.7771 8.29043 16.3192 9.67537 16.3192 12.7549C16.3192 16.3168 19.4467 17.577 19.5404 17.6082C19.526 17.685 19.0435 19.3339 17.8914 21.0141C16.8641 22.4926 15.7912 23.9688 14.159 23.9688C12.5269 23.9688 12.1068 23.0207 10.2226 23.0207C8.38644 23.0207 7.73358 24 6.24063 24C4.74768 24 3.70597 22.6319 2.50825 20.9517C1.12091 18.9787 0 15.9136 0 13.0045C0 8.33843 3.0339 5.86379 6.0198 5.86379C7.60636 5.86379 8.92889 6.90549 9.925 6.90549C10.8731 6.90549 12.3516 5.80138 14.1566 5.80138C14.8407 5.80138 17.2985 5.86379 18.9163 8.18242ZM13.2997 3.82598C14.0462 2.94029 14.5743 1.71137 14.5743 0.482448C14.5743 0.312031 14.5599 0.139214 14.5287 0C13.3141 0.0456046 11.8692 0.808881 10.9979 1.81938C10.3138 2.59706 9.67537 3.82598 9.67537 5.07171C9.67537 5.25893 9.70657 5.44614 9.72097 5.50615C9.79778 5.52055 9.9226 5.53735 10.0474 5.53735C11.1371 5.53735 12.5077 4.80768 13.2997 3.82598Z'
      fill='#272727'
    />
  </Icon>
);
export const Google = (props: IconProps) => (
  <Icon viewBox='0 0 24 24' {...props}>
    <g clipPath='url(#clip0_13_806)'>
      <path
        d='M23.49 12.27C23.49 11.48 23.42 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.33 17.24 16.07 18.09V21.09H19.93C22.19 19 23.49 15.92 23.49 12.27Z'
        fill='#4285F4'
      />
      <path
        d='M12.0001 24.0001C15.2401 24.0001 17.9501 22.9201 19.9301 21.09L16.0701 18.09C14.9901 18.81 13.6201 19.25 12.0001 19.25C8.87005 19.25 6.22005 17.14 5.27005 14.29H1.29004V17.38C3.26004 21.3 7.31005 24.0001 12.0001 24.0001Z'
        fill='#34A853'
      />
      <path
        d='M5.27001 14.2899C5.02001 13.5699 4.89001 12.7999 4.89001 11.9999C4.89001 11.1999 5.03001 10.4299 5.27001 9.70988V6.61987H1.29C0.47 8.23988 0 10.0599 0 11.9999C0 13.9399 0.47 15.7599 1.29 17.3799L5.27001 14.2899Z'
        fill='#FBBC05'
      />
      <path
        d='M12.0001 4.75001C13.7701 4.75001 15.3501 5.36001 16.6001 6.55001L20.0201 3.13001C17.9501 1.19 15.2401 0 12.0001 0C7.31005 0 3.26004 2.70001 1.29004 6.62001L5.27005 9.71002C6.22005 6.86001 8.87005 4.75001 12.0001 4.75001Z'
        fill='#EA4335'
      />
    </g>
  </Icon>
);

import { useTranslation } from 'react-i18next';
import { HomeWithStatsQuery, Location, User } from '../../gql/generated';
import { useCallback, useMemo } from 'react';
import UserInfo from '../users/UserInfo';
import { createColumnHelper } from '@tanstack/react-table';
import { Link, Text, VStack } from '@chakra-ui/react';
import HomeMateColumn from '../common/HomeMateColumn';
import { DataTable } from '../common/DataTable';
import generateGooglePlaceUrl from '../../utils/generateGooglePlaceUrl';
import { generatePath, useNavigate } from 'react-router-dom';
import { AppPaths } from '../../routes/paths';
import TableTooltipLabel from '../common/TableTooltipLabel';

type TUser = Pick<User, 'id' | 'profile' | 'email' | 'isVerified'>;

type HomeData = {
  owner: TUser;
  homemates: TUser[];
  location: Location;
  totalTasks: number;
  dwelinkTasks: number;
  zones: number;
  items: number;
  phone: string;
};

interface IHomeItem {
  item: HomeWithStatsQuery;
}

const columnHelper = createColumnHelper<HomeData>();

const HomeItem = ({ item }: IHomeItem) => {
  const { t } = useTranslation();
  const [totalTasks, dwelinkTasks] = item.home.taskCount;

  const parse = {
    owner: item.home.owner,
    totalTasks,
    dwelinkTasks,
    location: item.home.location,
    homemates: item.home.homemates,
    zones: item.homeStats.zones,
    items: item.homeStats.items,
    phone: item.home.owner.profile?.phone || '',
  };

  const columns = useMemo(() => {
    const selectedColumns = [
      columnHelper.accessor('owner', {
        cell: (info) => <UserInfo {...info.getValue()} />,
        header: t('home_table.header.owner'),
      }),
      columnHelper.accessor('homemates', {
        cell: (info) => {
          const homemates = info.getValue();

          return <HomeMateColumn homemates={homemates} />;
        },
        header: t('home_table.header.homemates'),
      }),
      columnHelper.accessor('location', {
        cell: (info) => {
          const location = info.getValue();
          const placeUrl = generateGooglePlaceUrl(location);
          const value = `${String(location.street1)} ${String(location.city)}, ${String(location.state)}`;
          return (
            <VStack maxW='250' align='flex-start' gap={0}>
              <Link href={placeUrl} isExternal>
                <TableTooltipLabel text={value} maxW='300' />
              </Link>
            </VStack>
          );
        },
        header: t('home_table.header.address'),
      }),
      columnHelper.accessor('phone', {
        cell: (info) => {
          const phone = info.getValue();
          if (!phone)
            return (
              <Text color='primary.main' fontWeight='semibold'>
                N/A
              </Text>
            );
          return info.getValue();
        },
        header: t('home_table.header.phone'),
      }),
      columnHelper.accessor('zones', {
        cell: (info) => info.getValue(),
        header: t('home_table.header.zones'),
      }),
      columnHelper.accessor('items', {
        cell: (info) => info.getValue(),
        header: t('home_table.header.items'),
      }),
      columnHelper.accessor('totalTasks', {
        cell: (info) => info.getValue(),
        header: t('home_table.header.total_tasks'),
      }),
      columnHelper.accessor('dwelinkTasks', {
        cell: (info) => info.getValue(),
        header: t('home_table.header.dwelink_tasks'),
      }),
    ];
    return selectedColumns;
  }, [t]);

  const navigate = useNavigate();
  const handleOnClickRow = useCallback(() => {
    navigate(generatePath(AppPaths.HOME_DETAILS, { id: item.home.id }));
  }, [item.home.id, navigate]);

  const disabledOnClickRow = location.href.indexOf('/homes') !== -1;

  return (
    <DataTable
      columns={columns}
      data={[parse]}
      onClickRow={!disabledOnClickRow ? handleOnClickRow : undefined}
      emptyTitle={t('home_table.empty.title')}
      emptyDescription={t('home_table.empty.description')}
    />
  );
};

export default HomeItem;

import { Navigate, Outlet, matchPath, useLocation } from 'react-router-dom';
import LoadingOverlay from '../common/LoadingOverlay';
import { AppPaths, AuthPaths } from '../../routes/paths';
import useCurrentUser from '../../hooks/auth/useCurrentUser';
import { ERole } from '../../gql/generated';
import useLogout from '../../hooks/auth/useLogout';
import { useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const unauthorizedUserPaths = [
  AuthPaths.LOGIN,
  AuthPaths.FORGOT,
  AuthPaths.SET_PASSWORD,
  AuthPaths.GOOGLE_REDIRECT,
  AuthPaths.ACCEPT_INVITATION,
];
const unverifiedUserPaths = [AuthPaths.VERIFY_EMAIL];
const appPaths = Object.values(AppPaths);

const pathnameIsAllowed = (paths: Array<AuthPaths | AppPaths>, pathname: string) => {
  return paths.some((pattern) => matchPath(pattern, pathname));
};

export default function AuthGuard() {
  const [user, { loading }] = useCurrentUser({ fetchPolicy: 'cache-and-network' });
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const logout = useLogout();
  const id = 'access_error_toast';
  const toast = useToast();

  if (loading) {
    return <LoadingOverlay />;
  }

  if (!user && !pathnameIsAllowed(unauthorizedUserPaths, pathname)) {
    return <Navigate to={AuthPaths.LOGIN} />;
  }

  if (user && ![ERole.DWELINK_HOMEMATE, ERole.DWELINK_ADMIN].includes(user?.role)) {
    if (!toast.isActive(id)) {
      toast.closeAll();
      toast({
        id,
        description: t('auth.no_access'),
        status: 'error',
      });
    }
    logout();
    return <Navigate to={AuthPaths.LOGIN} />;
  }

  if (user && !user.isVerified && !pathnameIsAllowed(unverifiedUserPaths, pathname)) {
    return <Navigate to={AuthPaths.VERIFY_EMAIL} />;
  }

  if (user && user.isVerified && !pathnameIsAllowed(appPaths, pathname)) {
    return <Navigate to={AppPaths.HOMES} />;
  }

  return <Outlet />;
}

import { VStack, useToast } from '@chakra-ui/react';
import OtpVerifyEmailForm from '../../components/auth/forms/OtpVerifyEmailForm';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef } from 'react';
import useLogout from '../../hooks/auth/useLogout';
import AuthCard from '../../components/auth/AuthCard';
import { useSendVerificationCodeMutation } from '../../gql/generated';
import Button, { EButtonVariant } from '../../components/common/Button';

export default function VerifyEmail() {
  const wasRequested = useRef(false); //  avoid double request in strict mode
  const { t } = useTranslation();
  const toast = useToast();
  const handleError = (e: Error) => {
    toast({
      description: e.message,
      status: 'error',
    });
  };

  const [sendVerificationCode] = useSendVerificationCodeMutation({ onError: handleError });
  const logout = useLogout();

  useEffect(() => {
    if (!wasRequested.current) {
      void sendVerificationCode();
      wasRequested.current = true;
    }
  }, [sendVerificationCode]);

  return (
    <AuthCard title={t('verify_email_header')}>
      <VStack width='w-100' gap={4}>
        <OtpVerifyEmailForm />
        <Button styleVariant={EButtonVariant.gray} w='100%' onClick={() => logout()}>
          {t('logout')}
        </Button>
      </VStack>
    </AuthCard>
  );
}

import { Box, Text, TextProps, Tooltip, WrapItem } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';

interface ITableTooltipLabelProps extends TextProps {
  text: string;
}

const TableTooltipLabel = ({ text, ...props }: ITableTooltipLabelProps) => {
  const [isOverflown, setIsOverflown] = useState(false);
  const ref = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    const element = ref.current!;
    setIsOverflown(element ? element.offsetHeight < element.scrollHeight : false);
  }, []);

  return (
    <Box maxW={props.maxW || '230'}>
      <Tooltip label={text} isDisabled={!isOverflown}>
        <WrapItem>
          <Text ref={ref} whiteSpace='normal' maxW='230' {...props}>
            {text}
          </Text>
        </WrapItem>
      </Tooltip>
    </Box>
  );
};
export default TableTooltipLabel;

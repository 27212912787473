import { useEffect } from 'react';
import CodeForm from './CodeForm';
import { useToast } from '@chakra-ui/react';
import { useLoginWithResetPasswordCodeMutation, useSendResetPasswordCodeMutation } from '../../../gql/generated';

type Props = {
  email: string;
  onSuccess?: (token: string) => Promise<void> | void;
  onLoading?: (loading: boolean) => Promise<void> | void;
};

export default function OtpForgotPasswordForm({ email, onLoading, onSuccess }: Props) {
  const toast = useToast();
  const handleError = (e: Error) => {
    toast({
      description: e.message,
      status: 'error',
    });
  };

  const [login, { loading }] = useLoginWithResetPasswordCodeMutation({
    onError: handleError,
  });

  const [sendResetPasswordCode, { loading: resendLoading }] = useSendResetPasswordCodeMutation({
    onError: handleError,
  });

  useEffect(() => {
    void onLoading?.(loading || resendLoading);
  }, [onLoading, loading, resendLoading]);

  const onSubmit = async ({ code }: { code: string }) => {
    await login({
      variables: {
        email,
        code,
      },
      onCompleted: (data) => {
        if (data.loginWithResetPasswordCode.resetToken) {
          void onSuccess?.(data.loginWithResetPasswordCode.resetToken);
        }
      },
    });
  };

  const onResend = async () => {
    await sendResetPasswordCode({ variables: { email } });
  };

  return (
    <CodeForm email={email} onSubmit={onSubmit} onResend={onResend} isLoading={loading} isDisabled={resendLoading} />
  );
}

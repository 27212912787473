import jwtDecode from 'jwt-decode';

interface IJWTPayload {
  exp: number;
}

export class BaseTokenService {
  protected key;

  constructor(key: string) {
    this.key = key;
  }

  getToken = () => localStorage.getItem(this.key);

  getTokenExp = () => {
    const token = this.getToken();
    if (!token) return null;

    const decoded = jwtDecode<IJWTPayload | undefined>(token);
    return decoded?.exp || null;
  };

  isTokenExp = () => {
    const token = this.getToken();
    if (!token) return true;

    const decoded = jwtDecode<IJWTPayload | undefined>(token);

    return decoded?.exp && decoded?.exp > new Date().getTime();
  };

  setToken = (token?: string | null) => localStorage.setItem(this.key, token || '');

  clearToken = () => localStorage.removeItem(this.key);
}

import { Box, Card, CardBody, Center, SimpleGrid, Spinner } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { useParams } from 'react-router-dom';
import HomeInfoEditor from './HomeInfoEditor';
import { useHomeWithStatsQuery } from '../../../gql/generated';
import UsersTable from './UsersTable';

const Info = () => {
  const { id } = useParams();
  const { data, loading } = useHomeWithStatsQuery({ variables: { homeId: id! }, fetchPolicy: 'cache-and-network' });

  return (
    <Box>
      {loading && isEmpty(data) ? (
        <Center height='550'>
          <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='primary.main' size='xl' />
        </Center>
      ) : (
        <SimpleGrid
          gap={4}
          columns={{
            base: 1,
            lg: 2,
          }}
        >
          <Card>
            <CardBody>
              <HomeInfoEditor homeStats={data?.homeStats} home={data?.home} />
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <UsersTable owner={data?.home.owner} homemates={data?.home.homemates} />
            </CardBody>
          </Card>
        </SimpleGrid>
      )}
    </Box>
  );
};

export default Info;

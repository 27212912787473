export enum EButtonVariant {
  gray = 'gray',
  default = 'default',
  outline = 'outline',
  grayOutline = 'grayOutline',
  deleteButton = 'deleteButton',
  statusButton = 'statusButton',
  ghost = 'ghost',
  red = 'red',
}

export const buttonVariants = {
  [EButtonVariant.gray]: {
    bg: 'gray.100',
    _hover: { background: 'gray.200' },
    color: 'gray.800',
  },
  [EButtonVariant.default]: { bg: 'primary.main', _hover: { background: 'primary.200' }, color: 'white' },
  [EButtonVariant.outline]: {
    variant: 'outline',
    color: 'primary.main',
    borderColor: 'primary.main',
    _hover: { background: 'primary.50' },
  },
  [EButtonVariant.grayOutline]: { variant: 'outline', borderColor: 'gray.200', _hover: { background: 'gray.100' } },
  [EButtonVariant.deleteButton]: {
    variant: 'outline',
    borderColor: 'red.500',
    _hover: { background: 'red.100' },
    color: 'red.500',
  },
  [EButtonVariant.statusButton]: {
    variant: 'outline',
    bg: 'transparent',
    color: 'gray.400',
    borderColor: 'gray.400',
    borderRadius: '10',
    disabled: true,
    _hover: { bg: 'transparent' },
    cursor: 'default',
  },
  [EButtonVariant.ghost]: {
    fill: 'gray.400',
    variant: 'ghost',
    borderRadius: '10',
  },
  [EButtonVariant.red]: {
    bg: 'red.500',
    _hover: { background: 'red.300' },
    color: 'white',
  },
};

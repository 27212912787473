import { Navigate, RouteObject, RouterProvider, createBrowserRouter } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import AppLayout from '../components/layouts/AppLayout/AppLayout';
import { AdminAppPaths, AppPaths, CommonPaths } from './paths';
import AuthGuard from '../components/auth/AuthGuard';
import Customers from '../pages/app/Customers';
import { t } from 'i18next';
import DwelinkUsers from '../pages/app/DwelinkUsers';
import { getCustomerCrumbTitle, getTaskCrumbTitle } from './helpers';
import client from '../gql/apollo/client';
import { ERole, TaskDocument, UserDocument } from '../gql/generated';
import EditTask from '../pages/app/EditTask';
import Tasks from '../pages/app/Tasks';
import DoItRequestDetails from '../pages/app/DoItRequestDetails';
import DoItRequests from '../pages/app/DoItRequests';
import Profile from '../pages/app/Profile';
import AccessTokenService from '../services/tokens/AccessTokenService';
import JobberRedirect from '../pages/app/JobberRedirect';
import Settings from '../pages/app/Settings';
import RedirectHandler from '../pages/RedirectHandler';
import homeDetailsPaths from './homeDetailsPaths';
import authPaths from './authPaths';
import useCurrentUser from '../hooks/auth/useCurrentUser';
import LoadingOverlay from '../components/common/LoadingOverlay';

const adminRoutes = [
  {
    path: AdminAppPaths.SETTINGS,
    handle: { crumb: () => t('crumb.settings') },
    element: <Settings />,
  },
  {
    path: AdminAppPaths.DWELINK_USERS,
    element: <DwelinkUsers />,
    handle: { crumb: () => t('sidebar.dwelink_users') },
  },
] as RouteObject[];

const getRouter = (role: ERole) =>
  [
    {
      path: CommonPaths.REDIRECT,
      element: <RedirectHandler />,
    },
    {
      element: <AuthGuard />,
      children: [
        {
          element: <AppLayout />,
          children: [
            {
              path: AppPaths.JOBBER_REDIRECT,
              element: <JobberRedirect />,
            },
            // HOMES PATHS
            homeDetailsPaths,
            {
              path: AppPaths.CUSTOMERS,
              children: [
                { path: AppPaths.CUSTOMERS, element: <Customers /> },
                {
                  path: AppPaths.CUSTOMER_PROFILE,
                  element: <Profile />,
                  loader: async ({ request }) => {
                    try {
                      const userId = request.url.split('customers/')[1];
                      if (!userId) return 'N/A';
                      const data = await client.query({ query: UserDocument, variables: { userId } });
                      return data;
                    } catch (error) {
                      return 'N/A';
                    }
                  },
                  handle: { crumb: getCustomerCrumbTitle },
                },
              ],
              handle: { crumb: () => t('sidebar.customers') },
            },
            {
              path: AppPaths.USER_PROFILE,
              element: <Profile />,
              handle: { crumb: () => t('crumb.account_info') },
            },
            {
              path: AppPaths.TASKS,
              children: [
                { path: AppPaths.TASKS, element: <Tasks /> },
                {
                  path: AppPaths.EDIT_TASK,
                  element: <EditTask showHomeStats />,
                  loader: async ({ request }) => {
                    try {
                      const isTokenExp = AccessTokenService.isTokenExp();
                      if (isTokenExp) return null;
                      const taskId = request.url.split('tasks/')[1];
                      if (!taskId) return null;
                      const data = await client.query({ query: TaskDocument, variables: { taskId } });
                      return data;
                    } catch (error) {
                      return 'N/A';
                    }
                  },
                  handle: { crumb: getTaskCrumbTitle },
                },
              ],
              handle: { crumb: () => t('sidebar.tasks') },
            },
            {
              path: AppPaths.DOIT_REQUESTS,
              children: [
                {
                  path: AppPaths.DOIT_REQUESTS,
                  element: <DoItRequests />,
                },
                {
                  path: AppPaths.DOIT_REQUEST_DETAILS,
                  element: <DoItRequestDetails />,
                  handle: { crumb: () => t('crumb.doit_request_details') },
                },
              ],
              handle: { crumb: () => t('sidebar.doit_requests') },
            },
            { path: '*', element: <Navigate to={AppPaths.HOMES} /> },
            // ADMIN PATHS
            ...(role === ERole.DWELINK_ADMIN ? adminRoutes : []),
          ],
        },
        authPaths,
      ],
    },
  ] as RouteObject[];

export default function RootRouter() {
  const [user, { loading }] = useCurrentUser();

  if (loading && isEmpty(user)) return <LoadingOverlay />;

  const router = createBrowserRouter(getRouter(user?.role || ERole.DWELINK_HOMEMATE));

  return <RouterProvider router={router} />;
}

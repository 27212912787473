import { Box, useToast } from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { ItemFragment, useZoneQuery } from '../../../gql/generated';
import InventoryItemsTable, { InventoryItemsData } from '../inventoryItemsTab/InventoryItemsTable';
import Pagination from '../../common/Pagination';
import { CellContext, Row } from '@tanstack/react-table';
import { AppPaths } from '../../../routes/paths';
import { formatGraphQLErrors } from '../../../gql/apollo/helpers';

const ITEMS_PER_PAGE = 40;

const ZoneInventory = () => {
  const { id: homeId = '', zoneId = '' } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const toast = useToast();

  const { data, loading, refetch } = useZoneQuery({
    variables: { zoneId },
    fetchPolicy: 'cache-and-network',
    onError: (e) => toast({ description: formatGraphQLErrors(e).message, status: 'error' }),
  });

  const handleGetNavigationPath = (row: Row<ItemFragment>) =>
    generatePath(AppPaths.ZONE_ITEM_DETAILS, { id: homeId, zoneId: row.original.zoneId, itemId: row.original.id });

  const handlerGetTaskCountNavigationPath = useCallback(
    (info: CellContext<InventoryItemsData, number>) =>
      generatePath(AppPaths.ZONE_ITEM_DETAILS_MAINTENANCE_PLAN, {
        id: info.row.original.homeId,
        zoneId,
        itemId: info.row.original.id,
      }),
    [zoneId],
  );

  return (
    <Box>
      <InventoryItemsTable
        isLoading={loading}
        items={data?.zone.items.items}
        getNavigationPath={handleGetNavigationPath}
        getTaskCountNavigationPath={handlerGetTaskCountNavigationPath}
        archiveCb={refetch}
      />
      {data?.zone.items.items && (
        <Pagination
          total={data.zone.items.total}
          current={currentPage}
          defaultPageSize={ITEMS_PER_PAGE}
          onChange={(page) => setCurrentPage(page)}
        />
      )}
    </Box>
  );
};
export default ZoneInventory;

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: string; output: string };
};

export type AddItemsInput = {
  items: Array<ItemInput>;
  zoneId: Scalars['ID']['input'];
};

export type AppleLoginInput = {
  idToken: Scalars['String']['input'];
  profile?: InputMaybe<SetProfileInput>;
};

export type AssignTaskInput = {
  assignSimilar?: InputMaybe<Scalars['Boolean']['input']>;
  jobPerformer: EJobPerformer;
  /** Only for jobPerformer = third_party */
  jobPerformerName?: InputMaybe<Scalars['String']['input']>;
  taskId: Scalars['ID']['input'];
};

export type AuthResponse = {
  __typename?: 'AuthResponse';
  accessToken?: Maybe<Scalars['String']['output']>;
  refreshToken?: Maybe<Scalars['String']['output']>;
  resetToken?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type ChangePasswordInput = {
  currentPassword: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
};

export type ChangeRoleInput = {
  role: ERole;
  userId: Scalars['ID']['input'];
};

export type ConfirmFileInput = {
  fileId: Scalars['ID']['input'];
  parentId: Scalars['ID']['input'];
};

export type ConfirmFilesInput = {
  fileIds: Array<Scalars['ID']['input']>;
  parentId: Scalars['ID']['input'];
};

export type CreateHomeInput = {
  location: LocationInput;
  squareFootage: Scalars['Float']['input'];
  yearBuilt: Scalars['Int']['input'];
};

export type CreateTaskInput = {
  costEstimate?: InputMaybe<Scalars['Float']['input']>;
  description: Scalars['String']['input'];
  importance: Scalars['Int']['input'];
  itemId: Scalars['ID']['input'];
  jobPerformer?: InputMaybe<EJobPerformer>;
  /** Only for jobPerformer = third_party */
  jobPerformerName?: InputMaybe<Scalars['String']['input']>;
  levelOfSkill: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  recommendedDate: Scalars['DateTime']['input'];
  riskOfNotDoing: Scalars['String']['input'];
  scheduledDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CreateUserInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type DeferTaskInput = {
  deferForGood?: InputMaybe<Scalars['Boolean']['input']>;
  /** Ignored if deferForGood is true */
  newDate?: InputMaybe<Scalars['DateTime']['input']>;
  taskId: Scalars['ID']['input'];
};

export type DeleteFileInput = {
  fileId: Scalars['ID']['input'];
  parentId: Scalars['ID']['input'];
};

export type DeleteFilesInput = {
  fileIds: Array<Scalars['ID']['input']>;
  parentId: Scalars['ID']['input'];
};

export type DeleteTaskImagesInput = {
  deleteFromItem?: InputMaybe<Scalars['Boolean']['input']>;
  fileIds: Array<Scalars['ID']['input']>;
  parentId: Scalars['ID']['input'];
};

export type DoItFilter = {
  status?: InputMaybe<Array<EDoItRequestStatus>>;
  timeRange?: InputMaybe<TimeRange>;
  timing?: InputMaybe<Array<EDoItRequestTiming>>;
};

export type DoItFilterByHome = {
  homeId: Scalars['ID']['input'];
  status?: InputMaybe<Array<EDoItRequestStatus>>;
  timeRange?: InputMaybe<TimeRange>;
  timing?: InputMaybe<Array<EDoItRequestTiming>>;
};

export type DoItPaginationResponse = {
  __typename?: 'DoItPaginationResponse';
  doitRequests: Array<DoItRequest>;
  hasMore: Scalars['Boolean']['output'];
  total: Scalars['Int']['output'];
};

export type DoItRequest = {
  __typename?: 'DoItRequest';
  coverImage?: Maybe<File>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['ID']['output'];
  description?: Maybe<Scalars['String']['output']>;
  home: Home;
  id: Scalars['ID']['output'];
  jobberWebUri?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  sentDate?: Maybe<Scalars['DateTime']['output']>;
  status: EDoItRequestStatus;
  timing?: Maybe<EDoItRequestTiming>;
  updatedAt: Scalars['DateTime']['output'];
};

export enum EAuthServiceType {
  APPLE = 'APPLE',
  GOOGLE = 'GOOGLE',
  PASSWORD = 'PASSWORD',
}

export enum EDoItRequestStatus {
  ACTIVE = 'ACTIVE',
  FINISHED = 'FINISHED',
}

export enum EDoItRequestTiming {
  IMMEDIATE = 'IMMEDIATE',
  WHILE_YOU_ARE_HERE = 'WHILE_YOU_ARE_HERE',
  WITHIN_MONTH = 'WITHIN_MONTH',
  WITHIN_WEEK = 'WITHIN_WEEK',
}

export enum EInviteUserResponseCode {
  CHANGED_ROLE_OF_EXISTING_USER = 'CHANGED_ROLE_OF_EXISTING_USER',
  CREATED = 'CREATED',
  UPDATED_EXPIRATION_DATE = 'UPDATED_EXPIRATION_DATE',
}

export enum EItemCondition {
  AVERAGE = 'AVERAGE',
  BAD = 'BAD',
  EXCELLENT = 'EXCELLENT',
  GOOD = 'GOOD',
  NORMAL = 'NORMAL',
  VERY_BAD = 'VERY_BAD',
}

export enum EJobPerformer {
  DWELINK = 'DWELINK',
  THIRD_PARTY = 'THIRD_PARTY',
  UNKNOWN = 'UNKNOWN',
  USER = 'USER',
}

export enum EJobberStatus {
  DISABLED = 'DISABLED',
  ERROR = 'ERROR',
  OK = 'OK',
  UNAUTHORIZED = 'UNAUTHORIZED',
  UNKNOWN = 'UNKNOWN',
}

export enum ELoginWithPasswordAbility {
  HAS_ACCOUNT_NO_PASSWORD = 'HAS_ACCOUNT_NO_PASSWORD',
  HAS_ACCOUNT_PASSWORD = 'HAS_ACCOUNT_PASSWORD',
  NO_ACCOUNT = 'NO_ACCOUNT',
}

export enum EMembershipRole {
  DWELINK_HOMEMATE = 'DWELINK_HOMEMATE',
  HOME_OWNER = 'HOME_OWNER',
}

export enum ERole {
  DWELINK_ADMIN = 'DWELINK_ADMIN',
  DWELINK_HOMEMATE = 'DWELINK_HOMEMATE',
  USER = 'USER',
}

export enum ETaskStatus {
  ACTIVE = 'ACTIVE',
  DEFERRED_FOR_GOOD = 'DEFERRED_FOR_GOOD',
  FINISHED = 'FINISHED',
}

export enum EUserSort {
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  PROFILE = 'PROFILE',
}

export type File = {
  __typename?: 'File';
  blurHash?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  filename: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  mimetype: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  url: Scalars['String']['output'];
};

export type FileInput = {
  filename: Scalars['String']['input'];
  mimetype: Scalars['String']['input'];
  sizeBytes: Scalars['Float']['input'];
};

export type Home = {
  __typename?: 'Home';
  coverImage?: Maybe<File>;
  createdAt: Scalars['DateTime']['output'];
  homemates: Array<User>;
  id: Scalars['ID']['output'];
  jobberWebUri?: Maybe<Scalars['String']['output']>;
  location: Location;
  owner: User;
  score?: Maybe<Scalars['Float']['output']>;
  squareFootage: Scalars['Float']['output'];
  taskCount: Array<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  yearBuilt: Scalars['Int']['output'];
  zoneCount: Array<Scalars['Int']['output']>;
};

export type HometaskCountArgs = {
  filters: Array<TaskFilter>;
};

export type HomezoneCountArgs = {
  filters: Array<ZoneFilter>;
};

export type HomeFilterByUser = {
  membershipRole?: InputMaybe<Array<EMembershipRole>>;
  userId: Scalars['ID']['input'];
};

export type HomePaginationResponse = {
  __typename?: 'HomePaginationResponse';
  hasMore: Scalars['Boolean']['output'];
  homes: Array<Home>;
  total: Scalars['Int']['output'];
};

export type HomeScore = {
  __typename?: 'HomeScore';
  total?: Maybe<Scalars['Float']['output']>;
  totalByZone: Array<ZoneScore>;
};

export type HomeStats = {
  __typename?: 'HomeStats';
  activeTasks: Scalars['Float']['output'];
  dwelinkTasks: Scalars['Float']['output'];
  finishedTasks: Scalars['Float']['output'];
  items: Scalars['Float']['output'];
  score: HomeScore;
  zones: Scalars['Float']['output'];
};

export type HomeStatsFilterInput = {
  homeId: Scalars['ID']['input'];
  /** Does not have effect on `score` field */
  timeRange?: InputMaybe<TimeRange>;
};

export type Invitation = {
  __typename?: 'Invitation';
  createdBy: Scalars['ID']['output'];
  email: Scalars['String']['output'];
  expiresAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  inviter: User;
  profile: UserProfile;
  role: ERole;
};

export type InvitationFilterInput = {
  isUsed?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type InvitationPaginationResponse = {
  __typename?: 'InvitationPaginationResponse';
  hasMore: Scalars['Boolean']['output'];
  invitations: Array<Invitation>;
  total: Scalars['Int']['output'];
};

export type InviteUserInput = {
  email: Scalars['String']['input'];
  profile: SetProfileInput;
  role: ERole;
};

export type InviteUserResponse = {
  __typename?: 'InviteUserResponse';
  code: EInviteUserResponseCode;
  /** Only available when code is CREATED or UPDATED_EXPIRATION_DATE */
  invitation?: Maybe<Invitation>;
};

export type Item = {
  __typename?: 'Item';
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  brand?: Maybe<Scalars['String']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  condition?: Maybe<EItemCondition>;
  coverImage?: Maybe<File>;
  home: Home;
  homeId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  installationYear?: Maybe<Scalars['DateTime']['output']>;
  isArchived: Scalars['Boolean']['output'];
  itemTemplateId: Scalars['ID']['output'];
  keyIssues?: Maybe<Scalars['String']['output']>;
  lastMaintenanceDate?: Maybe<Scalars['DateTime']['output']>;
  mainImageId?: Maybe<Scalars['ID']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  purchasedFrom?: Maybe<Scalars['String']['output']>;
  serial?: Maybe<Scalars['String']['output']>;
  taskCount: Array<Scalars['Int']['output']>;
  template: ItemTemplate;
  type?: Maybe<Scalars['String']['output']>;
  zone: Zone;
  zoneId: Scalars['ID']['output'];
};

export type ItemtaskCountArgs = {
  filters: Array<TaskFilter>;
};

export type ItemFilterByHome = {
  homeId: Scalars['ID']['input'];
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ItemImage = {
  __typename?: 'ItemImage';
  blurHash?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  filename: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isMain: Scalars['Boolean']['output'];
  mimetype: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  url: Scalars['String']['output'];
};

export type ItemInput = {
  itemTemplateId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type ItemPaginationResponse = {
  __typename?: 'ItemPaginationResponse';
  hasMore: Scalars['Boolean']['output'];
  items: Array<Item>;
  total: Scalars['Float']['output'];
};

export type ItemTemplate = {
  __typename?: 'ItemTemplate';
  iconUrl: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  maxQuantity: Scalars['Float']['output'];
  name: Scalars['String']['output'];
};

export type Location = {
  __typename?: 'Location';
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  googlePlaceId: Scalars['String']['output'];
  lat: Scalars['Float']['output'];
  lng: Scalars['Float']['output'];
  state: Scalars['String']['output'];
  street1: Scalars['String']['output'];
  street2: Scalars['String']['output'];
  zipCode: Scalars['String']['output'];
};

export type LocationInput = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  googlePlaceId: Scalars['String']['input'];
  lat: Scalars['Float']['input'];
  lng: Scalars['Float']['input'];
  state: Scalars['String']['input'];
  street1: Scalars['String']['input'];
  street2?: InputMaybe<Scalars['String']['input']>;
  zipCode: Scalars['String']['input'];
};

export type MonthTotal = {
  __typename?: 'MonthTotal';
  month: Scalars['DateTime']['output'];
  total: Scalars['Float']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addItems: Array<Item>;
  archiveItem: Scalars['Boolean']['output'];
  archiveTask: Scalars['Boolean']['output'];
  assignHomemates: Home;
  assignTask: Task;
  changePassword: Scalars['Boolean']['output'];
  changeUserRole: User;
  confirmDoItRequestDraft: DoItRequest;
  confirmDoitRequestImagesUploading: Array<File>;
  confirmHomeImageUploading: File;
  confirmItemImagesUploading: Array<ItemImage>;
  confirmTaskImagesUploading: Array<TaskImage>;
  createDoItRequestDraft: DoItRequest;
  createHome: Home;
  createTask: Task;
  deferTask: Task;
  deleteAccount: Scalars['Boolean']['output'];
  deleteDoitRequestImages: Scalars['Boolean']['output'];
  deleteHomeImage: Scalars['Boolean']['output'];
  deleteInvitation: Scalars['Boolean']['output'];
  deleteItemImages: Scalars['Boolean']['output'];
  deleteTaskImages: Scalars['Boolean']['output'];
  finishDoitRequest: DoItRequest;
  finishTask: Task;
  inviteUser: InviteUserResponse;
  login: AuthResponse;
  loginWithAppleToken: AuthResponse;
  loginWithGoogleCode: AuthResponse;
  loginWithGoogleToken: AuthResponse;
  loginWithResetPasswordCode: AuthResponse;
  logout: Scalars['Boolean']['output'];
  register: AuthResponse;
  registerByInvitation: AuthResponse;
  requestDoitRequestImagesUploading: Array<UploadResponse>;
  requestHomeImageUploading: UploadResponse;
  requestItemImagesUploading: Array<UploadResponse>;
  requestTaskImagesUploading: Array<UploadResponse>;
  resetPassword: Scalars['Boolean']['output'];
  sendResetPasswordCode: Scalars['Boolean']['output'];
  sendVerificationCode: Scalars['Boolean']['output'];
  setClientHubUrl: User;
  /** This mutation works only when Jobber integration is enabled, otherwise it throws an error */
  setJobberTokens: Scalars['Boolean']['output'];
  setMainItemImage: Scalars['Boolean']['output'];
  setMainTaskImage: Scalars['Boolean']['output'];
  setProfile: User;
  setUserPassword: Scalars['Boolean']['output'];
  setupHome: Scalars['Boolean']['output'];
  token: AuthResponse;
  unassignHomemates: Home;
  updateDoItRequestDraft: DoItRequest;
  updateDoitRequest: DoItRequest;
  updateHome: Home;
  updateItem: Item;
  updateTask: Task;
  updateZone: Zone;
  updateZones: Array<Zone>;
  verifyUserEmail: Scalars['Boolean']['output'];
};

export type MutationaddItemsArgs = {
  input: AddItemsInput;
};

export type MutationarchiveItemArgs = {
  itemId: Scalars['ID']['input'];
};

export type MutationarchiveTaskArgs = {
  taskId: Scalars['ID']['input'];
};

export type MutationassignHomematesArgs = {
  input: UpdateHomematesInput;
};

export type MutationassignTaskArgs = {
  input: AssignTaskInput;
};

export type MutationchangePasswordArgs = {
  input: ChangePasswordInput;
};

export type MutationchangeUserRoleArgs = {
  input: ChangeRoleInput;
};

export type MutationconfirmDoItRequestDraftArgs = {
  draftId: Scalars['ID']['input'];
};

export type MutationconfirmDoitRequestImagesUploadingArgs = {
  input: ConfirmFilesInput;
};

export type MutationconfirmHomeImageUploadingArgs = {
  input: ConfirmFileInput;
};

export type MutationconfirmItemImagesUploadingArgs = {
  input: ConfirmFilesInput;
};

export type MutationconfirmTaskImagesUploadingArgs = {
  input: ConfirmFilesInput;
};

export type MutationcreateDoItRequestDraftArgs = {
  homeId: Scalars['ID']['input'];
};

export type MutationcreateHomeArgs = {
  input: CreateHomeInput;
};

export type MutationcreateTaskArgs = {
  input: CreateTaskInput;
};

export type MutationdeferTaskArgs = {
  input: DeferTaskInput;
};

export type MutationdeleteDoitRequestImagesArgs = {
  input: DeleteFilesInput;
};

export type MutationdeleteHomeImageArgs = {
  input: DeleteFileInput;
};

export type MutationdeleteInvitationArgs = {
  invitationId: Scalars['ID']['input'];
};

export type MutationdeleteItemImagesArgs = {
  input: DeleteFilesInput;
};

export type MutationdeleteTaskImagesArgs = {
  input: DeleteTaskImagesInput;
};

export type MutationfinishDoitRequestArgs = {
  doitRequestId: Scalars['ID']['input'];
};

export type MutationfinishTaskArgs = {
  taskId: Scalars['ID']['input'];
};

export type MutationinviteUserArgs = {
  input: InviteUserInput;
};

export type MutationloginArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type MutationloginWithAppleTokenArgs = {
  input: AppleLoginInput;
};

export type MutationloginWithGoogleCodeArgs = {
  code: Scalars['String']['input'];
};

export type MutationloginWithGoogleTokenArgs = {
  googleToken: Scalars['String']['input'];
};

export type MutationloginWithResetPasswordCodeArgs = {
  code: Scalars['String']['input'];
  email: Scalars['String']['input'];
};

export type MutationregisterArgs = {
  input: CreateUserInput;
};

export type MutationregisterByInvitationArgs = {
  input: RegisterByInvitationInput;
};

export type MutationrequestDoitRequestImagesUploadingArgs = {
  input: UploadFilesInput;
};

export type MutationrequestHomeImageUploadingArgs = {
  input: UploadFileInput;
};

export type MutationrequestItemImagesUploadingArgs = {
  input: UploadFilesInput;
};

export type MutationrequestTaskImagesUploadingArgs = {
  input: UploadFilesInput;
};

export type MutationresetPasswordArgs = {
  input: ResetPasswordInput;
};

export type MutationsendResetPasswordCodeArgs = {
  email: Scalars['String']['input'];
};

export type MutationsetClientHubUrlArgs = {
  input: SetClientUrlInput;
};

export type MutationsetJobberTokensArgs = {
  code: Scalars['String']['input'];
};

export type MutationsetMainItemImageArgs = {
  input: SetMainFileInput;
};

export type MutationsetMainTaskImageArgs = {
  input: SetMainFileInput;
};

export type MutationsetProfileArgs = {
  input: SetProfileInput;
};

export type MutationsetUserPasswordArgs = {
  input: SetPasswordInput;
};

export type MutationsetupHomeArgs = {
  homeId: Scalars['ID']['input'];
};

export type MutationtokenArgs = {
  refreshToken: Scalars['String']['input'];
};

export type MutationunassignHomematesArgs = {
  input: UpdateHomematesInput;
};

export type MutationupdateDoItRequestDraftArgs = {
  input: UpdateDoItRequestDraftInput;
};

export type MutationupdateDoitRequestArgs = {
  input: UpdateDoItRequestInput;
};

export type MutationupdateHomeArgs = {
  input: UpdateHomeInput;
};

export type MutationupdateItemArgs = {
  input: UpdateItemInput;
};

export type MutationupdateTaskArgs = {
  input: UpdateTaskInput;
};

export type MutationupdateZoneArgs = {
  input: UpdateZoneInput;
};

export type MutationupdateZonesArgs = {
  input: UpdateZonesInput;
};

export type MutationverifyUserEmailArgs = {
  code: Scalars['String']['input'];
};

export type Query = {
  __typename?: 'Query';
  doitRequest: DoItRequest;
  doitRequestDraft?: Maybe<DoItRequest>;
  doitRequestImages: Array<File>;
  doitRequests: DoItPaginationResponse;
  doitRequestsByHomeId: DoItPaginationResponse;
  home: Home;
  homeStats: HomeStats;
  homes: HomePaginationResponse;
  homesByUserId: HomePaginationResponse;
  invitations: InvitationPaginationResponse;
  item: Item;
  itemImages: Array<ItemImage>;
  itemTemplates: Array<ItemTemplate>;
  itemsByHomeId: ItemPaginationResponse;
  jobberStatus: EJobberStatus;
  loginWithPasswordAbility: ELoginWithPasswordAbility;
  me: User;
  myHomes: Array<Home>;
  task: Task;
  taskImages: Array<TaskImage>;
  tasks: TaskPaginationResponse;
  tasksByHomeId: TaskPaginationResponse;
  tasksByItemId: TaskPaginationResponse;
  tasksByZoneId: TaskPaginationResponse;
  user: User;
  users: UserPaginationResponse;
  zone: Zone;
  zoneTemplates: Array<ZoneTemplate>;
  zones: ZonePaginationResponse;
};

export type QuerydoitRequestArgs = {
  doitRequestId: Scalars['ID']['input'];
};

export type QuerydoitRequestDraftArgs = {
  homeId: Scalars['ID']['input'];
};

export type QuerydoitRequestImagesArgs = {
  doitRequestId: Scalars['ID']['input'];
};

export type QuerydoitRequestsArgs = {
  filter: DoItFilter;
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
};

export type QuerydoitRequestsByHomeIdArgs = {
  filter: DoItFilterByHome;
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
};

export type QueryhomeArgs = {
  homeId: Scalars['ID']['input'];
};

export type QueryhomeStatsArgs = {
  filter: HomeStatsFilterInput;
};

export type QueryhomesArgs = {
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
};

export type QueryhomesByUserIdArgs = {
  filter: HomeFilterByUser;
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
};

export type QueryinvitationsArgs = {
  filter: InvitationFilterInput;
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
};

export type QueryitemArgs = {
  itemId: Scalars['ID']['input'];
};

export type QueryitemImagesArgs = {
  itemId: Scalars['ID']['input'];
};

export type QueryitemTemplatesArgs = {
  zoneTemplateId: Scalars['ID']['input'];
};

export type QueryitemsByHomeIdArgs = {
  filter: ItemFilterByHome;
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
};

export type QueryloginWithPasswordAbilityArgs = {
  email: Scalars['String']['input'];
};

export type QuerytaskArgs = {
  taskId: Scalars['ID']['input'];
};

export type QuerytaskImagesArgs = {
  taskId: Scalars['ID']['input'];
};

export type QuerytasksArgs = {
  filter: TaskFilter;
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
};

export type QuerytasksByHomeIdArgs = {
  filter: TaskFilterByHome;
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
};

export type QuerytasksByItemIdArgs = {
  filter: TaskFilterByItem;
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
};

export type QuerytasksByZoneIdArgs = {
  filter: TaskFilterByZone;
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
};

export type QueryuserArgs = {
  userId: Scalars['ID']['input'];
};

export type QueryusersArgs = {
  filter: UserFilterInput;
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
  sort?: EUserSort;
};

export type QueryzoneArgs = {
  zoneId: Scalars['ID']['input'];
};

export type QueryzonesArgs = {
  filter: ZoneFilterInput;
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
};

export type RegisterByInvitationInput = {
  inviteId: Scalars['ID']['input'];
  password: Scalars['String']['input'];
};

export type ResetPasswordInput = {
  newPassword: Scalars['String']['input'];
  resetToken: Scalars['String']['input'];
};

export type SetClientUrlInput = {
  url?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
};

export type SetMainFileInput = {
  fileId: Scalars['ID']['input'];
  parentId: Scalars['ID']['input'];
};

export type SetPasswordInput = {
  password: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type SetProfileInput = {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type Task = {
  __typename?: 'Task';
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  completedDate?: Maybe<Scalars['DateTime']['output']>;
  costEstimate?: Maybe<Scalars['Float']['output']>;
  coverImage?: Maybe<File>;
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  importance: Scalars['Float']['output'];
  isArchived: Scalars['Boolean']['output'];
  item: Item;
  jobPerformer: EJobPerformer;
  jobPerformerName?: Maybe<Scalars['String']['output']>;
  jobberWebUri?: Maybe<Scalars['String']['output']>;
  levelOfSkill: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  recommendedDate: Scalars['DateTime']['output'];
  riskOfNotDoing: Scalars['String']['output'];
  scheduledDate?: Maybe<Scalars['DateTime']['output']>;
  serviceId?: Maybe<Scalars['ID']['output']>;
  status: ETaskStatus;
  updatedAt: Scalars['DateTime']['output'];
};

export type TaskFilter = {
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  jobPerformer?: InputMaybe<Array<EJobPerformer>>;
  status?: InputMaybe<Array<ETaskStatus>>;
  timeRange?: InputMaybe<TimeRange>;
};

export type TaskFilterByHome = {
  homeId: Scalars['ID']['input'];
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  jobPerformer?: InputMaybe<Array<EJobPerformer>>;
  status?: InputMaybe<Array<ETaskStatus>>;
  timeRange?: InputMaybe<TimeRange>;
};

export type TaskFilterByItem = {
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  itemId: Scalars['ID']['input'];
  jobPerformer?: InputMaybe<Array<EJobPerformer>>;
  status?: InputMaybe<Array<ETaskStatus>>;
  timeRange?: InputMaybe<TimeRange>;
};

export type TaskFilterByZone = {
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  jobPerformer?: InputMaybe<Array<EJobPerformer>>;
  status?: InputMaybe<Array<ETaskStatus>>;
  timeRange?: InputMaybe<TimeRange>;
  zoneId: Scalars['ID']['input'];
};

export type TaskImage = {
  __typename?: 'TaskImage';
  blurHash?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  filename: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isMain: Scalars['Boolean']['output'];
  mimetype: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  url: Scalars['String']['output'];
};

export type TaskPaginationResponse = {
  __typename?: 'TaskPaginationResponse';
  hasMore: Scalars['Boolean']['output'];
  tasks: Array<Task>;
  total: Scalars['Float']['output'];
  totalByMonth: Array<MonthTotal>;
};

export type TimeRange = {
  end: Scalars['DateTime']['input'];
  start: Scalars['DateTime']['input'];
};

export type TimeRangeType = {
  __typename?: 'TimeRangeType';
  end: Scalars['DateTime']['output'];
  start: Scalars['DateTime']['output'];
};

export type UpdateDoItRequestDraftInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  draftId: Scalars['ID']['input'];
  timing?: InputMaybe<EDoItRequestTiming>;
};

export type UpdateDoItRequestInput = {
  doitRequestId: Scalars['ID']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateHomeInput = {
  id: Scalars['ID']['input'];
  location?: InputMaybe<LocationInput>;
  squareFootage?: InputMaybe<Scalars['Float']['input']>;
  yearBuilt?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateHomematesInput = {
  homeId: Scalars['ID']['input'];
  homemateIds: Array<Scalars['ID']['input']>;
};

export type UpdateItemInput = {
  brand?: InputMaybe<Scalars['String']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  condition?: InputMaybe<EItemCondition>;
  id: Scalars['ID']['input'];
  installationYear?: InputMaybe<Scalars['DateTime']['input']>;
  keyIssues?: InputMaybe<Scalars['String']['input']>;
  lastMaintenanceDate?: InputMaybe<Scalars['DateTime']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  purchasedFrom?: InputMaybe<Scalars['String']['input']>;
  serial?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTaskInput = {
  costEstimate?: InputMaybe<Scalars['Float']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  importance?: InputMaybe<Scalars['Int']['input']>;
  levelOfSkill?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  recommendedDate?: InputMaybe<Scalars['DateTime']['input']>;
  riskOfNotDoing?: InputMaybe<Scalars['String']['input']>;
  scheduledDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UpdateZoneInput = {
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateZonesInput = {
  homeId: Scalars['ID']['input'];
  zoneIdsForArchive?: InputMaybe<Array<Scalars['ID']['input']>>;
  zoneTemplatesForAdd?: InputMaybe<Array<ZoneTemplateInput>>;
};

export type UploadFileInput = {
  file: FileInput;
  parentId: Scalars['ID']['input'];
};

export type UploadFilesInput = {
  files: Array<FileInput>;
  parentId: Scalars['ID']['input'];
};

export type UploadResponse = {
  __typename?: 'UploadResponse';
  filename: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  url: Scalars['String']['output'];
};

export type User = {
  __typename?: 'User';
  authServices: Array<EAuthServiceType>;
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isVerified: Scalars['Boolean']['output'];
  jobberClientHubUri?: Maybe<Scalars['String']['output']>;
  jobberWebUri?: Maybe<Scalars['String']['output']>;
  numberOfHomes: Scalars['Int']['output'];
  profile?: Maybe<UserProfile>;
  role: ERole;
  updatedAt: Scalars['DateTime']['output'];
};

export type UsernumberOfHomesArgs = {
  role?: EMembershipRole;
};

export type UserFilterInput = {
  role?: InputMaybe<Array<ERole>>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type UserPaginationResponse = {
  __typename?: 'UserPaginationResponse';
  hasMore: Scalars['Boolean']['output'];
  total: Scalars['Float']['output'];
  users: Array<User>;
};

export type UserProfile = {
  __typename?: 'UserProfile';
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
};

export type Zone = {
  __typename?: 'Zone';
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  homeId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  isArchived: Scalars['Boolean']['output'];
  /** Currently returns all items and doesn`t accept pagination args */
  items: ItemPaginationResponse;
  name: Scalars['String']['output'];
  score?: Maybe<Scalars['Float']['output']>;
  taskCount: Array<Scalars['Int']['output']>;
  template: ZoneTemplate;
  updatedAt: Scalars['DateTime']['output'];
};

export type ZoneitemsArgs = {
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type ZonetaskCountArgs = {
  filters: Array<TaskFilter>;
};

export type ZoneFilter = {
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ZoneFilterInput = {
  homeId: Scalars['ID']['input'];
  isArchivedItem?: InputMaybe<Scalars['Boolean']['input']>;
  isArchivedZone?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type ZonePaginationResponse = {
  __typename?: 'ZonePaginationResponse';
  hasMore: Scalars['Boolean']['output'];
  total: Scalars['Float']['output'];
  zones: Array<Zone>;
};

export type ZoneScore = {
  __typename?: 'ZoneScore';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  score?: Maybe<Scalars['Float']['output']>;
};

export type ZoneTemplate = {
  __typename?: 'ZoneTemplate';
  id: Scalars['ID']['output'];
  maxQuantity: Scalars['Float']['output'];
  name: Scalars['String']['output'];
};

export type ZoneTemplateInput = {
  name: Scalars['String']['input'];
  zoneTemplateId: Scalars['ID']['input'];
};

export type AuthResponseFragment = {
  __typename?: 'AuthResponse';
  accessToken?: string | null;
  refreshToken?: string | null;
  resetToken?: string | null;
  user?: {
    __typename?: 'User';
    id: string;
    email: string;
    role: ERole;
    isVerified: boolean;
    createdAt: string;
    updatedAt: string;
    numberOfHomes: number;
    jobberWebUri?: string | null;
    jobberClientHubUri?: string | null;
    profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string; phone?: string | null } | null;
  } | null;
};

export type CurrentUserFragment = {
  __typename?: 'User';
  id: string;
  email: string;
  role: ERole;
  isVerified: boolean;
  createdAt: string;
  updatedAt: string;
  numberOfHomes: number;
  jobberWebUri?: string | null;
  jobberClientHubUri?: string | null;
  profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string; phone?: string | null } | null;
};

export type DoItPaginationResponseFragment = {
  __typename?: 'DoItPaginationResponse';
  hasMore: boolean;
  total: number;
  doitRequests: Array<{
    __typename?: 'DoItRequest';
    id: string;
    createdBy: string;
    description?: string | null;
    status: EDoItRequestStatus;
    timing?: EDoItRequestTiming | null;
    sentDate?: string | null;
    note?: string | null;
    jobberWebUri?: string | null;
    createdAt: string;
    updatedAt: string;
    home: {
      __typename?: 'Home';
      id: string;
      squareFootage: number;
      yearBuilt: number;
      score?: number | null;
      jobberWebUri?: string | null;
      zoneCount: Array<number>;
      taskCount: Array<number>;
      createdAt: string;
      coverImage?: {
        __typename?: 'File';
        id: string;
        filename: string;
        mimetype: string;
        blurHash?: string | null;
        createdAt: string;
        updatedAt: string;
        url: string;
      } | null;
      location: {
        __typename?: 'Location';
        lat: number;
        lng: number;
        street1: string;
        street2: string;
        city: string;
        state: string;
        country: string;
        zipCode: string;
        googlePlaceId: string;
      };
      homemates: Array<{
        __typename?: 'User';
        id: string;
        email: string;
        role: ERole;
        isVerified: boolean;
        createdAt: string;
        updatedAt: string;
        numberOfHomes: number;
        jobberWebUri?: string | null;
        jobberClientHubUri?: string | null;
        profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string; phone?: string | null } | null;
      }>;
      owner: {
        __typename?: 'User';
        id: string;
        role: ERole;
        email: string;
        isVerified: boolean;
        profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string; phone?: string | null } | null;
      };
    };
    coverImage?: {
      __typename?: 'File';
      id: string;
      filename: string;
      mimetype: string;
      blurHash?: string | null;
      createdAt: string;
      updatedAt: string;
      url: string;
    } | null;
  }>;
};

export type DoItRequestFragment = {
  __typename?: 'DoItRequest';
  id: string;
  createdBy: string;
  description?: string | null;
  status: EDoItRequestStatus;
  timing?: EDoItRequestTiming | null;
  sentDate?: string | null;
  note?: string | null;
  jobberWebUri?: string | null;
  createdAt: string;
  updatedAt: string;
  home: {
    __typename?: 'Home';
    id: string;
    squareFootage: number;
    yearBuilt: number;
    score?: number | null;
    jobberWebUri?: string | null;
    zoneCount: Array<number>;
    taskCount: Array<number>;
    createdAt: string;
    coverImage?: {
      __typename?: 'File';
      id: string;
      filename: string;
      mimetype: string;
      blurHash?: string | null;
      createdAt: string;
      updatedAt: string;
      url: string;
    } | null;
    location: {
      __typename?: 'Location';
      lat: number;
      lng: number;
      street1: string;
      street2: string;
      city: string;
      state: string;
      country: string;
      zipCode: string;
      googlePlaceId: string;
    };
    homemates: Array<{
      __typename?: 'User';
      id: string;
      email: string;
      role: ERole;
      isVerified: boolean;
      createdAt: string;
      updatedAt: string;
      numberOfHomes: number;
      jobberWebUri?: string | null;
      jobberClientHubUri?: string | null;
      profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string; phone?: string | null } | null;
    }>;
    owner: {
      __typename?: 'User';
      id: string;
      role: ERole;
      email: string;
      isVerified: boolean;
      profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string; phone?: string | null } | null;
    };
  };
  coverImage?: {
    __typename?: 'File';
    id: string;
    filename: string;
    mimetype: string;
    blurHash?: string | null;
    createdAt: string;
    updatedAt: string;
    url: string;
  } | null;
};

export type CoverImageFragment = {
  __typename?: 'File';
  id: string;
  filename: string;
  mimetype: string;
  blurHash?: string | null;
  createdAt: string;
  updatedAt: string;
  url: string;
};

export type HomeFragment = {
  __typename?: 'Home';
  id: string;
  squareFootage: number;
  yearBuilt: number;
  score?: number | null;
  jobberWebUri?: string | null;
  zoneCount: Array<number>;
  taskCount: Array<number>;
  createdAt: string;
  coverImage?: {
    __typename?: 'File';
    id: string;
    filename: string;
    mimetype: string;
    blurHash?: string | null;
    createdAt: string;
    updatedAt: string;
    url: string;
  } | null;
  location: {
    __typename?: 'Location';
    lat: number;
    lng: number;
    street1: string;
    street2: string;
    city: string;
    state: string;
    country: string;
    zipCode: string;
    googlePlaceId: string;
  };
  homemates: Array<{
    __typename?: 'User';
    id: string;
    email: string;
    role: ERole;
    isVerified: boolean;
    createdAt: string;
    updatedAt: string;
    numberOfHomes: number;
    jobberWebUri?: string | null;
    jobberClientHubUri?: string | null;
    profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string; phone?: string | null } | null;
  }>;
  owner: {
    __typename?: 'User';
    id: string;
    role: ERole;
    email: string;
    isVerified: boolean;
    profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string; phone?: string | null } | null;
  };
};

export type ItemFragment = {
  __typename?: 'Item';
  id: string;
  zoneId: string;
  homeId: string;
  name: string;
  condition?: EItemCondition | null;
  lastMaintenanceDate?: string | null;
  purchasedFrom?: string | null;
  installationYear?: string | null;
  brand?: string | null;
  model?: string | null;
  serial?: string | null;
  type?: string | null;
  color?: string | null;
  keyIssues?: string | null;
  itemTemplateId: string;
  mainImageId?: string | null;
  taskCount: Array<number>;
  isArchived: boolean;
  coverImage?: {
    __typename?: 'File';
    id: string;
    filename: string;
    mimetype: string;
    blurHash?: string | null;
    createdAt: string;
    updatedAt: string;
    url: string;
  } | null;
  template: { __typename?: 'ItemTemplate'; id: string; name: string; maxQuantity: number; iconUrl: string };
  zone: { __typename?: 'Zone'; name: string; homeId: string; id: string };
};

export type TaskFragment = {
  __typename?: 'Task';
  id: string;
  name: string;
  serviceId?: string | null;
  importance: number;
  levelOfSkill: number;
  status: ETaskStatus;
  jobPerformer: EJobPerformer;
  jobPerformerName?: string | null;
  costEstimate?: number | null;
  recommendedDate: string;
  scheduledDate?: string | null;
  jobberWebUri?: string | null;
  completedDate?: string | null;
  riskOfNotDoing: string;
  description: string;
  isArchived: boolean;
  createdAt: string;
  updatedAt: string;
  coverImage?: {
    __typename?: 'File';
    id: string;
    filename: string;
    mimetype: string;
    blurHash?: string | null;
    createdAt: string;
    updatedAt: string;
    url: string;
  } | null;
  item: {
    __typename?: 'Item';
    id: string;
    zoneId: string;
    homeId: string;
    name: string;
    condition?: EItemCondition | null;
    lastMaintenanceDate?: string | null;
    purchasedFrom?: string | null;
    installationYear?: string | null;
    brand?: string | null;
    model?: string | null;
    serial?: string | null;
    type?: string | null;
    color?: string | null;
    keyIssues?: string | null;
    itemTemplateId: string;
    mainImageId?: string | null;
    taskCount: Array<number>;
    isArchived: boolean;
    coverImage?: {
      __typename?: 'File';
      id: string;
      filename: string;
      mimetype: string;
      blurHash?: string | null;
      createdAt: string;
      updatedAt: string;
      url: string;
    } | null;
    template: { __typename?: 'ItemTemplate'; id: string; name: string; maxQuantity: number; iconUrl: string };
    zone: { __typename?: 'Zone'; name: string; homeId: string; id: string };
  };
};

export type TaskPaginationResponseFragment = {
  __typename?: 'TaskPaginationResponse';
  total: number;
  hasMore: boolean;
  tasks: Array<{
    __typename?: 'Task';
    id: string;
    name: string;
    serviceId?: string | null;
    importance: number;
    levelOfSkill: number;
    status: ETaskStatus;
    jobPerformer: EJobPerformer;
    jobPerformerName?: string | null;
    costEstimate?: number | null;
    recommendedDate: string;
    scheduledDate?: string | null;
    jobberWebUri?: string | null;
    completedDate?: string | null;
    riskOfNotDoing: string;
    description: string;
    isArchived: boolean;
    createdAt: string;
    updatedAt: string;
    item: {
      __typename?: 'Item';
      id: string;
      zoneId: string;
      homeId: string;
      name: string;
      condition?: EItemCondition | null;
      lastMaintenanceDate?: string | null;
      purchasedFrom?: string | null;
      installationYear?: string | null;
      brand?: string | null;
      model?: string | null;
      serial?: string | null;
      type?: string | null;
      color?: string | null;
      keyIssues?: string | null;
      itemTemplateId: string;
      mainImageId?: string | null;
      taskCount: Array<number>;
      isArchived: boolean;
      home: {
        __typename?: 'Home';
        id: string;
        squareFootage: number;
        yearBuilt: number;
        score?: number | null;
        jobberWebUri?: string | null;
        zoneCount: Array<number>;
        taskCount: Array<number>;
        createdAt: string;
        coverImage?: {
          __typename?: 'File';
          id: string;
          filename: string;
          mimetype: string;
          blurHash?: string | null;
          createdAt: string;
          updatedAt: string;
          url: string;
        } | null;
        location: {
          __typename?: 'Location';
          lat: number;
          lng: number;
          street1: string;
          street2: string;
          city: string;
          state: string;
          country: string;
          zipCode: string;
          googlePlaceId: string;
        };
        homemates: Array<{
          __typename?: 'User';
          id: string;
          email: string;
          role: ERole;
          isVerified: boolean;
          createdAt: string;
          updatedAt: string;
          numberOfHomes: number;
          jobberWebUri?: string | null;
          jobberClientHubUri?: string | null;
          profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string; phone?: string | null } | null;
        }>;
        owner: {
          __typename?: 'User';
          id: string;
          role: ERole;
          email: string;
          isVerified: boolean;
          profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string; phone?: string | null } | null;
        };
      };
      coverImage?: {
        __typename?: 'File';
        id: string;
        filename: string;
        mimetype: string;
        blurHash?: string | null;
        createdAt: string;
        updatedAt: string;
        url: string;
      } | null;
      template: { __typename?: 'ItemTemplate'; id: string; name: string; maxQuantity: number; iconUrl: string };
      zone: { __typename?: 'Zone'; name: string; homeId: string; id: string };
    };
    coverImage?: {
      __typename?: 'File';
      id: string;
      filename: string;
      mimetype: string;
      blurHash?: string | null;
      createdAt: string;
      updatedAt: string;
      url: string;
    } | null;
  }>;
  totalByMonth: Array<{ __typename?: 'MonthTotal'; month: string; total: number }>;
};

export type ZoneFragment = {
  __typename?: 'Zone';
  id: string;
  name: string;
  homeId: string;
  score?: number | null;
  createdAt: string;
  updatedAt: string;
  isArchived: boolean;
  taskCount: Array<number>;
  items: {
    __typename?: 'ItemPaginationResponse';
    total: number;
    items: Array<{ __typename?: 'Item'; id: string; name: string; itemTemplateId: string }>;
  };
  template: { __typename?: 'ZoneTemplate'; id: string; name: string; maxQuantity: number };
};

export type ZonePaginationResponseFragment = {
  __typename?: 'ZonePaginationResponse';
  total: number;
  hasMore: boolean;
  zones: Array<{
    __typename?: 'Zone';
    id: string;
    name: string;
    homeId: string;
    score?: number | null;
    createdAt: string;
    updatedAt: string;
    isArchived: boolean;
    taskCount: Array<number>;
    items: {
      __typename?: 'ItemPaginationResponse';
      total: number;
      items: Array<{ __typename?: 'Item'; id: string; name: string; itemTemplateId: string }>;
    };
    template: { __typename?: 'ZoneTemplate'; id: string; name: string; maxQuantity: number };
  }>;
};

export type ZoneTemplateFragment = { __typename?: 'ZoneTemplate'; id: string; name: string; maxQuantity: number };

export type AddItemsMutationVariables = Exact<{
  input: AddItemsInput;
}>;

export type AddItemsMutation = {
  __typename?: 'Mutation';
  addItems: Array<{
    __typename?: 'Item';
    id: string;
    zoneId: string;
    homeId: string;
    name: string;
    condition?: EItemCondition | null;
    lastMaintenanceDate?: string | null;
    purchasedFrom?: string | null;
    installationYear?: string | null;
    brand?: string | null;
    model?: string | null;
    serial?: string | null;
    type?: string | null;
    color?: string | null;
    keyIssues?: string | null;
    itemTemplateId: string;
    mainImageId?: string | null;
    taskCount: Array<number>;
    isArchived: boolean;
    coverImage?: {
      __typename?: 'File';
      id: string;
      filename: string;
      mimetype: string;
      blurHash?: string | null;
      createdAt: string;
      updatedAt: string;
      url: string;
    } | null;
    template: { __typename?: 'ItemTemplate'; id: string; name: string; maxQuantity: number; iconUrl: string };
    zone: { __typename?: 'Zone'; name: string; homeId: string; id: string };
  }>;
};

export type ArchiveItemMutationVariables = Exact<{
  itemId: Scalars['ID']['input'];
}>;

export type ArchiveItemMutation = { __typename?: 'Mutation'; archiveItem: boolean };

export type ArchiveTaskMutationVariables = Exact<{
  taskId: Scalars['ID']['input'];
}>;

export type ArchiveTaskMutation = { __typename?: 'Mutation'; archiveTask: boolean };

export type AssignHomematesMutationVariables = Exact<{
  input: UpdateHomematesInput;
}>;

export type AssignHomematesMutation = {
  __typename?: 'Mutation';
  assignHomemates: {
    __typename?: 'Home';
    id: string;
    squareFootage: number;
    yearBuilt: number;
    score?: number | null;
    jobberWebUri?: string | null;
    zoneCount: Array<number>;
    taskCount: Array<number>;
    createdAt: string;
    coverImage?: {
      __typename?: 'File';
      id: string;
      filename: string;
      mimetype: string;
      blurHash?: string | null;
      createdAt: string;
      updatedAt: string;
      url: string;
    } | null;
    location: {
      __typename?: 'Location';
      lat: number;
      lng: number;
      street1: string;
      street2: string;
      city: string;
      state: string;
      country: string;
      zipCode: string;
      googlePlaceId: string;
    };
    homemates: Array<{
      __typename?: 'User';
      id: string;
      email: string;
      role: ERole;
      isVerified: boolean;
      createdAt: string;
      updatedAt: string;
      numberOfHomes: number;
      jobberWebUri?: string | null;
      jobberClientHubUri?: string | null;
      profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string; phone?: string | null } | null;
    }>;
    owner: {
      __typename?: 'User';
      id: string;
      role: ERole;
      email: string;
      isVerified: boolean;
      profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string; phone?: string | null } | null;
    };
  };
};

export type AssignTaskMutationVariables = Exact<{
  input: AssignTaskInput;
}>;

export type AssignTaskMutation = {
  __typename?: 'Mutation';
  assignTask: {
    __typename?: 'Task';
    id: string;
    name: string;
    serviceId?: string | null;
    importance: number;
    levelOfSkill: number;
    status: ETaskStatus;
    jobPerformer: EJobPerformer;
    jobPerformerName?: string | null;
    costEstimate?: number | null;
    recommendedDate: string;
    scheduledDate?: string | null;
    jobberWebUri?: string | null;
    completedDate?: string | null;
    riskOfNotDoing: string;
    description: string;
    isArchived: boolean;
    createdAt: string;
    updatedAt: string;
    coverImage?: {
      __typename?: 'File';
      id: string;
      filename: string;
      mimetype: string;
      blurHash?: string | null;
      createdAt: string;
      updatedAt: string;
      url: string;
    } | null;
    item: {
      __typename?: 'Item';
      id: string;
      zoneId: string;
      homeId: string;
      name: string;
      condition?: EItemCondition | null;
      lastMaintenanceDate?: string | null;
      purchasedFrom?: string | null;
      installationYear?: string | null;
      brand?: string | null;
      model?: string | null;
      serial?: string | null;
      type?: string | null;
      color?: string | null;
      keyIssues?: string | null;
      itemTemplateId: string;
      mainImageId?: string | null;
      taskCount: Array<number>;
      isArchived: boolean;
      coverImage?: {
        __typename?: 'File';
        id: string;
        filename: string;
        mimetype: string;
        blurHash?: string | null;
        createdAt: string;
        updatedAt: string;
        url: string;
      } | null;
      template: { __typename?: 'ItemTemplate'; id: string; name: string; maxQuantity: number; iconUrl: string };
      zone: { __typename?: 'Zone'; name: string; homeId: string; id: string };
    };
  };
};

export type ChangePasswordMutationVariables = Exact<{
  input: ChangePasswordInput;
}>;

export type ChangePasswordMutation = { __typename?: 'Mutation'; changePassword: boolean };

export type ChangeUserRoleMutationVariables = Exact<{
  input: ChangeRoleInput;
}>;

export type ChangeUserRoleMutation = {
  __typename?: 'Mutation';
  changeUserRole: { __typename?: 'User'; id: string; role: ERole };
};

export type ConfirmDoItRequestDraftMutationVariables = Exact<{
  draftId: Scalars['ID']['input'];
}>;

export type ConfirmDoItRequestDraftMutation = {
  __typename?: 'Mutation';
  confirmDoItRequestDraft: {
    __typename?: 'DoItRequest';
    id: string;
    createdBy: string;
    description?: string | null;
    status: EDoItRequestStatus;
    timing?: EDoItRequestTiming | null;
    sentDate?: string | null;
    note?: string | null;
    jobberWebUri?: string | null;
    createdAt: string;
    updatedAt: string;
    home: {
      __typename?: 'Home';
      id: string;
      squareFootage: number;
      yearBuilt: number;
      score?: number | null;
      jobberWebUri?: string | null;
      zoneCount: Array<number>;
      taskCount: Array<number>;
      createdAt: string;
      coverImage?: {
        __typename?: 'File';
        id: string;
        filename: string;
        mimetype: string;
        blurHash?: string | null;
        createdAt: string;
        updatedAt: string;
        url: string;
      } | null;
      location: {
        __typename?: 'Location';
        lat: number;
        lng: number;
        street1: string;
        street2: string;
        city: string;
        state: string;
        country: string;
        zipCode: string;
        googlePlaceId: string;
      };
      homemates: Array<{
        __typename?: 'User';
        id: string;
        email: string;
        role: ERole;
        isVerified: boolean;
        createdAt: string;
        updatedAt: string;
        numberOfHomes: number;
        jobberWebUri?: string | null;
        jobberClientHubUri?: string | null;
        profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string; phone?: string | null } | null;
      }>;
      owner: {
        __typename?: 'User';
        id: string;
        role: ERole;
        email: string;
        isVerified: boolean;
        profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string; phone?: string | null } | null;
      };
    };
    coverImage?: {
      __typename?: 'File';
      id: string;
      filename: string;
      mimetype: string;
      blurHash?: string | null;
      createdAt: string;
      updatedAt: string;
      url: string;
    } | null;
  };
};

export type ConfirmDoitRequestImagesUploadingMutationVariables = Exact<{
  input: ConfirmFilesInput;
}>;

export type ConfirmDoitRequestImagesUploadingMutation = {
  __typename?: 'Mutation';
  confirmDoitRequestImagesUploading: Array<{
    __typename?: 'File';
    id: string;
    filename: string;
    mimetype: string;
    blurHash?: string | null;
    createdAt: string;
    updatedAt: string;
    url: string;
  }>;
};

export type ConfirmHomeImageUploadingMutationVariables = Exact<{
  input: ConfirmFileInput;
}>;

export type ConfirmHomeImageUploadingMutation = {
  __typename?: 'Mutation';
  confirmHomeImageUploading: {
    __typename?: 'File';
    id: string;
    filename: string;
    mimetype: string;
    blurHash?: string | null;
    createdAt: string;
    updatedAt: string;
    url: string;
  };
};

export type ConfirmItemImagesUploadingMutationVariables = Exact<{
  input: ConfirmFilesInput;
}>;

export type ConfirmItemImagesUploadingMutation = {
  __typename?: 'Mutation';
  confirmItemImagesUploading: Array<{
    __typename?: 'ItemImage';
    id: string;
    filename: string;
    mimetype: string;
    blurHash?: string | null;
    createdAt: string;
    updatedAt: string;
    url: string;
    isMain: boolean;
  }>;
};

export type ConfirmTaskImagesUploadingMutationVariables = Exact<{
  input: ConfirmFilesInput;
}>;

export type ConfirmTaskImagesUploadingMutation = {
  __typename?: 'Mutation';
  confirmTaskImagesUploading: Array<{
    __typename?: 'TaskImage';
    id: string;
    filename: string;
    mimetype: string;
    blurHash?: string | null;
    createdAt: string;
    updatedAt: string;
    url: string;
    isMain: boolean;
  }>;
};

export type CreateDoItRequestDraftMutationVariables = Exact<{
  homeId: Scalars['ID']['input'];
}>;

export type CreateDoItRequestDraftMutation = {
  __typename?: 'Mutation';
  createDoItRequestDraft: {
    __typename?: 'DoItRequest';
    id: string;
    createdBy: string;
    description?: string | null;
    status: EDoItRequestStatus;
    timing?: EDoItRequestTiming | null;
    sentDate?: string | null;
    note?: string | null;
    jobberWebUri?: string | null;
    createdAt: string;
    updatedAt: string;
    home: {
      __typename?: 'Home';
      id: string;
      squareFootage: number;
      yearBuilt: number;
      score?: number | null;
      jobberWebUri?: string | null;
      zoneCount: Array<number>;
      taskCount: Array<number>;
      createdAt: string;
      coverImage?: {
        __typename?: 'File';
        id: string;
        filename: string;
        mimetype: string;
        blurHash?: string | null;
        createdAt: string;
        updatedAt: string;
        url: string;
      } | null;
      location: {
        __typename?: 'Location';
        lat: number;
        lng: number;
        street1: string;
        street2: string;
        city: string;
        state: string;
        country: string;
        zipCode: string;
        googlePlaceId: string;
      };
      homemates: Array<{
        __typename?: 'User';
        id: string;
        email: string;
        role: ERole;
        isVerified: boolean;
        createdAt: string;
        updatedAt: string;
        numberOfHomes: number;
        jobberWebUri?: string | null;
        jobberClientHubUri?: string | null;
        profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string; phone?: string | null } | null;
      }>;
      owner: {
        __typename?: 'User';
        id: string;
        role: ERole;
        email: string;
        isVerified: boolean;
        profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string; phone?: string | null } | null;
      };
    };
    coverImage?: {
      __typename?: 'File';
      id: string;
      filename: string;
      mimetype: string;
      blurHash?: string | null;
      createdAt: string;
      updatedAt: string;
      url: string;
    } | null;
  };
};

export type CreateHomeMutationVariables = Exact<{
  input: CreateHomeInput;
}>;

export type CreateHomeMutation = {
  __typename?: 'Mutation';
  createHome: {
    __typename?: 'Home';
    id: string;
    squareFootage: number;
    yearBuilt: number;
    score?: number | null;
    jobberWebUri?: string | null;
    zoneCount: Array<number>;
    taskCount: Array<number>;
    createdAt: string;
    coverImage?: {
      __typename?: 'File';
      id: string;
      filename: string;
      mimetype: string;
      blurHash?: string | null;
      createdAt: string;
      updatedAt: string;
      url: string;
    } | null;
    location: {
      __typename?: 'Location';
      lat: number;
      lng: number;
      street1: string;
      street2: string;
      city: string;
      state: string;
      country: string;
      zipCode: string;
      googlePlaceId: string;
    };
    homemates: Array<{
      __typename?: 'User';
      id: string;
      email: string;
      role: ERole;
      isVerified: boolean;
      createdAt: string;
      updatedAt: string;
      numberOfHomes: number;
      jobberWebUri?: string | null;
      jobberClientHubUri?: string | null;
      profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string; phone?: string | null } | null;
    }>;
    owner: {
      __typename?: 'User';
      id: string;
      role: ERole;
      email: string;
      isVerified: boolean;
      profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string; phone?: string | null } | null;
    };
  };
};

export type CreateTaskMutationVariables = Exact<{
  input: CreateTaskInput;
}>;

export type CreateTaskMutation = {
  __typename?: 'Mutation';
  createTask: {
    __typename?: 'Task';
    id: string;
    name: string;
    serviceId?: string | null;
    importance: number;
    levelOfSkill: number;
    status: ETaskStatus;
    jobPerformer: EJobPerformer;
    jobPerformerName?: string | null;
    costEstimate?: number | null;
    recommendedDate: string;
    scheduledDate?: string | null;
    jobberWebUri?: string | null;
    completedDate?: string | null;
    riskOfNotDoing: string;
    description: string;
    isArchived: boolean;
    createdAt: string;
    updatedAt: string;
    coverImage?: {
      __typename?: 'File';
      id: string;
      filename: string;
      mimetype: string;
      blurHash?: string | null;
      createdAt: string;
      updatedAt: string;
      url: string;
    } | null;
    item: {
      __typename?: 'Item';
      id: string;
      zoneId: string;
      homeId: string;
      name: string;
      condition?: EItemCondition | null;
      lastMaintenanceDate?: string | null;
      purchasedFrom?: string | null;
      installationYear?: string | null;
      brand?: string | null;
      model?: string | null;
      serial?: string | null;
      type?: string | null;
      color?: string | null;
      keyIssues?: string | null;
      itemTemplateId: string;
      mainImageId?: string | null;
      taskCount: Array<number>;
      isArchived: boolean;
      coverImage?: {
        __typename?: 'File';
        id: string;
        filename: string;
        mimetype: string;
        blurHash?: string | null;
        createdAt: string;
        updatedAt: string;
        url: string;
      } | null;
      template: { __typename?: 'ItemTemplate'; id: string; name: string; maxQuantity: number; iconUrl: string };
      zone: { __typename?: 'Zone'; name: string; homeId: string; id: string };
    };
  };
};

export type DeferTaskMutationVariables = Exact<{
  input: DeferTaskInput;
}>;

export type DeferTaskMutation = {
  __typename?: 'Mutation';
  deferTask: {
    __typename?: 'Task';
    id: string;
    name: string;
    serviceId?: string | null;
    importance: number;
    levelOfSkill: number;
    status: ETaskStatus;
    jobPerformer: EJobPerformer;
    jobPerformerName?: string | null;
    costEstimate?: number | null;
    recommendedDate: string;
    scheduledDate?: string | null;
    jobberWebUri?: string | null;
    completedDate?: string | null;
    riskOfNotDoing: string;
    description: string;
    isArchived: boolean;
    createdAt: string;
    updatedAt: string;
    coverImage?: {
      __typename?: 'File';
      id: string;
      filename: string;
      mimetype: string;
      blurHash?: string | null;
      createdAt: string;
      updatedAt: string;
      url: string;
    } | null;
    item: {
      __typename?: 'Item';
      id: string;
      zoneId: string;
      homeId: string;
      name: string;
      condition?: EItemCondition | null;
      lastMaintenanceDate?: string | null;
      purchasedFrom?: string | null;
      installationYear?: string | null;
      brand?: string | null;
      model?: string | null;
      serial?: string | null;
      type?: string | null;
      color?: string | null;
      keyIssues?: string | null;
      itemTemplateId: string;
      mainImageId?: string | null;
      taskCount: Array<number>;
      isArchived: boolean;
      coverImage?: {
        __typename?: 'File';
        id: string;
        filename: string;
        mimetype: string;
        blurHash?: string | null;
        createdAt: string;
        updatedAt: string;
        url: string;
      } | null;
      template: { __typename?: 'ItemTemplate'; id: string; name: string; maxQuantity: number; iconUrl: string };
      zone: { __typename?: 'Zone'; name: string; homeId: string; id: string };
    };
  };
};

export type DeleteDoitRequestImagesMutationVariables = Exact<{
  input: DeleteFilesInput;
}>;

export type DeleteDoitRequestImagesMutation = { __typename?: 'Mutation'; deleteDoitRequestImages: boolean };

export type DeleteInvitationMutationVariables = Exact<{
  invitationId: Scalars['ID']['input'];
}>;

export type DeleteInvitationMutation = { __typename?: 'Mutation'; deleteInvitation: boolean };

export type DeleteItemImagesMutationVariables = Exact<{
  input: DeleteFilesInput;
}>;

export type DeleteItemImagesMutation = { __typename?: 'Mutation'; deleteItemImages: boolean };

export type DeleteTaskImagesMutationVariables = Exact<{
  input: DeleteTaskImagesInput;
}>;

export type DeleteTaskImagesMutation = { __typename?: 'Mutation'; deleteTaskImages: boolean };

export type FinishDoitRequestMutationVariables = Exact<{
  doitRequestId: Scalars['ID']['input'];
}>;

export type FinishDoitRequestMutation = {
  __typename?: 'Mutation';
  finishDoitRequest: {
    __typename?: 'DoItRequest';
    id: string;
    createdBy: string;
    description?: string | null;
    status: EDoItRequestStatus;
    timing?: EDoItRequestTiming | null;
    sentDate?: string | null;
    note?: string | null;
    jobberWebUri?: string | null;
    createdAt: string;
    updatedAt: string;
    home: {
      __typename?: 'Home';
      id: string;
      squareFootage: number;
      yearBuilt: number;
      score?: number | null;
      jobberWebUri?: string | null;
      zoneCount: Array<number>;
      taskCount: Array<number>;
      createdAt: string;
      coverImage?: {
        __typename?: 'File';
        id: string;
        filename: string;
        mimetype: string;
        blurHash?: string | null;
        createdAt: string;
        updatedAt: string;
        url: string;
      } | null;
      location: {
        __typename?: 'Location';
        lat: number;
        lng: number;
        street1: string;
        street2: string;
        city: string;
        state: string;
        country: string;
        zipCode: string;
        googlePlaceId: string;
      };
      homemates: Array<{
        __typename?: 'User';
        id: string;
        email: string;
        role: ERole;
        isVerified: boolean;
        createdAt: string;
        updatedAt: string;
        numberOfHomes: number;
        jobberWebUri?: string | null;
        jobberClientHubUri?: string | null;
        profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string; phone?: string | null } | null;
      }>;
      owner: {
        __typename?: 'User';
        id: string;
        role: ERole;
        email: string;
        isVerified: boolean;
        profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string; phone?: string | null } | null;
      };
    };
    coverImage?: {
      __typename?: 'File';
      id: string;
      filename: string;
      mimetype: string;
      blurHash?: string | null;
      createdAt: string;
      updatedAt: string;
      url: string;
    } | null;
  };
};

export type FinishTaskMutationVariables = Exact<{
  taskId: Scalars['ID']['input'];
}>;

export type FinishTaskMutation = {
  __typename?: 'Mutation';
  finishTask: {
    __typename?: 'Task';
    id: string;
    name: string;
    serviceId?: string | null;
    importance: number;
    levelOfSkill: number;
    status: ETaskStatus;
    jobPerformer: EJobPerformer;
    jobPerformerName?: string | null;
    costEstimate?: number | null;
    recommendedDate: string;
    scheduledDate?: string | null;
    jobberWebUri?: string | null;
    completedDate?: string | null;
    riskOfNotDoing: string;
    description: string;
    isArchived: boolean;
    createdAt: string;
    updatedAt: string;
    coverImage?: {
      __typename?: 'File';
      id: string;
      filename: string;
      mimetype: string;
      blurHash?: string | null;
      createdAt: string;
      updatedAt: string;
      url: string;
    } | null;
    item: {
      __typename?: 'Item';
      id: string;
      zoneId: string;
      homeId: string;
      name: string;
      condition?: EItemCondition | null;
      lastMaintenanceDate?: string | null;
      purchasedFrom?: string | null;
      installationYear?: string | null;
      brand?: string | null;
      model?: string | null;
      serial?: string | null;
      type?: string | null;
      color?: string | null;
      keyIssues?: string | null;
      itemTemplateId: string;
      mainImageId?: string | null;
      taskCount: Array<number>;
      isArchived: boolean;
      coverImage?: {
        __typename?: 'File';
        id: string;
        filename: string;
        mimetype: string;
        blurHash?: string | null;
        createdAt: string;
        updatedAt: string;
        url: string;
      } | null;
      template: { __typename?: 'ItemTemplate'; id: string; name: string; maxQuantity: number; iconUrl: string };
      zone: { __typename?: 'Zone'; name: string; homeId: string; id: string };
    };
  };
};

export type InviteUserMutationVariables = Exact<{
  input: InviteUserInput;
}>;

export type InviteUserMutation = {
  __typename?: 'Mutation';
  inviteUser: {
    __typename?: 'InviteUserResponse';
    code: EInviteUserResponseCode;
    invitation?: {
      __typename?: 'Invitation';
      id: string;
      email: string;
      createdBy: string;
      role: ERole;
      expiresAt: string;
      profile: { __typename?: 'UserProfile'; firstName: string; lastName: string; phone?: string | null };
    } | null;
  };
};

export type LoginMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;

export type LoginMutation = {
  __typename?: 'Mutation';
  login: {
    __typename?: 'AuthResponse';
    accessToken?: string | null;
    refreshToken?: string | null;
    resetToken?: string | null;
    user?: {
      __typename?: 'User';
      id: string;
      email: string;
      role: ERole;
      isVerified: boolean;
      createdAt: string;
      updatedAt: string;
      numberOfHomes: number;
      jobberWebUri?: string | null;
      jobberClientHubUri?: string | null;
      profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string; phone?: string | null } | null;
    } | null;
  };
};

export type LoginWithAppleTokenMutationVariables = Exact<{
  input: AppleLoginInput;
}>;

export type LoginWithAppleTokenMutation = {
  __typename?: 'Mutation';
  loginWithAppleToken: {
    __typename?: 'AuthResponse';
    accessToken?: string | null;
    refreshToken?: string | null;
    resetToken?: string | null;
    user?: {
      __typename?: 'User';
      id: string;
      email: string;
      role: ERole;
      isVerified: boolean;
      createdAt: string;
      updatedAt: string;
      numberOfHomes: number;
      jobberWebUri?: string | null;
      jobberClientHubUri?: string | null;
      profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string; phone?: string | null } | null;
    } | null;
  };
};

export type LoginWithGoogleCodeMutationVariables = Exact<{
  code: Scalars['String']['input'];
}>;

export type LoginWithGoogleCodeMutation = {
  __typename?: 'Mutation';
  loginWithGoogleCode: {
    __typename?: 'AuthResponse';
    accessToken?: string | null;
    refreshToken?: string | null;
    resetToken?: string | null;
    user?: {
      __typename?: 'User';
      id: string;
      email: string;
      role: ERole;
      isVerified: boolean;
      createdAt: string;
      updatedAt: string;
      numberOfHomes: number;
      jobberWebUri?: string | null;
      jobberClientHubUri?: string | null;
      profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string; phone?: string | null } | null;
    } | null;
  };
};

export type LoginWithGoogleTokenMutationVariables = Exact<{
  googleToken: Scalars['String']['input'];
}>;

export type LoginWithGoogleTokenMutation = {
  __typename?: 'Mutation';
  loginWithGoogleToken: {
    __typename?: 'AuthResponse';
    accessToken?: string | null;
    refreshToken?: string | null;
    resetToken?: string | null;
    user?: {
      __typename?: 'User';
      id: string;
      email: string;
      role: ERole;
      isVerified: boolean;
      createdAt: string;
      updatedAt: string;
      numberOfHomes: number;
      jobberWebUri?: string | null;
      jobberClientHubUri?: string | null;
      profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string; phone?: string | null } | null;
    } | null;
  };
};

export type LoginWithResetPasswordCodeMutationVariables = Exact<{
  email: Scalars['String']['input'];
  code: Scalars['String']['input'];
}>;

export type LoginWithResetPasswordCodeMutation = {
  __typename?: 'Mutation';
  loginWithResetPasswordCode: {
    __typename?: 'AuthResponse';
    accessToken?: string | null;
    refreshToken?: string | null;
    resetToken?: string | null;
    user?: {
      __typename?: 'User';
      id: string;
      email: string;
      role: ERole;
      isVerified: boolean;
      createdAt: string;
      updatedAt: string;
      numberOfHomes: number;
      jobberWebUri?: string | null;
      jobberClientHubUri?: string | null;
      profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string; phone?: string | null } | null;
    } | null;
  };
};

export type LogoutMutationVariables = Exact<{ [key: string]: never }>;

export type LogoutMutation = { __typename?: 'Mutation'; logout: boolean };

export type RegisterByInvitationMutationVariables = Exact<{
  input: RegisterByInvitationInput;
}>;

export type RegisterByInvitationMutation = {
  __typename?: 'Mutation';
  registerByInvitation: {
    __typename?: 'AuthResponse';
    accessToken?: string | null;
    refreshToken?: string | null;
    resetToken?: string | null;
    user?: {
      __typename?: 'User';
      id: string;
      email: string;
      role: ERole;
      isVerified: boolean;
      createdAt: string;
      updatedAt: string;
      numberOfHomes: number;
      jobberWebUri?: string | null;
      jobberClientHubUri?: string | null;
      profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string; phone?: string | null } | null;
    } | null;
  };
};

export type RequestDoitRequestImagesUploadingMutationVariables = Exact<{
  input: UploadFilesInput;
}>;

export type RequestDoitRequestImagesUploadingMutation = {
  __typename?: 'Mutation';
  requestDoitRequestImagesUploading: Array<{
    __typename?: 'UploadResponse';
    id: string;
    url: string;
    filename: string;
  }>;
};

export type RequestHomeImageUploadingMutationVariables = Exact<{
  input: UploadFileInput;
}>;

export type RequestHomeImageUploadingMutation = {
  __typename?: 'Mutation';
  requestHomeImageUploading: { __typename?: 'UploadResponse'; id: string; url: string };
};

export type RequestItemImagesUploadingMutationVariables = Exact<{
  input: UploadFilesInput;
}>;

export type RequestItemImagesUploadingMutation = {
  __typename?: 'Mutation';
  requestItemImagesUploading: Array<{ __typename?: 'UploadResponse'; id: string; url: string; filename: string }>;
};

export type RequestTaskImagesUploadingMutationVariables = Exact<{
  input: UploadFilesInput;
}>;

export type RequestTaskImagesUploadingMutation = {
  __typename?: 'Mutation';
  requestTaskImagesUploading: Array<{ __typename?: 'UploadResponse'; id: string; url: string; filename: string }>;
};

export type ResetPasswordMutationVariables = Exact<{
  input: ResetPasswordInput;
}>;

export type ResetPasswordMutation = { __typename?: 'Mutation'; resetPassword: boolean };

export type SendResetPasswordCodeMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;

export type SendResetPasswordCodeMutation = { __typename?: 'Mutation'; sendResetPasswordCode: boolean };

export type SendVerificationCodeMutationVariables = Exact<{ [key: string]: never }>;

export type SendVerificationCodeMutation = { __typename?: 'Mutation'; sendVerificationCode: boolean };

export type SetClientHubUrlMutationVariables = Exact<{
  input: SetClientUrlInput;
}>;

export type SetClientHubUrlMutation = {
  __typename?: 'Mutation';
  setClientHubUrl: {
    __typename?: 'User';
    id: string;
    email: string;
    role: ERole;
    isVerified: boolean;
    createdAt: string;
    updatedAt: string;
    numberOfHomes: number;
    jobberWebUri?: string | null;
    jobberClientHubUri?: string | null;
    profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string; phone?: string | null } | null;
  };
};

export type SetJobberTokensMutationVariables = Exact<{
  code: Scalars['String']['input'];
}>;

export type SetJobberTokensMutation = { __typename?: 'Mutation'; setJobberTokens: boolean };

export type SetMainItemImageMutationVariables = Exact<{
  input: SetMainFileInput;
}>;

export type SetMainItemImageMutation = { __typename?: 'Mutation'; setMainItemImage: boolean };

export type SetMainTaskImageMutationVariables = Exact<{
  input: SetMainFileInput;
}>;

export type SetMainTaskImageMutation = { __typename?: 'Mutation'; setMainTaskImage: boolean };

export type SetProfileMutationVariables = Exact<{
  input: SetProfileInput;
}>;

export type SetProfileMutation = {
  __typename?: 'Mutation';
  setProfile: {
    __typename?: 'User';
    id: string;
    email: string;
    role: ERole;
    isVerified: boolean;
    createdAt: string;
    updatedAt: string;
    numberOfHomes: number;
    jobberWebUri?: string | null;
    jobberClientHubUri?: string | null;
    profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string; phone?: string | null } | null;
  };
};

export type SetUserPasswordMutationVariables = Exact<{
  input: SetPasswordInput;
}>;

export type SetUserPasswordMutation = { __typename?: 'Mutation'; setUserPassword: boolean };

export type UnassignHomematesMutationVariables = Exact<{
  input: UpdateHomematesInput;
}>;

export type UnassignHomematesMutation = {
  __typename?: 'Mutation';
  unassignHomemates: {
    __typename?: 'Home';
    id: string;
    squareFootage: number;
    yearBuilt: number;
    score?: number | null;
    jobberWebUri?: string | null;
    zoneCount: Array<number>;
    taskCount: Array<number>;
    createdAt: string;
    coverImage?: {
      __typename?: 'File';
      id: string;
      filename: string;
      mimetype: string;
      blurHash?: string | null;
      createdAt: string;
      updatedAt: string;
      url: string;
    } | null;
    location: {
      __typename?: 'Location';
      lat: number;
      lng: number;
      street1: string;
      street2: string;
      city: string;
      state: string;
      country: string;
      zipCode: string;
      googlePlaceId: string;
    };
    homemates: Array<{
      __typename?: 'User';
      id: string;
      email: string;
      role: ERole;
      isVerified: boolean;
      createdAt: string;
      updatedAt: string;
      numberOfHomes: number;
      jobberWebUri?: string | null;
      jobberClientHubUri?: string | null;
      profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string; phone?: string | null } | null;
    }>;
    owner: {
      __typename?: 'User';
      id: string;
      role: ERole;
      email: string;
      isVerified: boolean;
      profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string; phone?: string | null } | null;
    };
  };
};

export type UpdateDoItRequestDraftMutationVariables = Exact<{
  input: UpdateDoItRequestDraftInput;
}>;

export type UpdateDoItRequestDraftMutation = {
  __typename?: 'Mutation';
  updateDoItRequestDraft: {
    __typename?: 'DoItRequest';
    id: string;
    createdBy: string;
    description?: string | null;
    status: EDoItRequestStatus;
    timing?: EDoItRequestTiming | null;
    sentDate?: string | null;
    note?: string | null;
    jobberWebUri?: string | null;
    createdAt: string;
    updatedAt: string;
    home: {
      __typename?: 'Home';
      id: string;
      squareFootage: number;
      yearBuilt: number;
      score?: number | null;
      jobberWebUri?: string | null;
      zoneCount: Array<number>;
      taskCount: Array<number>;
      createdAt: string;
      coverImage?: {
        __typename?: 'File';
        id: string;
        filename: string;
        mimetype: string;
        blurHash?: string | null;
        createdAt: string;
        updatedAt: string;
        url: string;
      } | null;
      location: {
        __typename?: 'Location';
        lat: number;
        lng: number;
        street1: string;
        street2: string;
        city: string;
        state: string;
        country: string;
        zipCode: string;
        googlePlaceId: string;
      };
      homemates: Array<{
        __typename?: 'User';
        id: string;
        email: string;
        role: ERole;
        isVerified: boolean;
        createdAt: string;
        updatedAt: string;
        numberOfHomes: number;
        jobberWebUri?: string | null;
        jobberClientHubUri?: string | null;
        profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string; phone?: string | null } | null;
      }>;
      owner: {
        __typename?: 'User';
        id: string;
        role: ERole;
        email: string;
        isVerified: boolean;
        profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string; phone?: string | null } | null;
      };
    };
    coverImage?: {
      __typename?: 'File';
      id: string;
      filename: string;
      mimetype: string;
      blurHash?: string | null;
      createdAt: string;
      updatedAt: string;
      url: string;
    } | null;
  };
};

export type UpdateDoitRequestMutationVariables = Exact<{
  input: UpdateDoItRequestInput;
}>;

export type UpdateDoitRequestMutation = {
  __typename?: 'Mutation';
  updateDoitRequest: {
    __typename?: 'DoItRequest';
    id: string;
    createdBy: string;
    description?: string | null;
    status: EDoItRequestStatus;
    timing?: EDoItRequestTiming | null;
    sentDate?: string | null;
    note?: string | null;
    jobberWebUri?: string | null;
    createdAt: string;
    updatedAt: string;
    home: {
      __typename?: 'Home';
      id: string;
      squareFootage: number;
      yearBuilt: number;
      score?: number | null;
      jobberWebUri?: string | null;
      zoneCount: Array<number>;
      taskCount: Array<number>;
      createdAt: string;
      coverImage?: {
        __typename?: 'File';
        id: string;
        filename: string;
        mimetype: string;
        blurHash?: string | null;
        createdAt: string;
        updatedAt: string;
        url: string;
      } | null;
      location: {
        __typename?: 'Location';
        lat: number;
        lng: number;
        street1: string;
        street2: string;
        city: string;
        state: string;
        country: string;
        zipCode: string;
        googlePlaceId: string;
      };
      homemates: Array<{
        __typename?: 'User';
        id: string;
        email: string;
        role: ERole;
        isVerified: boolean;
        createdAt: string;
        updatedAt: string;
        numberOfHomes: number;
        jobberWebUri?: string | null;
        jobberClientHubUri?: string | null;
        profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string; phone?: string | null } | null;
      }>;
      owner: {
        __typename?: 'User';
        id: string;
        role: ERole;
        email: string;
        isVerified: boolean;
        profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string; phone?: string | null } | null;
      };
    };
    coverImage?: {
      __typename?: 'File';
      id: string;
      filename: string;
      mimetype: string;
      blurHash?: string | null;
      createdAt: string;
      updatedAt: string;
      url: string;
    } | null;
  };
};

export type UpdateHomeMutationVariables = Exact<{
  input: UpdateHomeInput;
}>;

export type UpdateHomeMutation = {
  __typename?: 'Mutation';
  updateHome: {
    __typename?: 'Home';
    id: string;
    squareFootage: number;
    yearBuilt: number;
    score?: number | null;
    jobberWebUri?: string | null;
    zoneCount: Array<number>;
    taskCount: Array<number>;
    createdAt: string;
    coverImage?: {
      __typename?: 'File';
      id: string;
      filename: string;
      mimetype: string;
      blurHash?: string | null;
      createdAt: string;
      updatedAt: string;
      url: string;
    } | null;
    location: {
      __typename?: 'Location';
      lat: number;
      lng: number;
      street1: string;
      street2: string;
      city: string;
      state: string;
      country: string;
      zipCode: string;
      googlePlaceId: string;
    };
    homemates: Array<{
      __typename?: 'User';
      id: string;
      email: string;
      role: ERole;
      isVerified: boolean;
      createdAt: string;
      updatedAt: string;
      numberOfHomes: number;
      jobberWebUri?: string | null;
      jobberClientHubUri?: string | null;
      profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string; phone?: string | null } | null;
    }>;
    owner: {
      __typename?: 'User';
      id: string;
      role: ERole;
      email: string;
      isVerified: boolean;
      profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string; phone?: string | null } | null;
    };
  };
};

export type UpdateItemMutationVariables = Exact<{
  input: UpdateItemInput;
}>;

export type UpdateItemMutation = {
  __typename?: 'Mutation';
  updateItem: {
    __typename?: 'Item';
    id: string;
    zoneId: string;
    homeId: string;
    name: string;
    condition?: EItemCondition | null;
    lastMaintenanceDate?: string | null;
    purchasedFrom?: string | null;
    installationYear?: string | null;
    brand?: string | null;
    model?: string | null;
    serial?: string | null;
    type?: string | null;
    color?: string | null;
    keyIssues?: string | null;
    itemTemplateId: string;
    mainImageId?: string | null;
    taskCount: Array<number>;
    isArchived: boolean;
    coverImage?: {
      __typename?: 'File';
      id: string;
      filename: string;
      mimetype: string;
      blurHash?: string | null;
      createdAt: string;
      updatedAt: string;
      url: string;
    } | null;
    template: { __typename?: 'ItemTemplate'; id: string; name: string; maxQuantity: number; iconUrl: string };
    zone: { __typename?: 'Zone'; name: string; homeId: string; id: string };
  };
};

export type UpdateTaskMutationVariables = Exact<{
  input: UpdateTaskInput;
}>;

export type UpdateTaskMutation = {
  __typename?: 'Mutation';
  updateTask: {
    __typename?: 'Task';
    id: string;
    name: string;
    serviceId?: string | null;
    importance: number;
    levelOfSkill: number;
    status: ETaskStatus;
    jobPerformer: EJobPerformer;
    jobPerformerName?: string | null;
    costEstimate?: number | null;
    recommendedDate: string;
    scheduledDate?: string | null;
    jobberWebUri?: string | null;
    completedDate?: string | null;
    riskOfNotDoing: string;
    description: string;
    isArchived: boolean;
    createdAt: string;
    updatedAt: string;
    coverImage?: {
      __typename?: 'File';
      id: string;
      filename: string;
      mimetype: string;
      blurHash?: string | null;
      createdAt: string;
      updatedAt: string;
      url: string;
    } | null;
    item: {
      __typename?: 'Item';
      id: string;
      zoneId: string;
      homeId: string;
      name: string;
      condition?: EItemCondition | null;
      lastMaintenanceDate?: string | null;
      purchasedFrom?: string | null;
      installationYear?: string | null;
      brand?: string | null;
      model?: string | null;
      serial?: string | null;
      type?: string | null;
      color?: string | null;
      keyIssues?: string | null;
      itemTemplateId: string;
      mainImageId?: string | null;
      taskCount: Array<number>;
      isArchived: boolean;
      coverImage?: {
        __typename?: 'File';
        id: string;
        filename: string;
        mimetype: string;
        blurHash?: string | null;
        createdAt: string;
        updatedAt: string;
        url: string;
      } | null;
      template: { __typename?: 'ItemTemplate'; id: string; name: string; maxQuantity: number; iconUrl: string };
      zone: { __typename?: 'Zone'; name: string; homeId: string; id: string };
    };
  };
};

export type UpdateZoneMutationVariables = Exact<{
  input: UpdateZoneInput;
}>;

export type UpdateZoneMutation = {
  __typename?: 'Mutation';
  updateZone: {
    __typename?: 'Zone';
    id: string;
    name: string;
    homeId: string;
    template: { __typename?: 'ZoneTemplate'; id: string; name: string; maxQuantity: number };
  };
};

export type UpdateZonesMutationVariables = Exact<{
  input: UpdateZonesInput;
}>;

export type UpdateZonesMutation = {
  __typename?: 'Mutation';
  updateZones: Array<{
    __typename?: 'Zone';
    id: string;
    name: string;
    homeId: string;
    template: { __typename?: 'ZoneTemplate'; id: string; name: string; maxQuantity: number };
  }>;
};

export type VerifyUserEmailMutationVariables = Exact<{
  code: Scalars['String']['input'];
}>;

export type VerifyUserEmailMutation = { __typename?: 'Mutation'; verifyUserEmail: boolean };

export type DeleteHomeImageMutationVariables = Exact<{
  input: DeleteFileInput;
}>;

export type DeleteHomeImageMutation = { __typename?: 'Mutation'; deleteHomeImage: boolean };

export type DoitRequestQueryVariables = Exact<{
  doitRequestId: Scalars['ID']['input'];
}>;

export type DoitRequestQuery = {
  __typename?: 'Query';
  doitRequest: {
    __typename?: 'DoItRequest';
    id: string;
    createdBy: string;
    description?: string | null;
    status: EDoItRequestStatus;
    timing?: EDoItRequestTiming | null;
    sentDate?: string | null;
    note?: string | null;
    jobberWebUri?: string | null;
    createdAt: string;
    updatedAt: string;
    home: {
      __typename?: 'Home';
      id: string;
      squareFootage: number;
      yearBuilt: number;
      score?: number | null;
      jobberWebUri?: string | null;
      zoneCount: Array<number>;
      taskCount: Array<number>;
      createdAt: string;
      coverImage?: {
        __typename?: 'File';
        id: string;
        filename: string;
        mimetype: string;
        blurHash?: string | null;
        createdAt: string;
        updatedAt: string;
        url: string;
      } | null;
      location: {
        __typename?: 'Location';
        lat: number;
        lng: number;
        street1: string;
        street2: string;
        city: string;
        state: string;
        country: string;
        zipCode: string;
        googlePlaceId: string;
      };
      homemates: Array<{
        __typename?: 'User';
        id: string;
        email: string;
        role: ERole;
        isVerified: boolean;
        createdAt: string;
        updatedAt: string;
        numberOfHomes: number;
        jobberWebUri?: string | null;
        jobberClientHubUri?: string | null;
        profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string; phone?: string | null } | null;
      }>;
      owner: {
        __typename?: 'User';
        id: string;
        role: ERole;
        email: string;
        isVerified: boolean;
        profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string; phone?: string | null } | null;
      };
    };
    coverImage?: {
      __typename?: 'File';
      id: string;
      filename: string;
      mimetype: string;
      blurHash?: string | null;
      createdAt: string;
      updatedAt: string;
      url: string;
    } | null;
  };
};

export type DoitRequestDraftQueryVariables = Exact<{
  homeId: Scalars['ID']['input'];
}>;

export type DoitRequestDraftQuery = {
  __typename?: 'Query';
  doitRequestDraft?: {
    __typename?: 'DoItRequest';
    id: string;
    createdBy: string;
    description?: string | null;
    status: EDoItRequestStatus;
    timing?: EDoItRequestTiming | null;
    sentDate?: string | null;
    note?: string | null;
    jobberWebUri?: string | null;
    createdAt: string;
    updatedAt: string;
    home: {
      __typename?: 'Home';
      id: string;
      squareFootage: number;
      yearBuilt: number;
      score?: number | null;
      jobberWebUri?: string | null;
      zoneCount: Array<number>;
      taskCount: Array<number>;
      createdAt: string;
      coverImage?: {
        __typename?: 'File';
        id: string;
        filename: string;
        mimetype: string;
        blurHash?: string | null;
        createdAt: string;
        updatedAt: string;
        url: string;
      } | null;
      location: {
        __typename?: 'Location';
        lat: number;
        lng: number;
        street1: string;
        street2: string;
        city: string;
        state: string;
        country: string;
        zipCode: string;
        googlePlaceId: string;
      };
      homemates: Array<{
        __typename?: 'User';
        id: string;
        email: string;
        role: ERole;
        isVerified: boolean;
        createdAt: string;
        updatedAt: string;
        numberOfHomes: number;
        jobberWebUri?: string | null;
        jobberClientHubUri?: string | null;
        profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string; phone?: string | null } | null;
      }>;
      owner: {
        __typename?: 'User';
        id: string;
        role: ERole;
        email: string;
        isVerified: boolean;
        profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string; phone?: string | null } | null;
      };
    };
    coverImage?: {
      __typename?: 'File';
      id: string;
      filename: string;
      mimetype: string;
      blurHash?: string | null;
      createdAt: string;
      updatedAt: string;
      url: string;
    } | null;
  } | null;
};

export type DoitRequestImagesQueryVariables = Exact<{
  doitRequestId: Scalars['ID']['input'];
}>;

export type DoitRequestImagesQuery = {
  __typename?: 'Query';
  doitRequestImages: Array<{
    __typename?: 'File';
    id: string;
    filename: string;
    mimetype: string;
    blurHash?: string | null;
    createdAt: string;
    updatedAt: string;
    url: string;
  }>;
};

export type DoitRequestsQueryVariables = Exact<{
  filter: DoItFilter;
  offset?: Scalars['Int']['input'];
  limit?: Scalars['Int']['input'];
}>;

export type DoitRequestsQuery = {
  __typename?: 'Query';
  doitRequests: {
    __typename?: 'DoItPaginationResponse';
    hasMore: boolean;
    total: number;
    doitRequests: Array<{
      __typename?: 'DoItRequest';
      id: string;
      createdBy: string;
      description?: string | null;
      status: EDoItRequestStatus;
      timing?: EDoItRequestTiming | null;
      sentDate?: string | null;
      note?: string | null;
      jobberWebUri?: string | null;
      createdAt: string;
      updatedAt: string;
      home: {
        __typename?: 'Home';
        id: string;
        squareFootage: number;
        yearBuilt: number;
        score?: number | null;
        jobberWebUri?: string | null;
        zoneCount: Array<number>;
        taskCount: Array<number>;
        createdAt: string;
        coverImage?: {
          __typename?: 'File';
          id: string;
          filename: string;
          mimetype: string;
          blurHash?: string | null;
          createdAt: string;
          updatedAt: string;
          url: string;
        } | null;
        location: {
          __typename?: 'Location';
          lat: number;
          lng: number;
          street1: string;
          street2: string;
          city: string;
          state: string;
          country: string;
          zipCode: string;
          googlePlaceId: string;
        };
        homemates: Array<{
          __typename?: 'User';
          id: string;
          email: string;
          role: ERole;
          isVerified: boolean;
          createdAt: string;
          updatedAt: string;
          numberOfHomes: number;
          jobberWebUri?: string | null;
          jobberClientHubUri?: string | null;
          profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string; phone?: string | null } | null;
        }>;
        owner: {
          __typename?: 'User';
          id: string;
          role: ERole;
          email: string;
          isVerified: boolean;
          profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string; phone?: string | null } | null;
        };
      };
      coverImage?: {
        __typename?: 'File';
        id: string;
        filename: string;
        mimetype: string;
        blurHash?: string | null;
        createdAt: string;
        updatedAt: string;
        url: string;
      } | null;
    }>;
  };
};

export type DoitRequestsByHomeIdQueryVariables = Exact<{
  filter: DoItFilterByHome;
  offset?: Scalars['Int']['input'];
  limit?: Scalars['Int']['input'];
}>;

export type DoitRequestsByHomeIdQuery = {
  __typename?: 'Query';
  doitRequestsByHomeId: {
    __typename?: 'DoItPaginationResponse';
    hasMore: boolean;
    total: number;
    doitRequests: Array<{
      __typename?: 'DoItRequest';
      id: string;
      createdBy: string;
      description?: string | null;
      status: EDoItRequestStatus;
      timing?: EDoItRequestTiming | null;
      sentDate?: string | null;
      note?: string | null;
      jobberWebUri?: string | null;
      createdAt: string;
      updatedAt: string;
      home: {
        __typename?: 'Home';
        id: string;
        squareFootage: number;
        yearBuilt: number;
        score?: number | null;
        jobberWebUri?: string | null;
        zoneCount: Array<number>;
        taskCount: Array<number>;
        createdAt: string;
        coverImage?: {
          __typename?: 'File';
          id: string;
          filename: string;
          mimetype: string;
          blurHash?: string | null;
          createdAt: string;
          updatedAt: string;
          url: string;
        } | null;
        location: {
          __typename?: 'Location';
          lat: number;
          lng: number;
          street1: string;
          street2: string;
          city: string;
          state: string;
          country: string;
          zipCode: string;
          googlePlaceId: string;
        };
        homemates: Array<{
          __typename?: 'User';
          id: string;
          email: string;
          role: ERole;
          isVerified: boolean;
          createdAt: string;
          updatedAt: string;
          numberOfHomes: number;
          jobberWebUri?: string | null;
          jobberClientHubUri?: string | null;
          profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string; phone?: string | null } | null;
        }>;
        owner: {
          __typename?: 'User';
          id: string;
          role: ERole;
          email: string;
          isVerified: boolean;
          profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string; phone?: string | null } | null;
        };
      };
      coverImage?: {
        __typename?: 'File';
        id: string;
        filename: string;
        mimetype: string;
        blurHash?: string | null;
        createdAt: string;
        updatedAt: string;
        url: string;
      } | null;
    }>;
  };
};

export type HomeQueryVariables = Exact<{
  homeId: Scalars['ID']['input'];
}>;

export type HomeQuery = {
  __typename?: 'Query';
  home: {
    __typename?: 'Home';
    id: string;
    squareFootage: number;
    yearBuilt: number;
    score?: number | null;
    jobberWebUri?: string | null;
    zoneCount: Array<number>;
    taskCount: Array<number>;
    createdAt: string;
    coverImage?: {
      __typename?: 'File';
      id: string;
      filename: string;
      mimetype: string;
      blurHash?: string | null;
      createdAt: string;
      updatedAt: string;
      url: string;
    } | null;
    location: {
      __typename?: 'Location';
      lat: number;
      lng: number;
      street1: string;
      street2: string;
      city: string;
      state: string;
      country: string;
      zipCode: string;
      googlePlaceId: string;
    };
    homemates: Array<{
      __typename?: 'User';
      id: string;
      email: string;
      role: ERole;
      isVerified: boolean;
      createdAt: string;
      updatedAt: string;
      numberOfHomes: number;
      jobberWebUri?: string | null;
      jobberClientHubUri?: string | null;
      profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string; phone?: string | null } | null;
    }>;
    owner: {
      __typename?: 'User';
      id: string;
      role: ERole;
      email: string;
      isVerified: boolean;
      profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string; phone?: string | null } | null;
    };
  };
};

export type HomeStatsQueryVariables = Exact<{
  filter: HomeStatsFilterInput;
}>;

export type HomeStatsQuery = {
  __typename?: 'Query';
  homeStats: {
    __typename?: 'HomeStats';
    activeTasks: number;
    dwelinkTasks: number;
    finishedTasks: number;
    zones: number;
    items: number;
    score: {
      __typename?: 'HomeScore';
      total?: number | null;
      totalByZone: Array<{ __typename?: 'ZoneScore'; id: string; name: string; score?: number | null }>;
    };
  };
};

export type HomeWithStatsQueryVariables = Exact<{
  homeId: Scalars['ID']['input'];
}>;

export type HomeWithStatsQuery = {
  __typename?: 'Query';
  homeStats: {
    __typename?: 'HomeStats';
    activeTasks: number;
    dwelinkTasks: number;
    finishedTasks: number;
    zones: number;
    items: number;
    score: {
      __typename?: 'HomeScore';
      total?: number | null;
      totalByZone: Array<{ __typename?: 'ZoneScore'; id: string; name: string; score?: number | null }>;
    };
  };
  home: {
    __typename?: 'Home';
    id: string;
    squareFootage: number;
    yearBuilt: number;
    score?: number | null;
    jobberWebUri?: string | null;
    zoneCount: Array<number>;
    taskCount: Array<number>;
    createdAt: string;
    homemates: Array<{
      __typename?: 'User';
      id: string;
      role: ERole;
      email: string;
      isVerified: boolean;
      createdAt: string;
      updatedAt: string;
      numberOfHomes: number;
      jobberWebUri?: string | null;
      jobberClientHubUri?: string | null;
      profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string; phone?: string | null } | null;
    }>;
    coverImage?: {
      __typename?: 'File';
      id: string;
      filename: string;
      mimetype: string;
      blurHash?: string | null;
      createdAt: string;
      updatedAt: string;
      url: string;
    } | null;
    location: {
      __typename?: 'Location';
      lat: number;
      lng: number;
      street1: string;
      street2: string;
      city: string;
      state: string;
      country: string;
      zipCode: string;
      googlePlaceId: string;
    };
    owner: {
      __typename?: 'User';
      id: string;
      role: ERole;
      email: string;
      isVerified: boolean;
      profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string; phone?: string | null } | null;
    };
  };
};

export type HomesQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;

export type HomesQuery = {
  __typename?: 'Query';
  homes: {
    __typename?: 'HomePaginationResponse';
    total: number;
    hasMore: boolean;
    homes: Array<{
      __typename?: 'Home';
      id: string;
      zoneCount: Array<number>;
      taskCount: Array<number>;
      location: {
        __typename?: 'Location';
        street1: string;
        city: string;
        state: string;
        googlePlaceId: string;
        lat: number;
        lng: number;
        country: string;
        street2: string;
        zipCode: string;
      };
      owner: {
        __typename?: 'User';
        id: string;
        email: string;
        isVerified: boolean;
        profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string; phone?: string | null } | null;
      };
      homemates: Array<{
        __typename?: 'User';
        id: string;
        email: string;
        isVerified: boolean;
        profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string } | null;
      }>;
    }>;
  };
};

export type HomesByUserIdQueryVariables = Exact<{
  filter: HomeFilterByUser;
  offset?: Scalars['Int']['input'];
  limit?: Scalars['Int']['input'];
}>;

export type HomesByUserIdQuery = {
  __typename?: 'Query';
  homesByUserId: {
    __typename?: 'HomePaginationResponse';
    total: number;
    hasMore: boolean;
    homes: Array<{
      __typename?: 'Home';
      id: string;
      squareFootage: number;
      yearBuilt: number;
      score?: number | null;
      jobberWebUri?: string | null;
      zoneCount: Array<number>;
      taskCount: Array<number>;
      createdAt: string;
      coverImage?: {
        __typename?: 'File';
        id: string;
        filename: string;
        mimetype: string;
        blurHash?: string | null;
        createdAt: string;
        updatedAt: string;
        url: string;
      } | null;
      location: {
        __typename?: 'Location';
        lat: number;
        lng: number;
        street1: string;
        street2: string;
        city: string;
        state: string;
        country: string;
        zipCode: string;
        googlePlaceId: string;
      };
      homemates: Array<{
        __typename?: 'User';
        id: string;
        email: string;
        role: ERole;
        isVerified: boolean;
        createdAt: string;
        updatedAt: string;
        numberOfHomes: number;
        jobberWebUri?: string | null;
        jobberClientHubUri?: string | null;
        profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string; phone?: string | null } | null;
      }>;
      owner: {
        __typename?: 'User';
        id: string;
        role: ERole;
        email: string;
        isVerified: boolean;
        profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string; phone?: string | null } | null;
      };
    }>;
  };
};

export type InvitationQueryVariables = Exact<{
  filter: InvitationFilterInput;
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;

export type InvitationQuery = {
  __typename?: 'Query';
  invitations: {
    __typename?: 'InvitationPaginationResponse';
    total: number;
    hasMore: boolean;
    invitations: Array<{
      __typename?: 'Invitation';
      id: string;
      email: string;
      role: ERole;
      expiresAt: string;
      profile: { __typename?: 'UserProfile'; firstName: string; lastName: string };
      inviter: {
        __typename?: 'User';
        id: string;
        email: string;
        isVerified: boolean;
        profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string } | null;
      };
    }>;
  };
};

export type ItemQueryVariables = Exact<{
  itemId: Scalars['ID']['input'];
}>;

export type ItemQuery = {
  __typename?: 'Query';
  item: {
    __typename?: 'Item';
    id: string;
    zoneId: string;
    homeId: string;
    name: string;
    condition?: EItemCondition | null;
    lastMaintenanceDate?: string | null;
    purchasedFrom?: string | null;
    installationYear?: string | null;
    brand?: string | null;
    model?: string | null;
    serial?: string | null;
    type?: string | null;
    color?: string | null;
    keyIssues?: string | null;
    itemTemplateId: string;
    mainImageId?: string | null;
    taskCount: Array<number>;
    isArchived: boolean;
    coverImage?: {
      __typename?: 'File';
      id: string;
      filename: string;
      mimetype: string;
      blurHash?: string | null;
      createdAt: string;
      updatedAt: string;
      url: string;
    } | null;
    template: { __typename?: 'ItemTemplate'; id: string; name: string; maxQuantity: number; iconUrl: string };
    zone: { __typename?: 'Zone'; name: string; homeId: string; id: string };
  };
};

export type ItemImagesQueryVariables = Exact<{
  itemId: Scalars['ID']['input'];
}>;

export type ItemImagesQuery = {
  __typename?: 'Query';
  itemImages: Array<{
    __typename?: 'ItemImage';
    id: string;
    filename: string;
    mimetype: string;
    blurHash?: string | null;
    url: string;
    isMain: boolean;
    createdAt: string;
    updatedAt: string;
  }>;
};

export type ItemTemplatesQueryVariables = Exact<{
  zoneTemplateId: Scalars['ID']['input'];
}>;

export type ItemTemplatesQuery = {
  __typename?: 'Query';
  itemTemplates: Array<{ __typename?: 'ItemTemplate'; id: string; name: string; maxQuantity: number; iconUrl: string }>;
};

export type ItemsByHomeIdQueryVariables = Exact<{
  filter: ItemFilterByHome;
  offset?: Scalars['Int']['input'];
  limit?: Scalars['Int']['input'];
}>;

export type ItemsByHomeIdQuery = {
  __typename?: 'Query';
  itemsByHomeId: {
    __typename?: 'ItemPaginationResponse';
    total: number;
    hasMore: boolean;
    items: Array<{
      __typename?: 'Item';
      id: string;
      zoneId: string;
      homeId: string;
      name: string;
      condition?: EItemCondition | null;
      lastMaintenanceDate?: string | null;
      purchasedFrom?: string | null;
      installationYear?: string | null;
      brand?: string | null;
      model?: string | null;
      serial?: string | null;
      type?: string | null;
      color?: string | null;
      keyIssues?: string | null;
      itemTemplateId: string;
      mainImageId?: string | null;
      taskCount: Array<number>;
      isArchived: boolean;
      coverImage?: {
        __typename?: 'File';
        id: string;
        filename: string;
        mimetype: string;
        blurHash?: string | null;
        createdAt: string;
        updatedAt: string;
        url: string;
      } | null;
      template: { __typename?: 'ItemTemplate'; id: string; name: string; maxQuantity: number; iconUrl: string };
      zone: { __typename?: 'Zone'; name: string; homeId: string; id: string };
    }>;
  };
};

export type JobberStatusQueryVariables = Exact<{ [key: string]: never }>;

export type JobberStatusQuery = { __typename?: 'Query'; jobberStatus: EJobberStatus };

export type LoginWithPasswordAbilityQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;

export type LoginWithPasswordAbilityQuery = {
  __typename?: 'Query';
  loginWithPasswordAbility: ELoginWithPasswordAbility;
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'User';
    id: string;
    email: string;
    role: ERole;
    isVerified: boolean;
    createdAt: string;
    updatedAt: string;
    numberOfHomes: number;
    jobberWebUri?: string | null;
    jobberClientHubUri?: string | null;
    profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string; phone?: string | null } | null;
  };
};

export type TaskQueryVariables = Exact<{
  taskId: Scalars['ID']['input'];
}>;

export type TaskQuery = {
  __typename?: 'Query';
  task: {
    __typename?: 'Task';
    id: string;
    name: string;
    serviceId?: string | null;
    importance: number;
    levelOfSkill: number;
    status: ETaskStatus;
    jobPerformer: EJobPerformer;
    jobPerformerName?: string | null;
    costEstimate?: number | null;
    recommendedDate: string;
    scheduledDate?: string | null;
    jobberWebUri?: string | null;
    completedDate?: string | null;
    riskOfNotDoing: string;
    description: string;
    isArchived: boolean;
    createdAt: string;
    updatedAt: string;
    coverImage?: {
      __typename?: 'File';
      id: string;
      filename: string;
      mimetype: string;
      blurHash?: string | null;
      createdAt: string;
      updatedAt: string;
      url: string;
    } | null;
    item: {
      __typename?: 'Item';
      id: string;
      zoneId: string;
      homeId: string;
      name: string;
      condition?: EItemCondition | null;
      lastMaintenanceDate?: string | null;
      purchasedFrom?: string | null;
      installationYear?: string | null;
      brand?: string | null;
      model?: string | null;
      serial?: string | null;
      type?: string | null;
      color?: string | null;
      keyIssues?: string | null;
      itemTemplateId: string;
      mainImageId?: string | null;
      taskCount: Array<number>;
      isArchived: boolean;
      coverImage?: {
        __typename?: 'File';
        id: string;
        filename: string;
        mimetype: string;
        blurHash?: string | null;
        createdAt: string;
        updatedAt: string;
        url: string;
      } | null;
      template: { __typename?: 'ItemTemplate'; id: string; name: string; maxQuantity: number; iconUrl: string };
      zone: { __typename?: 'Zone'; name: string; homeId: string; id: string };
    };
  };
};

export type TasksByHomeIdQueryVariables = Exact<{
  filter: TaskFilterByHome;
  offset?: Scalars['Int']['input'];
  limit?: Scalars['Int']['input'];
}>;

export type TasksByHomeIdQuery = {
  __typename?: 'Query';
  tasksByHomeId: {
    __typename?: 'TaskPaginationResponse';
    total: number;
    hasMore: boolean;
    tasks: Array<{
      __typename?: 'Task';
      id: string;
      name: string;
      serviceId?: string | null;
      importance: number;
      levelOfSkill: number;
      status: ETaskStatus;
      jobPerformer: EJobPerformer;
      jobPerformerName?: string | null;
      costEstimate?: number | null;
      recommendedDate: string;
      scheduledDate?: string | null;
      jobberWebUri?: string | null;
      completedDate?: string | null;
      riskOfNotDoing: string;
      description: string;
      isArchived: boolean;
      createdAt: string;
      updatedAt: string;
      item: {
        __typename?: 'Item';
        id: string;
        zoneId: string;
        homeId: string;
        name: string;
        condition?: EItemCondition | null;
        lastMaintenanceDate?: string | null;
        purchasedFrom?: string | null;
        installationYear?: string | null;
        brand?: string | null;
        model?: string | null;
        serial?: string | null;
        type?: string | null;
        color?: string | null;
        keyIssues?: string | null;
        itemTemplateId: string;
        mainImageId?: string | null;
        taskCount: Array<number>;
        isArchived: boolean;
        home: {
          __typename?: 'Home';
          id: string;
          squareFootage: number;
          yearBuilt: number;
          score?: number | null;
          jobberWebUri?: string | null;
          zoneCount: Array<number>;
          taskCount: Array<number>;
          createdAt: string;
          coverImage?: {
            __typename?: 'File';
            id: string;
            filename: string;
            mimetype: string;
            blurHash?: string | null;
            createdAt: string;
            updatedAt: string;
            url: string;
          } | null;
          location: {
            __typename?: 'Location';
            lat: number;
            lng: number;
            street1: string;
            street2: string;
            city: string;
            state: string;
            country: string;
            zipCode: string;
            googlePlaceId: string;
          };
          homemates: Array<{
            __typename?: 'User';
            id: string;
            email: string;
            role: ERole;
            isVerified: boolean;
            createdAt: string;
            updatedAt: string;
            numberOfHomes: number;
            jobberWebUri?: string | null;
            jobberClientHubUri?: string | null;
            profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string; phone?: string | null } | null;
          }>;
          owner: {
            __typename?: 'User';
            id: string;
            role: ERole;
            email: string;
            isVerified: boolean;
            profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string; phone?: string | null } | null;
          };
        };
        coverImage?: {
          __typename?: 'File';
          id: string;
          filename: string;
          mimetype: string;
          blurHash?: string | null;
          createdAt: string;
          updatedAt: string;
          url: string;
        } | null;
        template: { __typename?: 'ItemTemplate'; id: string; name: string; maxQuantity: number; iconUrl: string };
        zone: { __typename?: 'Zone'; name: string; homeId: string; id: string };
      };
      coverImage?: {
        __typename?: 'File';
        id: string;
        filename: string;
        mimetype: string;
        blurHash?: string | null;
        createdAt: string;
        updatedAt: string;
        url: string;
      } | null;
    }>;
    totalByMonth: Array<{ __typename?: 'MonthTotal'; month: string; total: number }>;
  };
};

export type TaskImagesQueryVariables = Exact<{
  taskId: Scalars['ID']['input'];
}>;

export type TaskImagesQuery = {
  __typename?: 'Query';
  taskImages: Array<{
    __typename?: 'TaskImage';
    id: string;
    filename: string;
    mimetype: string;
    blurHash?: string | null;
    createdAt: string;
    updatedAt: string;
    url: string;
    isMain: boolean;
  }>;
};

export type TasksQueryVariables = Exact<{
  filter: TaskFilter;
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
}>;

export type TasksQuery = {
  __typename?: 'Query';
  tasks: {
    __typename?: 'TaskPaginationResponse';
    total: number;
    hasMore: boolean;
    tasks: Array<{
      __typename?: 'Task';
      id: string;
      name: string;
      serviceId?: string | null;
      importance: number;
      levelOfSkill: number;
      status: ETaskStatus;
      jobPerformer: EJobPerformer;
      jobPerformerName?: string | null;
      costEstimate?: number | null;
      recommendedDate: string;
      scheduledDate?: string | null;
      jobberWebUri?: string | null;
      completedDate?: string | null;
      riskOfNotDoing: string;
      description: string;
      isArchived: boolean;
      createdAt: string;
      updatedAt: string;
      item: {
        __typename?: 'Item';
        id: string;
        zoneId: string;
        homeId: string;
        name: string;
        condition?: EItemCondition | null;
        lastMaintenanceDate?: string | null;
        purchasedFrom?: string | null;
        installationYear?: string | null;
        brand?: string | null;
        model?: string | null;
        serial?: string | null;
        type?: string | null;
        color?: string | null;
        keyIssues?: string | null;
        itemTemplateId: string;
        mainImageId?: string | null;
        taskCount: Array<number>;
        isArchived: boolean;
        home: {
          __typename?: 'Home';
          id: string;
          squareFootage: number;
          yearBuilt: number;
          score?: number | null;
          jobberWebUri?: string | null;
          zoneCount: Array<number>;
          taskCount: Array<number>;
          createdAt: string;
          coverImage?: {
            __typename?: 'File';
            id: string;
            filename: string;
            mimetype: string;
            blurHash?: string | null;
            createdAt: string;
            updatedAt: string;
            url: string;
          } | null;
          location: {
            __typename?: 'Location';
            lat: number;
            lng: number;
            street1: string;
            street2: string;
            city: string;
            state: string;
            country: string;
            zipCode: string;
            googlePlaceId: string;
          };
          homemates: Array<{
            __typename?: 'User';
            id: string;
            email: string;
            role: ERole;
            isVerified: boolean;
            createdAt: string;
            updatedAt: string;
            numberOfHomes: number;
            jobberWebUri?: string | null;
            jobberClientHubUri?: string | null;
            profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string; phone?: string | null } | null;
          }>;
          owner: {
            __typename?: 'User';
            id: string;
            role: ERole;
            email: string;
            isVerified: boolean;
            profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string; phone?: string | null } | null;
          };
        };
        coverImage?: {
          __typename?: 'File';
          id: string;
          filename: string;
          mimetype: string;
          blurHash?: string | null;
          createdAt: string;
          updatedAt: string;
          url: string;
        } | null;
        template: { __typename?: 'ItemTemplate'; id: string; name: string; maxQuantity: number; iconUrl: string };
        zone: { __typename?: 'Zone'; name: string; homeId: string; id: string };
      };
      coverImage?: {
        __typename?: 'File';
        id: string;
        filename: string;
        mimetype: string;
        blurHash?: string | null;
        createdAt: string;
        updatedAt: string;
        url: string;
      } | null;
    }>;
    totalByMonth: Array<{ __typename?: 'MonthTotal'; month: string; total: number }>;
  };
};

export type TasksByItemIdQueryVariables = Exact<{
  filter: TaskFilterByItem;
  offset?: Scalars['Int']['input'];
  limit?: Scalars['Int']['input'];
}>;

export type TasksByItemIdQuery = {
  __typename?: 'Query';
  tasksByItemId: {
    __typename?: 'TaskPaginationResponse';
    total: number;
    hasMore: boolean;
    tasks: Array<{
      __typename?: 'Task';
      id: string;
      name: string;
      serviceId?: string | null;
      importance: number;
      levelOfSkill: number;
      status: ETaskStatus;
      jobPerformer: EJobPerformer;
      jobPerformerName?: string | null;
      costEstimate?: number | null;
      recommendedDate: string;
      scheduledDate?: string | null;
      jobberWebUri?: string | null;
      completedDate?: string | null;
      riskOfNotDoing: string;
      description: string;
      isArchived: boolean;
      createdAt: string;
      updatedAt: string;
      item: {
        __typename?: 'Item';
        id: string;
        zoneId: string;
        homeId: string;
        name: string;
        condition?: EItemCondition | null;
        lastMaintenanceDate?: string | null;
        purchasedFrom?: string | null;
        installationYear?: string | null;
        brand?: string | null;
        model?: string | null;
        serial?: string | null;
        type?: string | null;
        color?: string | null;
        keyIssues?: string | null;
        itemTemplateId: string;
        mainImageId?: string | null;
        taskCount: Array<number>;
        isArchived: boolean;
        home: {
          __typename?: 'Home';
          id: string;
          squareFootage: number;
          yearBuilt: number;
          score?: number | null;
          jobberWebUri?: string | null;
          zoneCount: Array<number>;
          taskCount: Array<number>;
          createdAt: string;
          coverImage?: {
            __typename?: 'File';
            id: string;
            filename: string;
            mimetype: string;
            blurHash?: string | null;
            createdAt: string;
            updatedAt: string;
            url: string;
          } | null;
          location: {
            __typename?: 'Location';
            lat: number;
            lng: number;
            street1: string;
            street2: string;
            city: string;
            state: string;
            country: string;
            zipCode: string;
            googlePlaceId: string;
          };
          homemates: Array<{
            __typename?: 'User';
            id: string;
            email: string;
            role: ERole;
            isVerified: boolean;
            createdAt: string;
            updatedAt: string;
            numberOfHomes: number;
            jobberWebUri?: string | null;
            jobberClientHubUri?: string | null;
            profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string; phone?: string | null } | null;
          }>;
          owner: {
            __typename?: 'User';
            id: string;
            role: ERole;
            email: string;
            isVerified: boolean;
            profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string; phone?: string | null } | null;
          };
        };
        coverImage?: {
          __typename?: 'File';
          id: string;
          filename: string;
          mimetype: string;
          blurHash?: string | null;
          createdAt: string;
          updatedAt: string;
          url: string;
        } | null;
        template: { __typename?: 'ItemTemplate'; id: string; name: string; maxQuantity: number; iconUrl: string };
        zone: { __typename?: 'Zone'; name: string; homeId: string; id: string };
      };
      coverImage?: {
        __typename?: 'File';
        id: string;
        filename: string;
        mimetype: string;
        blurHash?: string | null;
        createdAt: string;
        updatedAt: string;
        url: string;
      } | null;
    }>;
    totalByMonth: Array<{ __typename?: 'MonthTotal'; month: string; total: number }>;
  };
};

export type TasksByZoneIdQueryVariables = Exact<{
  filter: TaskFilterByZone;
  offset?: Scalars['Int']['input'];
  limit?: Scalars['Int']['input'];
}>;

export type TasksByZoneIdQuery = {
  __typename?: 'Query';
  tasksByZoneId: {
    __typename?: 'TaskPaginationResponse';
    total: number;
    hasMore: boolean;
    tasks: Array<{
      __typename?: 'Task';
      id: string;
      name: string;
      serviceId?: string | null;
      importance: number;
      levelOfSkill: number;
      status: ETaskStatus;
      jobPerformer: EJobPerformer;
      jobPerformerName?: string | null;
      costEstimate?: number | null;
      recommendedDate: string;
      scheduledDate?: string | null;
      jobberWebUri?: string | null;
      completedDate?: string | null;
      riskOfNotDoing: string;
      description: string;
      isArchived: boolean;
      createdAt: string;
      updatedAt: string;
      item: {
        __typename?: 'Item';
        id: string;
        zoneId: string;
        homeId: string;
        name: string;
        condition?: EItemCondition | null;
        lastMaintenanceDate?: string | null;
        purchasedFrom?: string | null;
        installationYear?: string | null;
        brand?: string | null;
        model?: string | null;
        serial?: string | null;
        type?: string | null;
        color?: string | null;
        keyIssues?: string | null;
        itemTemplateId: string;
        mainImageId?: string | null;
        taskCount: Array<number>;
        isArchived: boolean;
        home: {
          __typename?: 'Home';
          id: string;
          squareFootage: number;
          yearBuilt: number;
          score?: number | null;
          jobberWebUri?: string | null;
          zoneCount: Array<number>;
          taskCount: Array<number>;
          createdAt: string;
          coverImage?: {
            __typename?: 'File';
            id: string;
            filename: string;
            mimetype: string;
            blurHash?: string | null;
            createdAt: string;
            updatedAt: string;
            url: string;
          } | null;
          location: {
            __typename?: 'Location';
            lat: number;
            lng: number;
            street1: string;
            street2: string;
            city: string;
            state: string;
            country: string;
            zipCode: string;
            googlePlaceId: string;
          };
          homemates: Array<{
            __typename?: 'User';
            id: string;
            email: string;
            role: ERole;
            isVerified: boolean;
            createdAt: string;
            updatedAt: string;
            numberOfHomes: number;
            jobberWebUri?: string | null;
            jobberClientHubUri?: string | null;
            profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string; phone?: string | null } | null;
          }>;
          owner: {
            __typename?: 'User';
            id: string;
            role: ERole;
            email: string;
            isVerified: boolean;
            profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string; phone?: string | null } | null;
          };
        };
        coverImage?: {
          __typename?: 'File';
          id: string;
          filename: string;
          mimetype: string;
          blurHash?: string | null;
          createdAt: string;
          updatedAt: string;
          url: string;
        } | null;
        template: { __typename?: 'ItemTemplate'; id: string; name: string; maxQuantity: number; iconUrl: string };
        zone: { __typename?: 'Zone'; name: string; homeId: string; id: string };
      };
      coverImage?: {
        __typename?: 'File';
        id: string;
        filename: string;
        mimetype: string;
        blurHash?: string | null;
        createdAt: string;
        updatedAt: string;
        url: string;
      } | null;
    }>;
    totalByMonth: Array<{ __typename?: 'MonthTotal'; month: string; total: number }>;
  };
};

export type UserQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;

export type UserQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    id: string;
    email: string;
    role: ERole;
    isVerified: boolean;
    createdAt: string;
    updatedAt: string;
    numberOfHomes: number;
    jobberWebUri?: string | null;
    jobberClientHubUri?: string | null;
    profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string; phone?: string | null } | null;
  };
};

export type UserRoleQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;

export type UserRoleQuery = { __typename?: 'Query'; user: { __typename?: 'User'; id: string; role: ERole } };

export type UsersQueryVariables = Exact<{
  filter: UserFilterInput;
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  membershipRole?: InputMaybe<EMembershipRole>;
  sort?: InputMaybe<EUserSort>;
}>;

export type UsersQuery = {
  __typename?: 'Query';
  users: {
    __typename?: 'UserPaginationResponse';
    hasMore: boolean;
    total: number;
    users: Array<{
      __typename?: 'User';
      id: string;
      email: string;
      numberOfHomes: number;
      authServices: Array<EAuthServiceType>;
      createdAt: string;
      isVerified: boolean;
      role: ERole;
      profile?: { __typename?: 'UserProfile'; firstName: string; lastName: string; phone?: string | null } | null;
    }>;
  };
};

export type ZoneQueryVariables = Exact<{
  zoneId: Scalars['ID']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  isArchivedItem?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type ZoneQuery = {
  __typename?: 'Query';
  zone: {
    __typename?: 'Zone';
    id: string;
    name: string;
    homeId: string;
    score?: number | null;
    createdAt: string;
    updatedAt: string;
    isArchived: boolean;
    taskCount: Array<number>;
    items: {
      __typename?: 'ItemPaginationResponse';
      total: number;
      items: Array<{
        __typename?: 'Item';
        id: string;
        name: string;
        itemTemplateId: string;
        zoneId: string;
        homeId: string;
        condition?: EItemCondition | null;
        lastMaintenanceDate?: string | null;
        purchasedFrom?: string | null;
        installationYear?: string | null;
        brand?: string | null;
        model?: string | null;
        serial?: string | null;
        type?: string | null;
        color?: string | null;
        keyIssues?: string | null;
        mainImageId?: string | null;
        taskCount: Array<number>;
        isArchived: boolean;
        coverImage?: {
          __typename?: 'File';
          id: string;
          filename: string;
          mimetype: string;
          blurHash?: string | null;
          createdAt: string;
          updatedAt: string;
          url: string;
        } | null;
        template: { __typename?: 'ItemTemplate'; id: string; name: string; maxQuantity: number; iconUrl: string };
        zone: { __typename?: 'Zone'; name: string; homeId: string; id: string };
      }>;
    };
    template: { __typename?: 'ZoneTemplate'; id: string; name: string; maxQuantity: number };
  };
};

export type ZoneTemplatesQueryVariables = Exact<{ [key: string]: never }>;

export type ZoneTemplatesQuery = {
  __typename?: 'Query';
  zoneTemplates: Array<{ __typename?: 'ZoneTemplate'; id: string; name: string; maxQuantity: number }>;
};

export type ZonesQueryVariables = Exact<{
  homeId: Scalars['ID']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  isArchivedItem?: InputMaybe<Scalars['Boolean']['input']>;
  isArchivedZone?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: Scalars['Int']['input'];
  limit?: Scalars['Int']['input'];
}>;

export type ZonesQuery = {
  __typename?: 'Query';
  zones: {
    __typename?: 'ZonePaginationResponse';
    total: number;
    hasMore: boolean;
    zones: Array<{
      __typename?: 'Zone';
      id: string;
      name: string;
      homeId: string;
      score?: number | null;
      createdAt: string;
      updatedAt: string;
      isArchived: boolean;
      taskCount: Array<number>;
      items: {
        __typename?: 'ItemPaginationResponse';
        total: number;
        items: Array<{ __typename?: 'Item'; id: string; name: string; itemTemplateId: string }>;
      };
      template: { __typename?: 'ZoneTemplate'; id: string; name: string; maxQuantity: number };
    }>;
  };
};

export type ZonesCompressedQueryVariables = Exact<{
  homeId: Scalars['ID']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  isArchivedItem?: InputMaybe<Scalars['Boolean']['input']>;
  isArchivedZone?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: Scalars['Int']['input'];
  limit?: Scalars['Int']['input'];
}>;

export type ZonesCompressedQuery = {
  __typename?: 'Query';
  zonesCompressed: {
    __typename?: 'ZonePaginationResponse';
    total: number;
    hasMore: boolean;
    zones: Array<{
      __typename?: 'Zone';
      id: string;
      name: string;
      homeId: string;
      template: { __typename?: 'ZoneTemplate'; id: string; name: string; maxQuantity: number };
    }>;
  };
};

export const CurrentUserFragmentDoc = gql`
  fragment CurrentUser on User {
    id
    email
    role
    isVerified
    createdAt
    updatedAt
    numberOfHomes
    jobberWebUri
    jobberClientHubUri
    profile {
      firstName
      lastName
      phone
    }
  }
`;
export const AuthResponseFragmentDoc = gql`
  fragment AuthResponse on AuthResponse {
    accessToken
    refreshToken
    resetToken
    user {
      ...CurrentUser
    }
  }
  ${CurrentUserFragmentDoc}
`;
export const CoverImageFragmentDoc = gql`
  fragment CoverImage on File {
    id
    filename
    mimetype
    blurHash
    createdAt
    updatedAt
    url
  }
`;
export const HomeFragmentDoc = gql`
  fragment Home on Home {
    id
    squareFootage
    yearBuilt
    score
    jobberWebUri
    zoneCount(filters: [{ isArchived: false }])
    taskCount(
      filters: [{ isArchived: false, status: ACTIVE }, { isArchived: false, jobPerformer: DWELINK, status: ACTIVE }]
    )
    coverImage {
      ...CoverImage
    }
    location {
      lat
      lng
      street1
      street2
      city
      state
      country
      zipCode
      googlePlaceId
    }
    createdAt
    homemates {
      ...CurrentUser
    }
    owner {
      id
      role
      profile {
        firstName
        lastName
        phone
      }
      email
      isVerified
    }
  }
  ${CoverImageFragmentDoc}
  ${CurrentUserFragmentDoc}
`;
export const DoItRequestFragmentDoc = gql`
  fragment DoItRequest on DoItRequest {
    id
    createdBy
    description
    status
    timing
    sentDate
    note
    jobberWebUri
    home {
      ...Home
    }
    createdAt
    updatedAt
    coverImage {
      ...CoverImage
    }
  }
  ${HomeFragmentDoc}
  ${CoverImageFragmentDoc}
`;
export const DoItPaginationResponseFragmentDoc = gql`
  fragment DoItPaginationResponse on DoItPaginationResponse {
    doitRequests {
      ...DoItRequest
    }
    hasMore
    total
  }
  ${DoItRequestFragmentDoc}
`;
export const ItemFragmentDoc = gql`
  fragment Item on Item {
    id
    zoneId
    homeId
    name
    condition
    lastMaintenanceDate
    purchasedFrom
    installationYear
    brand
    model
    serial
    type
    color
    keyIssues
    itemTemplateId
    mainImageId
    taskCount(
      filters: [{ isArchived: false, status: ACTIVE }, { isArchived: false, jobPerformer: DWELINK, status: ACTIVE }]
    )
    isArchived
    coverImage {
      ...CoverImage
    }
    template {
      id
      name
      maxQuantity
      iconUrl
    }
    zone {
      name
      homeId
      id
    }
  }
  ${CoverImageFragmentDoc}
`;
export const TaskFragmentDoc = gql`
  fragment Task on Task {
    id
    name
    serviceId
    importance
    levelOfSkill
    status
    jobPerformer
    jobPerformerName
    costEstimate
    recommendedDate
    scheduledDate
    jobberWebUri
    completedDate
    riskOfNotDoing
    description
    isArchived
    createdAt
    updatedAt
    coverImage {
      ...CoverImage
    }
    item {
      ...Item
    }
  }
  ${CoverImageFragmentDoc}
  ${ItemFragmentDoc}
`;
export const TaskPaginationResponseFragmentDoc = gql`
  fragment TaskPaginationResponse on TaskPaginationResponse {
    total
    hasMore
    tasks {
      ...Task
      item {
        ...Item
        home {
          ...Home
        }
      }
    }
    totalByMonth {
      month
      total
    }
  }
  ${TaskFragmentDoc}
  ${ItemFragmentDoc}
  ${HomeFragmentDoc}
`;
export const ZoneTemplateFragmentDoc = gql`
  fragment ZoneTemplate on ZoneTemplate {
    id
    name
    maxQuantity
  }
`;
export const ZoneFragmentDoc = gql`
  fragment Zone on Zone {
    id
    name
    homeId
    score
    createdAt
    updatedAt
    isArchived
    taskCount(
      filters: [{ isArchived: false, status: ACTIVE }, { isArchived: false, jobPerformer: DWELINK, status: ACTIVE }]
    )
    items(search: $search, isArchived: $isArchivedItem) {
      total
      items {
        id
        name
        itemTemplateId
      }
    }
    template {
      ...ZoneTemplate
    }
  }
  ${ZoneTemplateFragmentDoc}
`;
export const ZonePaginationResponseFragmentDoc = gql`
  fragment ZonePaginationResponse on ZonePaginationResponse {
    total
    hasMore
    zones {
      ...Zone
    }
  }
  ${ZoneFragmentDoc}
`;
export const AddItemsDocument = gql`
  mutation AddItems($input: AddItemsInput!) {
    addItems(input: $input) {
      ...Item
    }
  }
  ${ItemFragmentDoc}
`;
export type AddItemsMutationFn = Apollo.MutationFunction<AddItemsMutation, AddItemsMutationVariables>;

/**
 * __useAddItemsMutation__
 *
 * To run a mutation, you first call `useAddItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addItemsMutation, { data, loading, error }] = useAddItemsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddItemsMutation(
  baseOptions?: Apollo.MutationHookOptions<AddItemsMutation, AddItemsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddItemsMutation, AddItemsMutationVariables>(AddItemsDocument, options);
}
export type AddItemsMutationHookResult = ReturnType<typeof useAddItemsMutation>;
export type AddItemsMutationResult = Apollo.MutationResult<AddItemsMutation>;
export type AddItemsMutationOptions = Apollo.BaseMutationOptions<AddItemsMutation, AddItemsMutationVariables>;
export const ArchiveItemDocument = gql`
  mutation ArchiveItem($itemId: ID!) {
    archiveItem(itemId: $itemId)
  }
`;
export type ArchiveItemMutationFn = Apollo.MutationFunction<ArchiveItemMutation, ArchiveItemMutationVariables>;

/**
 * __useArchiveItemMutation__
 *
 * To run a mutation, you first call `useArchiveItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveItemMutation, { data, loading, error }] = useArchiveItemMutation({
 *   variables: {
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useArchiveItemMutation(
  baseOptions?: Apollo.MutationHookOptions<ArchiveItemMutation, ArchiveItemMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ArchiveItemMutation, ArchiveItemMutationVariables>(ArchiveItemDocument, options);
}
export type ArchiveItemMutationHookResult = ReturnType<typeof useArchiveItemMutation>;
export type ArchiveItemMutationResult = Apollo.MutationResult<ArchiveItemMutation>;
export type ArchiveItemMutationOptions = Apollo.BaseMutationOptions<ArchiveItemMutation, ArchiveItemMutationVariables>;
export const ArchiveTaskDocument = gql`
  mutation ArchiveTask($taskId: ID!) {
    archiveTask(taskId: $taskId)
  }
`;
export type ArchiveTaskMutationFn = Apollo.MutationFunction<ArchiveTaskMutation, ArchiveTaskMutationVariables>;

/**
 * __useArchiveTaskMutation__
 *
 * To run a mutation, you first call `useArchiveTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveTaskMutation, { data, loading, error }] = useArchiveTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useArchiveTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<ArchiveTaskMutation, ArchiveTaskMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ArchiveTaskMutation, ArchiveTaskMutationVariables>(ArchiveTaskDocument, options);
}
export type ArchiveTaskMutationHookResult = ReturnType<typeof useArchiveTaskMutation>;
export type ArchiveTaskMutationResult = Apollo.MutationResult<ArchiveTaskMutation>;
export type ArchiveTaskMutationOptions = Apollo.BaseMutationOptions<ArchiveTaskMutation, ArchiveTaskMutationVariables>;
export const AssignHomematesDocument = gql`
  mutation AssignHomemates($input: UpdateHomematesInput!) {
    assignHomemates(input: $input) {
      ...Home
    }
  }
  ${HomeFragmentDoc}
`;
export type AssignHomematesMutationFn = Apollo.MutationFunction<
  AssignHomematesMutation,
  AssignHomematesMutationVariables
>;

/**
 * __useAssignHomematesMutation__
 *
 * To run a mutation, you first call `useAssignHomematesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignHomematesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignHomematesMutation, { data, loading, error }] = useAssignHomematesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignHomematesMutation(
  baseOptions?: Apollo.MutationHookOptions<AssignHomematesMutation, AssignHomematesMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AssignHomematesMutation, AssignHomematesMutationVariables>(
    AssignHomematesDocument,
    options,
  );
}
export type AssignHomematesMutationHookResult = ReturnType<typeof useAssignHomematesMutation>;
export type AssignHomematesMutationResult = Apollo.MutationResult<AssignHomematesMutation>;
export type AssignHomematesMutationOptions = Apollo.BaseMutationOptions<
  AssignHomematesMutation,
  AssignHomematesMutationVariables
>;
export const AssignTaskDocument = gql`
  mutation AssignTask($input: AssignTaskInput!) {
    assignTask(input: $input) {
      ...Task
    }
  }
  ${TaskFragmentDoc}
`;
export type AssignTaskMutationFn = Apollo.MutationFunction<AssignTaskMutation, AssignTaskMutationVariables>;

/**
 * __useAssignTaskMutation__
 *
 * To run a mutation, you first call `useAssignTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignTaskMutation, { data, loading, error }] = useAssignTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<AssignTaskMutation, AssignTaskMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AssignTaskMutation, AssignTaskMutationVariables>(AssignTaskDocument, options);
}
export type AssignTaskMutationHookResult = ReturnType<typeof useAssignTaskMutation>;
export type AssignTaskMutationResult = Apollo.MutationResult<AssignTaskMutation>;
export type AssignTaskMutationOptions = Apollo.BaseMutationOptions<AssignTaskMutation, AssignTaskMutationVariables>;
export const ChangePasswordDocument = gql`
  mutation ChangePassword($input: ChangePasswordInput!) {
    changePassword(input: $input)
  }
`;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangePasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
}
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>;
export const ChangeUserRoleDocument = gql`
  mutation ChangeUserRole($input: ChangeRoleInput!) {
    changeUserRole(input: $input) {
      id
      role
    }
  }
`;
export type ChangeUserRoleMutationFn = Apollo.MutationFunction<ChangeUserRoleMutation, ChangeUserRoleMutationVariables>;

/**
 * __useChangeUserRoleMutation__
 *
 * To run a mutation, you first call `useChangeUserRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeUserRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeUserRoleMutation, { data, loading, error }] = useChangeUserRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeUserRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<ChangeUserRoleMutation, ChangeUserRoleMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChangeUserRoleMutation, ChangeUserRoleMutationVariables>(ChangeUserRoleDocument, options);
}
export type ChangeUserRoleMutationHookResult = ReturnType<typeof useChangeUserRoleMutation>;
export type ChangeUserRoleMutationResult = Apollo.MutationResult<ChangeUserRoleMutation>;
export type ChangeUserRoleMutationOptions = Apollo.BaseMutationOptions<
  ChangeUserRoleMutation,
  ChangeUserRoleMutationVariables
>;
export const ConfirmDoItRequestDraftDocument = gql`
  mutation ConfirmDoItRequestDraft($draftId: ID!) {
    confirmDoItRequestDraft(draftId: $draftId) {
      ...DoItRequest
    }
  }
  ${DoItRequestFragmentDoc}
`;
export type ConfirmDoItRequestDraftMutationFn = Apollo.MutationFunction<
  ConfirmDoItRequestDraftMutation,
  ConfirmDoItRequestDraftMutationVariables
>;

/**
 * __useConfirmDoItRequestDraftMutation__
 *
 * To run a mutation, you first call `useConfirmDoItRequestDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmDoItRequestDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmDoItRequestDraftMutation, { data, loading, error }] = useConfirmDoItRequestDraftMutation({
 *   variables: {
 *      draftId: // value for 'draftId'
 *   },
 * });
 */
export function useConfirmDoItRequestDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<ConfirmDoItRequestDraftMutation, ConfirmDoItRequestDraftMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ConfirmDoItRequestDraftMutation, ConfirmDoItRequestDraftMutationVariables>(
    ConfirmDoItRequestDraftDocument,
    options,
  );
}
export type ConfirmDoItRequestDraftMutationHookResult = ReturnType<typeof useConfirmDoItRequestDraftMutation>;
export type ConfirmDoItRequestDraftMutationResult = Apollo.MutationResult<ConfirmDoItRequestDraftMutation>;
export type ConfirmDoItRequestDraftMutationOptions = Apollo.BaseMutationOptions<
  ConfirmDoItRequestDraftMutation,
  ConfirmDoItRequestDraftMutationVariables
>;
export const ConfirmDoitRequestImagesUploadingDocument = gql`
  mutation ConfirmDoitRequestImagesUploading($input: ConfirmFilesInput!) {
    confirmDoitRequestImagesUploading(input: $input) {
      ...CoverImage
    }
  }
  ${CoverImageFragmentDoc}
`;
export type ConfirmDoitRequestImagesUploadingMutationFn = Apollo.MutationFunction<
  ConfirmDoitRequestImagesUploadingMutation,
  ConfirmDoitRequestImagesUploadingMutationVariables
>;

/**
 * __useConfirmDoitRequestImagesUploadingMutation__
 *
 * To run a mutation, you first call `useConfirmDoitRequestImagesUploadingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmDoitRequestImagesUploadingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmDoitRequestImagesUploadingMutation, { data, loading, error }] = useConfirmDoitRequestImagesUploadingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmDoitRequestImagesUploadingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmDoitRequestImagesUploadingMutation,
    ConfirmDoitRequestImagesUploadingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ConfirmDoitRequestImagesUploadingMutation,
    ConfirmDoitRequestImagesUploadingMutationVariables
  >(ConfirmDoitRequestImagesUploadingDocument, options);
}
export type ConfirmDoitRequestImagesUploadingMutationHookResult = ReturnType<
  typeof useConfirmDoitRequestImagesUploadingMutation
>;
export type ConfirmDoitRequestImagesUploadingMutationResult =
  Apollo.MutationResult<ConfirmDoitRequestImagesUploadingMutation>;
export type ConfirmDoitRequestImagesUploadingMutationOptions = Apollo.BaseMutationOptions<
  ConfirmDoitRequestImagesUploadingMutation,
  ConfirmDoitRequestImagesUploadingMutationVariables
>;
export const ConfirmHomeImageUploadingDocument = gql`
  mutation ConfirmHomeImageUploading($input: ConfirmFileInput!) {
    confirmHomeImageUploading(input: $input) {
      ...CoverImage
    }
  }
  ${CoverImageFragmentDoc}
`;
export type ConfirmHomeImageUploadingMutationFn = Apollo.MutationFunction<
  ConfirmHomeImageUploadingMutation,
  ConfirmHomeImageUploadingMutationVariables
>;

/**
 * __useConfirmHomeImageUploadingMutation__
 *
 * To run a mutation, you first call `useConfirmHomeImageUploadingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmHomeImageUploadingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmHomeImageUploadingMutation, { data, loading, error }] = useConfirmHomeImageUploadingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmHomeImageUploadingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmHomeImageUploadingMutation,
    ConfirmHomeImageUploadingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ConfirmHomeImageUploadingMutation, ConfirmHomeImageUploadingMutationVariables>(
    ConfirmHomeImageUploadingDocument,
    options,
  );
}
export type ConfirmHomeImageUploadingMutationHookResult = ReturnType<typeof useConfirmHomeImageUploadingMutation>;
export type ConfirmHomeImageUploadingMutationResult = Apollo.MutationResult<ConfirmHomeImageUploadingMutation>;
export type ConfirmHomeImageUploadingMutationOptions = Apollo.BaseMutationOptions<
  ConfirmHomeImageUploadingMutation,
  ConfirmHomeImageUploadingMutationVariables
>;
export const ConfirmItemImagesUploadingDocument = gql`
  mutation ConfirmItemImagesUploading($input: ConfirmFilesInput!) {
    confirmItemImagesUploading(input: $input) {
      id
      filename
      mimetype
      blurHash
      createdAt
      updatedAt
      url
      isMain
    }
  }
`;
export type ConfirmItemImagesUploadingMutationFn = Apollo.MutationFunction<
  ConfirmItemImagesUploadingMutation,
  ConfirmItemImagesUploadingMutationVariables
>;

/**
 * __useConfirmItemImagesUploadingMutation__
 *
 * To run a mutation, you first call `useConfirmItemImagesUploadingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmItemImagesUploadingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmItemImagesUploadingMutation, { data, loading, error }] = useConfirmItemImagesUploadingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmItemImagesUploadingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmItemImagesUploadingMutation,
    ConfirmItemImagesUploadingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ConfirmItemImagesUploadingMutation, ConfirmItemImagesUploadingMutationVariables>(
    ConfirmItemImagesUploadingDocument,
    options,
  );
}
export type ConfirmItemImagesUploadingMutationHookResult = ReturnType<typeof useConfirmItemImagesUploadingMutation>;
export type ConfirmItemImagesUploadingMutationResult = Apollo.MutationResult<ConfirmItemImagesUploadingMutation>;
export type ConfirmItemImagesUploadingMutationOptions = Apollo.BaseMutationOptions<
  ConfirmItemImagesUploadingMutation,
  ConfirmItemImagesUploadingMutationVariables
>;
export const ConfirmTaskImagesUploadingDocument = gql`
  mutation ConfirmTaskImagesUploading($input: ConfirmFilesInput!) {
    confirmTaskImagesUploading(input: $input) {
      id
      filename
      mimetype
      blurHash
      createdAt
      updatedAt
      url
      isMain
    }
  }
`;
export type ConfirmTaskImagesUploadingMutationFn = Apollo.MutationFunction<
  ConfirmTaskImagesUploadingMutation,
  ConfirmTaskImagesUploadingMutationVariables
>;

/**
 * __useConfirmTaskImagesUploadingMutation__
 *
 * To run a mutation, you first call `useConfirmTaskImagesUploadingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmTaskImagesUploadingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmTaskImagesUploadingMutation, { data, loading, error }] = useConfirmTaskImagesUploadingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmTaskImagesUploadingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmTaskImagesUploadingMutation,
    ConfirmTaskImagesUploadingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ConfirmTaskImagesUploadingMutation, ConfirmTaskImagesUploadingMutationVariables>(
    ConfirmTaskImagesUploadingDocument,
    options,
  );
}
export type ConfirmTaskImagesUploadingMutationHookResult = ReturnType<typeof useConfirmTaskImagesUploadingMutation>;
export type ConfirmTaskImagesUploadingMutationResult = Apollo.MutationResult<ConfirmTaskImagesUploadingMutation>;
export type ConfirmTaskImagesUploadingMutationOptions = Apollo.BaseMutationOptions<
  ConfirmTaskImagesUploadingMutation,
  ConfirmTaskImagesUploadingMutationVariables
>;
export const CreateDoItRequestDraftDocument = gql`
  mutation CreateDoItRequestDraft($homeId: ID!) {
    createDoItRequestDraft(homeId: $homeId) {
      ...DoItRequest
    }
  }
  ${DoItRequestFragmentDoc}
`;
export type CreateDoItRequestDraftMutationFn = Apollo.MutationFunction<
  CreateDoItRequestDraftMutation,
  CreateDoItRequestDraftMutationVariables
>;

/**
 * __useCreateDoItRequestDraftMutation__
 *
 * To run a mutation, you first call `useCreateDoItRequestDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDoItRequestDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDoItRequestDraftMutation, { data, loading, error }] = useCreateDoItRequestDraftMutation({
 *   variables: {
 *      homeId: // value for 'homeId'
 *   },
 * });
 */
export function useCreateDoItRequestDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateDoItRequestDraftMutation, CreateDoItRequestDraftMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateDoItRequestDraftMutation, CreateDoItRequestDraftMutationVariables>(
    CreateDoItRequestDraftDocument,
    options,
  );
}
export type CreateDoItRequestDraftMutationHookResult = ReturnType<typeof useCreateDoItRequestDraftMutation>;
export type CreateDoItRequestDraftMutationResult = Apollo.MutationResult<CreateDoItRequestDraftMutation>;
export type CreateDoItRequestDraftMutationOptions = Apollo.BaseMutationOptions<
  CreateDoItRequestDraftMutation,
  CreateDoItRequestDraftMutationVariables
>;
export const CreateHomeDocument = gql`
  mutation CreateHome($input: CreateHomeInput!) {
    createHome(input: $input) {
      ...Home
    }
  }
  ${HomeFragmentDoc}
`;
export type CreateHomeMutationFn = Apollo.MutationFunction<CreateHomeMutation, CreateHomeMutationVariables>;

/**
 * __useCreateHomeMutation__
 *
 * To run a mutation, you first call `useCreateHomeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateHomeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createHomeMutation, { data, loading, error }] = useCreateHomeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateHomeMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateHomeMutation, CreateHomeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateHomeMutation, CreateHomeMutationVariables>(CreateHomeDocument, options);
}
export type CreateHomeMutationHookResult = ReturnType<typeof useCreateHomeMutation>;
export type CreateHomeMutationResult = Apollo.MutationResult<CreateHomeMutation>;
export type CreateHomeMutationOptions = Apollo.BaseMutationOptions<CreateHomeMutation, CreateHomeMutationVariables>;
export const CreateTaskDocument = gql`
  mutation CreateTask($input: CreateTaskInput!) {
    createTask(input: $input) {
      ...Task
    }
  }
  ${TaskFragmentDoc}
`;
export type CreateTaskMutationFn = Apollo.MutationFunction<CreateTaskMutation, CreateTaskMutationVariables>;

/**
 * __useCreateTaskMutation__
 *
 * To run a mutation, you first call `useCreateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskMutation, { data, loading, error }] = useCreateTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTaskMutation, CreateTaskMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTaskMutation, CreateTaskMutationVariables>(CreateTaskDocument, options);
}
export type CreateTaskMutationHookResult = ReturnType<typeof useCreateTaskMutation>;
export type CreateTaskMutationResult = Apollo.MutationResult<CreateTaskMutation>;
export type CreateTaskMutationOptions = Apollo.BaseMutationOptions<CreateTaskMutation, CreateTaskMutationVariables>;
export const DeferTaskDocument = gql`
  mutation DeferTask($input: DeferTaskInput!) {
    deferTask(input: $input) {
      ...Task
    }
  }
  ${TaskFragmentDoc}
`;
export type DeferTaskMutationFn = Apollo.MutationFunction<DeferTaskMutation, DeferTaskMutationVariables>;

/**
 * __useDeferTaskMutation__
 *
 * To run a mutation, you first call `useDeferTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeferTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deferTaskMutation, { data, loading, error }] = useDeferTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeferTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<DeferTaskMutation, DeferTaskMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeferTaskMutation, DeferTaskMutationVariables>(DeferTaskDocument, options);
}
export type DeferTaskMutationHookResult = ReturnType<typeof useDeferTaskMutation>;
export type DeferTaskMutationResult = Apollo.MutationResult<DeferTaskMutation>;
export type DeferTaskMutationOptions = Apollo.BaseMutationOptions<DeferTaskMutation, DeferTaskMutationVariables>;
export const DeleteDoitRequestImagesDocument = gql`
  mutation DeleteDoitRequestImages($input: DeleteFilesInput!) {
    deleteDoitRequestImages(input: $input)
  }
`;
export type DeleteDoitRequestImagesMutationFn = Apollo.MutationFunction<
  DeleteDoitRequestImagesMutation,
  DeleteDoitRequestImagesMutationVariables
>;

/**
 * __useDeleteDoitRequestImagesMutation__
 *
 * To run a mutation, you first call `useDeleteDoitRequestImagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDoitRequestImagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDoitRequestImagesMutation, { data, loading, error }] = useDeleteDoitRequestImagesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteDoitRequestImagesMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteDoitRequestImagesMutation, DeleteDoitRequestImagesMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteDoitRequestImagesMutation, DeleteDoitRequestImagesMutationVariables>(
    DeleteDoitRequestImagesDocument,
    options,
  );
}
export type DeleteDoitRequestImagesMutationHookResult = ReturnType<typeof useDeleteDoitRequestImagesMutation>;
export type DeleteDoitRequestImagesMutationResult = Apollo.MutationResult<DeleteDoitRequestImagesMutation>;
export type DeleteDoitRequestImagesMutationOptions = Apollo.BaseMutationOptions<
  DeleteDoitRequestImagesMutation,
  DeleteDoitRequestImagesMutationVariables
>;
export const DeleteInvitationDocument = gql`
  mutation DeleteInvitation($invitationId: ID!) {
    deleteInvitation(invitationId: $invitationId)
  }
`;
export type DeleteInvitationMutationFn = Apollo.MutationFunction<
  DeleteInvitationMutation,
  DeleteInvitationMutationVariables
>;

/**
 * __useDeleteInvitationMutation__
 *
 * To run a mutation, you first call `useDeleteInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInvitationMutation, { data, loading, error }] = useDeleteInvitationMutation({
 *   variables: {
 *      invitationId: // value for 'invitationId'
 *   },
 * });
 */
export function useDeleteInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteInvitationMutation, DeleteInvitationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteInvitationMutation, DeleteInvitationMutationVariables>(
    DeleteInvitationDocument,
    options,
  );
}
export type DeleteInvitationMutationHookResult = ReturnType<typeof useDeleteInvitationMutation>;
export type DeleteInvitationMutationResult = Apollo.MutationResult<DeleteInvitationMutation>;
export type DeleteInvitationMutationOptions = Apollo.BaseMutationOptions<
  DeleteInvitationMutation,
  DeleteInvitationMutationVariables
>;
export const DeleteItemImagesDocument = gql`
  mutation DeleteItemImages($input: DeleteFilesInput!) {
    deleteItemImages(input: $input)
  }
`;
export type DeleteItemImagesMutationFn = Apollo.MutationFunction<
  DeleteItemImagesMutation,
  DeleteItemImagesMutationVariables
>;

/**
 * __useDeleteItemImagesMutation__
 *
 * To run a mutation, you first call `useDeleteItemImagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteItemImagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteItemImagesMutation, { data, loading, error }] = useDeleteItemImagesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteItemImagesMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteItemImagesMutation, DeleteItemImagesMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteItemImagesMutation, DeleteItemImagesMutationVariables>(
    DeleteItemImagesDocument,
    options,
  );
}
export type DeleteItemImagesMutationHookResult = ReturnType<typeof useDeleteItemImagesMutation>;
export type DeleteItemImagesMutationResult = Apollo.MutationResult<DeleteItemImagesMutation>;
export type DeleteItemImagesMutationOptions = Apollo.BaseMutationOptions<
  DeleteItemImagesMutation,
  DeleteItemImagesMutationVariables
>;
export const DeleteTaskImagesDocument = gql`
  mutation DeleteTaskImages($input: DeleteTaskImagesInput!) {
    deleteTaskImages(input: $input)
  }
`;
export type DeleteTaskImagesMutationFn = Apollo.MutationFunction<
  DeleteTaskImagesMutation,
  DeleteTaskImagesMutationVariables
>;

/**
 * __useDeleteTaskImagesMutation__
 *
 * To run a mutation, you first call `useDeleteTaskImagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaskImagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaskImagesMutation, { data, loading, error }] = useDeleteTaskImagesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTaskImagesMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteTaskImagesMutation, DeleteTaskImagesMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteTaskImagesMutation, DeleteTaskImagesMutationVariables>(
    DeleteTaskImagesDocument,
    options,
  );
}
export type DeleteTaskImagesMutationHookResult = ReturnType<typeof useDeleteTaskImagesMutation>;
export type DeleteTaskImagesMutationResult = Apollo.MutationResult<DeleteTaskImagesMutation>;
export type DeleteTaskImagesMutationOptions = Apollo.BaseMutationOptions<
  DeleteTaskImagesMutation,
  DeleteTaskImagesMutationVariables
>;
export const FinishDoitRequestDocument = gql`
  mutation FinishDoitRequest($doitRequestId: ID!) {
    finishDoitRequest(doitRequestId: $doitRequestId) {
      ...DoItRequest
    }
  }
  ${DoItRequestFragmentDoc}
`;
export type FinishDoitRequestMutationFn = Apollo.MutationFunction<
  FinishDoitRequestMutation,
  FinishDoitRequestMutationVariables
>;

/**
 * __useFinishDoitRequestMutation__
 *
 * To run a mutation, you first call `useFinishDoitRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishDoitRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishDoitRequestMutation, { data, loading, error }] = useFinishDoitRequestMutation({
 *   variables: {
 *      doitRequestId: // value for 'doitRequestId'
 *   },
 * });
 */
export function useFinishDoitRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<FinishDoitRequestMutation, FinishDoitRequestMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FinishDoitRequestMutation, FinishDoitRequestMutationVariables>(
    FinishDoitRequestDocument,
    options,
  );
}
export type FinishDoitRequestMutationHookResult = ReturnType<typeof useFinishDoitRequestMutation>;
export type FinishDoitRequestMutationResult = Apollo.MutationResult<FinishDoitRequestMutation>;
export type FinishDoitRequestMutationOptions = Apollo.BaseMutationOptions<
  FinishDoitRequestMutation,
  FinishDoitRequestMutationVariables
>;
export const FinishTaskDocument = gql`
  mutation FinishTask($taskId: ID!) {
    finishTask(taskId: $taskId) {
      ...Task
    }
  }
  ${TaskFragmentDoc}
`;
export type FinishTaskMutationFn = Apollo.MutationFunction<FinishTaskMutation, FinishTaskMutationVariables>;

/**
 * __useFinishTaskMutation__
 *
 * To run a mutation, you first call `useFinishTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishTaskMutation, { data, loading, error }] = useFinishTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useFinishTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<FinishTaskMutation, FinishTaskMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FinishTaskMutation, FinishTaskMutationVariables>(FinishTaskDocument, options);
}
export type FinishTaskMutationHookResult = ReturnType<typeof useFinishTaskMutation>;
export type FinishTaskMutationResult = Apollo.MutationResult<FinishTaskMutation>;
export type FinishTaskMutationOptions = Apollo.BaseMutationOptions<FinishTaskMutation, FinishTaskMutationVariables>;
export const InviteUserDocument = gql`
  mutation InviteUser($input: InviteUserInput!) {
    inviteUser(input: $input) {
      code
      invitation {
        id
        email
        createdBy
        role
        expiresAt
        profile {
          firstName
          lastName
          phone
        }
      }
    }
  }
`;
export type InviteUserMutationFn = Apollo.MutationFunction<InviteUserMutation, InviteUserMutationVariables>;

/**
 * __useInviteUserMutation__
 *
 * To run a mutation, you first call `useInviteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUserMutation, { data, loading, error }] = useInviteUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteUserMutation(
  baseOptions?: Apollo.MutationHookOptions<InviteUserMutation, InviteUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InviteUserMutation, InviteUserMutationVariables>(InviteUserDocument, options);
}
export type InviteUserMutationHookResult = ReturnType<typeof useInviteUserMutation>;
export type InviteUserMutationResult = Apollo.MutationResult<InviteUserMutation>;
export type InviteUserMutationOptions = Apollo.BaseMutationOptions<InviteUserMutation, InviteUserMutationVariables>;
export const LoginDocument = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      ...AuthResponse
    }
  }
  ${AuthResponseFragmentDoc}
`;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LoginWithAppleTokenDocument = gql`
  mutation LoginWithAppleToken($input: AppleLoginInput!) {
    loginWithAppleToken(input: $input) {
      ...AuthResponse
    }
  }
  ${AuthResponseFragmentDoc}
`;
export type LoginWithAppleTokenMutationFn = Apollo.MutationFunction<
  LoginWithAppleTokenMutation,
  LoginWithAppleTokenMutationVariables
>;

/**
 * __useLoginWithAppleTokenMutation__
 *
 * To run a mutation, you first call `useLoginWithAppleTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginWithAppleTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginWithAppleTokenMutation, { data, loading, error }] = useLoginWithAppleTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginWithAppleTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<LoginWithAppleTokenMutation, LoginWithAppleTokenMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginWithAppleTokenMutation, LoginWithAppleTokenMutationVariables>(
    LoginWithAppleTokenDocument,
    options,
  );
}
export type LoginWithAppleTokenMutationHookResult = ReturnType<typeof useLoginWithAppleTokenMutation>;
export type LoginWithAppleTokenMutationResult = Apollo.MutationResult<LoginWithAppleTokenMutation>;
export type LoginWithAppleTokenMutationOptions = Apollo.BaseMutationOptions<
  LoginWithAppleTokenMutation,
  LoginWithAppleTokenMutationVariables
>;
export const LoginWithGoogleCodeDocument = gql`
  mutation LoginWithGoogleCode($code: String!) {
    loginWithGoogleCode(code: $code) {
      ...AuthResponse
    }
  }
  ${AuthResponseFragmentDoc}
`;
export type LoginWithGoogleCodeMutationFn = Apollo.MutationFunction<
  LoginWithGoogleCodeMutation,
  LoginWithGoogleCodeMutationVariables
>;

/**
 * __useLoginWithGoogleCodeMutation__
 *
 * To run a mutation, you first call `useLoginWithGoogleCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginWithGoogleCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginWithGoogleCodeMutation, { data, loading, error }] = useLoginWithGoogleCodeMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useLoginWithGoogleCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<LoginWithGoogleCodeMutation, LoginWithGoogleCodeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginWithGoogleCodeMutation, LoginWithGoogleCodeMutationVariables>(
    LoginWithGoogleCodeDocument,
    options,
  );
}
export type LoginWithGoogleCodeMutationHookResult = ReturnType<typeof useLoginWithGoogleCodeMutation>;
export type LoginWithGoogleCodeMutationResult = Apollo.MutationResult<LoginWithGoogleCodeMutation>;
export type LoginWithGoogleCodeMutationOptions = Apollo.BaseMutationOptions<
  LoginWithGoogleCodeMutation,
  LoginWithGoogleCodeMutationVariables
>;
export const LoginWithGoogleTokenDocument = gql`
  mutation LoginWithGoogleToken($googleToken: String!) {
    loginWithGoogleToken(googleToken: $googleToken) {
      ...AuthResponse
    }
  }
  ${AuthResponseFragmentDoc}
`;
export type LoginWithGoogleTokenMutationFn = Apollo.MutationFunction<
  LoginWithGoogleTokenMutation,
  LoginWithGoogleTokenMutationVariables
>;

/**
 * __useLoginWithGoogleTokenMutation__
 *
 * To run a mutation, you first call `useLoginWithGoogleTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginWithGoogleTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginWithGoogleTokenMutation, { data, loading, error }] = useLoginWithGoogleTokenMutation({
 *   variables: {
 *      googleToken: // value for 'googleToken'
 *   },
 * });
 */
export function useLoginWithGoogleTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<LoginWithGoogleTokenMutation, LoginWithGoogleTokenMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginWithGoogleTokenMutation, LoginWithGoogleTokenMutationVariables>(
    LoginWithGoogleTokenDocument,
    options,
  );
}
export type LoginWithGoogleTokenMutationHookResult = ReturnType<typeof useLoginWithGoogleTokenMutation>;
export type LoginWithGoogleTokenMutationResult = Apollo.MutationResult<LoginWithGoogleTokenMutation>;
export type LoginWithGoogleTokenMutationOptions = Apollo.BaseMutationOptions<
  LoginWithGoogleTokenMutation,
  LoginWithGoogleTokenMutationVariables
>;
export const LoginWithResetPasswordCodeDocument = gql`
  mutation LoginWithResetPasswordCode($email: String!, $code: String!) {
    loginWithResetPasswordCode(email: $email, code: $code) {
      ...AuthResponse
    }
  }
  ${AuthResponseFragmentDoc}
`;
export type LoginWithResetPasswordCodeMutationFn = Apollo.MutationFunction<
  LoginWithResetPasswordCodeMutation,
  LoginWithResetPasswordCodeMutationVariables
>;

/**
 * __useLoginWithResetPasswordCodeMutation__
 *
 * To run a mutation, you first call `useLoginWithResetPasswordCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginWithResetPasswordCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginWithResetPasswordCodeMutation, { data, loading, error }] = useLoginWithResetPasswordCodeMutation({
 *   variables: {
 *      email: // value for 'email'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useLoginWithResetPasswordCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginWithResetPasswordCodeMutation,
    LoginWithResetPasswordCodeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginWithResetPasswordCodeMutation, LoginWithResetPasswordCodeMutationVariables>(
    LoginWithResetPasswordCodeDocument,
    options,
  );
}
export type LoginWithResetPasswordCodeMutationHookResult = ReturnType<typeof useLoginWithResetPasswordCodeMutation>;
export type LoginWithResetPasswordCodeMutationResult = Apollo.MutationResult<LoginWithResetPasswordCodeMutation>;
export type LoginWithResetPasswordCodeMutationOptions = Apollo.BaseMutationOptions<
  LoginWithResetPasswordCodeMutation,
  LoginWithResetPasswordCodeMutationVariables
>;
export const LogoutDocument = gql`
  mutation Logout {
    logout
  }
`;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
}
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const RegisterByInvitationDocument = gql`
  mutation RegisterByInvitation($input: RegisterByInvitationInput!) {
    registerByInvitation(input: $input) {
      ...AuthResponse
    }
  }
  ${AuthResponseFragmentDoc}
`;
export type RegisterByInvitationMutationFn = Apollo.MutationFunction<
  RegisterByInvitationMutation,
  RegisterByInvitationMutationVariables
>;

/**
 * __useRegisterByInvitationMutation__
 *
 * To run a mutation, you first call `useRegisterByInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterByInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerByInvitationMutation, { data, loading, error }] = useRegisterByInvitationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterByInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<RegisterByInvitationMutation, RegisterByInvitationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RegisterByInvitationMutation, RegisterByInvitationMutationVariables>(
    RegisterByInvitationDocument,
    options,
  );
}
export type RegisterByInvitationMutationHookResult = ReturnType<typeof useRegisterByInvitationMutation>;
export type RegisterByInvitationMutationResult = Apollo.MutationResult<RegisterByInvitationMutation>;
export type RegisterByInvitationMutationOptions = Apollo.BaseMutationOptions<
  RegisterByInvitationMutation,
  RegisterByInvitationMutationVariables
>;
export const RequestDoitRequestImagesUploadingDocument = gql`
  mutation RequestDoitRequestImagesUploading($input: UploadFilesInput!) {
    requestDoitRequestImagesUploading(input: $input) {
      id
      url
      filename
    }
  }
`;
export type RequestDoitRequestImagesUploadingMutationFn = Apollo.MutationFunction<
  RequestDoitRequestImagesUploadingMutation,
  RequestDoitRequestImagesUploadingMutationVariables
>;

/**
 * __useRequestDoitRequestImagesUploadingMutation__
 *
 * To run a mutation, you first call `useRequestDoitRequestImagesUploadingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestDoitRequestImagesUploadingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestDoitRequestImagesUploadingMutation, { data, loading, error }] = useRequestDoitRequestImagesUploadingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestDoitRequestImagesUploadingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestDoitRequestImagesUploadingMutation,
    RequestDoitRequestImagesUploadingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestDoitRequestImagesUploadingMutation,
    RequestDoitRequestImagesUploadingMutationVariables
  >(RequestDoitRequestImagesUploadingDocument, options);
}
export type RequestDoitRequestImagesUploadingMutationHookResult = ReturnType<
  typeof useRequestDoitRequestImagesUploadingMutation
>;
export type RequestDoitRequestImagesUploadingMutationResult =
  Apollo.MutationResult<RequestDoitRequestImagesUploadingMutation>;
export type RequestDoitRequestImagesUploadingMutationOptions = Apollo.BaseMutationOptions<
  RequestDoitRequestImagesUploadingMutation,
  RequestDoitRequestImagesUploadingMutationVariables
>;
export const RequestHomeImageUploadingDocument = gql`
  mutation RequestHomeImageUploading($input: UploadFileInput!) {
    requestHomeImageUploading(input: $input) {
      id
      url
    }
  }
`;
export type RequestHomeImageUploadingMutationFn = Apollo.MutationFunction<
  RequestHomeImageUploadingMutation,
  RequestHomeImageUploadingMutationVariables
>;

/**
 * __useRequestHomeImageUploadingMutation__
 *
 * To run a mutation, you first call `useRequestHomeImageUploadingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestHomeImageUploadingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestHomeImageUploadingMutation, { data, loading, error }] = useRequestHomeImageUploadingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestHomeImageUploadingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestHomeImageUploadingMutation,
    RequestHomeImageUploadingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RequestHomeImageUploadingMutation, RequestHomeImageUploadingMutationVariables>(
    RequestHomeImageUploadingDocument,
    options,
  );
}
export type RequestHomeImageUploadingMutationHookResult = ReturnType<typeof useRequestHomeImageUploadingMutation>;
export type RequestHomeImageUploadingMutationResult = Apollo.MutationResult<RequestHomeImageUploadingMutation>;
export type RequestHomeImageUploadingMutationOptions = Apollo.BaseMutationOptions<
  RequestHomeImageUploadingMutation,
  RequestHomeImageUploadingMutationVariables
>;
export const RequestItemImagesUploadingDocument = gql`
  mutation RequestItemImagesUploading($input: UploadFilesInput!) {
    requestItemImagesUploading(input: $input) {
      id
      url
      filename
    }
  }
`;
export type RequestItemImagesUploadingMutationFn = Apollo.MutationFunction<
  RequestItemImagesUploadingMutation,
  RequestItemImagesUploadingMutationVariables
>;

/**
 * __useRequestItemImagesUploadingMutation__
 *
 * To run a mutation, you first call `useRequestItemImagesUploadingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestItemImagesUploadingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestItemImagesUploadingMutation, { data, loading, error }] = useRequestItemImagesUploadingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestItemImagesUploadingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestItemImagesUploadingMutation,
    RequestItemImagesUploadingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RequestItemImagesUploadingMutation, RequestItemImagesUploadingMutationVariables>(
    RequestItemImagesUploadingDocument,
    options,
  );
}
export type RequestItemImagesUploadingMutationHookResult = ReturnType<typeof useRequestItemImagesUploadingMutation>;
export type RequestItemImagesUploadingMutationResult = Apollo.MutationResult<RequestItemImagesUploadingMutation>;
export type RequestItemImagesUploadingMutationOptions = Apollo.BaseMutationOptions<
  RequestItemImagesUploadingMutation,
  RequestItemImagesUploadingMutationVariables
>;
export const RequestTaskImagesUploadingDocument = gql`
  mutation RequestTaskImagesUploading($input: UploadFilesInput!) {
    requestTaskImagesUploading(input: $input) {
      id
      url
      filename
    }
  }
`;
export type RequestTaskImagesUploadingMutationFn = Apollo.MutationFunction<
  RequestTaskImagesUploadingMutation,
  RequestTaskImagesUploadingMutationVariables
>;

/**
 * __useRequestTaskImagesUploadingMutation__
 *
 * To run a mutation, you first call `useRequestTaskImagesUploadingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestTaskImagesUploadingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestTaskImagesUploadingMutation, { data, loading, error }] = useRequestTaskImagesUploadingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestTaskImagesUploadingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestTaskImagesUploadingMutation,
    RequestTaskImagesUploadingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RequestTaskImagesUploadingMutation, RequestTaskImagesUploadingMutationVariables>(
    RequestTaskImagesUploadingDocument,
    options,
  );
}
export type RequestTaskImagesUploadingMutationHookResult = ReturnType<typeof useRequestTaskImagesUploadingMutation>;
export type RequestTaskImagesUploadingMutationResult = Apollo.MutationResult<RequestTaskImagesUploadingMutation>;
export type RequestTaskImagesUploadingMutationOptions = Apollo.BaseMutationOptions<
  RequestTaskImagesUploadingMutation,
  RequestTaskImagesUploadingMutationVariables
>;
export const ResetPasswordDocument = gql`
  mutation ResetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input)
  }
`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
}
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export const SendResetPasswordCodeDocument = gql`
  mutation SendResetPasswordCode($email: String!) {
    sendResetPasswordCode(email: $email)
  }
`;
export type SendResetPasswordCodeMutationFn = Apollo.MutationFunction<
  SendResetPasswordCodeMutation,
  SendResetPasswordCodeMutationVariables
>;

/**
 * __useSendResetPasswordCodeMutation__
 *
 * To run a mutation, you first call `useSendResetPasswordCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendResetPasswordCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendResetPasswordCodeMutation, { data, loading, error }] = useSendResetPasswordCodeMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendResetPasswordCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<SendResetPasswordCodeMutation, SendResetPasswordCodeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendResetPasswordCodeMutation, SendResetPasswordCodeMutationVariables>(
    SendResetPasswordCodeDocument,
    options,
  );
}
export type SendResetPasswordCodeMutationHookResult = ReturnType<typeof useSendResetPasswordCodeMutation>;
export type SendResetPasswordCodeMutationResult = Apollo.MutationResult<SendResetPasswordCodeMutation>;
export type SendResetPasswordCodeMutationOptions = Apollo.BaseMutationOptions<
  SendResetPasswordCodeMutation,
  SendResetPasswordCodeMutationVariables
>;
export const SendVerificationCodeDocument = gql`
  mutation SendVerificationCode {
    sendVerificationCode
  }
`;
export type SendVerificationCodeMutationFn = Apollo.MutationFunction<
  SendVerificationCodeMutation,
  SendVerificationCodeMutationVariables
>;

/**
 * __useSendVerificationCodeMutation__
 *
 * To run a mutation, you first call `useSendVerificationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendVerificationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendVerificationCodeMutation, { data, loading, error }] = useSendVerificationCodeMutation({
 *   variables: {
 *   },
 * });
 */
export function useSendVerificationCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<SendVerificationCodeMutation, SendVerificationCodeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendVerificationCodeMutation, SendVerificationCodeMutationVariables>(
    SendVerificationCodeDocument,
    options,
  );
}
export type SendVerificationCodeMutationHookResult = ReturnType<typeof useSendVerificationCodeMutation>;
export type SendVerificationCodeMutationResult = Apollo.MutationResult<SendVerificationCodeMutation>;
export type SendVerificationCodeMutationOptions = Apollo.BaseMutationOptions<
  SendVerificationCodeMutation,
  SendVerificationCodeMutationVariables
>;
export const SetClientHubUrlDocument = gql`
  mutation SetClientHubUrl($input: SetClientUrlInput!) {
    setClientHubUrl(input: $input) {
      ...CurrentUser
    }
  }
  ${CurrentUserFragmentDoc}
`;
export type SetClientHubUrlMutationFn = Apollo.MutationFunction<
  SetClientHubUrlMutation,
  SetClientHubUrlMutationVariables
>;

/**
 * __useSetClientHubUrlMutation__
 *
 * To run a mutation, you first call `useSetClientHubUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetClientHubUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setClientHubUrlMutation, { data, loading, error }] = useSetClientHubUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetClientHubUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<SetClientHubUrlMutation, SetClientHubUrlMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetClientHubUrlMutation, SetClientHubUrlMutationVariables>(
    SetClientHubUrlDocument,
    options,
  );
}
export type SetClientHubUrlMutationHookResult = ReturnType<typeof useSetClientHubUrlMutation>;
export type SetClientHubUrlMutationResult = Apollo.MutationResult<SetClientHubUrlMutation>;
export type SetClientHubUrlMutationOptions = Apollo.BaseMutationOptions<
  SetClientHubUrlMutation,
  SetClientHubUrlMutationVariables
>;
export const SetJobberTokensDocument = gql`
  mutation SetJobberTokens($code: String!) {
    setJobberTokens(code: $code)
  }
`;
export type SetJobberTokensMutationFn = Apollo.MutationFunction<
  SetJobberTokensMutation,
  SetJobberTokensMutationVariables
>;

/**
 * __useSetJobberTokensMutation__
 *
 * To run a mutation, you first call `useSetJobberTokensMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetJobberTokensMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setJobberTokensMutation, { data, loading, error }] = useSetJobberTokensMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useSetJobberTokensMutation(
  baseOptions?: Apollo.MutationHookOptions<SetJobberTokensMutation, SetJobberTokensMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetJobberTokensMutation, SetJobberTokensMutationVariables>(
    SetJobberTokensDocument,
    options,
  );
}
export type SetJobberTokensMutationHookResult = ReturnType<typeof useSetJobberTokensMutation>;
export type SetJobberTokensMutationResult = Apollo.MutationResult<SetJobberTokensMutation>;
export type SetJobberTokensMutationOptions = Apollo.BaseMutationOptions<
  SetJobberTokensMutation,
  SetJobberTokensMutationVariables
>;
export const SetMainItemImageDocument = gql`
  mutation SetMainItemImage($input: SetMainFileInput!) {
    setMainItemImage(input: $input)
  }
`;
export type SetMainItemImageMutationFn = Apollo.MutationFunction<
  SetMainItemImageMutation,
  SetMainItemImageMutationVariables
>;

/**
 * __useSetMainItemImageMutation__
 *
 * To run a mutation, you first call `useSetMainItemImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetMainItemImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setMainItemImageMutation, { data, loading, error }] = useSetMainItemImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetMainItemImageMutation(
  baseOptions?: Apollo.MutationHookOptions<SetMainItemImageMutation, SetMainItemImageMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetMainItemImageMutation, SetMainItemImageMutationVariables>(
    SetMainItemImageDocument,
    options,
  );
}
export type SetMainItemImageMutationHookResult = ReturnType<typeof useSetMainItemImageMutation>;
export type SetMainItemImageMutationResult = Apollo.MutationResult<SetMainItemImageMutation>;
export type SetMainItemImageMutationOptions = Apollo.BaseMutationOptions<
  SetMainItemImageMutation,
  SetMainItemImageMutationVariables
>;
export const SetMainTaskImageDocument = gql`
  mutation SetMainTaskImage($input: SetMainFileInput!) {
    setMainTaskImage(input: $input)
  }
`;
export type SetMainTaskImageMutationFn = Apollo.MutationFunction<
  SetMainTaskImageMutation,
  SetMainTaskImageMutationVariables
>;

/**
 * __useSetMainTaskImageMutation__
 *
 * To run a mutation, you first call `useSetMainTaskImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetMainTaskImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setMainTaskImageMutation, { data, loading, error }] = useSetMainTaskImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetMainTaskImageMutation(
  baseOptions?: Apollo.MutationHookOptions<SetMainTaskImageMutation, SetMainTaskImageMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetMainTaskImageMutation, SetMainTaskImageMutationVariables>(
    SetMainTaskImageDocument,
    options,
  );
}
export type SetMainTaskImageMutationHookResult = ReturnType<typeof useSetMainTaskImageMutation>;
export type SetMainTaskImageMutationResult = Apollo.MutationResult<SetMainTaskImageMutation>;
export type SetMainTaskImageMutationOptions = Apollo.BaseMutationOptions<
  SetMainTaskImageMutation,
  SetMainTaskImageMutationVariables
>;
export const SetProfileDocument = gql`
  mutation SetProfile($input: SetProfileInput!) {
    setProfile(input: $input) {
      ...CurrentUser
    }
  }
  ${CurrentUserFragmentDoc}
`;
export type SetProfileMutationFn = Apollo.MutationFunction<SetProfileMutation, SetProfileMutationVariables>;

/**
 * __useSetProfileMutation__
 *
 * To run a mutation, you first call `useSetProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setProfileMutation, { data, loading, error }] = useSetProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<SetProfileMutation, SetProfileMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetProfileMutation, SetProfileMutationVariables>(SetProfileDocument, options);
}
export type SetProfileMutationHookResult = ReturnType<typeof useSetProfileMutation>;
export type SetProfileMutationResult = Apollo.MutationResult<SetProfileMutation>;
export type SetProfileMutationOptions = Apollo.BaseMutationOptions<SetProfileMutation, SetProfileMutationVariables>;
export const SetUserPasswordDocument = gql`
  mutation SetUserPassword($input: SetPasswordInput!) {
    setUserPassword(input: $input)
  }
`;
export type SetUserPasswordMutationFn = Apollo.MutationFunction<
  SetUserPasswordMutation,
  SetUserPasswordMutationVariables
>;

/**
 * __useSetUserPasswordMutation__
 *
 * To run a mutation, you first call `useSetUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserPasswordMutation, { data, loading, error }] = useSetUserPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetUserPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<SetUserPasswordMutation, SetUserPasswordMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetUserPasswordMutation, SetUserPasswordMutationVariables>(
    SetUserPasswordDocument,
    options,
  );
}
export type SetUserPasswordMutationHookResult = ReturnType<typeof useSetUserPasswordMutation>;
export type SetUserPasswordMutationResult = Apollo.MutationResult<SetUserPasswordMutation>;
export type SetUserPasswordMutationOptions = Apollo.BaseMutationOptions<
  SetUserPasswordMutation,
  SetUserPasswordMutationVariables
>;
export const UnassignHomematesDocument = gql`
  mutation UnassignHomemates($input: UpdateHomematesInput!) {
    unassignHomemates(input: $input) {
      ...Home
    }
  }
  ${HomeFragmentDoc}
`;
export type UnassignHomematesMutationFn = Apollo.MutationFunction<
  UnassignHomematesMutation,
  UnassignHomematesMutationVariables
>;

/**
 * __useUnassignHomematesMutation__
 *
 * To run a mutation, you first call `useUnassignHomematesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignHomematesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignHomematesMutation, { data, loading, error }] = useUnassignHomematesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnassignHomematesMutation(
  baseOptions?: Apollo.MutationHookOptions<UnassignHomematesMutation, UnassignHomematesMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnassignHomematesMutation, UnassignHomematesMutationVariables>(
    UnassignHomematesDocument,
    options,
  );
}
export type UnassignHomematesMutationHookResult = ReturnType<typeof useUnassignHomematesMutation>;
export type UnassignHomematesMutationResult = Apollo.MutationResult<UnassignHomematesMutation>;
export type UnassignHomematesMutationOptions = Apollo.BaseMutationOptions<
  UnassignHomematesMutation,
  UnassignHomematesMutationVariables
>;
export const UpdateDoItRequestDraftDocument = gql`
  mutation UpdateDoItRequestDraft($input: UpdateDoItRequestDraftInput!) {
    updateDoItRequestDraft(input: $input) {
      ...DoItRequest
    }
  }
  ${DoItRequestFragmentDoc}
`;
export type UpdateDoItRequestDraftMutationFn = Apollo.MutationFunction<
  UpdateDoItRequestDraftMutation,
  UpdateDoItRequestDraftMutationVariables
>;

/**
 * __useUpdateDoItRequestDraftMutation__
 *
 * To run a mutation, you first call `useUpdateDoItRequestDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDoItRequestDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDoItRequestDraftMutation, { data, loading, error }] = useUpdateDoItRequestDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDoItRequestDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateDoItRequestDraftMutation, UpdateDoItRequestDraftMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateDoItRequestDraftMutation, UpdateDoItRequestDraftMutationVariables>(
    UpdateDoItRequestDraftDocument,
    options,
  );
}
export type UpdateDoItRequestDraftMutationHookResult = ReturnType<typeof useUpdateDoItRequestDraftMutation>;
export type UpdateDoItRequestDraftMutationResult = Apollo.MutationResult<UpdateDoItRequestDraftMutation>;
export type UpdateDoItRequestDraftMutationOptions = Apollo.BaseMutationOptions<
  UpdateDoItRequestDraftMutation,
  UpdateDoItRequestDraftMutationVariables
>;
export const UpdateDoitRequestDocument = gql`
  mutation UpdateDoitRequest($input: UpdateDoItRequestInput!) {
    updateDoitRequest(input: $input) {
      ...DoItRequest
    }
  }
  ${DoItRequestFragmentDoc}
`;
export type UpdateDoitRequestMutationFn = Apollo.MutationFunction<
  UpdateDoitRequestMutation,
  UpdateDoitRequestMutationVariables
>;

/**
 * __useUpdateDoitRequestMutation__
 *
 * To run a mutation, you first call `useUpdateDoitRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDoitRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDoitRequestMutation, { data, loading, error }] = useUpdateDoitRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDoitRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateDoitRequestMutation, UpdateDoitRequestMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateDoitRequestMutation, UpdateDoitRequestMutationVariables>(
    UpdateDoitRequestDocument,
    options,
  );
}
export type UpdateDoitRequestMutationHookResult = ReturnType<typeof useUpdateDoitRequestMutation>;
export type UpdateDoitRequestMutationResult = Apollo.MutationResult<UpdateDoitRequestMutation>;
export type UpdateDoitRequestMutationOptions = Apollo.BaseMutationOptions<
  UpdateDoitRequestMutation,
  UpdateDoitRequestMutationVariables
>;
export const UpdateHomeDocument = gql`
  mutation UpdateHome($input: UpdateHomeInput!) {
    updateHome(input: $input) {
      ...Home
    }
  }
  ${HomeFragmentDoc}
`;
export type UpdateHomeMutationFn = Apollo.MutationFunction<UpdateHomeMutation, UpdateHomeMutationVariables>;

/**
 * __useUpdateHomeMutation__
 *
 * To run a mutation, you first call `useUpdateHomeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHomeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHomeMutation, { data, loading, error }] = useUpdateHomeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateHomeMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateHomeMutation, UpdateHomeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateHomeMutation, UpdateHomeMutationVariables>(UpdateHomeDocument, options);
}
export type UpdateHomeMutationHookResult = ReturnType<typeof useUpdateHomeMutation>;
export type UpdateHomeMutationResult = Apollo.MutationResult<UpdateHomeMutation>;
export type UpdateHomeMutationOptions = Apollo.BaseMutationOptions<UpdateHomeMutation, UpdateHomeMutationVariables>;
export const UpdateItemDocument = gql`
  mutation UpdateItem($input: UpdateItemInput!) {
    updateItem(input: $input) {
      ...Item
    }
  }
  ${ItemFragmentDoc}
`;
export type UpdateItemMutationFn = Apollo.MutationFunction<UpdateItemMutation, UpdateItemMutationVariables>;

/**
 * __useUpdateItemMutation__
 *
 * To run a mutation, you first call `useUpdateItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateItemMutation, { data, loading, error }] = useUpdateItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateItemMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateItemMutation, UpdateItemMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateItemMutation, UpdateItemMutationVariables>(UpdateItemDocument, options);
}
export type UpdateItemMutationHookResult = ReturnType<typeof useUpdateItemMutation>;
export type UpdateItemMutationResult = Apollo.MutationResult<UpdateItemMutation>;
export type UpdateItemMutationOptions = Apollo.BaseMutationOptions<UpdateItemMutation, UpdateItemMutationVariables>;
export const UpdateTaskDocument = gql`
  mutation UpdateTask($input: UpdateTaskInput!) {
    updateTask(input: $input) {
      ...Task
    }
  }
  ${TaskFragmentDoc}
`;
export type UpdateTaskMutationFn = Apollo.MutationFunction<UpdateTaskMutation, UpdateTaskMutationVariables>;

/**
 * __useUpdateTaskMutation__
 *
 * To run a mutation, you first call `useUpdateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskMutation, { data, loading, error }] = useUpdateTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTaskMutation, UpdateTaskMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTaskMutation, UpdateTaskMutationVariables>(UpdateTaskDocument, options);
}
export type UpdateTaskMutationHookResult = ReturnType<typeof useUpdateTaskMutation>;
export type UpdateTaskMutationResult = Apollo.MutationResult<UpdateTaskMutation>;
export type UpdateTaskMutationOptions = Apollo.BaseMutationOptions<UpdateTaskMutation, UpdateTaskMutationVariables>;
export const UpdateZoneDocument = gql`
  mutation UpdateZone($input: UpdateZoneInput!) {
    updateZone(input: $input) {
      id
      name
      homeId
      template {
        ...ZoneTemplate
      }
    }
  }
  ${ZoneTemplateFragmentDoc}
`;
export type UpdateZoneMutationFn = Apollo.MutationFunction<UpdateZoneMutation, UpdateZoneMutationVariables>;

/**
 * __useUpdateZoneMutation__
 *
 * To run a mutation, you first call `useUpdateZoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateZoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateZoneMutation, { data, loading, error }] = useUpdateZoneMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateZoneMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateZoneMutation, UpdateZoneMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateZoneMutation, UpdateZoneMutationVariables>(UpdateZoneDocument, options);
}
export type UpdateZoneMutationHookResult = ReturnType<typeof useUpdateZoneMutation>;
export type UpdateZoneMutationResult = Apollo.MutationResult<UpdateZoneMutation>;
export type UpdateZoneMutationOptions = Apollo.BaseMutationOptions<UpdateZoneMutation, UpdateZoneMutationVariables>;
export const UpdateZonesDocument = gql`
  mutation UpdateZones($input: UpdateZonesInput!) {
    updateZones(input: $input) {
      id
      name
      homeId
      template {
        ...ZoneTemplate
      }
    }
  }
  ${ZoneTemplateFragmentDoc}
`;
export type UpdateZonesMutationFn = Apollo.MutationFunction<UpdateZonesMutation, UpdateZonesMutationVariables>;

/**
 * __useUpdateZonesMutation__
 *
 * To run a mutation, you first call `useUpdateZonesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateZonesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateZonesMutation, { data, loading, error }] = useUpdateZonesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateZonesMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateZonesMutation, UpdateZonesMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateZonesMutation, UpdateZonesMutationVariables>(UpdateZonesDocument, options);
}
export type UpdateZonesMutationHookResult = ReturnType<typeof useUpdateZonesMutation>;
export type UpdateZonesMutationResult = Apollo.MutationResult<UpdateZonesMutation>;
export type UpdateZonesMutationOptions = Apollo.BaseMutationOptions<UpdateZonesMutation, UpdateZonesMutationVariables>;
export const VerifyUserEmailDocument = gql`
  mutation VerifyUserEmail($code: String!) {
    verifyUserEmail(code: $code)
  }
`;
export type VerifyUserEmailMutationFn = Apollo.MutationFunction<
  VerifyUserEmailMutation,
  VerifyUserEmailMutationVariables
>;

/**
 * __useVerifyUserEmailMutation__
 *
 * To run a mutation, you first call `useVerifyUserEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyUserEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyUserEmailMutation, { data, loading, error }] = useVerifyUserEmailMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useVerifyUserEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<VerifyUserEmailMutation, VerifyUserEmailMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<VerifyUserEmailMutation, VerifyUserEmailMutationVariables>(
    VerifyUserEmailDocument,
    options,
  );
}
export type VerifyUserEmailMutationHookResult = ReturnType<typeof useVerifyUserEmailMutation>;
export type VerifyUserEmailMutationResult = Apollo.MutationResult<VerifyUserEmailMutation>;
export type VerifyUserEmailMutationOptions = Apollo.BaseMutationOptions<
  VerifyUserEmailMutation,
  VerifyUserEmailMutationVariables
>;
export const DeleteHomeImageDocument = gql`
  mutation DeleteHomeImage($input: DeleteFileInput!) {
    deleteHomeImage(input: $input)
  }
`;
export type DeleteHomeImageMutationFn = Apollo.MutationFunction<
  DeleteHomeImageMutation,
  DeleteHomeImageMutationVariables
>;

/**
 * __useDeleteHomeImageMutation__
 *
 * To run a mutation, you first call `useDeleteHomeImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteHomeImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteHomeImageMutation, { data, loading, error }] = useDeleteHomeImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteHomeImageMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteHomeImageMutation, DeleteHomeImageMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteHomeImageMutation, DeleteHomeImageMutationVariables>(
    DeleteHomeImageDocument,
    options,
  );
}
export type DeleteHomeImageMutationHookResult = ReturnType<typeof useDeleteHomeImageMutation>;
export type DeleteHomeImageMutationResult = Apollo.MutationResult<DeleteHomeImageMutation>;
export type DeleteHomeImageMutationOptions = Apollo.BaseMutationOptions<
  DeleteHomeImageMutation,
  DeleteHomeImageMutationVariables
>;
export const DoitRequestDocument = gql`
  query DoitRequest($doitRequestId: ID!) {
    doitRequest(doitRequestId: $doitRequestId) {
      ...DoItRequest
    }
  }
  ${DoItRequestFragmentDoc}
`;

/**
 * __useDoitRequestQuery__
 *
 * To run a query within a React component, call `useDoitRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useDoitRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDoitRequestQuery({
 *   variables: {
 *      doitRequestId: // value for 'doitRequestId'
 *   },
 * });
 */
export function useDoitRequestQuery(baseOptions: Apollo.QueryHookOptions<DoitRequestQuery, DoitRequestQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DoitRequestQuery, DoitRequestQueryVariables>(DoitRequestDocument, options);
}
export function useDoitRequestLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DoitRequestQuery, DoitRequestQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DoitRequestQuery, DoitRequestQueryVariables>(DoitRequestDocument, options);
}
export type DoitRequestQueryHookResult = ReturnType<typeof useDoitRequestQuery>;
export type DoitRequestLazyQueryHookResult = ReturnType<typeof useDoitRequestLazyQuery>;
export type DoitRequestQueryResult = Apollo.QueryResult<DoitRequestQuery, DoitRequestQueryVariables>;
export const DoitRequestDraftDocument = gql`
  query DoitRequestDraft($homeId: ID!) {
    doitRequestDraft(homeId: $homeId) {
      ...DoItRequest
    }
  }
  ${DoItRequestFragmentDoc}
`;

/**
 * __useDoitRequestDraftQuery__
 *
 * To run a query within a React component, call `useDoitRequestDraftQuery` and pass it any options that fit your needs.
 * When your component renders, `useDoitRequestDraftQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDoitRequestDraftQuery({
 *   variables: {
 *      homeId: // value for 'homeId'
 *   },
 * });
 */
export function useDoitRequestDraftQuery(
  baseOptions: Apollo.QueryHookOptions<DoitRequestDraftQuery, DoitRequestDraftQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DoitRequestDraftQuery, DoitRequestDraftQueryVariables>(DoitRequestDraftDocument, options);
}
export function useDoitRequestDraftLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DoitRequestDraftQuery, DoitRequestDraftQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DoitRequestDraftQuery, DoitRequestDraftQueryVariables>(DoitRequestDraftDocument, options);
}
export type DoitRequestDraftQueryHookResult = ReturnType<typeof useDoitRequestDraftQuery>;
export type DoitRequestDraftLazyQueryHookResult = ReturnType<typeof useDoitRequestDraftLazyQuery>;
export type DoitRequestDraftQueryResult = Apollo.QueryResult<DoitRequestDraftQuery, DoitRequestDraftQueryVariables>;
export const DoitRequestImagesDocument = gql`
  query DoitRequestImages($doitRequestId: ID!) {
    doitRequestImages(doitRequestId: $doitRequestId) {
      ...CoverImage
    }
  }
  ${CoverImageFragmentDoc}
`;

/**
 * __useDoitRequestImagesQuery__
 *
 * To run a query within a React component, call `useDoitRequestImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDoitRequestImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDoitRequestImagesQuery({
 *   variables: {
 *      doitRequestId: // value for 'doitRequestId'
 *   },
 * });
 */
export function useDoitRequestImagesQuery(
  baseOptions: Apollo.QueryHookOptions<DoitRequestImagesQuery, DoitRequestImagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DoitRequestImagesQuery, DoitRequestImagesQueryVariables>(DoitRequestImagesDocument, options);
}
export function useDoitRequestImagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DoitRequestImagesQuery, DoitRequestImagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DoitRequestImagesQuery, DoitRequestImagesQueryVariables>(
    DoitRequestImagesDocument,
    options,
  );
}
export type DoitRequestImagesQueryHookResult = ReturnType<typeof useDoitRequestImagesQuery>;
export type DoitRequestImagesLazyQueryHookResult = ReturnType<typeof useDoitRequestImagesLazyQuery>;
export type DoitRequestImagesQueryResult = Apollo.QueryResult<DoitRequestImagesQuery, DoitRequestImagesQueryVariables>;
export const DoitRequestsDocument = gql`
  query DoitRequests($filter: DoItFilter!, $offset: Int! = 0, $limit: Int! = 20) {
    doitRequests(filter: $filter, offset: $offset, limit: $limit) {
      ...DoItPaginationResponse
    }
  }
  ${DoItPaginationResponseFragmentDoc}
`;

/**
 * __useDoitRequestsQuery__
 *
 * To run a query within a React component, call `useDoitRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDoitRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDoitRequestsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useDoitRequestsQuery(
  baseOptions: Apollo.QueryHookOptions<DoitRequestsQuery, DoitRequestsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DoitRequestsQuery, DoitRequestsQueryVariables>(DoitRequestsDocument, options);
}
export function useDoitRequestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DoitRequestsQuery, DoitRequestsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DoitRequestsQuery, DoitRequestsQueryVariables>(DoitRequestsDocument, options);
}
export type DoitRequestsQueryHookResult = ReturnType<typeof useDoitRequestsQuery>;
export type DoitRequestsLazyQueryHookResult = ReturnType<typeof useDoitRequestsLazyQuery>;
export type DoitRequestsQueryResult = Apollo.QueryResult<DoitRequestsQuery, DoitRequestsQueryVariables>;
export const DoitRequestsByHomeIdDocument = gql`
  query DoitRequestsByHomeId($filter: DoItFilterByHome!, $offset: Int! = 0, $limit: Int! = 20) {
    doitRequestsByHomeId(filter: $filter, offset: $offset, limit: $limit) {
      ...DoItPaginationResponse
    }
  }
  ${DoItPaginationResponseFragmentDoc}
`;

/**
 * __useDoitRequestsByHomeIdQuery__
 *
 * To run a query within a React component, call `useDoitRequestsByHomeIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useDoitRequestsByHomeIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDoitRequestsByHomeIdQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useDoitRequestsByHomeIdQuery(
  baseOptions: Apollo.QueryHookOptions<DoitRequestsByHomeIdQuery, DoitRequestsByHomeIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DoitRequestsByHomeIdQuery, DoitRequestsByHomeIdQueryVariables>(
    DoitRequestsByHomeIdDocument,
    options,
  );
}
export function useDoitRequestsByHomeIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DoitRequestsByHomeIdQuery, DoitRequestsByHomeIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DoitRequestsByHomeIdQuery, DoitRequestsByHomeIdQueryVariables>(
    DoitRequestsByHomeIdDocument,
    options,
  );
}
export type DoitRequestsByHomeIdQueryHookResult = ReturnType<typeof useDoitRequestsByHomeIdQuery>;
export type DoitRequestsByHomeIdLazyQueryHookResult = ReturnType<typeof useDoitRequestsByHomeIdLazyQuery>;
export type DoitRequestsByHomeIdQueryResult = Apollo.QueryResult<
  DoitRequestsByHomeIdQuery,
  DoitRequestsByHomeIdQueryVariables
>;
export const HomeDocument = gql`
  query Home($homeId: ID!) {
    home(homeId: $homeId) {
      ...Home
    }
  }
  ${HomeFragmentDoc}
`;

/**
 * __useHomeQuery__
 *
 * To run a query within a React component, call `useHomeQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeQuery({
 *   variables: {
 *      homeId: // value for 'homeId'
 *   },
 * });
 */
export function useHomeQuery(baseOptions: Apollo.QueryHookOptions<HomeQuery, HomeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HomeQuery, HomeQueryVariables>(HomeDocument, options);
}
export function useHomeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomeQuery, HomeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HomeQuery, HomeQueryVariables>(HomeDocument, options);
}
export type HomeQueryHookResult = ReturnType<typeof useHomeQuery>;
export type HomeLazyQueryHookResult = ReturnType<typeof useHomeLazyQuery>;
export type HomeQueryResult = Apollo.QueryResult<HomeQuery, HomeQueryVariables>;
export const HomeStatsDocument = gql`
  query HomeStats($filter: HomeStatsFilterInput!) {
    homeStats(filter: $filter) {
      activeTasks
      dwelinkTasks
      finishedTasks
      zones
      items
      score {
        total
        totalByZone {
          id
          name
          score
        }
      }
    }
  }
`;

/**
 * __useHomeStatsQuery__
 *
 * To run a query within a React component, call `useHomeStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeStatsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useHomeStatsQuery(baseOptions: Apollo.QueryHookOptions<HomeStatsQuery, HomeStatsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HomeStatsQuery, HomeStatsQueryVariables>(HomeStatsDocument, options);
}
export function useHomeStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<HomeStatsQuery, HomeStatsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HomeStatsQuery, HomeStatsQueryVariables>(HomeStatsDocument, options);
}
export type HomeStatsQueryHookResult = ReturnType<typeof useHomeStatsQuery>;
export type HomeStatsLazyQueryHookResult = ReturnType<typeof useHomeStatsLazyQuery>;
export type HomeStatsQueryResult = Apollo.QueryResult<HomeStatsQuery, HomeStatsQueryVariables>;
export const HomeWithStatsDocument = gql`
  query HomeWithStats($homeId: ID!) {
    homeStats(filter: { homeId: $homeId }) {
      activeTasks
      dwelinkTasks
      finishedTasks
      zones
      items
      score {
        total
        totalByZone {
          id
          name
          score
        }
      }
    }
    home(homeId: $homeId) {
      ...Home
      homemates {
        id
        role
        profile {
          firstName
          lastName
        }
        email
        isVerified
      }
    }
  }
  ${HomeFragmentDoc}
`;

/**
 * __useHomeWithStatsQuery__
 *
 * To run a query within a React component, call `useHomeWithStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeWithStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeWithStatsQuery({
 *   variables: {
 *      homeId: // value for 'homeId'
 *   },
 * });
 */
export function useHomeWithStatsQuery(
  baseOptions: Apollo.QueryHookOptions<HomeWithStatsQuery, HomeWithStatsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HomeWithStatsQuery, HomeWithStatsQueryVariables>(HomeWithStatsDocument, options);
}
export function useHomeWithStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<HomeWithStatsQuery, HomeWithStatsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HomeWithStatsQuery, HomeWithStatsQueryVariables>(HomeWithStatsDocument, options);
}
export type HomeWithStatsQueryHookResult = ReturnType<typeof useHomeWithStatsQuery>;
export type HomeWithStatsLazyQueryHookResult = ReturnType<typeof useHomeWithStatsLazyQuery>;
export type HomeWithStatsQueryResult = Apollo.QueryResult<HomeWithStatsQuery, HomeWithStatsQueryVariables>;
export const HomesDocument = gql`
  query Homes($offset: Int, $limit: Int) {
    homes(offset: $offset, limit: $limit) {
      total
      hasMore
      homes {
        id
        zoneCount(filters: [{ isArchived: false }])
        taskCount(
          filters: [{ isArchived: false, status: ACTIVE }, { isArchived: false, jobPerformer: DWELINK, status: ACTIVE }]
        )
        location {
          street1
          city
          state
          googlePlaceId
          lat
          lng
          country
          street2
          zipCode
        }
        owner {
          id
          profile {
            firstName
            lastName
            phone
          }
          email
          isVerified
        }
        homemates {
          id
          profile {
            firstName
            lastName
          }
          email
          isVerified
        }
      }
    }
  }
`;

/**
 * __useHomesQuery__
 *
 * To run a query within a React component, call `useHomesQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomesQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useHomesQuery(baseOptions?: Apollo.QueryHookOptions<HomesQuery, HomesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HomesQuery, HomesQueryVariables>(HomesDocument, options);
}
export function useHomesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomesQuery, HomesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HomesQuery, HomesQueryVariables>(HomesDocument, options);
}
export type HomesQueryHookResult = ReturnType<typeof useHomesQuery>;
export type HomesLazyQueryHookResult = ReturnType<typeof useHomesLazyQuery>;
export type HomesQueryResult = Apollo.QueryResult<HomesQuery, HomesQueryVariables>;
export const HomesByUserIdDocument = gql`
  query HomesByUserId($filter: HomeFilterByUser!, $offset: Int! = 0, $limit: Int! = 10) {
    homesByUserId(filter: $filter, offset: $offset, limit: $limit) {
      total
      hasMore
      homes {
        ...Home
      }
    }
  }
  ${HomeFragmentDoc}
`;

/**
 * __useHomesByUserIdQuery__
 *
 * To run a query within a React component, call `useHomesByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomesByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomesByUserIdQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useHomesByUserIdQuery(
  baseOptions: Apollo.QueryHookOptions<HomesByUserIdQuery, HomesByUserIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HomesByUserIdQuery, HomesByUserIdQueryVariables>(HomesByUserIdDocument, options);
}
export function useHomesByUserIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<HomesByUserIdQuery, HomesByUserIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HomesByUserIdQuery, HomesByUserIdQueryVariables>(HomesByUserIdDocument, options);
}
export type HomesByUserIdQueryHookResult = ReturnType<typeof useHomesByUserIdQuery>;
export type HomesByUserIdLazyQueryHookResult = ReturnType<typeof useHomesByUserIdLazyQuery>;
export type HomesByUserIdQueryResult = Apollo.QueryResult<HomesByUserIdQuery, HomesByUserIdQueryVariables>;
export const InvitationDocument = gql`
  query Invitation($filter: InvitationFilterInput!, $offset: Int, $limit: Int) {
    invitations(filter: $filter, offset: $offset, limit: $limit) {
      invitations {
        id
        email
        profile {
          firstName
          lastName
        }
        role
        expiresAt
        inviter {
          id
          email
          profile {
            firstName
            lastName
          }
          isVerified
        }
      }
      total
      hasMore
    }
  }
`;

/**
 * __useInvitationQuery__
 *
 * To run a query within a React component, call `useInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvitationQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useInvitationQuery(baseOptions: Apollo.QueryHookOptions<InvitationQuery, InvitationQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InvitationQuery, InvitationQueryVariables>(InvitationDocument, options);
}
export function useInvitationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InvitationQuery, InvitationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InvitationQuery, InvitationQueryVariables>(InvitationDocument, options);
}
export type InvitationQueryHookResult = ReturnType<typeof useInvitationQuery>;
export type InvitationLazyQueryHookResult = ReturnType<typeof useInvitationLazyQuery>;
export type InvitationQueryResult = Apollo.QueryResult<InvitationQuery, InvitationQueryVariables>;
export const ItemDocument = gql`
  query Item($itemId: ID!) {
    item(itemId: $itemId) {
      ...Item
    }
  }
  ${ItemFragmentDoc}
`;

/**
 * __useItemQuery__
 *
 * To run a query within a React component, call `useItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemQuery({
 *   variables: {
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useItemQuery(baseOptions: Apollo.QueryHookOptions<ItemQuery, ItemQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ItemQuery, ItemQueryVariables>(ItemDocument, options);
}
export function useItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ItemQuery, ItemQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ItemQuery, ItemQueryVariables>(ItemDocument, options);
}
export type ItemQueryHookResult = ReturnType<typeof useItemQuery>;
export type ItemLazyQueryHookResult = ReturnType<typeof useItemLazyQuery>;
export type ItemQueryResult = Apollo.QueryResult<ItemQuery, ItemQueryVariables>;
export const ItemImagesDocument = gql`
  query ItemImages($itemId: ID!) {
    itemImages(itemId: $itemId) {
      id
      filename
      mimetype
      blurHash
      url
      isMain
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useItemImagesQuery__
 *
 * To run a query within a React component, call `useItemImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemImagesQuery({
 *   variables: {
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useItemImagesQuery(baseOptions: Apollo.QueryHookOptions<ItemImagesQuery, ItemImagesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ItemImagesQuery, ItemImagesQueryVariables>(ItemImagesDocument, options);
}
export function useItemImagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ItemImagesQuery, ItemImagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ItemImagesQuery, ItemImagesQueryVariables>(ItemImagesDocument, options);
}
export type ItemImagesQueryHookResult = ReturnType<typeof useItemImagesQuery>;
export type ItemImagesLazyQueryHookResult = ReturnType<typeof useItemImagesLazyQuery>;
export type ItemImagesQueryResult = Apollo.QueryResult<ItemImagesQuery, ItemImagesQueryVariables>;
export const ItemTemplatesDocument = gql`
  query ItemTemplates($zoneTemplateId: ID!) {
    itemTemplates(zoneTemplateId: $zoneTemplateId) {
      id
      name
      maxQuantity
      iconUrl
    }
  }
`;

/**
 * __useItemTemplatesQuery__
 *
 * To run a query within a React component, call `useItemTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemTemplatesQuery({
 *   variables: {
 *      zoneTemplateId: // value for 'zoneTemplateId'
 *   },
 * });
 */
export function useItemTemplatesQuery(
  baseOptions: Apollo.QueryHookOptions<ItemTemplatesQuery, ItemTemplatesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ItemTemplatesQuery, ItemTemplatesQueryVariables>(ItemTemplatesDocument, options);
}
export function useItemTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ItemTemplatesQuery, ItemTemplatesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ItemTemplatesQuery, ItemTemplatesQueryVariables>(ItemTemplatesDocument, options);
}
export type ItemTemplatesQueryHookResult = ReturnType<typeof useItemTemplatesQuery>;
export type ItemTemplatesLazyQueryHookResult = ReturnType<typeof useItemTemplatesLazyQuery>;
export type ItemTemplatesQueryResult = Apollo.QueryResult<ItemTemplatesQuery, ItemTemplatesQueryVariables>;
export const ItemsByHomeIdDocument = gql`
  query ItemsByHomeId($filter: ItemFilterByHome!, $offset: Int! = 0, $limit: Int! = 10) {
    itemsByHomeId(filter: $filter, offset: $offset, limit: $limit) {
      total
      hasMore
      items {
        ...Item
      }
    }
  }
  ${ItemFragmentDoc}
`;

/**
 * __useItemsByHomeIdQuery__
 *
 * To run a query within a React component, call `useItemsByHomeIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemsByHomeIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemsByHomeIdQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useItemsByHomeIdQuery(
  baseOptions: Apollo.QueryHookOptions<ItemsByHomeIdQuery, ItemsByHomeIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ItemsByHomeIdQuery, ItemsByHomeIdQueryVariables>(ItemsByHomeIdDocument, options);
}
export function useItemsByHomeIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ItemsByHomeIdQuery, ItemsByHomeIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ItemsByHomeIdQuery, ItemsByHomeIdQueryVariables>(ItemsByHomeIdDocument, options);
}
export type ItemsByHomeIdQueryHookResult = ReturnType<typeof useItemsByHomeIdQuery>;
export type ItemsByHomeIdLazyQueryHookResult = ReturnType<typeof useItemsByHomeIdLazyQuery>;
export type ItemsByHomeIdQueryResult = Apollo.QueryResult<ItemsByHomeIdQuery, ItemsByHomeIdQueryVariables>;
export const JobberStatusDocument = gql`
  query JobberStatus {
    jobberStatus
  }
`;

/**
 * __useJobberStatusQuery__
 *
 * To run a query within a React component, call `useJobberStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobberStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobberStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useJobberStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<JobberStatusQuery, JobberStatusQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<JobberStatusQuery, JobberStatusQueryVariables>(JobberStatusDocument, options);
}
export function useJobberStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<JobberStatusQuery, JobberStatusQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<JobberStatusQuery, JobberStatusQueryVariables>(JobberStatusDocument, options);
}
export type JobberStatusQueryHookResult = ReturnType<typeof useJobberStatusQuery>;
export type JobberStatusLazyQueryHookResult = ReturnType<typeof useJobberStatusLazyQuery>;
export type JobberStatusQueryResult = Apollo.QueryResult<JobberStatusQuery, JobberStatusQueryVariables>;
export const LoginWithPasswordAbilityDocument = gql`
  query LoginWithPasswordAbility($email: String!) {
    loginWithPasswordAbility(email: $email)
  }
`;

/**
 * __useLoginWithPasswordAbilityQuery__
 *
 * To run a query within a React component, call `useLoginWithPasswordAbilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoginWithPasswordAbilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoginWithPasswordAbilityQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useLoginWithPasswordAbilityQuery(
  baseOptions: Apollo.QueryHookOptions<LoginWithPasswordAbilityQuery, LoginWithPasswordAbilityQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoginWithPasswordAbilityQuery, LoginWithPasswordAbilityQueryVariables>(
    LoginWithPasswordAbilityDocument,
    options,
  );
}
export function useLoginWithPasswordAbilityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LoginWithPasswordAbilityQuery, LoginWithPasswordAbilityQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LoginWithPasswordAbilityQuery, LoginWithPasswordAbilityQueryVariables>(
    LoginWithPasswordAbilityDocument,
    options,
  );
}
export type LoginWithPasswordAbilityQueryHookResult = ReturnType<typeof useLoginWithPasswordAbilityQuery>;
export type LoginWithPasswordAbilityLazyQueryHookResult = ReturnType<typeof useLoginWithPasswordAbilityLazyQuery>;
export type LoginWithPasswordAbilityQueryResult = Apollo.QueryResult<
  LoginWithPasswordAbilityQuery,
  LoginWithPasswordAbilityQueryVariables
>;
export const MeDocument = gql`
  query Me {
    me {
      ...CurrentUser
    }
  }
  ${CurrentUserFragmentDoc}
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const TaskDocument = gql`
  query Task($taskId: ID!) {
    task(taskId: $taskId) {
      ...Task
    }
  }
  ${TaskFragmentDoc}
`;

/**
 * __useTaskQuery__
 *
 * To run a query within a React component, call `useTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useTaskQuery(baseOptions: Apollo.QueryHookOptions<TaskQuery, TaskQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TaskQuery, TaskQueryVariables>(TaskDocument, options);
}
export function useTaskLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskQuery, TaskQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TaskQuery, TaskQueryVariables>(TaskDocument, options);
}
export type TaskQueryHookResult = ReturnType<typeof useTaskQuery>;
export type TaskLazyQueryHookResult = ReturnType<typeof useTaskLazyQuery>;
export type TaskQueryResult = Apollo.QueryResult<TaskQuery, TaskQueryVariables>;
export const TasksByHomeIdDocument = gql`
  query TasksByHomeId($filter: TaskFilterByHome!, $offset: Int! = 0, $limit: Int! = 20) {
    tasksByHomeId(filter: $filter, offset: $offset, limit: $limit) {
      ...TaskPaginationResponse
    }
  }
  ${TaskPaginationResponseFragmentDoc}
`;

/**
 * __useTasksByHomeIdQuery__
 *
 * To run a query within a React component, call `useTasksByHomeIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useTasksByHomeIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTasksByHomeIdQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useTasksByHomeIdQuery(
  baseOptions: Apollo.QueryHookOptions<TasksByHomeIdQuery, TasksByHomeIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TasksByHomeIdQuery, TasksByHomeIdQueryVariables>(TasksByHomeIdDocument, options);
}
export function useTasksByHomeIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TasksByHomeIdQuery, TasksByHomeIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TasksByHomeIdQuery, TasksByHomeIdQueryVariables>(TasksByHomeIdDocument, options);
}
export type TasksByHomeIdQueryHookResult = ReturnType<typeof useTasksByHomeIdQuery>;
export type TasksByHomeIdLazyQueryHookResult = ReturnType<typeof useTasksByHomeIdLazyQuery>;
export type TasksByHomeIdQueryResult = Apollo.QueryResult<TasksByHomeIdQuery, TasksByHomeIdQueryVariables>;
export const TaskImagesDocument = gql`
  query TaskImages($taskId: ID!) {
    taskImages(taskId: $taskId) {
      id
      filename
      mimetype
      blurHash
      createdAt
      updatedAt
      url
      isMain
    }
  }
`;

/**
 * __useTaskImagesQuery__
 *
 * To run a query within a React component, call `useTaskImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskImagesQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useTaskImagesQuery(baseOptions: Apollo.QueryHookOptions<TaskImagesQuery, TaskImagesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TaskImagesQuery, TaskImagesQueryVariables>(TaskImagesDocument, options);
}
export function useTaskImagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TaskImagesQuery, TaskImagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TaskImagesQuery, TaskImagesQueryVariables>(TaskImagesDocument, options);
}
export type TaskImagesQueryHookResult = ReturnType<typeof useTaskImagesQuery>;
export type TaskImagesLazyQueryHookResult = ReturnType<typeof useTaskImagesLazyQuery>;
export type TaskImagesQueryResult = Apollo.QueryResult<TaskImagesQuery, TaskImagesQueryVariables>;
export const TasksDocument = gql`
  query Tasks($filter: TaskFilter!, $offset: Int!, $limit: Int!) {
    tasks(filter: $filter, offset: $offset, limit: $limit) {
      ...TaskPaginationResponse
    }
  }
  ${TaskPaginationResponseFragmentDoc}
`;

/**
 * __useTasksQuery__
 *
 * To run a query within a React component, call `useTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTasksQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useTasksQuery(baseOptions: Apollo.QueryHookOptions<TasksQuery, TasksQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TasksQuery, TasksQueryVariables>(TasksDocument, options);
}
export function useTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TasksQuery, TasksQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TasksQuery, TasksQueryVariables>(TasksDocument, options);
}
export type TasksQueryHookResult = ReturnType<typeof useTasksQuery>;
export type TasksLazyQueryHookResult = ReturnType<typeof useTasksLazyQuery>;
export type TasksQueryResult = Apollo.QueryResult<TasksQuery, TasksQueryVariables>;
export const TasksByItemIdDocument = gql`
  query TasksByItemId($filter: TaskFilterByItem!, $offset: Int! = 0, $limit: Int! = 20) {
    tasksByItemId(filter: $filter, offset: $offset, limit: $limit) {
      ...TaskPaginationResponse
    }
  }
  ${TaskPaginationResponseFragmentDoc}
`;

/**
 * __useTasksByItemIdQuery__
 *
 * To run a query within a React component, call `useTasksByItemIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useTasksByItemIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTasksByItemIdQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useTasksByItemIdQuery(
  baseOptions: Apollo.QueryHookOptions<TasksByItemIdQuery, TasksByItemIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TasksByItemIdQuery, TasksByItemIdQueryVariables>(TasksByItemIdDocument, options);
}
export function useTasksByItemIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TasksByItemIdQuery, TasksByItemIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TasksByItemIdQuery, TasksByItemIdQueryVariables>(TasksByItemIdDocument, options);
}
export type TasksByItemIdQueryHookResult = ReturnType<typeof useTasksByItemIdQuery>;
export type TasksByItemIdLazyQueryHookResult = ReturnType<typeof useTasksByItemIdLazyQuery>;
export type TasksByItemIdQueryResult = Apollo.QueryResult<TasksByItemIdQuery, TasksByItemIdQueryVariables>;
export const TasksByZoneIdDocument = gql`
  query TasksByZoneId($filter: TaskFilterByZone!, $offset: Int! = 0, $limit: Int! = 20) {
    tasksByZoneId(filter: $filter, offset: $offset, limit: $limit) {
      ...TaskPaginationResponse
    }
  }
  ${TaskPaginationResponseFragmentDoc}
`;

/**
 * __useTasksByZoneIdQuery__
 *
 * To run a query within a React component, call `useTasksByZoneIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useTasksByZoneIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTasksByZoneIdQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useTasksByZoneIdQuery(
  baseOptions: Apollo.QueryHookOptions<TasksByZoneIdQuery, TasksByZoneIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TasksByZoneIdQuery, TasksByZoneIdQueryVariables>(TasksByZoneIdDocument, options);
}
export function useTasksByZoneIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TasksByZoneIdQuery, TasksByZoneIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TasksByZoneIdQuery, TasksByZoneIdQueryVariables>(TasksByZoneIdDocument, options);
}
export type TasksByZoneIdQueryHookResult = ReturnType<typeof useTasksByZoneIdQuery>;
export type TasksByZoneIdLazyQueryHookResult = ReturnType<typeof useTasksByZoneIdLazyQuery>;
export type TasksByZoneIdQueryResult = Apollo.QueryResult<TasksByZoneIdQuery, TasksByZoneIdQueryVariables>;
export const UserDocument = gql`
  query User($userId: ID!) {
    user(userId: $userId) {
      ...CurrentUser
    }
  }
  ${CurrentUserFragmentDoc}
`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
}
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
}
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const UserRoleDocument = gql`
  query UserRole($userId: ID!) {
    user(userId: $userId) {
      id
      role
    }
  }
`;

/**
 * __useUserRoleQuery__
 *
 * To run a query within a React component, call `useUserRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserRoleQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserRoleQuery(baseOptions: Apollo.QueryHookOptions<UserRoleQuery, UserRoleQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserRoleQuery, UserRoleQueryVariables>(UserRoleDocument, options);
}
export function useUserRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserRoleQuery, UserRoleQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserRoleQuery, UserRoleQueryVariables>(UserRoleDocument, options);
}
export type UserRoleQueryHookResult = ReturnType<typeof useUserRoleQuery>;
export type UserRoleLazyQueryHookResult = ReturnType<typeof useUserRoleLazyQuery>;
export type UserRoleQueryResult = Apollo.QueryResult<UserRoleQuery, UserRoleQueryVariables>;
export const UsersDocument = gql`
  query Users(
    $filter: UserFilterInput!
    $offset: Int = 0
    $limit: Int = 3
    $membershipRole: EMembershipRole
    $sort: EUserSort
  ) {
    users(filter: $filter, limit: $limit, offset: $offset, sort: $sort) {
      hasMore
      total
      users {
        id
        email
        numberOfHomes(role: $membershipRole)
        authServices
        profile {
          firstName
          lastName
          phone
        }
        createdAt
        isVerified
        role
      }
    }
  }
`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      membershipRole: // value for 'membershipRole'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useUsersQuery(baseOptions: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
}
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
}
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const ZoneDocument = gql`
  query Zone($zoneId: ID!, $search: String = "", $isArchivedItem: Boolean = false) {
    zone(zoneId: $zoneId) {
      ...Zone
      items(search: $search, isArchived: $isArchivedItem) {
        total
        items {
          ...Item
        }
      }
    }
  }
  ${ZoneFragmentDoc}
  ${ItemFragmentDoc}
`;

/**
 * __useZoneQuery__
 *
 * To run a query within a React component, call `useZoneQuery` and pass it any options that fit your needs.
 * When your component renders, `useZoneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useZoneQuery({
 *   variables: {
 *      zoneId: // value for 'zoneId'
 *      search: // value for 'search'
 *      isArchivedItem: // value for 'isArchivedItem'
 *   },
 * });
 */
export function useZoneQuery(baseOptions: Apollo.QueryHookOptions<ZoneQuery, ZoneQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ZoneQuery, ZoneQueryVariables>(ZoneDocument, options);
}
export function useZoneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ZoneQuery, ZoneQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ZoneQuery, ZoneQueryVariables>(ZoneDocument, options);
}
export type ZoneQueryHookResult = ReturnType<typeof useZoneQuery>;
export type ZoneLazyQueryHookResult = ReturnType<typeof useZoneLazyQuery>;
export type ZoneQueryResult = Apollo.QueryResult<ZoneQuery, ZoneQueryVariables>;
export const ZoneTemplatesDocument = gql`
  query ZoneTemplates {
    zoneTemplates {
      ...ZoneTemplate
    }
  }
  ${ZoneTemplateFragmentDoc}
`;

/**
 * __useZoneTemplatesQuery__
 *
 * To run a query within a React component, call `useZoneTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useZoneTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useZoneTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useZoneTemplatesQuery(
  baseOptions?: Apollo.QueryHookOptions<ZoneTemplatesQuery, ZoneTemplatesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ZoneTemplatesQuery, ZoneTemplatesQueryVariables>(ZoneTemplatesDocument, options);
}
export function useZoneTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ZoneTemplatesQuery, ZoneTemplatesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ZoneTemplatesQuery, ZoneTemplatesQueryVariables>(ZoneTemplatesDocument, options);
}
export type ZoneTemplatesQueryHookResult = ReturnType<typeof useZoneTemplatesQuery>;
export type ZoneTemplatesLazyQueryHookResult = ReturnType<typeof useZoneTemplatesLazyQuery>;
export type ZoneTemplatesQueryResult = Apollo.QueryResult<ZoneTemplatesQuery, ZoneTemplatesQueryVariables>;
export const ZonesDocument = gql`
  query Zones(
    $homeId: ID!
    $search: String
    $isArchivedItem: Boolean
    $isArchivedZone: Boolean
    $offset: Int! = 0
    $limit: Int! = 5
  ) {
    zones(
      filter: { homeId: $homeId, search: $search, isArchivedItem: $isArchivedItem, isArchivedZone: $isArchivedZone }
      offset: $offset
      limit: $limit
    ) {
      total
      hasMore
      zones {
        ...Zone
      }
    }
  }
  ${ZoneFragmentDoc}
`;

/**
 * __useZonesQuery__
 *
 * To run a query within a React component, call `useZonesQuery` and pass it any options that fit your needs.
 * When your component renders, `useZonesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useZonesQuery({
 *   variables: {
 *      homeId: // value for 'homeId'
 *      search: // value for 'search'
 *      isArchivedItem: // value for 'isArchivedItem'
 *      isArchivedZone: // value for 'isArchivedZone'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useZonesQuery(baseOptions: Apollo.QueryHookOptions<ZonesQuery, ZonesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ZonesQuery, ZonesQueryVariables>(ZonesDocument, options);
}
export function useZonesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ZonesQuery, ZonesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ZonesQuery, ZonesQueryVariables>(ZonesDocument, options);
}
export type ZonesQueryHookResult = ReturnType<typeof useZonesQuery>;
export type ZonesLazyQueryHookResult = ReturnType<typeof useZonesLazyQuery>;
export type ZonesQueryResult = Apollo.QueryResult<ZonesQuery, ZonesQueryVariables>;
export const ZonesCompressedDocument = gql`
  query ZonesCompressed(
    $homeId: ID!
    $search: String
    $isArchivedItem: Boolean
    $isArchivedZone: Boolean
    $offset: Int! = 0
    $limit: Int! = 50
  ) {
    zonesCompressed: zones(
      filter: { homeId: $homeId, search: $search, isArchivedItem: $isArchivedItem, isArchivedZone: $isArchivedZone }
      offset: $offset
      limit: $limit
    ) {
      total
      hasMore
      zones {
        id
        name
        homeId
        template {
          ...ZoneTemplate
        }
      }
    }
  }
  ${ZoneTemplateFragmentDoc}
`;

/**
 * __useZonesCompressedQuery__
 *
 * To run a query within a React component, call `useZonesCompressedQuery` and pass it any options that fit your needs.
 * When your component renders, `useZonesCompressedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useZonesCompressedQuery({
 *   variables: {
 *      homeId: // value for 'homeId'
 *      search: // value for 'search'
 *      isArchivedItem: // value for 'isArchivedItem'
 *      isArchivedZone: // value for 'isArchivedZone'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useZonesCompressedQuery(
  baseOptions: Apollo.QueryHookOptions<ZonesCompressedQuery, ZonesCompressedQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ZonesCompressedQuery, ZonesCompressedQueryVariables>(ZonesCompressedDocument, options);
}
export function useZonesCompressedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ZonesCompressedQuery, ZonesCompressedQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ZonesCompressedQuery, ZonesCompressedQueryVariables>(ZonesCompressedDocument, options);
}
export type ZonesCompressedQueryHookResult = ReturnType<typeof useZonesCompressedQuery>;
export type ZonesCompressedLazyQueryHookResult = ReturnType<typeof useZonesCompressedLazyQuery>;
export type ZonesCompressedQueryResult = Apollo.QueryResult<ZonesCompressedQuery, ZonesCompressedQueryVariables>;

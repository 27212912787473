import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import resources from 'virtual:i18next-loader';

export default async function initI18n() {
  await i18n.use(initReactI18next).init({
    lng: 'en',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    resources,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });
}

import { FlexProps, Input, Textarea, Flex, FormControl, FormLabel, FormErrorMessage } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface IFormInputProps {
  name: string;
  isTextarea?: boolean;
  containerProps?: FlexProps;
  placeholder?: string;
  isRequired?: boolean;
  label: string;
  disabled?: boolean;
  isEdit?: boolean;
}

const FormInput = (props: IFormInputProps) => {
  const { name, isTextarea, placeholder, containerProps, isRequired, isEdit = true, label, disabled } = props;
  const { t } = useTranslation();

  const { register, formState } = useFormContext();

  const InputComponent = isTextarea ? Textarea : Input;
  const error = formState.errors[name];

  const uneditableInput = {
    borderWidth: '0',
    disabled: true,
    _disabled: { color: 'black' },
  };

  return (
    <FormControl isRequired={isRequired} isInvalid={!!error}>
      <Flex {...containerProps} alignItems='center' py='2' flexDirection='row'>
        <FormLabel width='40%' fontWeight='500'>
          {t(label)}
        </FormLabel>
        <Flex flex='1' flexDirection='column'>
          <InputComponent
            type='text'
            {...register(name)}
            disabled={disabled}
            {...(!isEdit && uneditableInput)}
            maxH={250}
            placeholder={t(placeholder || '')}
            focusBorderColor='primary.main'
          />
          <FormErrorMessage>{error?.message as string}</FormErrorMessage>
        </Flex>
      </Flex>
    </FormControl>
  );
};

export default FormInput;

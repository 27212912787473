import {
  FlexProps,
  Flex,
  FormControl,
  FormLabel,
  NumberInput,
  FormErrorMessage,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  NumberInputProps,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface IFormNumberInputProps extends NumberInputProps {
  name: string;
  containerProps?: FlexProps;
  placeholder?: string;
  isRequired?: boolean;
  label: string;
  disabled?: boolean;
  isEdit?: boolean;
  hideStepper?: boolean;
}

const FormNumberInput = (props: IFormNumberInputProps) => {
  const { name, placeholder, containerProps, isRequired, isEdit = true, label, disabled } = props;
  const { t } = useTranslation();

  const { register, formState } = useFormContext();

  const error = formState.errors[name];

  const uneditableInput = {
    borderWidth: '0',
    disabled: true,
    _disabled: { color: 'black' },
  };

  const isStepperVisible = !props.hideStepper && !disabled;

  return (
    <FormControl isRequired={isRequired} isInvalid={!!error}>
      <Flex {...containerProps} alignItems='center' py='2' flexDirection='row'>
        <FormLabel width='40%' fontWeight='500'>
          {t(label)}
        </FormLabel>
        <Flex flex='1' flexDirection='column'>
          <NumberInput min={props.min} max={props.max} isDisabled={disabled} w='100%' focusBorderColor='primary.main'>
            <NumberInputField
              {...(!isEdit && uneditableInput)}
              {...register(name)}
              disabled={disabled}
              placeholder={t(placeholder || '')}
            />
            {isStepperVisible ? (
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            ) : null}
          </NumberInput>
          <FormErrorMessage>{error?.message as string}</FormErrorMessage>
        </Flex>
      </Flex>
    </FormControl>
  );
};

export default FormNumberInput;

import { ApolloError } from '@apollo/client';
import { Text } from '@chakra-ui/react';

const PageError = ({ error }: { error: ApolloError }) => {
  return (
    <Text textAlign='center' color='red.500' fontSize='6xl'>
      {error.message}
    </Text>
  );
};

export default PageError;

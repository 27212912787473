import useCurrentUser from './useCurrentUser';
import { useVerifyUserEmailMutation } from '../../gql/generated';

const useVerifyEmail = () => {
  const [user] = useCurrentUser();
  const mutation = useVerifyUserEmailMutation({
    update: (cache) => {
      cache.modify({
        id: cache.identify(user || {}),
        fields: {
          isVerified: () => true,
        },
      });
    },
  });

  return mutation;
};

export default useVerifyEmail;

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import concat from 'lodash/concat';
import { createColumnHelper } from '@tanstack/react-table';
import { useParams } from 'react-router-dom';
import { Box, Text, useDisclosure, Flex, IconButton, Tooltip, WrapItem } from '@chakra-ui/react';
import { ERole, HomeWithStatsDocument, User, useMeQuery, useUnassignHomematesMutation } from '../../../gql/generated';
import { DataTable } from '../../common/DataTable';
import { Plus, Trash } from '../../../icons';
import ConfirmationModal from '../../common/ConfirmationModal';
import AddHomeMateModal from './AddHomeMateModal';
import Button from '../../common/Button';
import UserInfo from '../../users/UserInfo';
import roleVariants from '../../../constants/roles';

type TUser = Pick<User, 'id' | 'profile' | 'email' | 'isVerified' | 'role'>;

interface IUsersTable {
  user?: TUser;
  role?: ERole;
  action?: TUser;
  isVerified?: boolean;
}

const columnHelper = createColumnHelper<IUsersTable>();

const ActionColumn = ({ user }: { user: TUser }) => {
  const { id: homeId } = useParams();
  const {
    isOpen: isOpenConfirmationModal,
    onOpen: onOpenConfirmationModal,
    onClose: onCloseConfirmationModal,
  } = useDisclosure();

  const [unassignHomemates, { loading: mutationLoading }] = useUnassignHomematesMutation();

  const { t } = useTranslation();

  if (isEmpty(user.profile)) return null;
  const name = `${user?.profile?.firstName || ''} ${user?.profile?.lastName || ''}`;
  return (
    <>
      <Tooltip hasArrow label={t('delete')}>
        <IconButton onClick={onOpenConfirmationModal} float='right' variant='ghost' aria-label=''>
          <WrapItem>
            <Trash fill='red.500' />
          </WrapItem>
        </IconButton>
      </Tooltip>
      <ConfirmationModal
        loading={mutationLoading}
        onSubmit={() => {
          if (!homeId) return;
          unassignHomemates({
            variables: { input: { homeId, homemateIds: [user.id] } },
            awaitRefetchQueries: true,
            refetchQueries: [{ query: HomeWithStatsDocument, variables: { homeId } }],
          }).then(onCloseConfirmationModal);
        }}
        onClose={onCloseConfirmationModal}
        isOpen={isOpenConfirmationModal}
        title={t('home_details.unassign_home_mate_title', { name })}
      />
    </>
  );
};

const UsersTable = ({ owner, homemates }: { owner?: TUser; homemates?: TUser[] }) => {
  const { t } = useTranslation();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data } = useMeQuery();
  const isAdmin = data?.me.role === ERole.DWELINK_ADMIN;

  const parseData = concat(owner, homemates)?.map((user) => ({
    user,
    role: user?.role,
    action: user,
  }));

  const columns = useMemo(
    () => [
      columnHelper.accessor('user', {
        cell: (info) => {
          const user = info.getValue();
          if (!user) return '';
          return <UserInfo {...user} />;
        },
        header: t('name'),
      }),
      columnHelper.accessor('role', {
        cell: (info) => {
          const isOwner = info?.row?.original?.user?.id === owner?.id;
          if (isOwner) return 'Owner';
          const preset = roleVariants.DWELINK_HOMEMATE;
          return preset?.label;
        },
        header: t('role'),
      }),
      columnHelper.accessor('action', {
        cell: (info) => {
          if (!isAdmin) return;

          const user = info.getValue();
          const isOwner = user?.id === owner?.id;
          if (!user || isOwner) return null;
          return <ActionColumn user={user} />;
        },
        header: () => null,
      }),
    ],
    [isAdmin, owner?.id, t],
  );

  return (
    <Box>
      <Flex justifyContent='space-between' marginBottom='10px'>
        <Text fontSize='3xl'>{t('home_details.access_control')}</Text>
        <Button hidden={!isAdmin} onClick={onOpen}>
          <Plus fill='white' />
        </Button>
      </Flex>
      <DataTable
        boxProps={{ minHeight: 300, shadow: 'none' }}
        withBorder
        columns={columns}
        data={parseData || []}
        emptyTitle={t('home_details.empty_home_mates_table_title')}
        emptyDescription={t('home_details.empty_home_mates_table_description')}
      />
      <AddHomeMateModal ownerId={owner?.id || ''} assignHomemates={homemates} isOpen={isOpen} onClose={onClose} />
    </Box>
  );
};

export default UsersTable;

import { format } from 'date-fns';
import addMinutes from 'date-fns/addMinutes';

export const formatDate = (date: Date) => {
  return format(date, 'dd.MM.yyyy');
};
export const formatDateTime = (date: Date) => {
  return format(date, 'dd.MM.yyyy HH:mm:ss');
};

export const formatSlashDate = (date: Date) => {
  return format(date, 'MM/dd/yyyy');
};

export const getRecommendedDate = (recommendedDate: string) => {
  return addMinutes(new Date(recommendedDate), new Date().getTimezoneOffset());
};

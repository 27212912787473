import { useCallback, useState } from 'react';
import { Box, Button, Flex, Popover, PopoverArrow, PopoverContent, PopoverTrigger, Spinner } from '@chakra-ui/react';
import { Check, Cross } from '../../../icons';
import InfoItem from '../../common/InfoItem';
import NumberInput from '../../common/NumberInput';

export enum EFields {
  squareFootage = 'squareFootage',
  yearBuilt = 'yearBuilt',
}

export interface IOnSubmitProps {
  [EFields.squareFootage]?: number;
  [EFields.yearBuilt]?: number;
}

interface IEditItem {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  defaultValue: number;
  onSubmit: (props: IOnSubmitProps) => Promise<void>;
  name: EFields;
  title: string;
  toggle: () => void;
  min: number;
  max: number;
}

const EditItem = ({ min, max, toggle, title, name, onSubmit, defaultValue, isOpen, onOpen, onClose }: IEditItem) => {
  const [value, setValue] = useState(defaultValue);
  const [loading, setLoading] = useState(false);

  const handleOnClose = useCallback(() => {
    setValue(defaultValue);
    onClose();
  }, [defaultValue, onClose]);
  const handleOnSubmit = useCallback(async () => {
    setLoading(true);
    const props = { [name]: value };
    await onSubmit(props);
    onClose();
    setLoading(false);
  }, [name, onClose, onSubmit, value]);

  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={handleOnClose} placement='left' closeOnBlur={false}>
      <PopoverArrow />
      <PopoverContent py='2'>
        <Flex alignItems='center' flexDirection='row'>
          <NumberInput
            onChange={(newValue) => setValue(parseInt(newValue, 10))}
            defaultValue={defaultValue}
            min={min}
            max={max}
            value={value}
            flex={1}
            mx='20px'
            w='fit-content'
            placeholder={title}
          />
          <Button isDisabled={!value || value === defaultValue} onClick={handleOnSubmit} p='1' m='1'>
            {loading ? (
              <Spinner thickness='1px' speed='0.65s' emptyColor='gray.200' color='primary.main' size='sm' />
            ) : (
              <Check color='green.400' />
            )}
          </Button>
          <Button height={0} variant='ghost' padding='0' onClick={handleOnClose}>
            <Cross />
          </Button>
        </Flex>
      </PopoverContent>
      <InfoItem
        title={title}
        value={defaultValue}
        actionButton={
          <PopoverTrigger>
            <Box ml='1' cursor='pointer'>
              <IconCarbonEdit onClick={toggle} />
            </Box>
          </PopoverTrigger>
        }
      />
    </Popover>
  );
};

export default EditItem;

import { AspectRatio, IconButton } from '@chakra-ui/react';
import { Plus } from '../../../icons';

type Props = {
  isDisabled?: boolean;
  onClick: () => void;
  boxSize?: string;
};

const AddImagesButton = ({ boxSize = '', onClick, isDisabled }: Props) => {
  return (
    <AspectRatio boxSize={['-moz-fit-content', boxSize]}>
      <IconButton
        aria-label='add-image'
        variant='outline'
        onClick={onClick}
        width='full'
        h='full'
        borderWidth='2px'
        borderStyle='dashed'
        borderColor='#8BB8E8'
        icon={<Plus fill='#8BB8E8' />}
        isDisabled={isDisabled}
      />
    </AspectRatio>
  );
};

export default AddImagesButton;

import { Box, Flex, Text } from '@chakra-ui/react';
import { CurrentUserFragment } from '../../../gql/generated';
import JobberLink from '../../jobber/JobberLink';

interface IUserProfileInfoHeaderProps {
  user: CurrentUserFragment;
}

const SIZE = 80;

const UserProfileInfoHeader = ({ user }: IUserProfileInfoHeaderProps) => {
  const name = `${user?.profile?.firstName || ''} ${user?.profile?.lastName || ''}`;

  return (
    <Flex alignItems='center'>
      <Flex
        style={{
          backgroundColor: '#E7F4FF',
          height: SIZE,
          width: SIZE,
          borderRadius: SIZE / 2,
          alignItems: 'center',
          justifyContent: 'center',
          alignSelf: 'center',
          fontSize: 25,
          fontWeight: 600,
        }}
      >
        {user.profile ? `${user.profile.firstName[0]} ${user.profile.lastName[0]}` : 'N/A'}
      </Flex>
      <Box ml={2} p='1.5'>
        <Text fontWeight='500'>{name}</Text>
        <Text color='gray'>{user.email}</Text>
        <JobberLink link={user.jobberWebUri} />
      </Box>
    </Flex>
  );
};

export default UserProfileInfoHeader;

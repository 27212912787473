import { Box, Flex, useDisclosure, useToast } from '@chakra-ui/react';
import { DoItRequestFragment, EDoItRequestStatus, useFinishDoitRequestMutation } from '../../gql/generated';
import Button, { EButtonVariant } from '../../components/common/Button';
import { useTranslation } from 'react-i18next';
import { Check } from '../../icons';
import { useCallback } from 'react';
import { formatGraphQLErrors } from '../../gql/apollo/helpers';
import ConfirmationModal from '../common/ConfirmationModal';

interface IDoItDetailsButtonsBarProps {
  onSave: () => void;
  onSaveLoading?: boolean;
  doitRequest: DoItRequestFragment;
  resetForm: () => void;
}

const DoItDetailsButtonsBar = ({ onSave, onSaveLoading, doitRequest, resetForm }: IDoItDetailsButtonsBarProps) => {
  const toast = useToast();
  const { t } = useTranslation();

  const {
    isOpen: isOpenConfirmationModal,
    onOpen: onOpenConfirmationModal,
    onClose: onCloseConfirmationModal,
  } = useDisclosure();

  const [finishDoItRequest, { loading: finishDoItRequestLoading }] = useFinishDoitRequestMutation({
    variables: { doitRequestId: doitRequest.id },
    onError: (e) => toast({ description: formatGraphQLErrors(e).message, status: 'error' }),
    onCompleted: () => {
      toast({ description: t('doit_request_details.toast.processed'), status: 'success' });
      onCloseConfirmationModal();
      resetForm?.();
    },
  });

  const onFinishDoItRequest = useCallback(async () => await finishDoItRequest(), [finishDoItRequest]);

  const isFinished = doitRequest.status === EDoItRequestStatus.FINISHED;

  if (isFinished) {
    return (
      <Flex width='100%' flexDirection='row' paddingY='2' direction='column' gap='2'>
        <Button
          textTransform='uppercase'
          styleVariant={EButtonVariant.statusButton}
          rightIcon={<Check fill='gray.400' color='gray.400' boxSize='4' strokeWidth={6} />}
        >
          {t('doit_request_details.actions.processed')}
        </Button>
      </Flex>
    );
  }
  return (
    <Box>
      <Flex alignItems='flex-end' pb='2' gap={2} justifyContent='end' flex={1}>
        <Button
          isLoading={onSaveLoading}
          onClick={onSave}
          styleVariant={EButtonVariant.outline}
          rightIcon={<IconCarbonSave />}
          color='primary.main'
        >
          {t('doit_request_details.actions.save')}
        </Button>
        <Button
          variant='solid'
          rightIcon={<Check fill='white' boxSize='3' stroke='white' strokeWidth={2} />}
          width='fit-content'
          isLoading={finishDoItRequestLoading}
          onClick={onOpenConfirmationModal}
        >
          {t('doit_request_details.actions.processed')}
        </Button>
      </Flex>

      <ConfirmationModal
        title={t('doit_request_details.processed_task_confirm_modal_title')}
        description={t('doit_request_details.processed_task_confirm_modal_description')}
        onSubmit={onFinishDoItRequest}
        onClose={onCloseConfirmationModal}
        isOpen={isOpenConfirmationModal}
        loading={finishDoItRequestLoading}
      />
    </Box>
  );
};

export default DoItDetailsButtonsBar;

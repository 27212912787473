export enum AppPaths {
  INDEX = '/',
  HOMES = '/homes',
  HOME_DETAILS = '/homes/:id',
  HOME_DETAILS_GENERAL = '/homes/:id/general',
  // HOME ZONE TAB
  HOME_DETAILS_ZONES = '/homes/:id/zones',
  ZONE_ITEMS = '/homes/:id/zones/:zoneId/items',
  ZONE_ITEM_DETAILS = '/homes/:id/zones/:zoneId/items/:itemId',
  ZONE_ITEM_DETAILS_INFO = '/homes/:id/zones/:zoneId/items/:itemId/info',
  ZONE_ITEM_DETAILS_MAINTENANCE_PLAN = '/homes/:id/zones/:zoneId/items/:itemId/maintenance_plan',
  ZONE_EDIT_ITEM_TASK = '/homes/:id/zones/:zoneId/items/:itemId/maintenance_plan/:taskId',
  ZONE_ITEM_CREATE_TASK = '/homes/:id/zones/:zoneId/items/:itemId/create_tasks',
  ZONE_TASKS = '/homes/:id/zones/:zoneId/tasks',
  EDIT_ZONE_TASK = '/homes/:id/zones/:zoneId/tasks/:taskId',
  // HOME ITEMS TAB
  HOME_DETAILS_ITEMS = '/homes/:id/items',
  HOME_DETAILS_TASKS = '/homes/:id/tasks',
  HOME_DETAILS_DO_IT = '/homes/:id/doit_requests',
  ITEM_DETAILS = '/homes/:id/items/:itemId',
  ITEM_DETAILS_INFO = '/homes/:id/items/:itemId/info',
  ITEM_DETAILS_MAINTENANCE_PLAN = '/homes/:id/items/:itemId/maintenance_plan',
  CUSTOMERS = '/customers',
  CUSTOMER_PROFILE = '/customers/:userId',
  DWELINK_USERS = '/dwelink_users',
  USER_PROFILE = '/profile/:userId',
  TASKS = '/tasks',
  EDIT_TASK = '/tasks/:taskId',
  EDIT_HOME_TASK = '/homes/:id/tasks/:taskId',
  EDIT_ITEM_TASK = '/homes/:id/items/:itemId/tasks/:taskId',
  CREATE_TASK = '/homes/:id/items/:itemId/create_tasks',
  DOIT_REQUESTS = '/doit_requests',
  DOIT_REQUEST_DETAILS = '/doit_requests/:doitRequestId',
  HOME_DOIT_REQUEST_DETAILS = '/homes/:id/doit_requests/:doitRequestId',
  SETTINGS = '/settings',
  JOBBER_REDIRECT = '/jobber_redirect',
}

export enum AdminAppPaths {
  SETTINGS = '/settings',
  DWELINK_USERS = '/dwelink_users',
}

export enum AuthPaths {
  ACCEPT_INVITATION = '/accept_invitation/:id',
  LOGIN = '/login',
  SET_PASSWORD = '/set_password',
  FORGOT = '/forgot',
  VERIFY_EMAIL = '/verify_email',
  GOOGLE_REDIRECT = '/google_redirect',
}

export enum CommonPaths {
  REDIRECT = '/redirect',
}

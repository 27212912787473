import { useCallback, useState } from 'react';
import {
  TaskImagesDocument,
  useConfirmTaskImagesUploadingMutation,
  useRequestTaskImagesUploadingMutation,
  useSetMainTaskImageMutation,
} from '../../gql/generated';

const useConfirmTaskImagesUploader = ({ taskId }: { taskId: string }) => {
  const [loading, setLoading] = useState(false);

  const [requestTaskImagesUploading] = useRequestTaskImagesUploadingMutation();
  const [confirmTaskImageUploading] = useConfirmTaskImagesUploadingMutation();
  const [setMain] = useSetMainTaskImageMutation();

  const upload = useCallback(
    async (file: File) => {
      try {
        setLoading(true);
        const { data } = await requestTaskImagesUploading({
          variables: {
            input: { parentId: taskId, files: [{ sizeBytes: file.size, filename: file.name, mimetype: file.type }] },
          },
        });

        const uploadUrl = data?.requestTaskImagesUploading[0].url;
        const res = await fetch(uploadUrl as string, {
          method: 'PUT',
          body: file,
        });
        if (res.status !== 200) {
          console.warn(res.status, res.body);
          throw new Error('Upload failed');
        }

        await confirmTaskImageUploading({
          variables: {
            input: {
              parentId: taskId,
              fileIds: data?.requestTaskImagesUploading.map((i) => i.id) || [],
            },
          },
        });

        await setMain({
          variables: { input: { fileId: data?.requestTaskImagesUploading[0].id || '', parentId: taskId } },
          refetchQueries: [{ query: TaskImagesDocument, variables: { taskId } }],
        });
      } catch (error) {
        return error;
      } finally {
        setLoading(false);
      }
    },
    [confirmTaskImageUploading, requestTaskImagesUploading, setMain, taskId],
  );

  return {
    isLoading: loading,
    upload,
  };
};

export default useConfirmTaskImagesUploader;

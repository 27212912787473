import { HStack } from '@chakra-ui/react';
import { EAuthServiceType } from '../../gql/generated';
import { Apple, Google } from '../../icons/social-icons';

export default function SocialLogins({ authServices }: { authServices: EAuthServiceType[] }) {
  return (
    <HStack spacing='2'>
      {authServices.map((authService) => {
        switch (authService) {
          case EAuthServiceType.APPLE:
            return <Apple key={authService} />;
          case EAuthServiceType.GOOGLE:
            return <Google key={authService} />;
          default:
            return null;
        }
      })}
    </HStack>
  );
}

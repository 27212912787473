import { generatePath, useNavigate, useSearchParams } from 'react-router-dom';
import LoadingOverlay from '../components/common/LoadingOverlay';
import { useToast } from '@chakra-ui/react';
import { useCallback, useEffect } from 'react';
import { AppPaths } from '../routes/paths';
import { ERole, useUserRoleLazyQuery } from '../gql/generated';
import { formatGraphQLErrors } from '../gql/apollo/helpers';
import { useTranslation } from 'react-i18next';

enum PortalRedirect {
  USER = 'user',
  HOME = 'home',
  TASK = 'task',
  DOIT_REQUEST = 'doit_request',
}

function validateRedirectArgs(args: URLSearchParams) {
  const type = args.get('type') as PortalRedirect | null;
  const userId = args.get('userId');
  const homeId = args.get('homeId');
  const taskId = args.get('taskId');
  const doitRequestId = args.get('doitRequestId');

  switch (type) {
    case PortalRedirect.USER:
      return userId != null;
    case PortalRedirect.HOME:
      return homeId != null;
    case PortalRedirect.TASK:
      return homeId != null && taskId != null;
    case PortalRedirect.DOIT_REQUEST:
      return homeId != null && doitRequestId != null;
    default:
      return false;
  }
}

export default function RedirectHandler() {
  const [args] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const toast = useToast();

  const [getUserRole] = useUserRoleLazyQuery({
    fetchPolicy: 'network-only',
    onError: (err) => handleError(formatGraphQLErrors(err).message),
  });

  const handleError = useCallback(
    (message: string) => {
      toast({ description: message, status: 'error' });
      navigate(AppPaths.INDEX);
    },
    [navigate, toast],
  );

  const handleUserRedirect = useCallback(
    async (userId: string) => {
      const { data } = await getUserRole({ variables: { userId } });
      const path = data?.user.role === ERole.USER ? AppPaths.CUSTOMER_PROFILE : AppPaths.USER_PROFILE;
      navigate(generatePath(path, { userId }));
    },
    [getUserRole, navigate],
  );

  useEffect(() => {
    const isValid = validateRedirectArgs(args);
    if (!isValid) return handleError(t('redirect_handler.invalid_url'));

    const type = args.get('type');
    const userId = args.get('userId');
    const homeId = args.get('homeId');
    const taskId = args.get('taskId');
    const doitRequestId = args.get('doitRequestId');

    switch (type) {
      case PortalRedirect.USER:
        handleUserRedirect(userId!);
        break;
      case PortalRedirect.HOME:
        navigate(generatePath(AppPaths.HOME_DETAILS, { id: homeId }));
        break;
      case PortalRedirect.TASK:
        navigate(generatePath(AppPaths.EDIT_HOME_TASK, { id: homeId, taskId }));
        break;
      case PortalRedirect.DOIT_REQUEST:
        navigate(generatePath(AppPaths.HOME_DOIT_REQUEST_DETAILS, { id: homeId, doitRequestId }));
        break;
    }
  }, [args, navigate, toast, getUserRole, handleError, handleUserRedirect, t]);

  return <LoadingOverlay />;
}

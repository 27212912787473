import { Box, ButtonSpinner, FormErrorMessage, Text, VStack } from '@chakra-ui/react';
import { FormControl, Stack, HStack } from '@chakra-ui/react';
import { PinInput, PinInputField } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import ResendCode from '../ResendCode';
import Button from '../../common/Button';

type Props = {
  email: string;
  onSubmit: ({ code }: { code: string }) => Promise<void> | void;
  onResend: () => Promise<void>;
  isLoading?: boolean;
  isDisabled?: boolean;
};

const getSchema = () => {
  const schema = yup.object().shape({
    code: yup.string().label(i18n.t('otp')).length(6).required(),
  });
  return schema;
};

export default function CodeForm({ email, isLoading, isDisabled, onResend, onSubmit: onSubmitProp }: Props) {
  const { t } = useTranslation();

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(getSchema()),
  });

  const onSubmit = handleSubmit(onSubmitProp);

  return (
    <Box w='100%'>
      <form onSubmit={onSubmit}>
        <Stack gap={6}>
          <VStack gap={0}>
            <Text>{t('code_sent')}</Text>
            <Text>{email}</Text>
          </VStack>
          <FormControl isInvalid={!!errors.code}>
            <VStack gap={1}>
              <Controller
                control={control}
                name='code'
                render={({ field }) => (
                  <HStack>
                    <PinInput
                      autoFocus
                      focusBorderColor='primary.main'
                      isDisabled={isLoading || isDisabled}
                      isInvalid={!!errors.code}
                      onChange={(v) => setValue('code', v)}
                    >
                      <PinInputField {...field} />
                      <PinInputField {...field} />
                      <PinInputField {...field} />
                      <PinInputField {...field} />
                      <PinInputField {...field} />
                      <PinInputField {...field} />
                    </PinInput>
                  </HStack>
                )}
              />
              <FormErrorMessage>{errors.code?.message}</FormErrorMessage>
            </VStack>
          </FormControl>
          <ResendCode onResend={onResend} isLoading={isLoading || isDisabled} />
          <Button type='submit' className='w-100' isDisabled={isLoading || isDisabled}>
            {!isLoading ? t('submit') : <ButtonSpinner />}
          </Button>
        </Stack>
      </form>
    </Box>
  );
}

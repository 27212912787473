import {
  IconButton,
  Flex,
  useColorModeValue,
  FlexProps,
  MenuItem,
  MenuDivider,
  Image,
  HStack,
  Menu,
  MenuButton,
  Avatar,
  VStack,
  Text,
  MenuList,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Spinner,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Link, generatePath, useLocation, useMatches, useNavigate } from 'react-router-dom';
import logo from '../../../../assets/full-logo-primary.svg';
import useLogout from '../../../../hooks/auth/useLogout';
import useCurrentUser from '../../../../hooks/auth/useCurrentUser';
import { AppPaths } from '../../../../routes/paths';

interface HeaderProps extends FlexProps {
  onOpen: () => void;
}

type Handle = {
  crumb?: () => ReactNode | string;
};

const splitNameWidth = (name: ReactNode | string) => {
  if (!name) return name;
  if (typeof name === 'string') {
    const maxLength = 40;
    if (name.length > maxLength) return `${name.slice(0, maxLength - 3)}...`;
  }
  return name;
};

const Header = ({ onOpen, ...rest }: HeaderProps) => {
  const logout = useLogout();
  const matches = useMatches();
  const location = useLocation();
  const [user, { loading }] = useCurrentUser({ fetchPolicy: 'cache-and-network' });

  const navigate = useNavigate();
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height='85px'
      alignItems='center'
      justifyContent={{ base: 'space-between', md: 'flex-start' }}
      {...rest}
    >
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        variant='outline'
        aria-label='open menu'
        icon={<IconCarbonMenu />}
      />

      <Breadcrumb display={{ base: 'none', md: 'flex' }} fontWeight='medium' fontSize='md'>
        {matches
          .filter((match) => Boolean(match.handle))
          .map(({ pathname, handle }, index) => (
            <BreadcrumbItem key={index} isCurrentPage={pathname === location.pathname}>
              {pathname !== location.pathname ? (
                <BreadcrumbLink as={Link} textDecoration='none' to={pathname}>
                  {splitNameWidth((handle as Handle).crumb?.())}
                </BreadcrumbLink>
              ) : (
                <BreadcrumbLink textDecoration='none'>{splitNameWidth((handle as Handle).crumb?.())}</BreadcrumbLink>
              )}
            </BreadcrumbItem>
          ))}
      </Breadcrumb>

      <Image src={logo} display={{ base: 'flex', md: 'none' }} />

      <HStack spacing={{ base: '0', md: '6' }} display={{ base: 'flex', md: 'none' }}>
        <Flex alignItems={'center'}>
          <Menu>
            <MenuButton py={2} transition='all 0.3s' _focus={{ boxShadow: 'none' }}>
              <HStack>
                {isEmpty(user) && loading ? (
                  <Spinner size='xs' />
                ) : (
                  <Avatar
                    size={'sm'}
                    name={user?.profile ? `${user?.profile?.firstName} ${user?.profile?.lastName}` : 'N/A'}
                  />
                )}
                <VStack display={{ base: 'none', md: 'flex' }} alignItems='flex-start' spacing='1px' ml='2'>
                  <Text fontSize='sm'>Justina Clark</Text>
                  <Text fontSize='xs' color='gray.600'>
                    Admin
                  </Text>
                </VStack>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue('white', 'gray.900')}
              borderColor={useColorModeValue('gray.200', 'gray.700')}
            >
              <MenuItem onClick={() => navigate(generatePath(AppPaths.USER_PROFILE, { userId: user?.id || '' }))}>
                Profile
              </MenuItem>
              <MenuDivider />
              <MenuItem onClick={() => logout()}>Sign out</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
};

export default Header;

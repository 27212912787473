import { Box, useToast } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { useDropzone } from 'react-dropzone';

type ChildrenProps = {
  open: () => void;
};

type Props = {
  onDropAccepted: (files: File[]) => void;
  children: (props: ChildrenProps) => ReactNode;
  disabled?: boolean;
};

export default function DropZone({ onDropAccepted, children, disabled }: Props) {
  const toast = useToast();
  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    multiple: true,
    maxFiles: 5,
    accept: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
    },
    disabled,
    onDropRejected: (drop) => {
      drop.forEach(({ file, errors }) => {
        toast({
          status: 'error',
          title: file.name,
          description: errors[0].message,
        });
      });
    },
    onDropAccepted,
  });

  return (
    <Box {...getRootProps()}>
      {children({ open })}
      <input {...getInputProps()} />
    </Box>
  );
}

import { v4 as uuid4 } from 'uuid';
import { UploadResponse } from '../gql/generated';

export enum Status {
  IDLE = 'idle',
  REQUESTED = 'requested',
  UPLOADED = 'uploaded',
  CONFIRMING = 'confirming',
  CONFIRMED = 'confirmed',
  CANCELED = 'canceled',
  ERROR = 'error',
}

export default class UploadingFile {
  file: File;
  controller?: AbortController;
  requestResponse?: UploadResponse;
  uploadResponse?: Promise<Response | null>;
  private status: Status;
  private onUpdate: () => void;

  constructor(file: File, onUpdate: () => void) {
    this.file = new File([file], uuid4(), { type: file.type });
    this.status = Status.IDLE;
    this.onUpdate = onUpdate;
  }

  getStatus = () => this.status;

  setStatus = (status: Status) => {
    this.status = status;
    this.onUpdate();
  };

  cancel = () => {
    this.setStatus(Status.CANCELED);
    this.controller?.abort();
  };

  setRequestResponse = (requestResponse: UploadResponse) => {
    this.requestResponse = requestResponse;
    this.setStatus(Status.REQUESTED);
  };

  upload = () => {
    this.controller = new AbortController();
    if (this.status !== Status.REQUESTED) {
      throw new Error('File is not requested');
    }

    if (!this.requestResponse) {
      throw new Error('Request response is not defined');
    }

    this.uploadResponse = fetch(this.requestResponse?.url, {
      method: 'PUT',
      body: this.file,
      signal: this.controller.signal,
    })
      .catch((error: Error) => {
        if (error.name === 'AbortError') return null;
        this.setStatus(Status.ERROR);
        throw error;
      })
      .then((response) => {
        if (response?.status === 200 && this.status !== Status.CANCELED) {
          this.setStatus(Status.UPLOADED);
        }
        return response;
      })
      .finally(() => this.onUpdate());
  };

  isCancelable = () => [Status.IDLE, Status.REQUESTED, Status.UPLOADED].includes(this.status);
}

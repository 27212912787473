import { useTranslation } from 'react-i18next';
import { useDeleteItemImagesMutation } from '../../../gql/generated';
import ConfirmationModal from '../../common/ConfirmationModal';
import { ApolloError } from '@apollo/client';

type Props = {
  isOpen: boolean;
  itemId: string;
  fileId: string;
  onClose: () => void;
  onError: (e: ApolloError) => void;
};

export default function DeleteItemImageModal({ itemId, fileId, isOpen, onClose, onError }: Props) {
  const { t } = useTranslation();
  const [deleteItemImages, { loading }] = useDeleteItemImagesMutation({
    variables: {
      input: {
        parentId: itemId,
        fileIds: [fileId],
      },
    },
    update: (cache) => {
      cache.evict({
        id: `ItemImage:${fileId}`,
      });
    },
    onError,
    onCompleted: onClose,
  });

  return (
    <ConfirmationModal
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={deleteItemImages}
      title={t('item_image_delete_modal.title')}
      description={t('item_image_delete_modal.description')}
      loading={loading}
    />
  );
}

import { Flex, Highlight, Link, Text } from '@chakra-ui/react';
import { DataTable } from '../../components/common/DataTable';
import { Row, createColumnHelper } from '@tanstack/react-table';
import { format } from 'date-fns';
import { EAuthServiceType, User } from '../../gql/generated';
import UserInfo from '../../components/users/UserInfo';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import SocialLogins from './SocialLogins';
import { UserActions } from './UserActions';
import { useNavigate } from 'react-router-dom';

export type CustomerData = {
  user: Pick<User, 'profile' | 'email' | 'isVerified' | 'role' | 'id'>;
  phone?: string | null;
  homes: number;
  createdAt: Date;
  authServices: EAuthServiceType[];
  id: string;
};

const columnHelper = createColumnHelper<CustomerData>();

type Props = {
  isLoading?: boolean;
  search: string;
  customers: CustomerData[];
  getNavigationPath?: (row: Row<CustomerData>) => string;
};

const CustomerTable = ({ customers, search, getNavigationPath, isLoading }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      columnHelper.accessor('user', {
        cell: (info) => <UserInfo {...info.getValue()} search={search} />,
        header: t('user_table.header.user'),
      }),
      columnHelper.accessor('phone', {
        cell: (info) => {
          const phone = info.getValue() || '';
          return (
            <Link href={`tel:${phone}`} fontWeight='medium' isExternal _hover={{ textDecoration: 'none' }}>
              <Highlight query={search} styles={{ bg: 'orange.100' }}>
                {phone}
              </Highlight>
            </Link>
          );
        },
        header: t('user_table.header.phone'),
      }),
      columnHelper.accessor('homes', {
        cell: (info) => <Text fontWeight='normal'>{info.getValue()}</Text>,
        header: t('user_table.header.homes'),
      }),
      columnHelper.accessor('createdAt', {
        cell: (info) => format(new Date(info.getValue()), 'LLL dd, yyyy'),
        header: t('user_table.header.created_at'),
      }),
      columnHelper.accessor('authServices', {
        cell: (info) => <SocialLogins authServices={info.getValue()} />,
        header: t('user_table.header.auth_services'),
      }),
      columnHelper.accessor('id', {
        cell: (info) => (
          <Flex justifyContent='flex-end'>
            <UserActions navigationPath={getNavigationPath?.(info.row) || ''} withView user={info.row.original.user} />
          </Flex>
        ),
        header: '',
      }),
    ],
    [getNavigationPath, search, t],
  );

  const handlerOnClickRow = useCallback(
    (row: Row<CustomerData>) => getNavigationPath && navigate(getNavigationPath(row)),
    [getNavigationPath, navigate],
  );

  return (
    <DataTable
      columns={columns}
      data={customers}
      loading={isLoading}
      {...(getNavigationPath && { onClickRow: handlerOnClickRow })}
      boxProps={{ minHeight: 650 }}
      emptyTitle={t('user_table.empty.title')}
      emptyDescription={
        search ? t('user_table.empty.description.no_result') : t('user_table.empty.description.no_customers')
      }
    />
  );
};

export default CustomerTable;

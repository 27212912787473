import {
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput as CNumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberInputProps,
} from '@chakra-ui/react';

interface INumberInput extends NumberInputProps {
  placeholder?: string;
  hideStepper?: boolean;
}

const NumberInput = (props: INumberInput) => {
  return (
    <CNumberInput {...props} w='fit-content'>
      <NumberInputField placeholder={props.placeholder} />
      {!props.hideStepper ? (
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      ) : null}
    </CNumberInput>
  );
};

export default NumberInput;

import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import LoadingOverlay from '../../components/common/LoadingOverlay';
import { AppPaths } from '../../routes/paths';
import { useEffect, useMemo, useRef } from 'react';
import { useSetJobberTokensMutation } from '../../gql/generated';
import { useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export default function JobberRedirect() {
  const wasRequested = useRef(false); //  avoid double request in strict mode
  const navigate = useNavigate();
  const { t } = useTranslation();
  const toast = useToast();

  const onCompleted = () => {
    toast({
      description: t('jobber_updated'),
      status: 'success',
    });
    navigate(AppPaths.SETTINGS);
  };

  const onError = () => {
    toast({
      description: t('jobber_not_updated'),
      status: 'error',
    });
    navigate(AppPaths.SETTINGS);
  };

  const [setJobberTokens] = useSetJobberTokensMutation({
    fetchPolicy: 'network-only',
    onCompleted: onCompleted,
    onError: onError,
  });

  const [search] = useSearchParams();
  const code = useMemo(() => search.get('code'), [search]);

  useEffect(() => {
    if (code && !wasRequested.current) {
      setJobberTokens({ variables: { code } });
      wasRequested.current = true;
    }
  }, [code, setJobberTokens]);

  if (!code) {
    return <Navigate to={AppPaths.SETTINGS} replace />;
  }

  return <LoadingOverlay />;
}

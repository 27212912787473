import { ERole } from '../gql/generated';

type TRoleVariants = Record<ERole, { label: string }>;

const roleVariants: TRoleVariants = {
  [ERole.DWELINK_ADMIN]: {
    label: 'Admin',
  },
  [ERole.DWELINK_HOMEMATE]: {
    label: 'HomeMate',
  },
  [ERole.USER]: {
    label: 'User',
  },
};

export default roleVariants;

import { AppPaths } from './paths';
import { Outlet, RouteObject } from 'react-router-dom';
import { t } from 'i18next';
import ZoneInventory from '../components/homeDetails/Zones/ZoneInventory';
import ZoneTasks from '../components/homeDetails/Zones/ZoneTasks';
import ZoneItemDetails from '../components/homeDetails/Zones/ZoneItemDetails';
import AccessTokenService from '../services/tokens/AccessTokenService';
import client from '../gql/apollo/client';
import { ItemDocument, TaskDocument, ZoneDocument } from '../gql/generated';
import MaintenancePlan from '../components/itemDetails/maintenancePlan';
import InfoTab from '../components/itemDetails/infoTab';
import { getItemCrumbName, getTaskCrumbTitle, getZoneCrumbName } from './helpers';
import CreateTask from '../pages/app/CreateTask';
import EditTask from '../pages/app/EditTask';

export default {
  loader: async ({ request }) => {
    try {
      const isTokenExp = AccessTokenService.isTokenExp();
      if (isTokenExp) return null;
      const zoneId = request.url.split('zones/')[1]?.split('/')[0];
      if (!zoneId) return null;
      const data = await client.query({ query: ZoneDocument, variables: { zoneId } });
      return data;
    } catch (error) {
      return 'N/A';
    }
  },
  children: [
    {
      path: AppPaths.ZONE_ITEMS,
      element: <Outlet />,
      handle: { crumb: () => t('crumb.zone_items', { zoneName: getZoneCrumbName() }) },
      children: [
        { element: <ZoneInventory />, path: AppPaths.ZONE_ITEMS },
        {
          path: AppPaths.ZONE_ITEM_DETAILS,
          element: <ZoneItemDetails />,
          loader: async ({ request }) => {
            try {
              const isTokenExp = AccessTokenService.isTokenExp();
              if (isTokenExp) return null;
              const itemId = request.url.split('items/')[1].split('/')[0];
              if (!itemId) return null;
              const data = await client.query({ query: ItemDocument, variables: { itemId } });
              return data;
            } catch (error) {
              return 'N/A';
            }
          },
          children: [
            {
              path: AppPaths.ZONE_ITEM_CREATE_TASK,
              element: <CreateTask />,
              handle: { crumb: () => t('crumb.create_task') },
            },
            {
              path: AppPaths.ZONE_ITEM_DETAILS_INFO,
              element: <InfoTab />,
              handle: {
                crumb: () => t('crumb.item_info', { itemName: getItemCrumbName() }),
              },
            },
            {
              path: AppPaths.ZONE_ITEM_DETAILS_MAINTENANCE_PLAN,
              element: <Outlet />,
              children: [
                { path: AppPaths.ZONE_ITEM_DETAILS_MAINTENANCE_PLAN, element: <MaintenancePlan /> },
                {
                  path: AppPaths.ZONE_EDIT_ITEM_TASK,
                  element: <EditTask />,
                  loader: async ({ request }) => {
                    try {
                      const isTokenExp = AccessTokenService.isTokenExp();
                      if (isTokenExp) return null;
                      const taskId = request.url.split('tasks/')[1];
                      if (!taskId) return null;
                      const data = await client.query({ query: TaskDocument, variables: { taskId } });
                      return data;
                    } catch (error) {
                      return 'N/A';
                    }
                  },
                  handle: { crumb: getTaskCrumbTitle },
                },
              ],
              handle: {
                crumb: () => t('crumb.item_maintenance_plan', { itemName: getItemCrumbName() }),
              },
            },
          ],
        },
      ],
    },
    {
      path: AppPaths.ZONE_TASKS,
      element: <Outlet />,
      handle: { crumb: () => t('crumb.zone_tasks', { zoneName: getZoneCrumbName() }) },
      children: [
        { path: AppPaths.ZONE_TASKS, element: <ZoneTasks /> },
        {
          path: AppPaths.EDIT_ZONE_TASK,
          element: <EditTask />,
          loader: async ({ request }) => {
            try {
              const isTokenExp = AccessTokenService.isTokenExp();
              if (isTokenExp) return null;
              const taskId = request.url.split('tasks/')[1];
              if (!taskId) return null;
              const data = await client.query({ query: TaskDocument, variables: { taskId } });
              return data;
            } catch (error) {
              return 'N/A';
            }
          },
          handle: { crumb: getTaskCrumbTitle },
        },
      ],
    },
  ],
} as RouteObject;

import sortBy from 'lodash/sortBy';
import { CRITICALITY_SELECT_OPTIONS, LEVEL_OF_SKILL_SELECT_OPTIONS } from '../constants/presets';
import { HomeFragment } from '../gql/generated';

export function getLevelOfSkill(level: number) {
  const item = sortBy(LEVEL_OF_SKILL_SELECT_OPTIONS, ['value']).find((item) => level <= item.value);
  return item;
}

export function getImportanceLevel(importance: number) {
  const item = sortBy(CRITICALITY_SELECT_OPTIONS, ['value']).find((item) => importance <= item.value);
  return item;
}

export type PresetProps = {
  key: string;
  Icon?: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
  AdditionalIcon?: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
  color?: string;
  title?: string;
  translate?: boolean;
};

export function getItemFromPreset(preset: PresetProps[], key: string) {
  return preset.find((i) => i.key === key) as PresetProps;
}

export function sortHomesForHomemate(homes: HomeFragment[], homemateId: string) {
  return sortBy(homes || [], (i) => !i.homemates.map((hm) => hm.id).includes(homemateId));
}

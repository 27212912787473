import { Box, useToast } from '@chakra-ui/react';
import Header from './Header';
import { useDeferredValue, useEffect, useState } from 'react';
import { ERole, EUserSort, useUsersQuery } from '../../gql/generated';
import { formatGraphQLErrors } from '../../gql/apollo/helpers';
import DwelinkUserTable from './DwelinkUserTable';
import Pagination from '../common/Pagination';
import { isEmpty } from 'lodash';
import PageError from '../common/PageError';

const USERS_PER_PAGE = 40;

export default function ActiveUsers() {
  const [search, setSearch] = useState('');
  const toast = useToast();
  const deferredSearch = useDeferredValue(search.trim());
  const { data, error, loading, refetch } = useUsersQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      filter: { search: deferredSearch, role: [ERole.DWELINK_ADMIN, ERole.DWELINK_HOMEMATE] },
      limit: USERS_PER_PAGE,
      sort: EUserSort.PROFILE,
    },
    onError: (e) => toast({ description: formatGraphQLErrors(e).message, status: 'error' }),
    notifyOnNetworkStatusChange: true,
  });
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    refetch({
      offset: (currentPage - 1) * USERS_PER_PAGE,
      limit: USERS_PER_PAGE,
    });
  }, [currentPage, refetch]);

  useEffect(() => {
    setCurrentPage(1);
  }, [deferredSearch]);

  if (error?.message && isEmpty(data)) return <PageError error={error} />;

  return (
    <Box>
      <Header search={search} onChange={(e) => setSearch(e.target.value)} />
      <DwelinkUserTable
        isLoading={loading}
        search={deferredSearch}
        users={
          data?.users
            ? data.users.users.map((user) => ({
                user: user,
                role: user.role,
                homes: user.numberOfHomes,
                createdAt: new Date(user.createdAt),
                authServices: user.authServices,
                id: user.id,
              }))
            : []
        }
      />
      {data?.users && (
        <Pagination
          total={data.users.total}
          current={currentPage}
          defaultPageSize={USERS_PER_PAGE}
          onChange={(page) => setCurrentPage(page)}
        />
      )}
    </Box>
  );
}

import { useState } from 'react';
import Select, { ActionMeta, OnChangeValue, StylesConfig } from 'react-select';

export interface Option {
  value: string;
  label: string;
  isFixed?: boolean;
}

interface IMultiSelectProps {
  options: Option[];
  defaultValue?: Option[];
  onChange: (selectedValue: Option[]) => void;
}

const styles: StylesConfig<Option, true> = {
  multiValue: (base, state) => {
    return state.data?.isFixed ? { ...base, backgroundColor: 'gray' } : base;
  },
  multiValueLabel: (base, state) => {
    return state.data?.isFixed ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 } : base;
  },
  multiValueRemove: (base, state) => {
    return state.data?.isFixed ? { ...base, display: 'none' } : base;
  },
};

const orderOptions = (values: readonly Option[]) => {
  return values.filter((v) => v?.isFixed).concat(values.filter((v) => !v?.isFixed));
};

const MultiSelect = ({ options, defaultValue, onChange }: IMultiSelectProps) => {
  const [value, setValue] = useState<readonly Option[]>(orderOptions(defaultValue || []));

  const handleOnChange = (newValue: OnChangeValue<Option, true>, actionMeta: ActionMeta<Option>) => {
    switch (actionMeta.action) {
      case 'remove-value':
      case 'pop-value':
        if (actionMeta.removedValue.isFixed) return;
        break;
      case 'clear':
        newValue = options.filter((v) => v.isFixed);
        break;
    }

    onChange(orderOptions(newValue));
    setValue(orderOptions(newValue));
  };

  const isClearable: boolean = value.some((v) => !v?.isFixed);
  return (
    <Select
      value={value}
      isMulti
      styles={styles}
      isClearable={isClearable}
      name='colors'
      className='basic-multi-select'
      classNamePrefix='select'
      onChange={handleOnChange}
      options={options}
    />
  );
};

export default MultiSelect;

import Homes from '../pages/app/Homes';
import { AppPaths } from './paths';
import { Outlet, RouteObject } from 'react-router-dom';
import { t } from 'i18next';
import HomeDetails from '../pages/app/HomeDetails';
import { getTaskCrumbTitle } from './helpers';
import client from '../gql/apollo/client';
import { TaskDocument } from '../gql/generated';
import EditTask from '../pages/app/EditTask';
import TasksTab from '../components/homeDetails/Tasks';
import DoItRequestDetails from '../pages/app/DoItRequestDetails';
import AccessTokenService from '../services/tokens/AccessTokenService';
import GeneralTab from '../components/homeDetails/generalTab';
import Zones from '../components/homeDetails/Zones';
import InventoryItemsTab from '../components/homeDetails/inventoryItemsTab';
import HomeDoItRequests from '../components/homeDetails/DoItRequests';
import itemDetailsPaths from './itemDetailsPaths';
import zoneDetailsPaths from './zoneDetailsPaths';

export default {
  children: [
    {
      path: AppPaths.HOMES,
      element: <Homes />,
    },
    {
      path: AppPaths.HOME_DETAILS,
      element: <HomeDetails />,
      children: [
        {
          path: AppPaths.HOME_DETAILS_GENERAL,
          element: <GeneralTab />,
          handle: { crumb: () => t('crumb.home_general') },
        },
        {
          path: AppPaths.HOME_DETAILS_ZONES,
          element: <Outlet />,
          handle: { crumb: () => t('crumb.home_zones') },
          children: [
            { path: AppPaths.HOME_DETAILS_ZONES, element: <Zones /> },
            //  ZONE PATHS
            zoneDetailsPaths,
          ],
        },
        {
          path: AppPaths.HOME_DETAILS_ITEMS,
          element: <Outlet />,
          handle: { crumb: () => t('crumb.home_items') },
          children: [
            { path: AppPaths.HOME_DETAILS_ITEMS, element: <InventoryItemsTab /> },
            // ITEM PATHS
            itemDetailsPaths,
          ],
        },
        {
          path: AppPaths.HOME_DETAILS_TASKS,
          element: <Outlet />,
          handle: { crumb: () => t('crumb.home_tasks') },
          children: [
            { path: AppPaths.HOME_DETAILS_TASKS, element: <TasksTab /> },
            {
              path: AppPaths.EDIT_HOME_TASK,
              element: <EditTask />,
              loader: async ({ request }) => {
                try {
                  const isTokenExp = AccessTokenService.isTokenExp();
                  if (isTokenExp) return null;
                  const taskId = request.url.split('tasks/')[1];
                  if (!taskId) return null;
                  const data = await client.query({ query: TaskDocument, variables: { taskId } });
                  return data;
                } catch (error) {
                  return 'N/A';
                }
              },
              handle: { crumb: getTaskCrumbTitle },
            },
          ],
        },
        {
          path: AppPaths.HOME_DETAILS_DO_IT,
          element: <Outlet />,
          handle: { crumb: () => t('crumb.home_do_it') },
          children: [
            { path: AppPaths.HOME_DETAILS_DO_IT, element: <HomeDoItRequests /> },
            {
              path: AppPaths.HOME_DOIT_REQUEST_DETAILS,
              element: <DoItRequestDetails />,
              handle: { crumb: () => t('crumb.doit_request_details') },
            },
          ],
        },
      ],
    },
  ],
  handle: { crumb: () => t('sidebar.homes') },
} as RouteObject;

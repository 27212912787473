import { ApolloClient, useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import AccessTokenService from '../../services/tokens/AccessTokenService';
import RefreshTokenService from '../../services/tokens/RefreshTokenService';
import { useLogoutMutation } from '../../gql/generated';

export const afterLogout = async (client: ApolloClient<object>) => {
  AccessTokenService.clearToken();
  RefreshTokenService.clearToken();
  await client.resetStore();
};

const useLogout = () => {
  const client = useApolloClient();
  const [logoutMutation] = useLogoutMutation({});

  const logout = useCallback(async () => {
    await logoutMutation();
    await afterLogout(client);
  }, [client, logoutMutation]);

  return logout;
};

export default useLogout;

import Resizer from 'react-image-file-resizer';

const MAX_WIDTH = 1280;
const MAX_HEIGHT = 720;
const OUTPUT_FORMAT = 'JPEG';
const QUALITY = 75;
const ROTATION = 0;
const OUTPUT_TYPE = 'file';

export const resizeImage = (file: File) =>
  new Promise<File>((resolve) =>
    Resizer.imageFileResizer(
      file,
      MAX_WIDTH,
      MAX_HEIGHT,
      OUTPUT_FORMAT,
      QUALITY,
      ROTATION,
      (uri) => resolve(uri as File),
      OUTPUT_TYPE,
    ),
  );

export const resizeImages = (files: File[]) => Promise.all(files.map((file) => resizeImage(file)));

import { Flex, Tag, Text } from '@chakra-ui/react';
import { DataTable } from '../../components/common/DataTable';
import { createColumnHelper } from '@tanstack/react-table';
import format from 'date-fns/format';
import { EAuthServiceType, ERole, User } from '../../gql/generated';
import UserInfo from '../../components/users/UserInfo';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import SocialLogins from './../users/SocialLogins';
import { UserActions } from './../users/UserActions';

type DwelinkUserData = {
  user: Pick<User, 'profile' | 'email' | 'isVerified' | 'role' | 'id'>;
  role: ERole;
  homes: number;
  createdAt: Date;
  authServices: EAuthServiceType[];
  id: string;
};

const columnHelper = createColumnHelper<DwelinkUserData>();

type Props = {
  isLoading?: boolean;
  search: string;
  users: DwelinkUserData[];
};

const DwelinkUserTable = ({ users, search, isLoading }: Props) => {
  const { t } = useTranslation();

  const columns = useMemo(
    () => [
      columnHelper.accessor('user', {
        cell: (info) => <UserInfo {...info.getValue()} search={search} />,
        header: t('dwelink_user_table.header.user'),
      }),
      columnHelper.accessor('role', {
        cell: (info) => {
          const role = info.getValue();
          return (
            <Tag key={role} variant='subtle' colorScheme={role === ERole.DWELINK_ADMIN ? 'gray' : 'orange'}>
              {t(`invitation_table.role.${role.toLowerCase()}`).toUpperCase()}
            </Tag>
          );
        },
        header: t('invitation_table.header.role'),
      }),
      columnHelper.accessor('homes', {
        cell: (info) => <Text fontWeight='normal'>{info.getValue()}</Text>,
        header: t('dwelink_user_table.header.homes'),
      }),
      columnHelper.accessor('createdAt', {
        cell: (info) => format(new Date(info.getValue()), 'LLL dd, yyyy'),
        header: t('dwelink_user_table.header.created_at'),
      }),
      columnHelper.accessor('authServices', {
        cell: (info) => <SocialLogins authServices={info.getValue()} />,
        header: t('dwelink_user_table.header.auth_services'),
      }),
      columnHelper.accessor('id', {
        cell: (info) => (
          <Flex justifyContent='flex-end'>
            <UserActions user={info.row.original.user} />
          </Flex>
        ),
        header: '',
      }),
    ],
    [search, t],
  );

  return (
    <DataTable
      columns={columns}
      data={users}
      loading={isLoading}
      boxProps={{ minHeight: 650 }}
      emptyTitle={t('dwelink_user_table.empty.title')}
      emptyDescription={
        search ? t('dwelink_user_table.empty.description.no_result') : t('dwelink_user_table.empty.description')
      }
    />
  );
};

export default DwelinkUserTable;

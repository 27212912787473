import { useMemo } from 'react';
import { Box, Divider, Spacer, Tab, TabIndicator, TabList, TabPanels, Tabs } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import { NavLink, Navigate, generatePath, useOutlet, useParams } from 'react-router-dom';
import { useHomeWithStatsQuery } from '../../gql/generated';
import HomeItem from '../../components/homeDetails/HomeItem';
import LoadingOverlay from '../../components/common/LoadingOverlay';
import { AppPaths } from '../../routes/paths';
import PageError from '../../components/common/PageError';

const HomeDetails = () => {
  const { t } = useTranslation();
  const { id = '' } = useParams();

  const tabs = useMemo(
    () => [
      { label: t('home_details.general'), path: generatePath(AppPaths.HOME_DETAILS_GENERAL, { id }) },
      { label: t('home_details.zones'), path: generatePath(AppPaths.HOME_DETAILS_ZONES, { id }) },
      { label: t('home_details.inventory_items'), path: generatePath(AppPaths.HOME_DETAILS_ITEMS, { id }) },
      { label: t('home_details.tasks'), path: generatePath(AppPaths.HOME_DETAILS_TASKS, { id }) },
      { label: t('home_details.doit_requests'), path: generatePath(AppPaths.HOME_DETAILS_DO_IT, { id }) },
    ],
    [id, t],
  );

  const { data, loading, error } = useHomeWithStatsQuery({
    variables: { homeId: id },
    fetchPolicy: 'cache-and-network',
  });

  const showTabs = useMemo(() => {
    const homeDetailsPath = generatePath(AppPaths.HOME_DETAILS, { id });
    return tabs.findIndex((tab) => location.pathname === tab.path) > -1 || location.pathname === homeDetailsPath;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const outlet = useOutlet();

  const tabActive = useMemo(() => {
    const index = tabs.findIndex((tab) => location.pathname === tab.path);
    return index === -1 ? 0 : index;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  if (loading && isEmpty(data)) return <LoadingOverlay />;
  if (error?.message && isEmpty(data)) return <PageError error={error} />;
  if (!data) return null;

  return (
    <Box>
      <HomeItem item={data} />
      <Spacer height='20px' />
      {!showTabs ? (
        outlet
      ) : (
        <Tabs key={tabActive} isLazy variant='unstyled' defaultIndex={tabActive}>
          <TabList>
            {tabs.map(({ path, label }) => (
              <Tab
                key={path}
                as={NavLink}
                _focus={{ boxShadow: 'none' }}
                to={path}
                fontWeight='500'
                _selected={{ color: 'primary.main' }}
              >
                {label}
              </Tab>
            ))}
          </TabList>
          <TabIndicator height='2px' bg='primary.main' borderRadius='1px' />
          <Divider my='0.5' />
          <TabPanels pt='2'>
            {outlet}
            {!outlet && showTabs && <Navigate to={tabs[0].path} replace />}
          </TabPanels>
        </Tabs>
      )}
    </Box>
  );
};

export default HomeDetails;

import { AppPaths } from './paths';
import { RouteObject } from 'react-router-dom';
import { t } from 'i18next';
import InfoTab from '../components/itemDetails/infoTab';
import MaintenancePlan from '../components/itemDetails/maintenancePlan';
import ItemDetails from '../pages/app/ItemDetails';
import AccessTokenService from '../services/tokens/AccessTokenService';
import { ItemDocument, TaskDocument } from '../gql/generated';
import client from '../gql/apollo/client';
import { getItemCrumbName, getTaskCrumbTitle } from './helpers';
import EditTask from '../pages/app/EditTask';
import CreateTask from '../pages/app/CreateTask';

export default {
  children: [
    {
      path: AppPaths.ITEM_DETAILS,
      element: <ItemDetails />,
      loader: async ({ request }) => {
        try {
          const isTokenExp = AccessTokenService.isTokenExp();
          if (isTokenExp) return null;
          const itemId = request.url.split('items/')[1].split('/')[0];
          if (!itemId) return null;
          const data = await client.query({ query: ItemDocument, variables: { itemId } });
          return data;
        } catch (error) {
          return 'N/A';
        }
      },
      children: [
        {
          path: AppPaths.EDIT_ITEM_TASK,
          element: <EditTask />,
          loader: async ({ request }) => {
            try {
              const isTokenExp = AccessTokenService.isTokenExp();
              if (isTokenExp) return null;
              const taskId = request.url.split('tasks/')[1];
              if (!taskId) return null;
              const data = await client.query({ query: TaskDocument, variables: { taskId } });
              return data;
            } catch (error) {
              return 'N/A';
            }
          },
          handle: { crumb: getTaskCrumbTitle },
        },
        {
          path: AppPaths.CREATE_TASK,
          element: <CreateTask />,
          handle: { crumb: () => t('crumb.create_task') },
        },
        {
          path: AppPaths.ITEM_DETAILS_INFO,
          element: <InfoTab />,
          handle: {
            crumb: () => t('crumb.item_info', { itemName: getItemCrumbName() }),
          },
        },
        {
          path: AppPaths.ITEM_DETAILS_MAINTENANCE_PLAN,
          element: <MaintenancePlan />,
          handle: {
            crumb: () => t('crumb.item_maintenance_plan', { itemName: getItemCrumbName() }),
          },
        },
      ],
    },
  ],
} as RouteObject;

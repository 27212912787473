import { EItemCondition } from '../gql/generated';

const conditionVariants = {
  [EItemCondition.EXCELLENT]: {
    color: 'green.500',
  },
  [EItemCondition.GOOD]: {
    color: 'green.600',
  },
  [EItemCondition.NORMAL]: {
    color: 'yellow.500',
  },
  [EItemCondition.AVERAGE]: {
    color: 'primary.main',
  },
  [EItemCondition.BAD]: {
    color: 'red.500',
  },
  [EItemCondition.VERY_BAD]: {
    color: 'red.600',
  },
};

export default conditionVariants;

import { Flex, Tooltip, WrapItem } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Eye } from '../../icons';
import Button, { EButtonVariant } from '../common/Button';
import { Link } from 'react-router-dom';

interface IHomeActionsProps {
  navigationPath: string;
}

const HomeActions = ({ navigationPath }: IHomeActionsProps) => {
  const { t } = useTranslation();

  return (
    <Flex>
      <Tooltip label={t('home_table.actions.view')}>
        <WrapItem>
          <Link to={navigationPath}>
            <Button styleVariant={EButtonVariant.ghost}>
              <Eye />
            </Button>
          </Link>
        </WrapItem>
      </Tooltip>
    </Flex>
  );
};

export default HomeActions;

import AccessTokenService from '../../services/tokens/AccessTokenService';
import RefreshTokenService from '../../services/tokens/RefreshTokenService';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AuthPaths } from '../../routes/paths';
import { formatGraphQLErrors } from '../../gql/apollo/helpers';
import { useToast } from '@chakra-ui/react';
import { MeDocument, useLoginWithGoogleCodeMutation } from '../../gql/generated';

const useLoginWithGoogle = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();

  return useLoginWithGoogleCodeMutation({
    onError: (e) => {
      toast({
        description: formatGraphQLErrors(e).message,
        status: 'error',
      });
      navigate(AuthPaths.LOGIN);
    },
    onCompleted: (data) => {
      const { accessToken, refreshToken, user } = data.loginWithGoogleCode;
      if (!user) return;

      AccessTokenService.setToken(accessToken);
      RefreshTokenService.setToken(refreshToken);

      const name = user?.profile ? `${user.profile.firstName} ${user.profile.lastName}` : user?.email;
      toast({
        description: t('welcome', { name }),
        status: 'success',
      });
    },

    update: (cache, { data }) => {
      cache.writeQuery({ query: MeDocument, data: { me: data?.loginWithGoogleCode.user } });
    },
  });
};

export default useLoginWithGoogle;

import PasswordFormControl from '../../common/form/PasswordFormControl';
import { Box, ButtonSpinner, Stack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { passwordRegex } from '../../../constants/passwordRequirements';
import { useParams } from 'react-router-dom';
import useRegisterByInvitation from '../../../hooks/auth/useRegisterByInvitation';
import Button from '../../common/Button';

const getSchema = () => {
  const schema = yup.object().shape({
    password: yup.string().label(i18n.t('password')).matches(passwordRegex, i18n.t('password_regex')),
    confirmPassword: yup
      .string()
      .label(i18n.t('confirm_password'))
      .oneOf([yup.ref('password')], i18n.t('passwords_must_match')),
  });
  return schema;
};

export default function AcceptInvitationForm() {
  const { t } = useTranslation();
  const { id } = useParams();
  const [registerByInvitation, { loading }] = useRegisterByInvitation();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(getSchema()),
  });

  const onSubmit = handleSubmit(async (data) => {
    if (!data.password) return;
    await registerByInvitation({
      variables: {
        input: {
          password: data.password,
          inviteId: id!,
        },
      },
    });
  });

  return (
    <Box w='100%'>
      <form onSubmit={onSubmit}>
        <Stack gap={6}>
          <PasswordFormControl
            register={() => register('password')}
            isDisabled={loading}
            isInvalid={!!errors.password}
            errorMessage={errors.password?.message}
          />

          <PasswordFormControl
            label={t('confirm_password')}
            placeholder={t('confirm_password_placeholder')}
            register={() => register('confirmPassword')}
            isDisabled={loading}
            isInvalid={!errors.password && !!errors.confirmPassword}
            errorMessage={errors.confirmPassword?.message}
          />

          <Button type='submit' className='w-100' disabled={loading}>
            {!loading ? t('submit') : <ButtonSpinner />}
          </Button>
        </Stack>
      </form>
    </Box>
  );
}

import { useTranslation } from 'react-i18next';
import { useCallback, useState } from 'react';
import { Box, Divider } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { HomeWithStatsQuery, UpdateHomeInput, useUpdateHomeMutation } from '../../../gql/generated';
import HeaderInfo from './HeaderInfo';
import HomeStats from './HomeStats';
import EditItem, { EFields, IOnSubmitProps } from './EditItem';

interface IHomeInfoEditorProps {
  home?: HomeWithStatsQuery['home'];
  homeStats?: HomeWithStatsQuery['homeStats'];
}

const HomeInfoEditor = ({ home, homeStats }: IHomeInfoEditorProps) => {
  const { t } = useTranslation();

  const [showSquareFootageInput, setShowSquareFootageInput] = useState<boolean>(false);
  const [showYearBuiltInput, setShowYearBuiltInput] = useState<boolean>(false);

  const [updateHome] = useUpdateHomeMutation();

  const handleUpdateField = useCallback(
    async (fields: IOnSubmitProps) => {
      if (!home?.id) return;
      if (isEmpty(fields)) return;
      const input: UpdateHomeInput = { id: home.id };
      if (fields.squareFootage) input.squareFootage = fields.squareFootage;
      if (fields.yearBuilt) input.yearBuilt = fields.yearBuilt;
      await updateHome({
        variables: { input },
        onCompleted: () => {
          if (fields.squareFootage) setShowSquareFootageInput(false);
          if (fields.yearBuilt) setShowYearBuiltInput(false);
        },
      });
    },
    [home?.id, updateHome],
  );

  return (
    <>
      <HeaderInfo home={home} />
      <Box px='2' borderWidth='1px' mt='3' borderRadius='lg'>
        <EditItem
          toggle={() => setShowSquareFootageInput((prev) => !prev)}
          isOpen={showSquareFootageInput}
          onOpen={() => setShowSquareFootageInput(true)}
          onClose={() => setShowSquareFootageInput(false)}
          min={500}
          max={50000}
          name={EFields.squareFootage}
          defaultValue={home?.squareFootage || 0}
          onSubmit={handleUpdateField}
          title={t('home_details.square_footage')}
        />
        <Divider />
        <EditItem
          min={1800}
          max={new Date().getFullYear()}
          toggle={() => setShowYearBuiltInput((prev) => !prev)}
          isOpen={showYearBuiltInput}
          onOpen={() => setShowYearBuiltInput(true)}
          onClose={() => setShowYearBuiltInput(false)}
          name={EFields.yearBuilt}
          defaultValue={home?.yearBuilt || 0}
          onSubmit={handleUpdateField}
          title={t('home_details.year_built')}
        />
        <Divider />
        <HomeStats homeStats={homeStats} />
      </Box>
    </>
  );
};

export default HomeInfoEditor;

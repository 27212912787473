import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import {
  Box,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Button as CButton,
  Text,
  useToast,
} from '@chakra-ui/react';
import i18n from 'i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { EInviteUserResponseCode, ERole, useInviteUserMutation } from '../../gql/generated';
import Button from '../common/Button';
import { useCallback } from 'react';

const getSchema = () =>
  yup.object().shape({
    email: yup.string().max(50).required(i18n.t('form_errors.field_required_error_message')),
    firstName: yup.string().max(32).required(i18n.t('form_errors.field_required_error_message')),
    lastName: yup.string().max(32).required(i18n.t('form_errors.field_required_error_message')),
    // mixed<ERole>().oneOf(Object.keys(ERole))
    role: yup.string().required(i18n.t('form_errors.field_required_error_message')),
  });

interface IInvitationUserModal {
  isOpen: boolean;
  refetch?: () => void;
  onClose: () => void;
}

const getDescriptionByCode = (code: EInviteUserResponseCode) => {
  let message = 'invitation.created_success_message';
  if (code === EInviteUserResponseCode.CHANGED_ROLE_OF_EXISTING_USER)
    message = 'invitation.changed_role_of_existing_user_success_message';
  if (code === EInviteUserResponseCode.UPDATED_EXPIRATION_DATE)
    message = 'invitation.updated_expiration_date_success_message';
  return i18n.t(message);
};

const InvitationUserModal = ({ isOpen, refetch, onClose }: IInvitationUserModal) => {
  const { t } = useTranslation();
  const toast = useToast();
  const [inviteUser, { loading }] = useInviteUserMutation({
    onError: (error) => toast({ description: error.message, status: 'error' }),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(getSchema()),
  });

  const handleClose = useCallback(() => {
    reset();
    onClose();
  }, [onClose, reset]);

  const onSubmit = handleSubmit(async (data) => {
    const role = data.role as ERole;
    await inviteUser({
      variables: {
        input: { email: data.email, role, profile: { firstName: data.firstName, lastName: data.lastName } },
      },
      onCompleted: (data) => {
        const description = getDescriptionByCode(data.inviteUser.code);
        toast({
          description,
          status: 'success',
        });
        handleClose();
        refetch?.();
      },
    });
  });

  return (
    <Modal closeOnOverlayClick={false} motionPreset='slideInBottom' isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay bg='blackAlpha.300' />
      <ModalContent>
        <ModalHeader>{t('invitation.invitation_modal_title')}</ModalHeader>
        <ModalCloseButton />
        <Divider />
        <form onSubmit={onSubmit}>
          <ModalBody>
            <FormControl isRequired paddingBottom={5} isInvalid={!!errors.email}>
              <FormLabel>{t('email')}</FormLabel>
              <Input
                {...register('email')}
                autoFocus
                type='email'
                placeholder={t('email_placeholder')}
                focusBorderColor='primary.main'
              />
              <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired>
              <FormLabel>{t('user_profile')}</FormLabel>
              <FormControl isRequired paddingBottom={3} isInvalid={!!errors.firstName}>
                <Input
                  type='text'
                  placeholder={t('first_name_placeholder')}
                  {...register('firstName')}
                  focusBorderColor='primary.main'
                />
                <FormErrorMessage>{errors.firstName?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isRequired paddingBottom={3} isInvalid={!!errors.lastName}>
                <Input
                  type='text'
                  {...register('lastName')}
                  placeholder={t('last_name_placeholder')}
                  focusBorderColor='primary.main'
                />
                <FormErrorMessage>{errors.lastName?.message}</FormErrorMessage>
              </FormControl>
            </FormControl>
            <FormControl isRequired isInvalid={!!errors.role}>
              <FormLabel>{t('role')}</FormLabel>
              <Box cursor='pointer' display='flex'>
                <input type='radio' {...register('role')} value={ERole.DWELINK_ADMIN} />
                <Text cursor='pointer' onClick={() => setValue('role', ERole.DWELINK_ADMIN)} paddingLeft={2}>
                  {t('dwelink_admin')}
                </Text>
              </Box>
              <Box cursor='pointer' display='flex'>
                <input type='radio' {...register('role')} value={ERole.DWELINK_HOMEMATE} color='red' />
                <Text cursor='pointer' onClick={() => setValue('role', ERole.DWELINK_HOMEMATE)} paddingLeft={2}>
                  {t('dwelink_home_mate')}
                </Text>
              </Box>
              <FormErrorMessage>{errors.role?.message}</FormErrorMessage>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <CButton onClick={handleClose} marginRight={3}>
              {t('close')}
            </CButton>
            <Button disabled={loading} isLoading={loading} rightIcon={<IconCarbonEmail />} type='submit'>
              {t('send')}
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default InvitationUserModal;

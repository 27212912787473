import isEmpty from 'lodash/isEmpty';
import { useArchiveItemMutation, useItemQuery } from '../../../gql/generated';
import LoadingOverlay from '../../common/LoadingOverlay';
import { Card, CardBody, SimpleGrid, useDisclosure, useToast } from '@chakra-ui/react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { Flex } from '@chakra-ui/react';
import EditItemForm, { IForwardRef } from './EditItemForm';
import Button, { EButtonVariant } from '../../common/Button';
import { useTranslation } from 'react-i18next';
import { createRef, useCallback, useState } from 'react';
import { Plus, Trash } from '../../../icons';
import { AppPaths } from '../../../routes/paths';
import ItemImages from '../images/ItemImages';
import { formatGraphQLErrors } from '../../../gql/apollo/helpers';
import ConfirmationModal from '../../common/ConfirmationModal';
import PageError from '../../common/PageError';

const InfoTab = () => {
  const { id, zoneId = '' } = useParams();
  const editFormRef = createRef<IForwardRef>();
  const toast = useToast();
  const navigate = useNavigate();
  const [loadingMutation, setLoadingMutation] = useState(false);
  const { itemId = '' } = useParams();
  const { data, loading, error } = useItemQuery({
    variables: { itemId },
    skip: !itemId,
    fetchPolicy: 'cache-and-network',
  });

  const {
    isOpen: isOpenConfirmationModal,
    onOpen: onOpenConfirmationModal,
    onClose: onCloseConfirmationModal,
  } = useDisclosure();

  const [archiveItem, { loading: archiveItemLoading }] = useArchiveItemMutation({
    variables: { itemId },
    onError: (e) => toast({ description: formatGraphQLErrors(e).message, status: 'error' }),
    onCompleted: () => {
      toast({
        description: t('home_details.inventory_item_details.success_archive_item_message'),
        status: 'success',
      });
      navigate(generatePath(AppPaths.HOME_DETAILS, { id: data?.item.homeId || '' }));
    },
  });

  const handleSave = useCallback(async () => {
    setLoadingMutation(true);
    await editFormRef.current?.onSubmitForm?.();
    setLoadingMutation(false);
  }, [editFormRef]);

  const handleArchiveItem = useCallback(() => {
    editFormRef.current?.reset();
    archiveItem();
  }, [archiveItem, editFormRef]);

  const handleAddTask = useCallback(() => {
    const path = zoneId
      ? generatePath(AppPaths.ZONE_ITEM_CREATE_TASK, { id: id!, zoneId, itemId })
      : generatePath(AppPaths.CREATE_TASK, { id: id!, itemId });
    navigate(path);
  }, [id, itemId, navigate, zoneId]);

  const { t } = useTranslation();

  if (error?.message && isEmpty(data)) return <PageError error={error} />;

  if (loading && isEmpty(data)) return <LoadingOverlay />;
  return (
    <>
      <ConfirmationModal
        loading={archiveItemLoading}
        onSubmit={handleArchiveItem}
        onClose={onCloseConfirmationModal}
        isOpen={isOpenConfirmationModal}
        title={t('home_details.inventory_item_details.confirm_modal_archive_item_message_title', {
          name: data?.item.name,
        })}
        description={t('home_details.inventory_item_details.confirm_modal_archive_item_message_description')}
        submitButtonProps={{ title: t('archive'), styleVariant: EButtonVariant.red }}
      />
      <Flex alignItems='flex-end' pb='2' gap={2} justifyContent='end' flex={1}>
        <Button
          isLoading={archiveItemLoading}
          onClick={onOpenConfirmationModal}
          styleVariant={EButtonVariant.deleteButton}
          rightIcon={<Trash fill='red.500' />}
        >
          {t('archive')}
        </Button>
        <Button
          onClick={handleSave}
          isLoading={loadingMutation}
          rightIcon={<IconCarbonSave />}
          color='primary.main'
          styleVariant={EButtonVariant.outline}
        >
          {t('save')}
        </Button>
        <Button
          variant='solid'
          rightIcon={<Plus fill='white' boxSize='3' stroke='white' strokeWidth={2} />}
          width='fit-content'
          onClick={handleAddTask}
        >
          {t('home_details.add_task_button')}
        </Button>
      </Flex>
      <SimpleGrid
        gap={4}
        columns={{
          base: 1,
          lg: 2,
        }}
      >
        <Card>
          <CardBody p={4}>
            <EditItemForm ref={editFormRef} item={data?.item} />
          </CardBody>
        </Card>
        <ItemImages itemId={itemId} />
      </SimpleGrid>
    </>
  );
};

export default InfoTab;

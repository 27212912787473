import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberInput,
  NumberInputField,
  useToast,
} from '@chakra-ui/react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { User, useSetProfileMutation } from '../../gql/generated';
import parsePhoneNumber from 'libphonenumber-js';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Button, { EButtonVariant } from '../common/Button';
import { formatGraphQLErrors } from '../../gql/apollo/helpers';
import { useCallback } from 'react';
import i18next from 'i18next';

interface IChangeNameModalProps {
  isOpen: boolean;
  onClose: () => void;
  user: Pick<User, 'profile' | 'email' | 'isVerified' | 'role' | 'id'>;
}

const getSchema = () =>
  yup.object().shape({
    phone: yup
      .string()
      .test(
        i18next.t('change_phone_number_modal.invalid_phone_number'),
        i18next.t('change_phone_number_modal.invalid_phone_number'),
        (_value, testContext) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          if (!testContext.parent.phone) return true;
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
          const formattedNumber = parsePhoneNumber(testContext.parent.phone!, testContext.parent.countryCode);
          return formattedNumber?.isValid();
        },
      )
      .required(),
  });

const ChangePhoneNumberModal = ({ isOpen, onClose, user }: IChangeNameModalProps) => {
  const { t } = useTranslation();

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(getSchema()),
    defaultValues: { phone: user.profile?.phone || '' },
  });

  const toast = useToast();

  const handleOnClose = useCallback(() => {
    reset();
    onClose();
  }, [onClose, reset]);

  const [setUserProfile, { loading }] = useSetProfileMutation({
    onError: (error) => toast({ description: formatGraphQLErrors(error).message, status: 'error' }),
  });

  const onSubmit = handleSubmit((data) => {
    setUserProfile({
      variables: {
        input: { lastName: user.profile?.lastName || '', firstName: user.profile?.firstName || '', phone: data.phone },
      },
      onCompleted: () => {
        handleOnClose();
        reset({ phone: data.phone });
        toast({ description: t('change_phone_number_modal.success_message'), status: 'success' });
      },
    });
  });
  return (
    <Modal isOpen={isOpen} onClose={handleOnClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('change_phone_number_modal.title')}</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={onSubmit}>
          <ModalBody>
            <FormControl isInvalid={!!errors.phone}>
              <FormLabel>{t('change_phone_number_modal.phone_label')}</FormLabel>
              <NumberInput w='100%' focusBorderColor='primary.main'>
                <NumberInputField
                  {...register('phone')}
                  placeholder={t('change_phone_number_modal.phone_placeholder')}
                />
              </NumberInput>
              <FormErrorMessage>{errors.phone?.message}</FormErrorMessage>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button mr={2} styleVariant={EButtonVariant.gray} onClick={handleOnClose} variant='solid'>
              {t('cancel')}
            </Button>
            <Button isDisabled={!isValid} isLoading={loading} type='submit' styleVariant={EButtonVariant.default}>
              {t('save')}
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default ChangePhoneNumberModal;

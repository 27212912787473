import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { DataTable } from '../common/DataTable';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { ERole, User } from '../../gql/generated';
import UserInfo from '../users/UserInfo';
import { Tag } from '@chakra-ui/react';
import { InvitationActions } from './InvitationActions';

export type InvitationData = {
  email: string;
  invitee: Pick<User, 'email' | 'profile'>;
  role: ERole;
  inviter: Pick<User, 'id' | 'email' | 'profile' | 'isVerified'>;
  expirationDate: string;
  id: string;
};

const columnHelper = createColumnHelper<InvitationData>();

type Props = {
  isLoading?: boolean;
  search: string;
  invitations: InvitationData[];
  refetch?: () => void;
};

export default function InvitationTable({ invitations, isLoading, search, refetch }: Props) {
  const { t } = useTranslation();

  const columns = useMemo(
    () => [
      columnHelper.accessor('invitee', {
        cell: (info) => {
          return <UserInfo {...info.getValue()} id='null' isVerified search={search} />;
        },
        header: t('invitation_table.header.invitee'),
      }),
      columnHelper.accessor('role', {
        cell: (info) => {
          const role = info.getValue();
          return (
            <Tag key={role} variant='subtle' colorScheme={role === ERole.DWELINK_ADMIN ? 'gray' : 'orange'}>
              {t(`invitation_table.role.${role.toLowerCase()}`).toUpperCase()}
            </Tag>
          );
        },
        header: t('invitation_table.header.role'),
      }),
      columnHelper.accessor('inviter', {
        cell: (info) => {
          return <UserInfo {...info.getValue()} />;
        },
        header: t('invitation_table.header.inviter'),
      }),
      columnHelper.accessor('expirationDate', {
        cell: (info) => format(new Date(info.getValue()), 'MMMM d, yyyy h:mm a'),
        header: t('invitation_table.header.expiration_date'),
      }),
      columnHelper.accessor('id', {
        cell: (info) => <InvitationActions invitation={info.row.original} refetch={refetch} />,
        header: '',
      }),
    ],
    [search, refetch, t],
  );

  return (
    <DataTable
      columns={columns}
      data={invitations}
      boxProps={{ minHeight: 640 }}
      loading={isLoading}
      emptyTitle={t('invitation_table.empty.title')}
      emptyDescription={t('invitation_table.empty.description')}
    />
  );
}

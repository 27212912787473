import { Flex, IconButton, Menu, MenuButton, MenuItem, MenuList, useToast } from '@chakra-ui/react';
import { MenuDots } from '../../icons';
import { InvitationData } from './InvitationTable';
import { useDeleteInvitationMutation, useInviteUserMutation } from '../../gql/generated';
import { useTranslation } from 'react-i18next';
import { formatGraphQLErrors } from '../../gql/apollo/helpers';
import { ApolloError } from '@apollo/client';

type Props = {
  invitation: InvitationData;
  refetch?: () => void;
};

export function InvitationActions({ invitation, refetch }: Props) {
  const { t } = useTranslation();
  const toast = useToast();

  const handleError = (e: ApolloError) => {
    toast({
      description: formatGraphQLErrors(e).message,
      status: 'error',
    });
  };

  const [inviteUser] = useInviteUserMutation({
    variables: {
      input: {
        email: invitation.email,
        role: invitation.role,
        profile: {
          firstName: invitation.invitee.profile!.firstName,
          lastName: invitation.invitee.profile!.lastName,
        },
      },
    },
    onError: handleError,
    onCompleted: () => {
      refetch?.();
      toast({
        description: t('invitation_table.toast.resent_email'),
        status: 'success',
      });
    },
  });

  const [cancelInvitation] = useDeleteInvitationMutation({
    variables: {
      invitationId: invitation.id,
    },
    onError: handleError,
    onCompleted: () => {
      refetch?.();
      toast({
        description: t('invitation_table.toast.cancel_invitation'),
        status: 'success',
      });
    },
  });

  return (
    <Flex justifyContent='flex-end'>
      <Menu>
        <MenuButton as={IconButton} variant='ghost' transform='rotate(90deg)' icon={<MenuDots fill='gray.400' />} />
        <MenuList>
          <MenuItem onClick={() => inviteUser()}>{t('invitation_table.actions.resend')}</MenuItem>
          <MenuItem color='red.500' onClick={() => cancelInvitation()}>
            {t('invitation_table.actions.cancel')}
          </MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  );
}

import { useItemImagesQuery, useSetMainItemImageMutation } from '../../../gql/generated';
import { Card, CardBody, Flex, Spinner, Text, useDisclosure, useToast } from '@chakra-ui/react';
import Gallery from '../../common/images/Gallery';
import useItemImagesUploader from '../../../hooks/images/useItemImagesUploader';
import { useTranslation } from 'react-i18next';
import AddImagesButton from '../../common/images/AddImagesButton';
import DropZone from '../../common/images/DropZone';
import DeleteItemImageModal from './DeleteItemImageModal';
import { useCallback, useMemo, useState } from 'react';
import { ApolloError } from '@apollo/client';
import { formatGraphQLErrors } from '../../../gql/apollo/helpers';

type Props = {
  itemId: string;
};

const ItemImages = ({ itemId }: Props) => {
  const toast = useToast();
  const { t } = useTranslation();
  const [fileId, setFileId] = useState('');
  const { isOpen, onClose, onOpen } = useDisclosure();

  const { data, loading } = useItemImagesQuery({ variables: { itemId }, fetchPolicy: 'cache-and-network' });
  const { uploadingImages, upload, clear } = useItemImagesUploader({ itemId });
  const [setMainImage] = useSetMainItemImageMutation({
    onError: (e) => {
      toast({
        description: formatGraphQLErrors(e).message,
        status: 'error',
      });
    },
  });

  const handleDropAccepted = useCallback(
    (files: File[]) => {
      if (!uploadingImages.length) {
        upload(files).finally(clear);
      } else {
        toast({ description: t('wait_previous_uploading'), status: 'warning' });
      }
    },
    [clear, t, toast, upload, uploadingImages],
  );

  const handleDelete = useCallback(
    (fileId: string) => {
      setFileId(fileId);
      onOpen();
    },
    [onOpen],
  );

  const handleSetMain = useCallback(
    async (fileId: string) => {
      await setMainImage({ variables: { input: { parentId: itemId, fileId } } });
    },
    [itemId, setMainImage],
  );

  const onError = useCallback(
    (e: ApolloError) => toast({ description: formatGraphQLErrors(e).message, status: 'error' }),
    [toast],
  );

  const handleModalError = useCallback(
    (e: ApolloError) => {
      onError(e);
      onClose();
    },
    [onClose, onError],
  );

  const isAddButtonDisabled = useMemo(() => loading || uploadingImages.length > 0, [loading, uploadingImages]);

  return (
    <Card h='min-content'>
      <CardBody p={4}>
        <DropZone onDropAccepted={handleDropAccepted}>
          {({ open }) => (
            <>
              {loading ? (
                <Flex py='20' flexDirection='column' alignItems='center' gap={6}>
                  <Spinner size='xl' color='orange' />
                </Flex>
              ) : data?.itemImages.length || uploadingImages.length ? (
                <Gallery
                  uploadingImages={uploadingImages}
                  images={data?.itemImages || []}
                  deleteImage={handleDelete}
                  setMainImage={handleSetMain}
                  AddButton={<AddImagesButton onClick={open} isDisabled={isAddButtonDisabled} />}
                  showCarrousel
                />
              ) : (
                <Flex py='20' flexDirection='column' alignItems='center' gap={6}>
                  <AddImagesButton boxSize='120px' onClick={open} isDisabled={isAddButtonDisabled} />
                  <Text color='darkgray' fontSize='lg' mx='4' textAlign='center'>
                    {t('inventory_item.images.no_images')}
                  </Text>
                </Flex>
              )}
            </>
          )}
        </DropZone>
        <DeleteItemImageModal
          itemId={itemId}
          fileId={fileId}
          isOpen={isOpen}
          onClose={onClose}
          onError={handleModalError}
        />
      </CardBody>
    </Card>
  );
};

export default ItemImages;

import {
  Box,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react';
import pluralize from 'pluralize';
import {
  HomeWithStatsDocument,
  Zone,
  ZoneTemplate,
  ZonesCompressedDocument,
  ZonesQuery,
  useUpdateZonesMutation,
  useZoneTemplatesQuery,
  useZonesCompressedQuery,
} from '../../../gql/generated';
import { useEffect, useMemo, useState } from 'react';
import { countBy, isEmpty, uniqueId } from 'lodash';
import { formatGraphQLErrors } from '../../../gql/apollo/helpers';
import { ApolloError, ApolloQueryResult } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Check } from '../../../icons';
import Button, { EButtonVariant } from '../../common/Button';

type Props = {
  homeId: string;
  isOpen: boolean;
  onClose: () => void;
  updateCb: () => Promise<ApolloQueryResult<ZonesQuery>>;
};

const getAvailableTemplates = (templates: ZoneTemplate[], zones: Pick<Zone, 'id' | 'template'>[]) => {
  const templateFrequency = countBy(zones, 'template.id');
  return templates
    .filter((template) => {
      const frequency = templateFrequency[template.id];
      return !frequency || frequency < template.maxQuantity;
    })
    .map((template) => template.id);
};

export default function AddZoneModal({ updateCb, homeId, isOpen, onClose }: Props) {
  const { t } = useTranslation();
  const [newZones, setNewZones] = useState<Pick<Zone, 'id' | 'name' | 'template'>[]>([]);
  const [currentZones, setCurrentZones] = useState<Pick<Zone, 'id' | 'name' | 'template'>[]>([]);
  const toast = useToast();
  const onError = (error: ApolloError) => {
    toast({
      description: formatGraphQLErrors(error).message,
      status: 'error',
    });
  };

  const { data, loading: templateLoading } = useZoneTemplatesQuery({
    skip: !isOpen,
    onError,
    fetchPolicy: 'cache-and-network',
  });
  const {
    data: dataZonesCompressed,
    loading: zonesLoading,
    fetchMore,
  } = useZonesCompressedQuery({
    variables: { homeId, limit: 100, isArchivedZone: false },
    onCompleted: (data) => setCurrentZones(data.zonesCompressed.zones),
    onError,
    skip: !isOpen,
    fetchPolicy: 'cache-and-network',
  });

  const [updateZones, { loading }] = useUpdateZonesMutation({
    onCompleted: () => {
      toast({
        description: 'Zones updated',
        status: 'success',
      });
    },
    onError,
  });

  useEffect(() => {
    if (isOpen) {
      setNewZones([]);
    }
  }, [isOpen]);

  useEffect(() => {
    if (dataZonesCompressed?.zonesCompressed.hasMore) {
      fetchMore({
        variables: { offset: dataZonesCompressed.zonesCompressed.zones.length },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return {
            zonesCompressed: {
              ...prev.zonesCompressed,
              zones: [...prev.zonesCompressed.zones, ...fetchMoreResult.zonesCompressed.zones],
              hasMore: fetchMoreResult.zonesCompressed.hasMore,
            },
          };
        },
      });
    }
  }, [dataZonesCompressed, fetchMore]);

  const handleSubmit = async () => {
    await updateZones({
      variables: {
        input: {
          homeId,
          zoneTemplatesForAdd: newZones.map(({ name, template }) => ({
            name,
            zoneTemplateId: template.id,
          })),
        },
      },
      onCompleted: async () => {
        await updateCb();
      },
      refetchQueries: [{ query: HomeWithStatsDocument, variables: { homeId } }, ZonesCompressedDocument],
    });
    onClose();
  };

  const mergedZones = useMemo(() => [...newZones, ...currentZones], [currentZones, newZones]);
  const availableTemplates = useMemo(
    () => getAvailableTemplates(data?.zoneTemplates || [], mergedZones),
    [data?.zoneTemplates, mergedZones],
  );

  const addZone = (templateId: string) => {
    const template = data?.zoneTemplates.find((template) => template.id === templateId);
    if (!template) return;
    let name = template.name;
    if (template.maxQuantity > 1) {
      const frequency = countBy(mergedZones, 'template.id')[template.id];
      name += ` #${(frequency || 0) + 1}`;
    }

    setNewZones([...newZones, { id: uniqueId(template.id), name, template }]);
  };

  const removeZone = (id: string) => setNewZones(newZones.filter((zone) => zone.id !== id));

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='lg' closeOnOverlayClick={false} scrollBehavior='inside'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('add_zone_modal.title')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {isEmpty(mergedZones) && (templateLoading || zonesLoading) ? (
            <Flex h='25vh' alignItems='center' justifyContent='center'>
              <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='primary.main' size='xl' />
            </Flex>
          ) : (
            <VStack gap='20px'>
              <Box width='100%'>
                <Text fontSize='md' fontWeight='medium'>
                  {t('add_zone_modal.select_zone')}
                </Text>
                <HStack flexWrap='wrap' mt='2'>
                  {data?.zoneTemplates.map(({ id, name }) => {
                    const isAvailable = availableTemplates.includes(id);
                    return (
                      <Tag
                        key={id}
                        size='lg'
                        variant='subtle'
                        colorScheme={isAvailable ? 'blue' : 'gray'}
                        borderRadius='16'
                        cursor={isAvailable ? 'pointer' : 'not-allowed'}
                        _hover={isAvailable ? { bg: 'blue.500', color: 'white' } : {}}
                        onClick={() => (isAvailable ? addZone(id) : {})}
                      >
                        <TagLabel userSelect='none'>{name}</TagLabel>
                      </Tag>
                    );
                  })}
                </HStack>
              </Box>
              <Box width='100%'>
                <Text fontSize='md' fontWeight='medium'>
                  {t(pluralize('add_zone_modal.added_zone', mergedZones.length), { count: mergedZones.length })}
                </Text>
                <HStack flexWrap='wrap' mt='2'>
                  {mergedZones.map(({ id, name }) => (
                    <Tag key={id} size='lg' variant='outline' colorScheme='gray' borderRadius='16'>
                      <TagLabel>{name}</TagLabel>
                      {newZones.find((zone) => zone.id === id) && <TagCloseButton onClick={() => removeZone(id)} />}
                    </Tag>
                  ))}
                </HStack>
              </Box>
            </VStack>
          )}
        </ModalBody>
        <ModalFooter>
          <Button styleVariant={EButtonVariant.gray} onClick={onClose}>
            {t('add_zone_modal.close')}
          </Button>
          <Button
            ml={3}
            colorScheme='orange'
            isLoading={loading}
            isDisabled={templateLoading || zonesLoading}
            onClick={handleSubmit}
            rightIcon={<Check fill='white' boxSize='3' stroke='white' strokeWidth={2} />}
          >
            {t('add_zone_modal.submit')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

import * as yup from 'yup';
import i18n from 'i18next';
import { useForm } from 'react-hook-form';
import validator from 'validator';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  ButtonSpinner,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { formatGraphQLErrors } from '../../../gql/apollo/helpers';
import { useSendResetPasswordCodeMutation } from '../../../gql/generated';
import Button from '../../common/Button';

type Props = {
  email?: string;
  onChange?: (email: string) => Promise<void> | void;
  onSuccess?: (email: string) => Promise<void> | void;
  onLoading?: (loading: boolean) => Promise<void> | void;
  hideInput?: boolean;
};

const getSchema = () => {
  const schema = yup.object().shape({
    email: yup
      .string()
      .label(i18n.t('email'))
      .test(i18n.t('email_input_error'), i18n.t('email_input_error'), (value) => validator.isEmail(value || ''))
      .required(),
  });
  return schema;
};

export default function ForgotForm({ email, onChange, onLoading, onSuccess, hideInput }: Props) {
  const { t } = useTranslation();
  const toast = useToast();

  const [sendResetPasswordCode, { loading }] = useSendResetPasswordCodeMutation({
    onError: (e) => {
      toast({
        description: formatGraphQLErrors(e).message,
        status: 'error',
      });
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: email || '',
    },
    resolver: yupResolver(getSchema()),
  });

  const onSubmit = handleSubmit(async (data) => {
    await sendResetPasswordCode({
      variables: {
        ...data,
      },
      onCompleted: () => {
        void onSuccess?.(data.email);
      },
    });
  });

  useEffect(() => {
    void onLoading?.(loading);
  }, [onLoading, loading]);

  const input = register('email');

  return (
    <>
      <Box w='100%'>
        <form onSubmit={onSubmit}>
          <Flex direction='column' gap={6}>
            {hideInput ? (
              <Text textAlign='center' color='gray.700' fontSize='18'>
                {t('send_confirmation_code_modal_description')}
              </Text>
            ) : (
              <FormControl isInvalid={!!errors.email}>
                <FormLabel>{t('email')}</FormLabel>
                <Input
                  autoFocus
                  type='email'
                  placeholder={t('email_placeholder')}
                  disabled={loading}
                  {...input}
                  onChange={async (e) => {
                    await input.onChange(e);
                    await onChange?.(e.target.value);
                  }}
                  focusBorderColor='primary.main'
                />
                <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
              </FormControl>
            )}
            <Button type='submit' w='100%' isDisabled={loading}>
              {!loading ? t(hideInput ? 'send_confirmation_code' : 'submit') : <ButtonSpinner />}
            </Button>
          </Flex>
        </form>
      </Box>
    </>
  );
}

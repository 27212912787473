import { SyntheticEvent, createRef, useCallback, useMemo } from 'react';
import RDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import isArray from 'lodash/isArray';
import { Box, BoxProps, InputGroup, InputRightAddon, useDisclosure } from '@chakra-ui/react';
import TextInput from './TextInput';
import { formatSlashDate } from '../../utils/date';
import { Cross } from '../../icons';

interface IDatePickerProps<CustomModifierNames extends string | never, WithRange extends boolean | undefined>
  extends ReactDatePickerProps<CustomModifierNames, WithRange> {
  datePickerWrapperProps?: BoxProps;
  pickerPosition?: 'bottom' | 'top';
}

function DatePicker<CustomModifierNames extends string | never, WithRange extends boolean | undefined>({
  pickerPosition = 'bottom',
  ...props
}: IDatePickerProps<CustomModifierNames, WithRange>) {
  const inputRef = createRef<HTMLInputElement | undefined>();

  const placeholder = props.placeholderText || 'Select date';
  const isDisabled = props.disabled;
  const { isOpen, onClose, onToggle } = useDisclosure();
  const onChange = useCallback(
    (
      date: WithRange extends false | undefined ? Date | null : [Date | null, Date | null],
      event: SyntheticEvent<unknown, Event> | undefined,
    ) => {
      props.onChange(date, event);
      if (props.selectsRange && isArray(date)) {
        const [start, end] = date;
        if (start && end) onClose();
      } else onClose();
    },
    [onClose, props],
  );

  const onClear = useCallback(
    (event: SyntheticEvent<unknown, Event> | undefined) => {
      if (isDisabled) return;
      const newValue = (props.selectsRange ? [null, null] : null) as WithRange extends false | undefined
        ? Date | null
        : [Date | null, Date | null];

      props.onChange(newValue, event);
    },
    [isDisabled, props],
  );

  const handleOnClickOutside = useCallback(() => setTimeout(onClose, 100), [onClose]);

  const value = useMemo(() => {
    let result = undefined;
    if (props.selectsRange && props.startDate) {
      result = `${formatSlashDate(props.startDate)} - ${props.endDate ? formatSlashDate(props.endDate) : ''}`;
    } else if (props.selected) result = props.selected ? formatSlashDate(props.selected) : '';
    return result;
  }, [props.endDate, props.selected, props.selectsRange, props.startDate]);

  const DatePicker = isOpen ? (
    <Box
      {...props.datePickerWrapperProps}
      position='absolute'
      right={pickerPosition === 'top' ? '0' : undefined}
      bottom={pickerPosition === 'top' ? '0' : undefined}
      zIndex='1'
    >
      <RDatePicker
        {...props}
        disabledKeyboardNavigation
        onChange={onChange}
        showIcon
        inline
        onClickOutside={handleOnClickOutside}
      />
    </Box>
  ) : null;

  return (
    <>
      {pickerPosition === 'top' && DatePicker}
      <InputGroup flex={1} width='100%'>
        <TextInput
          ref={inputRef}
          bg='white'
          borderTopEndRadius='0'
          borderBottomEndRadius='0'
          contentEditable={false}
          containerProps={{ width: '100%' }}
          key={value}
          isDisabled={props.disabled}
          focusBorderColor='primary.main'
          defaultValue={value}
          placeholder={placeholder}
          onClick={onToggle}
          type='text'
        />
        <InputRightAddon
          children={
            <Box cursor={isDisabled ? 'not-allowed' : 'pointer'}>
              {value ? (
                <Cross onClick={onClear} width='1.2em' />
              ) : (
                <IconCarbonCalendar
                  width='1.2em'
                  onClick={() => {
                    if (isDisabled) return;
                    onToggle();
                    if (inputRef?.current) inputRef?.current?.focus();
                  }}
                />
              )}
            </Box>
          }
        />
      </InputGroup>
      {pickerPosition === 'bottom' && DatePicker}
    </>
  );
}

export default DatePicker;

import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthPaths } from '../../routes/paths';
import AuthCard from '../../components/auth/AuthCard';
import SetPasswordSteps from '../../components/auth/SetPasswordSteps';

type Props = {
  isForgot?: boolean;
};

export default function SetPassword({ isForgot }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as { email?: string } | null;

  return (
    <AuthCard title={isForgot ? t('forgot_password_header') : t('set_password_header')}>
      <SetPasswordSteps
        cancelButtonTitle={t('back_to_login')}
        onCancel={(email) => navigate(AuthPaths.LOGIN, { state: { email } })}
        initialEmail={state?.email || ''}
      />
    </AuthCard>
  );
}

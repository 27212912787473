import { Box, Flex, Input, InputGroup, InputLeftElement, useDisclosure } from '@chakra-ui/react';
import InvitationUserModal from '../invitation/InvitationUserModal';
import { Search, UserAdd } from '../../icons';
import { useTranslation } from 'react-i18next';
import Button from '../common/Button';

type Props = {
  search: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  refetch?: () => void;
};

export default function Header({ search, onChange, refetch }: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();

  return (
    <Flex>
      <InputGroup mb='4'>
        <InputLeftElement pointerEvents='none'>
          <Search />
        </InputLeftElement>
        <Input
          placeholder={t('search')}
          background='white'
          maxW={{ base: 'none', md: '260px' }}
          value={search}
          onChange={onChange}
        />
      </InputGroup>

      <Box>
        <Button onClick={onOpen} float='right' rightIcon={<UserAdd fill='white' />} colorScheme='primary.main' ml='2'>
          {t('invite')}
        </Button>
        <InvitationUserModal isOpen={isOpen} onClose={onClose} refetch={refetch} />
      </Box>
    </Flex>
  );
}

import { VStack } from '@chakra-ui/react';
import AuthCard from '../../components/auth/AuthCard';
import GoogleButton from '../../components/auth/GoogleButton';
import AppleButton from '../../components/auth/AppleButton';
import { useTranslation } from 'react-i18next';
import AcceptInvitationForm from '../../components/auth/forms/AcceptInvitationForm';

export default function AcceptInvitation() {
  const { t } = useTranslation();
  return (
    <AuthCard title={t('accept_invitation')}>
      <VStack width='w-100' gap={4}>
        <AcceptInvitationForm />
        <GoogleButton />
        <AppleButton />
      </VStack>
    </AuthCard>
  );
}

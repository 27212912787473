import { Box, BoxProps, SimpleGrid, useToast } from '@chakra-ui/react';
import UserProfileInfoHeader from './UserProfileInfoHeader';
import { CurrentUserFragment, EMembershipRole, ERole, useHomesByUserIdQuery } from '../../../gql/generated';
import HomeTable, { HomeData } from '../../homes/HomeTable';
import { formatGraphQLErrors } from '../../../gql/apollo/helpers';
import UserProfileInfo from './UserProfileInfo';
import { useMemo } from 'react';
import { generatePath } from 'react-router-dom';
import { Row } from '@tanstack/react-table';
import { AppPaths } from '../../../routes/paths';
import { sortHomesForHomemate } from '../../../utils/helpers';
import CustomerProfileInfoHeader from './CustomerProfileInfoHeader';
import ClientHubUrl from './ClientHubUrl';

const HOMES_PER_PAGE = 20;

const MEMBERSHIP_ROLES = {
  [ERole.USER]: EMembershipRole.HOME_OWNER,
  [ERole.DWELINK_ADMIN]: EMembershipRole.DWELINK_HOMEMATE,
  [ERole.DWELINK_HOMEMATE]: EMembershipRole.DWELINK_HOMEMATE,
};
interface IUserProfileInfoProps {
  user: CurrentUserFragment;
  me: CurrentUserFragment;
  boxProps?: BoxProps;
  isMe: boolean;
}

const ProfileInfo = ({ user, isMe, me, boxProps }: IUserProfileInfoProps) => {
  const toast = useToast();

  const { data, loading } = useHomesByUserIdQuery({
    variables: {
      filter: { userId: user.id, membershipRole: [MEMBERSHIP_ROLES[ERole.USER]] },
      limit: HOMES_PER_PAGE,
    },
    fetchPolicy: 'cache-and-network',
    onError: (e) => toast({ description: formatGraphQLErrors(e).message, status: 'error' }),
    notifyOnNetworkStatusChange: true,
  });

  const handleGetNavigationPath = (row: Row<HomeData>) => generatePath(AppPaths.HOME_DETAILS, { id: row.original.id });

  const handleDisableRow = (row: Row<HomeData>) => {
    if (me.role === ERole.DWELINK_ADMIN) return false;
    return !row.original.homemates.map((hm) => hm.id).includes(me.id);
  };

  const homes = useMemo(
    () => sortHomesForHomemate(data?.homesByUserId.homes || [], me.id),
    [data?.homesByUserId.homes, me.id],
  );

  return (
    <Box {...boxProps} bg='white' p='5' rounded='xl' shadow='md'>
      {isMe ? (
        <>
          <UserProfileInfoHeader user={user} />
          <UserProfileInfo countOfHomes={data?.homesByUserId.total || 0} user={user} />
        </>
      ) : (
        <SimpleGrid
          columns={{
            base: 1,
            lg: 2,
          }}
          alignItems='center'
        >
          <CustomerProfileInfoHeader user={user} />
          <ClientHubUrl userId={user.id} jobberClientHubUri={user?.jobberClientHubUri} />
        </SimpleGrid>
      )}
      <HomeTable
        boxProps={{
          minHeight: 200,
          borderColor: 'gray.200',
          shadow: 'none',
          p: '5',
          borderWidth: 1,
          borderRadius: '12',
        }}
        disableRow={handleDisableRow}
        getNavigationPath={handleGetNavigationPath}
        isLoading={loading}
        homes={homes}
      />
    </Box>
  );
};

export default ProfileInfo;

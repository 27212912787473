import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Flex, Tooltip, WrapItem, useDisclosure, useToast } from '@chakra-ui/react';
import { Add, Trash } from '../../../icons';
import { useTranslation } from 'react-i18next';
import { TZoneTableProps, ZoneData } from './ZoneTable';
import ConfirmationModal from '../../common/ConfirmationModal';
import { useUpdateZonesMutation } from '../../../gql/generated';
import RenameZoneModal from './RenameZoneAction';
import AddInventoryItemsModal from './AddInventoryItemsModal';
import Button, { EButtonVariant } from '../../common/Button';

type Props = {
  zone: ZoneData;
  archiveCb?: TZoneTableProps['archiveCb'];
};

export function ZoneActions({ zone, archiveCb }: Props) {
  const { t } = useTranslation();
  const [loadingUpdatedZone, setLoadingUpdatedZone] = useState(false);
  const { id: homeId } = useParams();
  const toast = useToast();

  const {
    isOpen: isOpenConfirmationModal,
    onOpen: onOpenConfirmationModal,
    onClose: onCloseConfirmationModal,
  } = useDisclosure();

  const { isOpen: isOpenRenameModal, onOpen: onOpenRenameModal, onClose: onCloseRenameModal } = useDisclosure();

  const { isOpen: isOpenAddItemsModal, onOpen: onOpenAddItemsModal, onClose: onCloseAddItemsModal } = useDisclosure();

  const [updateZones] = useUpdateZonesMutation();

  const handleArchiveZone = useCallback(async () => {
    setLoadingUpdatedZone(true);
    await updateZones({
      variables: { input: { homeId: homeId!, zoneIdsForArchive: [zone.id] } },
      onCompleted: () => {
        toast({
          description: t('home_details.archived_zone_toast'),
          status: 'success',
        });
      },
    });
    await archiveCb?.();
    onCloseConfirmationModal();
    setLoadingUpdatedZone(false);
  }, [archiveCb, homeId, onCloseConfirmationModal, t, toast, updateZones, zone.id]);

  return (
    <Flex gap='13px' justifyContent='flex-end'>
      <Tooltip hasArrow label={t('zone_table.actions.add_item')}>
        <WrapItem>
          <Button isDisabled={loadingUpdatedZone} onClick={onOpenAddItemsModal} styleVariant={EButtonVariant.outline}>
            <Add fill='primary.main' height='20px' width='20px' />
          </Button>
        </WrapItem>
      </Tooltip>
      <Tooltip hasArrow label={t('zone_table.actions.rename')}>
        <WrapItem>
          <Button onClick={onOpenRenameModal} styleVariant={EButtonVariant.gray}>
            <IconCarbonEdit />
          </Button>
        </WrapItem>
      </Tooltip>
      <Tooltip hasArrow label={t('zone_table.actions.archive')}>
        <WrapItem>
          <Button
            isDisabled={loadingUpdatedZone}
            onClick={onOpenConfirmationModal}
            styleVariant={EButtonVariant.deleteButton}
          >
            <Trash fill='red.500' />
          </Button>
        </WrapItem>
      </Tooltip>
      <ConfirmationModal
        title={t('home_details.archive_zone_confirm_modal_title', { name: zone.name })}
        description={t('home_details.archive_zone_confirm_modal_description')}
        onSubmit={handleArchiveZone}
        onClose={onCloseConfirmationModal}
        isOpen={isOpenConfirmationModal}
        loading={loadingUpdatedZone}
        submitButtonProps={{ title: t('archive'), styleVariant: EButtonVariant.red }}
      />
      <RenameZoneModal zoneName={zone.name} zoneId={zone.id} isOpen={isOpenRenameModal} onClose={onCloseRenameModal} />
      <AddInventoryItemsModal
        items={zone.items.items}
        zoneName={zone.name}
        zoneId={zone.id}
        zoneTemplateId={zone.template.id}
        isOpen={isOpenAddItemsModal}
        onClose={onCloseAddItemsModal}
      />
    </Flex>
  );
}

import { FlexProps, Flex, FormControl, FormLabel, FormErrorMessage } from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DatePicker from '../DatePicker';

interface IFormDatePickerProps extends FlexProps {
  name: string;
  disabled?: boolean;
  label: string;
  isRequired?: boolean;
  minDate?: Date;
  maxDate?: Date;
  showMonthYearPicker?: boolean;
  pickerPosition?: 'bottom' | 'top';
}

const FormDatePicker = (props: IFormDatePickerProps) => {
  const { name, isRequired, label, disabled, showMonthYearPicker, pickerPosition, minDate, maxDate, ...rest } = props;
  const { t } = useTranslation();

  const { formState, control } = useFormContext();

  const error = formState.errors[name];

  return (
    <FormControl isRequired={isRequired} isInvalid={!!error}>
      <Flex {...rest} alignItems='center' py='2' flexDirection='row'>
        <FormLabel width='40%' fontWeight='500'>
          {t(label)}
        </FormLabel>
        <Flex flex='1' flexDirection='column'>
          <Controller
            control={control}
            name={name}
            render={({ field }) => (
              <DatePicker
                datePickerWrapperProps={{ marginTop: '40px' }}
                popperProps={{
                  strategy: 'fixed',
                }}
                minDate={minDate}
                maxDate={maxDate}
                pickerPosition={pickerPosition}
                showMonthYearPicker={showMonthYearPicker}
                disabled={disabled}
                onChange={(date) => field.onChange(date)}
                selected={field.value as Date}
              />
            )}
          />
          <FormErrorMessage>{error?.message as string}</FormErrorMessage>
        </Flex>
      </Flex>
    </FormControl>
  );
};

export default FormDatePicker;

import { Box, Flex, useDisclosure, useToast } from '@chakra-ui/react';
import ZoneTable from './ZoneTable';
import { useZonesQuery } from '../../../gql/generated';
import { useParams } from 'react-router-dom';
import Pagination from '../../common/Pagination';
import { formatGraphQLErrors } from '../../../gql/apollo/helpers';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Plus } from '../../../icons';
import AddZoneModal from './AddZoneModal';
import Button from '../../common/Button';
import { isEmpty } from 'lodash';

export const ZONES_PER_PAGE = 40;

export default function Zones() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentPage, setCurrentPage] = useState(1);
  const { t } = useTranslation();
  const { id } = useParams();
  const toast = useToast();

  const offset = (currentPage - 1) * ZONES_PER_PAGE;
  const { data, loading, refetch } = useZonesQuery({
    variables: {
      homeId: id!,
      isArchivedZone: false,
      isArchivedItem: false,
      limit: ZONES_PER_PAGE,
      offset,
    },
    onError: (e) => toast({ description: formatGraphQLErrors(e).message, status: 'error' }),
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });

  return (
    <Flex width='100%' direction='column' gap='2' alignItems='flex-end'>
      <Button
        variant='solid'
        rightIcon={<Plus fill='white' boxSize='3' stroke='white' strokeWidth={2} />}
        width='fit-content'
        onClick={onOpen}
      >
        {t('add_zone_button')}
      </Button>
      <AddZoneModal isOpen={isOpen} updateCb={refetch} onClose={onClose} homeId={id!} />
      <Box width='100%'>
        <ZoneTable zones={data?.zones.zones || []} isLoading={loading && isEmpty(data)} archiveCb={refetch} />
        {data?.zones && (
          <Pagination
            total={data.zones.total}
            current={currentPage}
            defaultPageSize={ZONES_PER_PAGE}
            onChange={(page) => setCurrentPage(page)}
          />
        )}
      </Box>
    </Flex>
  );
}

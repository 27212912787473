import { Box, Divider, Flex, useToast } from '@chakra-ui/react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import TaskForm, { ICreateTaskForm, ITaskFormForwardRef } from '../../components/tasks/TaskForm';
import { useTranslation } from 'react-i18next';
import { useCallback, useRef } from 'react';
import { TaskFragment, useCreateTaskMutation, useItemQuery } from '../../gql/generated';
import Button from '../../components/common/Button';
import { Plus } from '../../icons';
import { formatGraphQLErrors } from '../../gql/apollo/helpers';
import { AppPaths } from '../../routes/paths';

const CreateTask = () => {
  const { id = '', itemId = '' } = useParams();
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();
  const formRef = useRef<ITaskFormForwardRef>(null);

  const { data: item } = useItemQuery({ variables: { itemId: itemId || '' }, fetchPolicy: 'cache-and-network' });

  const [createTask, { loading: createTaskLoading }] = useCreateTaskMutation({
    onError: (e) => toast({ description: formatGraphQLErrors(e).message, status: 'error' }),
    onCompleted: (data) => {
      toast({
        description: 'Task created',
        status: 'success',
      });
      const path = generatePath(AppPaths.EDIT_ITEM_TASK, { id, itemId, taskId: data.createTask.id });
      navigate(path);
    },
  });

  const onCreateClick = useCallback(() => {
    formRef.current?.onSubmitForm?.();
  }, []);

  const handlerOnSubmitCreateTask = useCallback(
    async (values: ICreateTaskForm) => {
      const createTaskInput = {
        costEstimate: values.costEstimate,
        description: values.description,
        itemId: itemId || '',
        importance: values.importance || 1,
        levelOfSkill: values.levelOfSkill || 1,
        jobPerformerName: values.jobPerformerName,
        jobPerformer: values.jobPerformer,
        name: values.name || '',
        recommendedDate: values.recommendedDate?.toDateString() || '',
        riskOfNotDoing: values.riskOfNotDoing || '',
        scheduledDate: values.scheduledDate?.toDateString() || null,
      };
      const { data } = await createTask({ variables: { input: createTaskInput } });
      return data?.createTask as TaskFragment;
    },
    [createTask, itemId],
  );

  const initialValues = { zone: item?.item.zone.name, inventoryItem: item?.item.name };

  return (
    <Box>
      <Flex width='100%' flexDirection='row' paddingY='2' direction='column' gap='2' justifyContent='flex-end'>
        <Button
          isLoading={createTaskLoading}
          onClick={onCreateClick}
          rightIcon={<Plus fill='white' boxSize='3' stroke='white' strokeWidth={2} />}
        >
          {t('task_form.actions.create')}
        </Button>
      </Flex>
      <Divider my='0.5' />
      <TaskForm isCreate onSubmitForm={handlerOnSubmitCreateTask} ref={formRef} initialValues={initialValues} />
    </Box>
  );
};

export default CreateTask;

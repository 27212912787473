import { Box, ButtonSpinner, Stack, Text, VStack, useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { passwordRegex } from '../../../constants/passwordRequirements';
import useLogin from '../../../hooks/auth/useLogin';
import { useEffect } from 'react';
import PasswordFormControl from '../../common/form/PasswordFormControl';
import { useResetPasswordMutation } from '../../../gql/generated';
import Button from '../../common/Button';

type Props = {
  email: string;
  token: string;
  onLoading?: (loading: boolean) => Promise<void> | void;
  onSuccess?: () => void;
  hideLoginToast?: boolean;
};

const getSchema = () => {
  const schema = yup.object().shape({
    password: yup.string().label(i18n.t('password')).matches(passwordRegex, i18n.t('password_regex')).required(),
  });
  return schema;
};

export default function NewPasswordForm({ email, token, hideLoginToast, onSuccess, onLoading }: Props) {
  const { t } = useTranslation();
  const toast = useToast();
  const handleError = (e: Error) => {
    toast({
      description: e.message,
      status: 'error',
    });
  };

  const [resetPassword, { loading: resetLoading }] = useResetPasswordMutation({
    onError: handleError,
  });
  const [login, { loading: loginLoading }] = useLogin(hideLoginToast);

  const loading = resetLoading || loginLoading;

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(getSchema()),
  });

  useEffect(() => {
    void onLoading?.(loading);
  }, [onLoading, loading]);

  const onSubmit = handleSubmit(async (data) => {
    if (!data.password) {
      return;
    }

    const { password } = data;

    await resetPassword({
      variables: {
        input: {
          resetToken: token,
          newPassword: password,
        },
      },
      onCompleted: async () => {
        void (await login({
          variables: {
            email,
            password,
          },
        }));
        onSuccess?.();
      },
    });
  });

  return (
    <Box w='100%'>
      <form onSubmit={onSubmit}>
        <Stack gap={6}>
          <VStack gap={1}>
            <Text>{t('set_new_password')}</Text>
            <Text>{email}</Text>
          </VStack>

          <PasswordFormControl
            register={() => register('password')}
            isDisabled={loading}
            isInvalid={!!errors.password}
            errorMessage={errors.password?.message}
          />

          <Button type='submit' className='w-100' disabled={loading}>
            {!loading ? t('submit') : <ButtonSpinner />}
          </Button>
        </Stack>
      </form>
    </Box>
  );
}

import { Box, Divider, Spacer, useToast } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import {
  EJobPerformer,
  ETaskStatus,
  HomeWithStatsDocument,
  TaskDocument,
  TaskFragment,
  useAssignTaskMutation,
  useHomeWithStatsQuery,
  useTaskQuery,
  useUpdateTaskMutation,
} from '../../gql/generated';
import TaskForm, { ICreateTaskForm, ITaskFormForwardRef } from '../../components/tasks/TaskForm';
import LoadingOverlay from '../../components/common/LoadingOverlay';
import { useCallback, useMemo, useRef } from 'react';
import EditTaskButtonsBar from '../../components/tasks/EditTaskButtonsBar';
import { formatGraphQLErrors } from '../../gql/apollo/helpers';
import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client';
import TaskImages from '../../components/tasks/images/TaskImages';
import { isEmpty } from 'lodash';
import PageError from '../../components/common/PageError';
import HomeItem from '../../components/homeDetails/HomeItem';
import { getRecommendedDate } from '../../utils/date';

const EditTask = ({ showHomeStats }: { showHomeStats?: boolean }) => {
  const { id, taskId = '' } = useParams();
  const toast = useToast();
  const { t } = useTranslation();
  const formRef = useRef<ITaskFormForwardRef>(null);

  const onError = useCallback(
    (e: ApolloError) => {
      toast({ description: formatGraphQLErrors(e).message, status: 'error' });
    },
    [toast],
  );

  const {
    data: task,
    error,
    loading: taskLoading,
  } = useTaskQuery({
    variables: { taskId },
    fetchPolicy: 'cache-and-network',
  });

  const homeId = id || task?.task.item.homeId || '';
  const [updateTask, { loading: updateTaskLoading }] = useUpdateTaskMutation({
    onError,
    onCompleted: () => {
      toast({
        description: t('task_form.toast.update_task'),
        status: 'success',
      });
    },
  });

  const [assignTask] = useAssignTaskMutation({ onError });

  const handlerOnSubmitJobPerformerSelect = useCallback(
    async (jobPerformer: EJobPerformer, jobPerformerName?: string) => {
      await assignTask({
        variables: { input: { taskId: task?.task.id ?? '', jobPerformer, jobPerformerName } },
        onError: (e) => console.log(e),
        refetchQueries: [{ query: HomeWithStatsDocument, variables: { homeId } }],
      });
    },
    [assignTask, homeId, task?.task.id],
  );

  const handlerOnSave = useCallback(() => {
    formRef.current?.onSubmitForm?.();
  }, []);

  const initialValues: ICreateTaskForm = useMemo(
    () => ({
      name: task?.task.name,
      importance: task?.task.importance,
      costEstimate: task?.task?.costEstimate,
      levelOfSkill: task?.task.levelOfSkill,
      jobPerformer: task?.task.jobPerformer,
      jobPerformerName: task?.task.jobPerformerName ?? '',
      recommendedDate: task?.task.recommendedDate ? getRecommendedDate(task.task.recommendedDate) : undefined,
      scheduledDate: task?.task.scheduledDate ? new Date(task.task.scheduledDate) : null,
      description: task?.task.description || '',
      riskOfNotDoing: task?.task.riskOfNotDoing,
      zone: task?.task.item.zone.name,
      inventoryItem: task?.task.item.name,
    }),
    [task?.task],
  );

  const handlerOnSubmitUpdateTask = useCallback(
    async (values: ICreateTaskForm) => {
      if (isEqual(initialValues, values)) return;
      const updateTaskInput = {
        costEstimate: values.costEstimate,
        description: values.description,
        id: task?.task.id || '',
        importance: values.importance,
        levelOfSkill: values.levelOfSkill,
        name: values.name,
        recommendedDate: values.recommendedDate?.toUTCString(),
        riskOfNotDoing: values.riskOfNotDoing,
        scheduledDate: values.scheduledDate ? values.scheduledDate?.toUTCString() : null,
      };
      const { data } = await updateTask({
        variables: { input: updateTaskInput },
        refetchQueries: [{ query: TaskDocument, variables: { taskId: task?.task.id } }],
      });

      return data?.updateTask as TaskFragment;
    },
    [initialValues, task?.task.id, updateTask],
  );

  const { data, loading: homeStatsLoading } = useHomeWithStatsQuery({
    variables: { homeId },
    fetchPolicy: 'cache-and-network',
    skip: !!id,
  });

  if (taskLoading || homeStatsLoading) return <LoadingOverlay />;

  const disableEdit =
    task?.task.isArchived ||
    task?.task.status === ETaskStatus.DEFERRED_FOR_GOOD ||
    task?.task.status === ETaskStatus.FINISHED;

  if (error?.message && isEmpty(task)) return <PageError error={error} />;

  return (
    <Box>
      {showHomeStats && (
        <>
          {data && <HomeItem item={data} />}
          <Spacer h='20px' />
        </>
      )}
      {task?.task && (
        <EditTaskButtonsBar
          resetForm={() => formRef.current?.reset()}
          onSaveLoading={updateTaskLoading}
          onSave={handlerOnSave}
          task={task?.task}
        />
      )}
      <Divider my='0.5' mb='2' />
      {taskLoading ? (
        <LoadingOverlay />
      ) : (
        <>
          <TaskForm
            onSubmitJobPerformerSelect={handlerOnSubmitJobPerformerSelect}
            onSubmitForm={handlerOnSubmitUpdateTask}
            disableEdit={disableEdit}
            ref={formRef}
            initialValues={initialValues}
          />
          <Spacer height='25' />
          <TaskImages taskId={taskId} disableEdit={disableEdit} />
        </>
      )}
    </Box>
  );
};

export default EditTask;

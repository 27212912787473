import {
  Avatar,
  Box,
  Button as CButton,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ERole, User, useChangeUserRoleMutation } from '../../gql/generated';
import Button from '../common/Button';
import { useCallback, useMemo, useState } from 'react';
import UserInfo from './UserInfo';
import { formatGraphQLErrors } from '../../gql/apollo/helpers';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  user: Pick<User, 'id' | 'role' | 'profile' | 'email' | 'isVerified'>;
};

export default function ChangeRoleModal({ isOpen, onClose, user }: Props) {
  const { t } = useTranslation();
  const toast = useToast();
  const [role, setRole] = useState(user.role);
  const name = useMemo(
    () => (user.profile ? `${user.profile.firstName} ${user.profile.lastName}` : 'N/A'),
    [user.profile],
  );

  const [changeRole, { loading }] = useChangeUserRoleMutation({
    variables: {
      input: {
        userId: user.id,
        role,
      },
    },
    onError: (e) => {
      toast({
        description: formatGraphQLErrors(e).message,
        status: 'error',
      });
    },
    onCompleted: onClose,
  });

  const handlerOnClose = useCallback(() => {
    onClose();
    setRole(user.role);
  }, [onClose, user.role]);

  return (
    <Modal isOpen={isOpen} onClose={handlerOnClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('change_role_modal.title')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <HStack gap='3'>
            <Avatar size={'md'} name={name || ''} backgroundColor='blue.300' color='black' />
            <UserInfo {...user} />
          </HStack>
          <Box mt='3'>
            <Text fontSize='lg'>{t('role')}</Text>
            <RadioGroup onChange={(v) => setRole(v as ERole)} value={role}>
              <Stack gap='2' mt='2'>
                {Object.keys(ERole).map((key) => (
                  <Radio key={key} value={key} colorScheme='orange'>
                    {t(`roles.${key.toLowerCase()}`)}
                  </Radio>
                ))}
              </Stack>
            </RadioGroup>
          </Box>
        </ModalBody>
        <ModalFooter>
          <CButton mr={2} onClick={handlerOnClose} variant='solid'>
            {t('cancel')}
          </CButton>
          <Button onClick={() => changeRole()} isLoading={loading}>
            {t('save')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

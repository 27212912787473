import { Box, Flex, useDisclosure, useToast } from '@chakra-ui/react';
import {
  ETaskStatus,
  TaskDocument,
  TaskFragment,
  TaskQuery,
  useArchiveTaskMutation,
  useAssignTaskMutation,
  useDeferTaskMutation,
} from '../../gql/generated';
import Button, { EButtonVariant } from '../../components/common/Button';
import { useTranslation } from 'react-i18next';
import { Check, TimeForward, Trash } from '../../icons';
import { useCallback } from 'react';
import { formatGraphQLErrors } from '../../gql/apollo/helpers';
import ConfirmationModal from '../common/ConfirmationModal';
import ConfirmTaskModal from './ConfirmTaskModal';

interface IEditTaskButtonsBarProps {
  onSave: () => void;
  onSaveLoading?: boolean;
  task: TaskFragment;
  resetForm: () => void;
}

const EditTaskButtonsBar = ({ onSave, onSaveLoading, task, resetForm }: IEditTaskButtonsBarProps) => {
  const { t } = useTranslation();
  const toast = useToast();

  const {
    isOpen: isOpenFinishTaskConfirmationModal,
    onOpen: onOpenFinishTaskConfirmationModal,
    onClose: onCloseFinishTaskConfirmationModal,
  } = useDisclosure();

  const {
    isOpen: isOpenArchiveTaskConfirmationModal,
    onOpen: onOpenArchiveTaskConfirmationModal,
    onClose: onCloseArchiveTaskConfirmationModal,
  } = useDisclosure();

  const [archiveTask, { loading: archiveTaskLoading }] = useArchiveTaskMutation({
    variables: { taskId: task.id },
    onError: (e) => toast({ description: formatGraphQLErrors(e).message, status: 'error' }),
    update(cache) {
      const taskQuery = cache.readQuery({ query: TaskDocument, variables: { taskId: task.id } }) as TaskQuery;
      cache.writeQuery({
        query: TaskDocument,
        variables: { taskId: task.id },
        data: { ...taskQuery, task: { ...taskQuery.task, isArchived: true } },
      });
    },
    onCompleted: () => {
      toast({
        description: t('task_form.toast.archived'),
        status: 'success',
      });
      resetForm?.();
      onCloseArchiveTaskConfirmationModal();
    },
  });
  const [deferTask, { loading: deferTaskLoading }] = useDeferTaskMutation({
    variables: { input: { taskId: task.id, deferForGood: true } },
    onError: (e) => toast({ description: formatGraphQLErrors(e).message, status: 'error' }),
    onCompleted: () => {
      toast({
        description: t('task_form.toast.defer_for_good'),
        status: 'success',
      });
      resetForm();
    },
  });
  const [activeTask, { loading: activeTaskLoading }] = useAssignTaskMutation({
    onError: (e) => toast({ description: formatGraphQLErrors(e).message, status: 'error' }),
    onCompleted: () => {
      toast({
        description: t('task_form.toast.activate'),
        status: 'success',
      });
    },
  });

  const onArchiveTask = useCallback(async () => await archiveTask(), [archiveTask]);

  const onDeferTask = useCallback(async () => await deferTask(), [deferTask]);

  const onActivateTask = useCallback(async () => {
    if (task)
      await activeTask({
        variables: {
          input: {
            taskId: task.id,
            jobPerformer: task.jobPerformer,
            jobPerformerName: task.jobPerformerName,
          },
        },
      });
  }, [activeTask, task]);

  const onSaveClick = useCallback(() => {
    onSave();
  }, [onSave]);

  const isDeferredTask = task.status === ETaskStatus.DEFERRED_FOR_GOOD;
  const isCompletedTask = task.status === ETaskStatus.FINISHED;
  const isArchived = task.isArchived;
  const isActive = !task.isArchived && task.status === ETaskStatus.ACTIVE;

  const renderStatus = () => {
    if (isArchived)
      return (
        <Button styleVariant={EButtonVariant.statusButton} rightIcon={<Trash fill='gray.400' color='gray.400' />}>
          {t('task_form.status.archived')}
        </Button>
      );
    else if (isCompletedTask)
      return (
        <Button
          styleVariant={EButtonVariant.statusButton}
          hidden={!isCompletedTask}
          rightIcon={<Check fill='gray.400' color='gray.400' boxSize='4' strokeWidth={6} />}
        >
          {t('task_form.status.completed')}
        </Button>
      );
    else if (isDeferredTask)
      return (
        <Flex width='100%' justifyContent='space-between'>
          <Button
            styleVariant={EButtonVariant.statusButton}
            hidden={!isDeferredTask}
            rightIcon={<TimeForward fill='gray.400' color='gray.400' />}
          >
            {t('task_form.status.deferred_for_good')}
          </Button>
          <Button
            isLoading={activeTaskLoading}
            onClick={onActivateTask}
            rightIcon={<Check fill='white' boxSize='3' stroke='white' strokeWidth={2} />}
          >
            {t('task_form.actions.activate_task')}
          </Button>
        </Flex>
      );
  };

  return (
    <Flex width='100%' flexDirection='row' paddingY='2' direction='column' gap='2' justifyContent='flex-end'>
      {!isActive ? (
        <Box width='100%'>{renderStatus()}</Box>
      ) : (
        <Flex flex='1' flexWrap='wrap'>
          <Flex alignItems='flex-end' flex={1} />
          <Flex gap='2' flexWrap='wrap'>
            <Button
              isLoading={archiveTaskLoading}
              onClick={onOpenArchiveTaskConfirmationModal}
              styleVariant={EButtonVariant.deleteButton}
              rightIcon={<Trash fill='red.500' />}
            >
              {t('task_form.actions.archive')}
            </Button>
            <Button
              bg='blue.100'
              color='black'
              _hover={{ bg: 'blue.200' }}
              isLoading={deferTaskLoading}
              onClick={onDeferTask}
              rightIcon={<TimeForward />}
            >
              {t('task_form.actions.defer_for_good')}
            </Button>
            <Button
              onClick={onOpenFinishTaskConfirmationModal}
              rightIcon={<Check fill='white' boxSize='3' stroke='white' strokeWidth={2} />}
            >
              {t('task_form.actions.complete')}
            </Button>
            <Button
              isLoading={onSaveLoading}
              styleVariant={EButtonVariant.outline}
              onClick={onSaveClick}
              rightIcon={<IconCarbonSave />}
            >
              {t('task_form.actions.save')}
            </Button>
          </Flex>
        </Flex>
      )}
      <ConfirmTaskModal
        taskId={task.id}
        isOpen={isOpenFinishTaskConfirmationModal}
        onClose={onCloseFinishTaskConfirmationModal}
      />
      <ConfirmationModal
        title={t('home_details.archive_task_confirm_modal_title')}
        description={t('home_details.archive_task_confirm_modal_description')}
        onSubmit={onArchiveTask}
        onClose={onCloseArchiveTaskConfirmationModal}
        isOpen={isOpenArchiveTaskConfirmationModal}
        loading={archiveTaskLoading}
        submitButtonProps={{ title: t('archive'), styleVariant: EButtonVariant.red }}
      />
    </Flex>
  );
};

export default EditTaskButtonsBar;

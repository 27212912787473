import { Flex, Text } from '@chakra-ui/react';

interface IInfoItem {
  title: string;
  value?: string | number;
  actionButton?: React.ReactNode;
}

const InfoItem = ({ title, value, actionButton }: IInfoItem) => {
  return (
    <Flex p='3' flexDirection='row' justifyContent='space-between'>
      <Text fontWeight='500'>{title}</Text>
      <Flex display='flex' alignItems='flex-end' justifyContent='center'>
        <Text fontWeight='500'>{value}</Text>
        {actionButton}
      </Flex>
    </Flex>
  );
};

export default InfoItem;

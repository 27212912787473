import { extendTheme } from '@chakra-ui/react';

export default extendTheme({
  styles: {
    global: () => ({
      body: {
        bg: '#f8f9fa',
      },
    }),
  },
  colors: {
    primary: {
      main: '#FF671F',
      50: '#ffeee6',
      100: '#ffb999',
      200: '#ff9666',
      300: '#ff7433',
      400: '#ff621a',
      500: '#ff5100',
      600: '#e64900',
      700: '#cc4100',
      800: '#993000',
      900: '#662000',
    },
  },
});

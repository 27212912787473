import { Box, Flex, Highlight, Text } from '@chakra-ui/react';
import { Cross } from '../../icons';
import { User } from '../../gql/generated';
import { chakra } from '@chakra-ui/system';
import useCurrentUser from '../../hooks/auth/useCurrentUser';
import { useTranslation } from 'react-i18next';

type Props = Pick<User, 'profile' | 'email' | 'isVerified'> & {
  id: string;
  search?: string;
};

export default function UserInfo({ id, profile, email, search, isVerified }: Props) {
  const [currentUser] = useCurrentUser();
  const { t } = useTranslation();

  return (
    <Box>
      <Text fontWeight='semibold' whiteSpace='normal' maxW='360' color={profile ? 'black' : 'primary.main'}>
        <Highlight query={search || ''} styles={{ bg: 'orange.100' }}>
          {profile ? `${profile.firstName} ${profile.lastName}` : 'N/A'}
        </Highlight>
        {id === currentUser?.id && (
          <chakra.span ml='1' fontWeight='normal' color='gray.500'>
            {t('you')}
          </chakra.span>
        )}
      </Text>
      <Flex alignItems='center'>
        <Text color='gray.500'>
          <Highlight query={search || ''} styles={{ bg: 'orange.100', color: 'gray.300' }}>
            {email}
          </Highlight>
        </Text>
        {!isVerified && <Cross ml='3px' boxSize='10px' strokeWidth={1} stroke='red.500' fill='red.500' />}
      </Flex>
    </Box>
  );
}

import { VStack } from '@chakra-ui/react';
import LoginForm from '../../components/auth/forms/LoginForm';
import { useTranslation } from 'react-i18next';
import GoogleButton from '../../components/auth/GoogleButton';
import AuthCard from '../../components/auth/AuthCard';
import AppleButton from '../../components/auth/AppleButton';

export default function Login() {
  const { t } = useTranslation();

  return (
    <AuthCard title={t('login')}>
      <VStack width='w-100' gap={4}>
        <LoginForm />
        <GoogleButton />
        <AppleButton />
      </VStack>
    </AuthCard>
  );
}

import { Flex, Tooltip, WrapItem, useBoolean, useDisclosure, useToast } from '@chakra-ui/react';
import { Trash } from '../../../icons';
import Button, { EButtonVariant } from '../../common/Button';
import { HomeWithStatsDocument, TaskFragment, TaskFragmentDoc, useArchiveTaskMutation } from '../../../gql/generated';
import { formatGraphQLErrors } from '../../../gql/apollo/helpers';
import { MouseEventHandler, useCallback, useMemo } from 'react';
import ConfirmationModal from '../../common/ConfirmationModal';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import client from '../../../gql/apollo/client';

type Props = {
  taskId: string;
  navigationPath: string;
};

export default function TaskActions({ taskId, navigationPath }: Props) {
  const toast = useToast();
  const { id } = useParams();
  const task = useMemo(
    () =>
      client.readFragment({
        fragmentName: 'Task',
        id: `Task:${taskId}`,
        fragment: TaskFragmentDoc,
      }),
    [taskId],
  ) as TaskFragment;

  const homeId = id || task?.item?.homeId;
  const [isArchiveTaskLoading, setIsArchiveTaskLoading] = useBoolean();
  const { t } = useTranslation();

  const {
    isOpen: isOpenConfirmationModal,
    onOpen: onOpenConfirmationModal,
    onClose: onCloseConfirmationModal,
  } = useDisclosure();

  const [archiveTask] = useArchiveTaskMutation({
    variables: { taskId },
    onError: (e) => toast({ description: formatGraphQLErrors(e).message, status: 'error' }),
    onCompleted: () => {
      client.cache.evict({ id: `Task:${taskId}` });
      onCloseConfirmationModal();
      toast({
        description: t('task_form.toast.archived'),
        status: 'success',
      });
    },
    refetchQueries: [{ query: HomeWithStatsDocument, variables: { homeId } }],
  });

  const handleOnShowModal: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.stopPropagation();
      onOpenConfirmationModal();
    },
    [onOpenConfirmationModal],
  );

  const onArchiveTask = useCallback(async () => {
    setIsArchiveTaskLoading.on();
    await archiveTask();
    setIsArchiveTaskLoading.off();
  }, [archiveTask, setIsArchiveTaskLoading]);

  return (
    <Flex gap='13px'>
      <Tooltip label={t('task_table.actions.edit')}>
        <WrapItem>
          <Link to={navigationPath}>
            <Button styleVariant={EButtonVariant.gray}>
              <IconCarbonEdit />
            </Button>
          </Link>
        </WrapItem>
      </Tooltip>
      <Tooltip label={t('task_form.actions.archive')}>
        <WrapItem>
          <Button
            isLoading={isArchiveTaskLoading}
            styleVariant={EButtonVariant.deleteButton}
            onClick={handleOnShowModal}
          >
            <Trash fill='red.500' />
          </Button>
        </WrapItem>
      </Tooltip>
      <ConfirmationModal
        title={t('home_details.archive_task_confirm_modal_title')}
        description={t('home_details.archive_task_confirm_modal_description')}
        onSubmit={onArchiveTask}
        onClose={onCloseConfirmationModal}
        isOpen={isOpenConfirmationModal}
        loading={isArchiveTaskLoading}
        submitButtonProps={{ title: t('archive'), styleVariant: EButtonVariant.red }}
      />
    </Flex>
  );
}

import RootRouter from './routes/index.tsx';
import init from './startup/index.ts';
import { ApolloProvider } from '@apollo/client';
import client from './gql/apollo/client.ts';
import { ChakraProvider } from '@chakra-ui/react';
import config from './constants/config.ts';
import { GoogleOAuthProvider } from '@react-oauth/google';
import theme from './constants/theme.ts';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import 'react-datepicker/dist/react-datepicker.css';

init().catch((err) => {
  console.error('Error initializing app', err);
});

export default function App() {
  return (
    <>
      <GoogleOAuthProvider clientId={config.googleClientId}>
        <ChakraProvider theme={theme} toastOptions={{ defaultOptions: { position: 'top-right', variant: 'subtle' } }}>
          <ApolloProvider client={client}>
            <RootRouter />
          </ApolloProvider>
        </ChakraProvider>
      </GoogleOAuthProvider>
    </>
  );
}

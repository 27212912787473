import { Box, Container, Flex } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';

export default function AuthLayout() {
  return (
    <Box backgroundColor='#f8f9fa'>
      <Container maxW='container.lg' h='100vh'>
        <Flex alignItems='center' justifyContent='center' h='100%'>
          <Box w={{ base: '100%', sm: '75%', md: '50%' }}>
            <Outlet />
          </Box>
        </Flex>
      </Container>
    </Box>
  );
}

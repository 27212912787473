import { Flex, Tooltip, WrapItem, useDisclosure, useToast } from '@chakra-ui/react';
import { Add, Trash } from '../../../icons';
import { HomeWithStatsDocument, ItemFragment, useArchiveItemMutation } from '../../../gql/generated';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from '../../common/ConfirmationModal';
import { formatGraphQLErrors } from '../../../gql/apollo/helpers';
import Button, { EButtonVariant } from '../../common/Button';
import { Link, generatePath, useNavigate, useParams } from 'react-router-dom';
import { AppPaths } from '../../../routes/paths';
import { MouseEventHandler, useCallback } from 'react';

type Props = {
  archiveCb?: () => void;
  item: ItemFragment;
  navigationPath: string;
};

const InventoryItemActions = ({ item, archiveCb, navigationPath }: Props) => {
  const { t } = useTranslation();
  const { id: homeId, zoneId = '' } = useParams();
  const navigate = useNavigate();
  const toast = useToast();

  const [archive, { loading }] = useArchiveItemMutation({
    onCompleted: () => {
      onCloseConfirmationModal();
      archiveCb?.();
      toast({
        description: t('home_details.inventory_item_details.success_archive_item_message'),
        status: 'success',
      });
    },
    onError: (error) => {
      toast({
        description: formatGraphQLErrors(error).message,
        status: 'error',
      });
    },
    refetchQueries: [{ query: HomeWithStatsDocument, variables: { homeId } }],
  });

  const {
    isOpen: isOpenConfirmationModal,
    onOpen: onOpenConfirmationModal,
    onClose: onCloseConfirmationModal,
  } = useDisclosure();

  const onArchiveClick = async () => {
    await archive({
      variables: {
        itemId: item.id,
      },
    });
  };

  const onOpenArchiveConfirmationModal: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.stopPropagation();
      onOpenConfirmationModal();
    },
    [onOpenConfirmationModal],
  );

  const onEditClick = useCallback(() => {
    navigate(generatePath(AppPaths.ITEM_DETAILS, { itemId: item.id, id: homeId || '' }));
  }, [homeId, item.id, navigate]);

  const onCreateTaskClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.stopPropagation();
      const path = zoneId
        ? generatePath(AppPaths.ZONE_ITEM_CREATE_TASK, { itemId: item.id, zoneId, id: homeId || '' })
        : generatePath(AppPaths.CREATE_TASK, { itemId: item.id, id: homeId || '' });
      navigate(path);
    },
    [homeId, item.id, navigate, zoneId],
  );

  return (
    <Flex gap='13px' justifyContent='flex-end'>
      <Tooltip label={t('home_details.add_task_button')}>
        <WrapItem>
          <Button onClick={onCreateTaskClick} styleVariant={EButtonVariant.outline}>
            <Add fill='primary.main' height='20px' width='20px' />
          </Button>
        </WrapItem>
      </Tooltip>
      <Tooltip label={t('home_details.edit_item')}>
        <WrapItem>
          <Link to={navigationPath}>
            <Button onClick={onEditClick} styleVariant={EButtonVariant.gray}>
              <IconCarbonEdit />
            </Button>
          </Link>
        </WrapItem>
      </Tooltip>
      <Tooltip label={t('archive')}>
        <WrapItem>
          <Button styleVariant={EButtonVariant.deleteButton} onClick={onOpenArchiveConfirmationModal}>
            <Trash fill='red.500' />
          </Button>
        </WrapItem>
      </Tooltip>
      <ConfirmationModal
        title={t('home_details.inventory_item_details.confirm_modal_archive_item_message_title', { name: item.name })}
        description={t('home_details.inventory_item_details.confirm_modal_archive_item_message_description')}
        onClose={onCloseConfirmationModal}
        isOpen={isOpenConfirmationModal}
        onSubmit={onArchiveClick}
        loading={loading}
        submitButtonProps={{ title: t('archive'), styleVariant: EButtonVariant.red }}
      />
    </Flex>
  );
};

export default InventoryItemActions;

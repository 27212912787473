import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import i18n from 'i18next';
import validator from 'validator';
import { useForm } from 'react-hook-form';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  useBoolean,
  useToast,
} from '@chakra-ui/react';
import PasswordFormControl from '../../common/form/PasswordFormControl';
import AppLink from '../../common/AppLink';
import { AuthPaths } from '../../../routes/paths';
import { ArrowRight } from '../../../icons';
import { ELoginWithPasswordAbility, useLoginWithPasswordAbilityLazyQuery } from '../../../gql/generated';
import { formatGraphQLErrors } from '../../../gql/apollo/helpers';
import useLogin from '../../../hooks/auth/useLogin';
import { useLocation } from 'react-router-dom';
import { KeyboardEventHandler, useCallback, useEffect } from 'react';
import Button, { EButtonVariant } from '../../common/Button';
import SocialLoginAlert from '../SocialLoginAlert';

const getSchema = () =>
  yup.object().shape({
    email: yup
      .string()
      .test(i18n.t('email_input_error'), i18n.t('email_input_error'), (value) => validator.isEmail(value || ''))
      .label(i18n.t('email'))
      .required(),
    password: yup.string().label(i18n.t('password')).required(),
  });

export default function LoginForm() {
  const [showSocialAlert, setShowSocialAlert] = useBoolean();
  const [showPasswordInput, setShowPasswordInput] = useBoolean(false);
  const { t } = useTranslation();
  const toast = useToast();
  const location = useLocation();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    setFocus,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(getSchema()),
  });

  const [login, { loading }] = useLogin();
  const [loginWithPasswordAbility, { loading: loginWithPasswordAbilityLoading }] = useLoginWithPasswordAbilityLazyQuery(
    {
      onCompleted: (data) => {
        switch (data.loginWithPasswordAbility) {
          case ELoginWithPasswordAbility.NO_ACCOUNT:
            return toast({
              description: t('auth.no_account'),
              status: 'error',
            });
          case ELoginWithPasswordAbility.HAS_ACCOUNT_NO_PASSWORD:
            return setShowSocialAlert.on();
          default:
            setShowPasswordInput.on();
        }
      },

      onError: (e) => {
        toast({
          description: formatGraphQLErrors(e).message,
          status: 'error',
        });
      },
    },
  );

  const handleEmailClick = useCallback(async () => {
    const isValid = await trigger('email');
    if (isValid) {
      await loginWithPasswordAbility({
        fetchPolicy: 'network-only',
        variables: {
          email: getValues('email').toLowerCase(),
        },
      });
    }
  }, [getValues, loginWithPasswordAbility, trigger]);

  useEffect(() => {
    const state = location.state as { email?: string };
    if (state?.email) setValue('email', state.email);
    location.state = null;
    if (showPasswordInput) setFocus('password');
  }, [location, setFocus, setValue, showPasswordInput]);

  const onSubmit = handleSubmit(async (data) => {
    await login({
      variables: {
        email: data.email.toLowerCase(),
        password: data.password,
      },
    });
  });

  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      if (e.code === 'Enter') {
        e.preventDefault();
        handleEmailClick();
      }
    },
    [handleEmailClick],
  );

  const emailInput = register('email');

  return (
    <Box w='100%'>
      <SocialLoginAlert isOpen={showSocialAlert} onClose={setShowSocialAlert.off} email={getValues('email')} />
      <form onSubmit={onSubmit}>
        <Flex direction='column' gap={6}>
          <FormControl isInvalid={!!errors.email}>
            <FormLabel>{t('email')}</FormLabel>
            <InputGroup size='md'>
              <Input
                autoFocus
                type='email'
                placeholder={t('email_placeholder')}
                disabled={loading}
                autoComplete='email'
                onSubmit={handleEmailClick}
                onKeyDown={onKeyDown}
                {...emailInput}
                onChange={async (e) => {
                  await emailInput.onChange(e);
                  setShowPasswordInput.off();
                }}
                focusBorderColor='primary.main'
              />
              {!showPasswordInput && (
                <InputRightElement mr='5px'>
                  <Button
                    styleVariant={EButtonVariant.grayOutline}
                    rounded='full'
                    h='30px'
                    onClick={handleEmailClick}
                    isLoading={loginWithPasswordAbilityLoading}
                  >
                    <ArrowRight />
                  </Button>
                </InputRightElement>
              )}
            </InputGroup>
            <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
          </FormControl>

          <Box hidden={!showPasswordInput}>
            <PasswordFormControl
              register={() => register('password')}
              isDisabled={loading}
              isInvalid={!!errors.password}
              errorMessage={errors.password?.message}
            />

            <Flex justifyContent='right' mt='12px'>
              <AppLink to={AuthPaths.FORGOT} size='md' state={{ email: getValues('email') }}>
                {t('forgot_password')}
              </AppLink>
            </Flex>

            <Button type='submit' w='100%' mt='16px' isLoading={loading}>
              {t('login')}
            </Button>
          </Box>
        </Flex>
      </form>
    </Box>
  );
}

import { forwardRef, useImperativeHandle } from 'react';
import { FormProvider, UseFormReset, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import i18next from 'i18next';
import { FormControl, FormErrorMessage, FormLabel, Textarea } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import FormPrompt from '../common/form/FormPrompt';

const getDoItNotesFormSchema = () =>
  yup.object().shape({
    note: yup.string().max(3000, i18next.t('form_errors.max_chars_error', { count: 3000 })),
  });

export interface IDoItNotesFormValues {
  note: string;
}

export interface IDoItNotesFormRef {
  onSubmit: (() => unknown) | null | undefined;
  reset: UseFormReset<{
    note: string | undefined;
  }>;
}

interface IDoItDetailsNotesProps {
  initialValues: IDoItNotesFormValues;
  onSubmit: (values: IDoItNotesFormValues) => void;
  disabled?: boolean;
}

const DoItDetailsNotes = forwardRef<IDoItNotesFormRef, IDoItDetailsNotesProps>(
  ({ initialValues, disabled, onSubmit }, ref) => {
    const { t } = useTranslation();

    const methods = useForm({
      resolver: yupResolver(getDoItNotesFormSchema()),
      defaultValues: initialValues,
    });

    const onSubmitForm = methods.handleSubmit(({ note }) => {
      onSubmit({ note: note || '' });
      methods.reset({ note });
    });

    useImperativeHandle(ref, () => ({
      onSubmit: onSubmitForm,
      reset: methods.reset,
    }));

    return (
      <FormProvider {...methods}>
        <form onSubmit={onSubmitForm}>
          <FormControl py={2} isInvalid={!!methods.formState.errors.note}>
            <FormPrompt />
            <FormLabel pb='4'>{t('doit_request_details.doit_request_form.note')}</FormLabel>
            <Textarea
              isInvalid={!!methods.formState.errors.note}
              {...methods.register('note')}
              disabled={disabled}
              placeholder={t('doit_request_details.doit_request_form.note_placeholder')}
              focusBorderColor='primary.main'
              minHeight='150'
              py='5'
            />
            <FormErrorMessage>{methods.formState.errors.note?.message}</FormErrorMessage>
          </FormControl>
          <button type='submit' style={{ display: 'none' }} />
        </form>
      </FormProvider>
    );
  },
);

export default DoItDetailsNotes;

import { Box, BoxProps, Input, InputProps, Text } from '@chakra-ui/react';
import { forwardRef } from 'react';

interface ITextInputProps extends InputProps {
  error?: string;
  containerProps?: BoxProps;
}

const TextInput = forwardRef(({ containerProps, error, ...rest }: ITextInputProps, ref) => {
  return (
    <Box {...containerProps}>
      <Input ref={ref} isInvalid={!!error} {...rest} />
      <Text color='tomato'>{error}</Text>
    </Box>
  );
});

export default TextInput;

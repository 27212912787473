import { onError } from "@apollo/client/link/error";
import { GraphQLError } from 'graphql';

const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  const logError = (error: GraphQLError, errorType: string) => {
    const { message, locations, path, extensions } = error;

    if (path?.includes('login')) return;

    const lVariables = JSON.stringify(operation.variables);
    const lPath = JSON.stringify(path);
    const lLocations = JSON.stringify(locations);
    const lMessage = JSON.stringify(message);
    console.error(
      [
        `[${errorType}]:`,
        lMessage && `Message: ${lMessage}`,
        lLocations && `Location: ${lLocations}`,
        lPath && `Path: ${lPath}`,
        lVariables && `Variables: ${lVariables}`,
      ]
        .filter(Boolean)
        .join('\n'),
      extensions,
    );
  };

  if (graphQLErrors) graphQLErrors.forEach((error: GraphQLError) => logError(error, 'GraphQL error'));
  forward(operation)
})

// Log any GraphQL errors or network error that occurred
// const errorLink = onError(({ graphQLErrors, networkError }) => {
//   if (graphQLErrors)
//     graphQLErrors.forEach(({ message, locations, path }) =>
//       console.log(
//         `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
//       )
//     );
//   if (networkError) console.log(`[Network error]: ${networkError}`);
// });

export default errorLink;

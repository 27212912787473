import { ApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';

export const formatGraphQLErrors = (
  error: ApolloError,
  resolver: (e: GraphQLError) => string | undefined = () => undefined,
) => {
  const graphqlErrorMsg = Array.isArray(error.graphQLErrors)
    ? error.graphQLErrors.map((err: GraphQLError) => resolver(err) ?? err.message).join('\n')
    : null;

  // const networkError = error.networkError as ServerParseError | ServerError ;
  // const networkErrorResultMsg = Array.isArray(networkError?.result?.errors)
  //   ? networkError?.result?.errors.map((err) => err.message).join('\n')
  //   : null;
  // const networkErrorMsg = networkError ? i18next.t('serverConnectionError') : null;
  console.log(error)

  const msg = graphqlErrorMsg; // || networkErrorResultMsg || networkErrorMsg;
  return msg ? new Error(msg) : error;
};



// export const formatDate = (date: Date | string, dateFormat = DATE_TIME_FORMAT) => {
//   return format(new Date(date), dateFormat, { locale: locales[i18n.language as Locales] });
// };

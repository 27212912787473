import { Flex, Text } from '@chakra-ui/react';

const ICON_SIZE = 10;

interface IItemWithEditActionProps {
  label?: string;
  value: string | number;
  onEdit?: () => void;
  disableEdit?: boolean;
}

const ItemWithEditAction = ({ label, value, disableEdit, onEdit }: IItemWithEditActionProps) => {
  return (
    <Flex w='100%'>
      <Flex alignItems='center' flex='1'>
        <Text pr='2' fontWeight='500'>
          {label}
        </Text>
      </Flex>
      <Flex alignItems='center' pl='5' minW='45%'>
        {value}
      </Flex>
      <Flex
        alignItems='center'
        justifyContent='center'
        cursor={!disableEdit ? 'pointer' : 'default'}
        width={ICON_SIZE}
        height={ICON_SIZE}
        ml='3'
        onClick={() => !disableEdit && onEdit?.()}
      >
        {!disableEdit && <IconCarbonEdit />}
      </Flex>
    </Flex>
  );
};
export default ItemWithEditAction;

import { IconButton } from '@chakra-ui/button';
import { Image } from '@chakra-ui/image';
import { AspectRatio, Box } from '@chakra-ui/layout';
import { Trash } from '../../../../icons';
import SetAsMainButton from './SetAsMainButton';

type Props = {
  id: string;
  url: string;
  isClickable?: boolean;
  onClick?: () => void;
  isMain?: boolean;
  onDelete?: () => Promise<void> | void;
  onSetMainImage?: () => void;
  isSelected?: boolean;
};

const isClickableProps = {
  cursor: 'pointer',
  _hover: {
    opacity: '0.7',
  },
};

export default function ImageCard({
  id,
  url,
  isSelected,
  isClickable,
  isMain,
  onSetMainImage,
  onClick,
  onDelete,
}: Props) {
  return (
    <>
      <Box position='relative'>
        <AspectRatio ratio={1}>
          <Image
            key={id}
            src={url}
            backgroundColor='gray.100'
            onClick={onClick}
            shadow={isSelected ? 'outline' : 'inner'}
            objectFit='cover'
            borderRadius='sm'
            {...(isClickable && isClickableProps)}
          />
        </AspectRatio>

        <SetAsMainButton
          hidden={!onSetMainImage && !isMain}
          isMain={isMain || false}
          variant='small'
          onSetMainImage={onSetMainImage}
        />
        {onDelete && (
          <IconButton
            onClick={onDelete}
            position='absolute'
            variant='ghost'
            size='sm'
            bottom='0'
            right='0'
            aria-label='delete'
            borderInlineEndRadius='none'
            borderBottomLeftRadius='none'
            bgColor='rgba(0,0,0,0.3)'
            _hover={{
              bgColor: 'rgba(0,0,0,0.5)',
            }}
            color='gray.300'
            fill='gray.300'
            icon={<Trash />}
          />
        )}
      </Box>
    </>
  );
}

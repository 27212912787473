import { Box, BoxProps, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const variants = {
  medium: { px: '4', py: '2', fontSize: 16 },
  small: { p: '1', fontSize: 14 },
};

interface ISetAsMainButtonProps extends BoxProps {
  onSetMainImage?: () => void;
  variant?: 'medium' | 'small';
  isMain: boolean;
}
const isClickableProps = { cursor: 'pointer', _hover: { textDecoration: 'underline' }, color: 'blue.200' };

const SetAsMainButton = ({ onSetMainImage, isMain, variant = 'medium', ...rest }: ISetAsMainButtonProps) => {
  const { t } = useTranslation();

  const onClick = !isMain ? onSetMainImage : undefined;

  return (
    <Box
      position='absolute'
      top='0'
      right='0'
      display='flex'
      justifyContent='center'
      alignItems='center'
      borderBottomLeftRadius='5'
      bg='rgba(0,0,0,0.3)'
      _hover={{
        bgColor: 'rgba(0,0,0,0.5)',
      }}
      zIndex='1'
      {...rest}
      {...variants[variant]}
    >
      <Text color='white' onClick={onClick} {...(!isMain && isClickableProps)}>
        {t(isMain ? 'main' : 'set_as_main')}
      </Text>
    </Box>
  );
};

export default SetAsMainButton;

import { formatGraphQLErrors } from '../../gql/apollo/helpers';
import AccessTokenService from '../../services/tokens/AccessTokenService';
import RefreshTokenService from '../../services/tokens/RefreshTokenService';
import { useToast } from '@chakra-ui/react';
import { MeDocument, useRegisterByInvitationMutation } from '../../gql/generated';

const useRegisterByInvitation = () => {
  const toast = useToast();
  return useRegisterByInvitationMutation({
    onError: (e) => {
      toast({
        description: formatGraphQLErrors(e).message,
        status: 'error',
      });
    },
    onCompleted: (data) => {
      const { accessToken, refreshToken, user } = data.registerByInvitation;
      if (!user) return;

      AccessTokenService.setToken(accessToken);
      RefreshTokenService.setToken(refreshToken);
    },

    update: (cache, { data }) => {
      cache.writeQuery({ query: MeDocument, data: { me: data?.registerByInvitation.user } });
    },
  });
};

export default useRegisterByInvitation;

import { setContext } from '@apollo/client/link/context';
import AccessTokenService from '../../../services/tokens/AccessTokenService';

const authLink = setContext((_, { headers }) => {
  const token = AccessTokenService.getToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});
export default authLink;

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  chakra,
  TableContainer,
  Spinner,
  Center,
  Box,
  Text,
  Heading,
  VStack,
  TableProps,
  BoxProps,
} from '@chakra-ui/react';
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  ColumnDef,
  SortingState,
  getSortedRowModel,
  Row,
} from '@tanstack/react-table';
import isEmpty from 'lodash/isEmpty';
import { AngleSmallDown, AngleSmallUp } from '../../icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export type DataTableProps<Data extends object> = {
  loading?: boolean;
  // showEmptyState?: boolean;
  emptyTitle?: string;
  emptyDescription?: string;
  data?: Data[] | [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: ColumnDef<Data, any>[];
  tableProps?: TableProps;
  withBorder?: boolean;
  boxProps?: BoxProps;
  onClickRow?: (row: Row<Data>) => void;
  disableRow?: (row: Row<Data>) => boolean;
};

const borderProps = { border: '1px', borderColor: 'gray.200' };
const disabledRowProps = { opacity: 0.4 };

export function DataTable<Data extends object>({
  data = [],
  columns,
  emptyTitle,
  emptyDescription,
  loading,
  tableProps,
  withBorder = false,
  boxProps,
  onClickRow,
  disableRow,
}: DataTableProps<Data>) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const { t } = useTranslation();
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
  });

  return (
    <Box bg='white' {...(withBorder && borderProps)} p='3' rounded='xl' shadow='md' position='relative' {...boxProps}>
      <TableContainer>
        <Table {...tableProps}>
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  const meta = header.column.columnDef.meta;
                  return (
                    // onClick={header.column.getToggleSortingHandler()}
                    <Th key={header.id} isNumeric={meta?.isNumeric}>
                      {flexRender(header.column.columnDef.header, header.getContext())}

                      <chakra.span pl='4'>
                        {header.column.getIsSorted() ? (
                          header.column.getIsSorted() === 'desc' ? (
                            <AngleSmallDown aria-label='sorted descending' />
                          ) : (
                            <AngleSmallUp aria-label='sorted ascending' />
                          )
                        ) : null}
                      </chakra.span>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {!isEmpty(data)
              ? table.getRowModel().rows.map((row) => {
                  const isDisabled = disableRow?.(row);
                  return (
                    <Tr
                      onClick={() => onClickRow?.(row)}
                      cursor={onClickRow ? 'pointer' : 'default'}
                      pointerEvents={isDisabled ? 'none' : undefined}
                      {...(isDisabled && disabledRowProps)}
                      key={row.id}
                    >
                      {row.getVisibleCells().map((cell) => {
                        const meta = cell.column.columnDef.meta;
                        return (
                          <Td key={cell.id} isNumeric={meta?.isNumeric} py='8px'>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })
              : null}
          </Tbody>
        </Table>
      </TableContainer>
      {loading && isEmpty(data) ? (
        <Center py='2' height={boxProps?.minHeight} width='100%'>
          <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='primary.main' size='xl' />
        </Center>
      ) : (
        isEmpty(data) && (
          <Center py='2' px='2' height={boxProps?.minHeight} width='100%' color='blackAlpha.600'>
            <VStack>
              <Heading size='lg'>{emptyTitle || t('data_table.empty.title')}</Heading>
              <Text textAlign='center' size='sm'>
                {emptyDescription || t('data_table.empty.description')}
              </Text>
            </VStack>
          </Center>
        )
      )}
    </Box>
  );
}

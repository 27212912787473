import { Box, Flex, Text } from '@chakra-ui/react';
import { CurrentUserFragment } from '../../../gql/generated';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import format from 'date-fns/format';
import JobberLink from '../../jobber/JobberLink';

interface IUserProfileInfoHeaderProps {
  user: CurrentUserFragment;
}

const AVATAR_SIZE = 120;

const CustomerProfileInfoHeader = ({ user }: IUserProfileInfoHeaderProps) => {
  const { t } = useTranslation();

  const name = `${user?.profile?.firstName || ''} ${user?.profile?.lastName || ''}`;

  const yearRegistered = useMemo(
    () => (user.createdAt ? format(new Date(user.createdAt), 'LLL dd, yyyy') : ''),
    [user.createdAt],
  );

  return (
    <Flex alignItems='center' pb='5'>
      <Flex
        style={{
          backgroundColor: '#E7F4FF',
          height: AVATAR_SIZE,
          width: AVATAR_SIZE,
          borderRadius: AVATAR_SIZE / 2,
          alignItems: 'center',
          justifyContent: 'center',
          alignSelf: 'center',
          fontSize: 25,
          fontWeight: 600,
        }}
      >
        <Text fontSize={40}>{user.profile ? `${user.profile.firstName[0]} ${user.profile.lastName[0]}` : 'N/A'}</Text>
      </Flex>
      <Box ml={2} p='1.5'>
        <Text fontWeight='500'>{name}</Text>
        <Flex>
          <Text color='gray.400'>{t('customer_profile_info.email_label')}</Text>
          <Text color='gray.500' pl='1'>
            {user.email}
          </Text>
        </Flex>
        <Flex>
          <Text color='gray.400'>{t('customer_profile_info.phone_label')}</Text>
          <Text color='gray.500' pl='1'>
            {user.profile?.phone || 'N/A'}
          </Text>
        </Flex>
        <Flex>
          <Text color='gray.400'>{t('customer_profile_info.registered_label')}</Text>
          <Text color='gray.500' pl='1'>
            {yearRegistered}
          </Text>
        </Flex>
        <JobberLink link={user.jobberWebUri} />
      </Box>
    </Flex>
  );
};

export default CustomerProfileInfoHeader;

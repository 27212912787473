import i18next from 'i18next';
import last from 'lodash/last';
import client from '../gql/apollo/client';
import {
  CurrentUserFragment,
  CurrentUserFragmentDoc,
  ItemFragment,
  ItemFragmentDoc,
  TaskFragment,
  TaskFragmentDoc,
  ZoneDocument,
  ZoneQuery,
} from '../gql/generated';

export const getItemCrumbName = () => {
  const itemId = location.pathname.split('items/')[1]?.split('/')[0];
  if (!itemId) return i18next.t('crumb.item_details', { itemName: '' });
  const fragment = client.readFragment({
    id: `Item:${itemId}`,
    fragmentName: 'Item',
    fragment: ItemFragmentDoc,
  }) as ItemFragment;
  return fragment?.name;
};
export const getZoneCrumbName = () => {
  const zoneId = location.pathname.split('zones/')[1]?.split('/')[0];
  if (!zoneId) return i18next.t('crumb.item_details', { itemName: '' });
  const query = client.readQuery({ query: ZoneDocument, variables: { zoneId } }) as ZoneQuery;
  return query?.zone.name;
};

export const getTaskCrumbTitle = () => {
  const taskId = last(location.pathname?.split('/'));
  if (!taskId) return i18next.t('crumb.item_details', { itemName: '' });
  const fragment = client.readFragment({
    id: `Task:${taskId}`,
    fragmentName: 'Task',
    fragment: TaskFragmentDoc,
  }) as TaskFragment;

  return i18next.t('crumb.item_details', { itemName: fragment?.name });
};

export const getCustomerCrumbTitle = () => {
  const userId = last(location.pathname.split('/'));
  if (!userId) return i18next.t('crumb.customer_details');
  const fragment = client.readFragment({
    id: `User:${userId}`,
    fragmentName: 'CurrentUser',
    fragment: CurrentUserFragmentDoc,
  }) as CurrentUserFragment;

  if (!fragment?.profile) return 'N/A';
  return `${fragment?.profile?.firstName || ''} ${fragment?.profile?.lastName || ''}`;
};

import { Divider, Tab, TabIndicator, TabList, TabPanels, Tabs } from '@chakra-ui/react';
import { useMemo } from 'react';
import { NavLink, Navigate, useOutlet } from 'react-router-dom';

interface IItemTabsProps {
  tabs: {
    label: string;
    path: string;
  }[];
  itemDetailsPath: string;
}

const ItemTabs = ({ tabs, itemDetailsPath }: IItemTabsProps) => {
  const showTabs = useMemo(() => {
    return tabs.findIndex((tab) => location.pathname === tab.path) > -1 || location.pathname === itemDetailsPath;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const outlet = useOutlet();
  const tabActive = useMemo(() => {
    const index = tabs.findIndex((tab) => location.pathname === tab.path);
    return index === -1 ? 0 : index;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <>
      {!showTabs ? (
        outlet
      ) : (
        <Tabs variant='unstyled' defaultIndex={tabActive} isLazy>
          <TabList>
            {tabs.map(({ path, label }) => (
              <Tab
                key={path}
                as={NavLink}
                _focus={{ boxShadow: 'none' }}
                to={path}
                fontWeight='500'
                _selected={{ color: 'primary.main' }}
              >
                {label}
              </Tab>
            ))}
          </TabList>
          <TabIndicator height='2px' bg='primary.main' borderRadius='1px' />
          <Divider my='0.5' />
          <TabPanels pt='2'>
            {outlet}
            {!outlet && showTabs && <Navigate to={tabs[0].path} replace />}
          </TabPanels>
        </Tabs>
      )}
    </>
  );
};

export default ItemTabs;

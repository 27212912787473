import { Box, Link } from '@chakra-ui/react';
import { Row, createColumnHelper } from '@tanstack/react-table';
import { useCallback, useMemo } from 'react';
import { DataTable } from '../../common/DataTable';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import DoItRequestsActions from './DoItRequestsActions';
import { Location, User } from '../../../gql/generated';
import generateGooglePlaceUrl from '../../../utils/generateGooglePlaceUrl';
import TableTooltipLabel from '../../common/TableTooltipLabel';
import { useNavigate } from 'react-router-dom';
import UserInfo from '../../users/UserInfo';

type UserInfo = Pick<User, 'id' | 'profile' | 'email' | 'isVerified'>;

export type DoItRequestsData = {
  description: string;
  owner: UserInfo;
  relatedHome: Location;
  timing: string;
  id: string;
  status: string;
  sentDate: string;
};

const columnHelper = createColumnHelper<DoItRequestsData>();

type Props = {
  isLoading?: boolean;
  doitRequests: DoItRequestsData[];
  withHomeInfo?: boolean;
  getNavigationPath?: (row: Row<DoItRequestsData>) => string;
};

export default function DoItRequestsTable({ doitRequests, withHomeInfo, getNavigationPath, isLoading }: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const columns = useMemo(() => {
    const selectedColumns = [
      columnHelper.accessor('description', {
        cell: (info) => <TableTooltipLabel noOfLines={2} text={info.getValue()} maxW='300' />,
        header: t('doit_requests_table.header.doit_request'),
      }),
      columnHelper.accessor('timing', {
        cell: (info) => {
          const jobPerformer = t(`timing.${info.getValue().toLowerCase()}`);
          return jobPerformer;
        },
        header: t('doit_requests_table.header.urgency'),
      }),
      columnHelper.accessor('sentDate', {
        cell: (info) => format(new Date(info.getValue()), 'dd LLLL yyyy'),
        header: t('doit_requests_table.header.date_sent'),
      }),
      ...(withHomeInfo
        ? [
            columnHelper.accessor('owner', {
              cell: (info) => <UserInfo {...info.getValue()} />,
              header: t('home_table.header.owner'),
            }),
            columnHelper.accessor('relatedHome', {
              cell: (info) => {
                const location = info.getValue();
                const value = `${String(location.street1)} ${String(location.city)}, ${String(location.state)}`;
                const placeUrl = generateGooglePlaceUrl(location);
                return (
                  <Box maxW='300'>
                    <Link href={placeUrl} isExternal>
                      <TableTooltipLabel text={value} maxW='300' />
                    </Link>
                  </Box>
                );
              },
              header: t('doit_requests_table.header.related_home'),
            }),
          ]
        : []),
      columnHelper.accessor('status', {
        cell: (info) => {
          const timing = t(`doit_request_status.${info.getValue().toLowerCase()}`);
          return timing;
        },
        header: t('doit_requests_table.header.status'),
      }),
      columnHelper.accessor('id', {
        cell: (info) => <DoItRequestsActions navigationPath={getNavigationPath?.(info.row) || ''} />,
        header: '',
      }),
    ];
    return selectedColumns;
  }, [getNavigationPath, t, withHomeInfo]);

  const handlerOnClickRow = useCallback(
    (row: Row<DoItRequestsData>) => getNavigationPath && navigate(getNavigationPath(row)),
    [getNavigationPath, navigate],
  );

  return (
    <DataTable
      columns={columns}
      data={doitRequests}
      boxProps={{ minHeight: 550 }}
      {...(getNavigationPath && { onClickRow: handlerOnClickRow })}
      loading={isLoading}
      emptyTitle={t('doit_requests_table.empty.title')}
      emptyDescription={t('doit_requests_table.empty.description')}
    />
  );
}

import { Box, Flex, Select, useToast, chakra } from '@chakra-ui/react';
import { ChangeEvent, useMemo, useState } from 'react';
import { EDoItRequestStatus, EDoItRequestTiming, useDoitRequestsQuery } from '../../gql/generated';
import Pagination from '../../components/common/Pagination';
import { formatGraphQLErrors } from '../../gql/apollo/helpers';
import { useTranslation } from 'react-i18next';
import DoItRequestsTable, { DoItRequestsData } from '../../components/homeDetails/DoItRequests/DoItRequestsTable';
import DatePicker from '../../components/common/DatePicker';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import { generatePath } from 'react-router-dom';
import { Row } from '@tanstack/react-table';
import { AppPaths } from '../../routes/paths';
import { FILTER_DO_IT_REQUEST_BY_URGENCY_SELECT } from '../../constants/presets';
import { isEmpty } from 'lodash';
import PageError from '../../components/common/PageError';

const DOIT_REQUESTS_PER_PAGE = 40;

const DoItRequests = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [status, setStatus] = useState(EDoItRequestStatus.ACTIVE);

  const [urgency, setUrgency] = useState<EDoItRequestTiming | undefined>();

  const { t } = useTranslation();
  const toast = useToast();

  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
  const [startDate, endDate] = dateRange;

  const handleUrgencyChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setUrgency(() => {
      setCurrentPage(1);
      return e.target.value as EDoItRequestTiming;
    });
  };
  const handleStatusChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setStatus(() => {
      setCurrentPage(1);
      return e.target.value as EDoItRequestStatus;
    });
  };

  const filterStatus = useMemo(
    () =>
      Object.keys(EDoItRequestStatus).includes(status)
        ? [status]
        : [EDoItRequestStatus.ACTIVE, EDoItRequestStatus.FINISHED],
    [status],
  );

  const timeRange = useMemo(
    () =>
      !!startDate && !!endDate
        ? { timeRange: { start: startOfDay(startDate).toISOString(), end: endOfDay(endDate).toISOString() } }
        : undefined,
    [endDate, startDate],
  );

  const timing = useMemo(
    () => (urgency && Object.keys(EDoItRequestTiming).includes(urgency) ? [urgency] : undefined),
    [urgency],
  );

  const { data, error, loading } = useDoitRequestsQuery({
    variables: {
      filter: { status: filterStatus, ...timeRange, timing },
      limit: DOIT_REQUESTS_PER_PAGE,
      offset: (currentPage - 1) * DOIT_REQUESTS_PER_PAGE,
    },
    onError: (e) => toast({ description: formatGraphQLErrors(e).message, status: 'error' }),
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });

  const handleGetNavigationPath = (row: Row<DoItRequestsData>) =>
    generatePath(AppPaths.DOIT_REQUEST_DETAILS, { doitRequestId: row.original.id });

  if (error?.message && isEmpty(data)) return <PageError error={error} />;

  return (
    <Box>
      <Flex py='2' justifyContent='space-between'>
        <Box width='300px'>
          <DatePicker<string, true>
            selectsRange
            startDate={startDate}
            endDate={endDate}
            placeholderText='MM/DD/YYYY - MM/DD/YYYY'
            onChange={(update) => {
              setDateRange(update);
            }}
            isClearable={true}
          />
        </Box>
        <Flex gap='2'>
          <Box>
            <Select value={urgency} onChange={handleUrgencyChange} variant='outlined'>
              {FILTER_DO_IT_REQUEST_BY_URGENCY_SELECT.map((key) => (
                <chakra.option value={key} key={key}>
                  {t(`doit_request_select_urgency.${key.toLowerCase()}`)}
                </chakra.option>
              ))}
            </Select>
          </Box>
          <Box>
            <Select value={status} onChange={handleStatusChange} variant='outlined'>
              {['all_do_its'].concat(Object.keys(EDoItRequestStatus)).map((key) => (
                <chakra.option value={key} key={key}>
                  {t(`doit_request_select_status.${key.toLowerCase()}`)}
                </chakra.option>
              ))}
            </Select>
          </Box>
        </Flex>
      </Flex>
      <DoItRequestsTable
        getNavigationPath={handleGetNavigationPath}
        doitRequests={(data?.doitRequests.doitRequests || []).map(
          ({ id, timing, description, sentDate, status, home: { location, owner } }) => ({
            timing: timing || '',
            status,
            owner,
            relatedHome: location,
            sentDate: sentDate || '',
            description: description || '',
            id,
          }),
        )}
        isLoading={loading}
        withHomeInfo
      />
      {data?.doitRequests && (
        <Pagination
          total={data.doitRequests.total}
          current={currentPage}
          defaultPageSize={DOIT_REQUESTS_PER_PAGE}
          onChange={(page) => setCurrentPage(page)}
        />
      )}
    </Box>
  );
};

export default DoItRequests;

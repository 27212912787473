import { VStack } from '@chakra-ui/react';
import { useState } from 'react';
import Button, { EButtonVariant } from '../common/Button';
import ForgotForm from '../auth/forms/ForgotForm';
import OtpForgotPasswordForm from '../auth/forms/OtpForgotPasswordForm';
import NewPasswordForm from '../auth/forms/NewPasswordForm';

enum Steps {
  Email,
  Code,
  NewPassword,
}

interface ISetPasswordStepsProps {
  initialEmail: string;
  onCancel?: (email?: string) => void;
  cancelButtonTitle: string;
  onLastStepSuccess?: () => void;
  hideLoginToast?: boolean;
  skipEmailStep?: boolean;
}

const SetPasswordSteps = ({
  initialEmail,
  cancelButtonTitle,
  hideLoginToast,
  skipEmailStep,
  onLastStepSuccess,
  onCancel,
}: ISetPasswordStepsProps) => {
  const [step, setStep] = useState(Steps.Email);
  const [email, setEmail] = useState(initialEmail);

  const [token, setToken] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const handleLoading = (loading: boolean) => setIsLoading(loading);

  const handleNextStep = () => {
    setStep((s) => s + 1);
    setIsLoading(false);
  };

  const onForgotSuccess = (email: string) => {
    setEmail(email);
    handleNextStep();
  };

  const onOtpSuccess = (token: string) => {
    setToken(token);
    handleNextStep();
  };

  const renderStep = () => {
    switch (step) {
      case Steps.Email:
        return (
          <ForgotForm
            hideInput={skipEmailStep}
            email={email}
            onChange={setEmail}
            onSuccess={onForgotSuccess}
            onLoading={handleLoading}
          />
        );
      case Steps.Code:
        return <OtpForgotPasswordForm email={email} onSuccess={onOtpSuccess} onLoading={handleLoading} />;
      case Steps.NewPassword:
        return (
          <NewPasswordForm
            email={email}
            token={token}
            hideLoginToast={hideLoginToast}
            onSuccess={onLastStepSuccess}
            onLoading={handleLoading}
          />
        );
    }
  };

  return (
    <VStack gap={4}>
      {renderStep()}
      <Button styleVariant={EButtonVariant.gray} w='100%' onClick={() => onCancel?.(email)} isDisabled={isLoading}>
        {cancelButtonTitle}
      </Button>
    </VStack>
  );
};

export default SetPasswordSteps;

import { ButtonProps, Text } from '@chakra-ui/react';
import { t } from 'i18next';
import generateRedirectUrl from '../../utils/generateRedirectUrl';
import useLoginWithApple from '../../hooks/auth/useLoginWithApple';
import AppleSignin from 'react-apple-signin-auth';
import config from '../../constants/config';
import { Apple } from '../../icons/social-icons';
import Button, { EButtonVariant } from '../common/Button';

type AppleResponse = {
  authorization?: {
    code?: string;
    id_token?: string;
  };
};

export default function AppleButton() {
  const [login] = useLoginWithApple();

  return (
    <AppleSignin
      authOptions={{
        clientId: config.appleClientId,
        redirectURI: generateRedirectUrl(),
        scope: 'email name',
        usePopup: true,
      }}
      noDefaultStyle={true}
      onSuccess={(response: AppleResponse) => {
        if (response.authorization?.id_token) {
          void login({ variables: { input: { idToken: response.authorization.id_token } } });
        }
      }}
      render={(props: ButtonProps) => (
        <Button styleVariant={EButtonVariant.grayOutline} w='100%' leftIcon={<Apple />} {...props}>
          <Text>{t('apple_continue')}</Text>
        </Button>
      )}
    />
  );
}

import isEmpty from 'lodash/isEmpty';
import ProfileInfo from '../../components/users/profile/ProfileInfo';
import { useMeQuery, useUserQuery } from '../../gql/generated';
import LoadingOverlay from '../../components/common/LoadingOverlay';
import { Box } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import PageError from '../../components/common/PageError';

const Profile = () => {
  const { userId = '' } = useParams();

  const { data, error, loading } = useUserQuery({ variables: { userId }, fetchPolicy: 'cache-and-network' });
  const { data: me, loading: meDataLoading } = useMeQuery({ fetchPolicy: 'cache-and-network' });

  console.log(error);
  if (loading || meDataLoading) return <LoadingOverlay />;
  if (error?.message && isEmpty(data)) return <PageError error={error} />;
  if (isEmpty(data) || isEmpty(me)) return null;

  return <Box>{data.user && <ProfileInfo isMe={me?.me.id === data.user.id} me={me?.me} user={data.user} />}</Box>;
};

export default Profile;

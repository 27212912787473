import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  HStack,
  Heading,
  Spinner,
  Tag,
  Text,
  VStack,
} from '@chakra-ui/react';
import generateJobberUrl from '../../utils/generateJobberUrl';
import { EJobberStatus, useJobberStatusQuery } from '../../gql/generated';
import { useTimer } from 'react-timer-hook';
import { addSeconds } from 'date-fns';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

const WAIT_SECONDS = 60;
const POLL_INTERVAL = 1000 * WAIT_SECONDS;

const getExpiryTimestamp = () => addSeconds(new Date(), WAIT_SECONDS);

export default function JobberCard() {
  const { t } = useTranslation();
  const [status, setStatus] = useState<EJobberStatus>();

  const { totalSeconds, restart } = useTimer({
    expiryTimestamp: getExpiryTimestamp(),
    autoStart: true,
  });

  const { loading } = useJobberStatusQuery({
    pollInterval: POLL_INTERVAL,
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ jobberStatus }) => setStatus(jobberStatus),
    onError: () => setStatus(undefined),
  });

  useEffect(() => {
    if (!loading) restart(getExpiryTimestamp(), true);
  }, [loading, restart]);

  return (
    <>
      <Card size='md'>
        <CardHeader>
          <Heading size='lg'>{t('jobber_card.header')}</Heading>
        </CardHeader>
        <CardBody py='0'>
          <Text>{t('jobber_card.body.description')}</Text>
          <Divider my='4' />
          <Heading size='md'>{t('jobber_card.body.current_status.title')}</Heading>
          <HStack mt='2'>
            <Text>{t('jobber_card.body.current_status.message')}</Text>
            {loading ? <Spinner size='xs' /> : <StatusTag status={status} />}
          </HStack>
          <HStack mt='2'>
            <Text>
              <Trans
                i18nKey='jobber_card.body.current_status.next_check'
                values={{ seconds: totalSeconds }}
                components={{ bold: <strong /> }}
                t={t}
              />
            </Text>
          </HStack>
          <Divider my='4' />
          <Heading size='md'>{t('jobber_card.body.status_overview.title')}</Heading>
          <VStack gap={4} alignItems='flex-start' mt='2'>
            <Box>
              <StatusTag status={EJobberStatus.OK} />
              <Text mt='1'>{t('jobber_card.body.status_overview.connected')}</Text>
            </Box>

            <Box>
              <StatusTag status={EJobberStatus.UNAUTHORIZED} />
              <Text mt='1'>{t('jobber_card.body.status_overview.unauthorized')}</Text>
            </Box>

            <Box>
              <StatusTag status={EJobberStatus.ERROR} />
              <Text mt='1'>{t('jobber_card.body.status_overview.error')}</Text>
            </Box>

            <Box>
              <StatusTag />
              <Text mt='1'>{t('jobber_card.body.status_overview.unknown')}</Text>
            </Box>
          </VStack>
          <Divider my='4' />
          <Heading size='md'>{t('jobber_card.body.important_note.title')}</Heading>
          <Text mt='1'>{t('jobber_card.body.important_note.description')}</Text>
        </CardBody>
        <CardFooter justifyContent='flex-end'>
          <Button
            colorScheme='red'
            variant='outline'
            onClick={() => (window.location.href = generateJobberUrl())}
            isDisabled={!status || status === EJobberStatus.DISABLED}
          >
            {t('jobber_card.footer.button')}
          </Button>
        </CardFooter>
      </Card>
    </>
  );
}

const StatusTag = ({ status }: { status?: EJobberStatus }) => {
  const { t } = useTranslation();

  switch (status) {
    case EJobberStatus.OK:
      return <Tag colorScheme='green'>{t('jobber_status.connected')}</Tag>;
    case EJobberStatus.UNAUTHORIZED:
      return <Tag colorScheme='yellow'>{t('jobber_status.unauthorized')}</Tag>;
    case EJobberStatus.ERROR:
      return <Tag colorScheme='red'>{t('jobber_status.error')}</Tag>;
    case EJobberStatus.DISABLED:
      return <Tag colorScheme='purple'>{t('jobber_status.disabled')}</Tag>;
    default:
      return <Tag colorScheme='gray'>{t('jobber_status.unknown')}</Tag>;
  }
};

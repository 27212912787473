import { useCallback, useMemo, useRef, useState } from 'react';
import {
  TaskImagesDocument,
  TaskImagesQuery,
  useConfirmTaskImagesUploadingMutation,
  useRequestTaskImagesUploadingMutation,
} from '../../gql/generated';
import UploadingFile from '../../classes/UploadingFile';
import { Status as UploadingFileStatus } from '../../classes/UploadingFile';
import { resizeImages } from '../../utils/resizeImages';

type Props = {
  taskId: string;
};

export default function useTaskImagesUploader({ taskId }: Props) {
  const [i, setI] = useState(0);
  const forceRerender = useCallback(() => setI((prev) => ++prev), [setI]);
  const [requestTaskImagesUploading] = useRequestTaskImagesUploadingMutation();
  const [confirmTaskImageUploading] = useConfirmTaskImagesUploadingMutation();
  const uploadingFiles = useRef<UploadingFile[]>([]);

  const requestUpload = useCallback(
    async (files: UploadingFile[]) => {
      const { data } = await requestTaskImagesUploading({
        variables: {
          input: {
            parentId: taskId,
            files: files.map(({ file }) => ({
              sizeBytes: file.size,
              filename: file.name,
              mimetype: file.type,
            })),
          },
        },
      });

      if (data?.requestTaskImagesUploading) {
        files.forEach((upload) => {
          const requestResponse = data.requestTaskImagesUploading.find(({ filename }) => filename === upload.file.name);
          if (requestResponse) upload.setRequestResponse(requestResponse);
        });
      } else {
        files.forEach((upload) => upload.setStatus(UploadingFileStatus.ERROR));
      }
    },
    [requestTaskImagesUploading, taskId],
  );

  const uploadToS3 = useCallback(async (files: UploadingFile[]) => {
    files.forEach(({ upload }) => upload());
    await Promise.all(files.map((file) => file.uploadResponse));
  }, []);

  const confirmUpload = useCallback(
    async (files: UploadingFile[]) => {
      files.forEach(({ setStatus }) => setStatus(UploadingFileStatus.CONFIRMING));

      await confirmTaskImageUploading({
        variables: {
          input: {
            parentId: taskId,
            fileIds: files.map((file) => file.requestResponse!.id),
          },
        },
        update: (cache, data) => {
          if (!data.data?.confirmTaskImagesUploading) return;

          const existingImages = cache.readQuery<TaskImagesQuery>({
            query: TaskImagesDocument,
            variables: { taskId },
          });

          const newImages = data.data.confirmTaskImagesUploading.map((image) => {
            const localFile = files.find(({ requestResponse }) => requestResponse?.id === image.id)?.file;
            return {
              ...image,
              url: localFile ? URL.createObjectURL(localFile) : image.url,
            };
          });

          cache.writeQuery({
            query: TaskImagesDocument,
            variables: { taskId },
            data: {
              taskImages: existingImages ? [...newImages, ...existingImages.taskImages] : newImages,
            },
          });
        },
      });
      files.forEach(({ setStatus }) => setStatus(UploadingFileStatus.CONFIRMED));
    },
    [confirmTaskImageUploading, taskId],
  );

  const upload = useCallback(
    async (files: File[]) => {
      const images = await resizeImages(files);
      uploadingFiles.current = images.map((file) => new UploadingFile(file, forceRerender));
      forceRerender();
      await requestUpload(uploadingFiles.current.filter(({ getStatus }) => getStatus() === UploadingFileStatus.IDLE));
      await uploadToS3(uploadingFiles.current.filter(({ getStatus }) => getStatus() === UploadingFileStatus.REQUESTED));
      await confirmUpload(
        uploadingFiles.current.filter(({ getStatus }) => getStatus() === UploadingFileStatus.UPLOADED),
      );
    },
    [forceRerender, requestUpload, uploadToS3, confirmUpload],
  );

  const clear = useCallback(() => {
    uploadingFiles.current = [];
    forceRerender();
  }, [forceRerender]);

  const uploadingImages = useMemo(
    () =>
      uploadingFiles.current.filter(
        ({ getStatus }) =>
          ![UploadingFileStatus.ERROR, UploadingFileStatus.CANCELED, UploadingFileStatus.CONFIRMED].includes(
            getStatus(),
          ),
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i],
  );

  return {
    uploadingImages,
    upload,
    clear,
  };
}

import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useCallback, useState } from 'react';
import Button, { EButtonVariant } from '../common/Button';
import { HomeWithStatsDocument, useFinishTaskMutation } from '../../gql/generated';
import { formatGraphQLErrors } from '../../gql/apollo/helpers';
import AddImagesButton from '../common/images/AddImagesButton';
import DropZone from '../common/images/DropZone';
import useConfirmTaskImagesUploader from '../../hooks/images/useConfirmTaskImagesUploader';
import ImageCard from '../common/images/Gallery/ImageCard';
import { useParams } from 'react-router-dom';

interface ISetPasswordModalProps {
  isOpen: boolean;
  onClose: () => void;
  taskId: string;
}

const ConfirmTaskModal = ({ isOpen, taskId, onClose }: ISetPasswordModalProps) => {
  const { t } = useTranslation();
  const toast = useToast();
  const { id: homeId = '' } = useParams();
  const [file, setFile] = useState<File>();

  const { isLoading, upload } = useConfirmTaskImagesUploader({ taskId });

  const handleDropAccepted = useCallback((files: File[]) => setFile(files[0]), []);

  const [finishTask, { loading: finishTaskLoading }] = useFinishTaskMutation({
    variables: { taskId },
    onError: (e) => {
      onClose();
      toast({ description: formatGraphQLErrors(e).message, status: 'error' });
    },
    onCompleted: async () => {
      if (file) {
        await upload(file);
      }
      toast({
        description: t('task_form.toast.completed'),
        status: 'success',
      });
      onClose();
    },
    refetchQueries: [{ query: HomeWithStatsDocument, variables: { homeId } }],
  });

  const onFinishTask = useCallback(async () => await finishTask(), [finishTask]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent minW='250'>
        <ModalHeader>{t('confirm_task_modal.title')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text pb='3' color='gray'>
            {t('confirm_task_modal.upload_photo_description')}
          </Text>
          <DropZone onDropAccepted={handleDropAccepted}>
            {({ open }) => (
              <Box boxSize='120px'>
                {file ? (
                  <ImageCard id={file.name} url={URL.createObjectURL(file)} onDelete={() => setFile(undefined)} />
                ) : (
                  <AddImagesButton boxSize='120px' onClick={open} />
                )}
              </Box>
            )}
          </DropZone>
        </ModalBody>
        <ModalFooter>
          <Button mr={2} styleVariant={EButtonVariant.gray} onClick={onClose} variant='solid'>
            {t('cancel')}
          </Button>
          <Button isLoading={isLoading || finishTaskLoading} onClick={onFinishTask}>
            {file ? t('confirm_task_modal.confirm') : t('confirm_task_modal.confirm_without_photo')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmTaskModal;

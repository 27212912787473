import { Divider } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { HomeWithStatsQuery } from '../../../gql/generated';
import InfoItem from '../../common/InfoItem';

interface IHomeStatsProps {
  homeStats?: HomeWithStatsQuery['homeStats'];
}

const HomeStats = ({ homeStats }: IHomeStatsProps) => {
  const { t } = useTranslation();
  return (
    <>
      <InfoItem title={t('home_details.zones')} value={homeStats?.zones} />
      <Divider />
      <InfoItem title={t('home_details.inventory_items')} value={homeStats?.items} />
      <Divider />
      <InfoItem title={t('home_details.tasks')} value={homeStats?.activeTasks} />
    </>
  );
};

export default HomeStats;

import { Card, CardBody, Flex, Spinner, Text, useDisclosure, useToast } from '@chakra-ui/react';
import { useSetMainTaskImageMutation, useTaskImagesQuery } from '../../../gql/generated';
import { useCallback, useMemo, useState } from 'react';
import { formatGraphQLErrors } from '../../../gql/apollo/helpers';
import Gallery from '../../common/images/Gallery';
import { ApolloError } from '@apollo/client';
import useTaskImagesUploader from '../../../hooks/images/useTaskImagesUploader';
import AddImagesButton from '../../common/images/AddImagesButton';
import { useTranslation } from 'react-i18next';
import DropZone from '../../common/images/DropZone';
import DeleteTaskImageModal from './DeleteTaskImageModal';

type Props = {
  taskId: string;
  disableEdit?: boolean;
};

export default function TaskImages({ taskId, disableEdit }: Props) {
  const toast = useToast();
  const { t } = useTranslation();
  const [fileId, setFileId] = useState('');
  const { isOpen, onClose, onOpen } = useDisclosure();

  const { data, loading } = useTaskImagesQuery({ variables: { taskId }, fetchPolicy: 'cache-and-network' });
  const { uploadingImages, upload, clear } = useTaskImagesUploader({ taskId });
  const [setMain] = useSetMainTaskImageMutation({
    onError: (e) => {
      toast({
        description: formatGraphQLErrors(e).message,
        status: 'error',
      });
    },
  });

  const setMainImage = useCallback(
    async (fileId: string) => {
      await setMain({
        variables: { input: { fileId, parentId: taskId } },
      });
    },
    [taskId, setMain],
  );

  const handleDropAccepted = useCallback(
    (files: File[]) => {
      if (!uploadingImages.length) {
        upload(files).finally(clear);
      } else {
        toast({ description: t('wait_previous_uploading'), status: 'warning' });
      }
    },
    [clear, t, toast, upload, uploadingImages],
  );

  const handleDelete = useCallback(
    (fileId: string) => {
      setFileId(fileId);
      onOpen();
    },
    [onOpen],
  );

  const onError = useCallback(
    (e: ApolloError) => toast({ description: formatGraphQLErrors(e).message, status: 'error' }),
    [toast],
  );

  const handleModalError = useCallback(
    (e: ApolloError) => {
      onError(e);
      onClose();
    },
    [onClose, onError],
  );

  const isAddButtonDisabled = useMemo(() => loading || uploadingImages.length > 0, [loading, uploadingImages]);

  return (
    <>
      <Card display={data?.taskImages.length === 0 && disableEdit ? 'none' : undefined}>
        <CardBody p={4}>
          <DropZone onDropAccepted={handleDropAccepted} disabled={disableEdit}>
            {({ open }) => (
              <>
                {loading ? (
                  <Flex py='20' flexDirection='column' alignItems='center' gap={6}>
                    <Spinner size='xl' color='orange' />
                  </Flex>
                ) : data?.taskImages.length || uploadingImages.length ? (
                  <Gallery
                    uploadingImages={uploadingImages}
                    images={data?.taskImages || []}
                    {...(!disableEdit && { setMainImage: setMainImage, deleteImage: handleDelete })}
                    AddButton={!disableEdit && <AddImagesButton onClick={open} isDisabled={isAddButtonDisabled} />}
                  />
                ) : (
                  <Flex py='20' flexDirection='column' alignItems='center' gap={6}>
                    <AddImagesButton boxSize='120px' onClick={open} isDisabled={isAddButtonDisabled} />
                    <Text color='darkgray' fontSize='lg' mx='4' textAlign='center'>
                      {t('task_images.no_images')}
                    </Text>
                  </Flex>
                )}
              </>
            )}
          </DropZone>
        </CardBody>
      </Card>
      <DeleteTaskImageModal
        taskId={taskId}
        fileId={fileId}
        isOpen={isOpen}
        onClose={onClose}
        onError={handleModalError}
      />
    </>
  );
}

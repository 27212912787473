import { Box, Flex, FormControl, FormLabel, Select, Switch, useToast } from '@chakra-ui/react';
import { generatePath, useLocation, useParams } from 'react-router-dom';
import Pagination from '../../common/Pagination';
import { formatGraphQLErrors } from '../../../gql/apollo/helpers';
import { ChangeEvent, useMemo, useState } from 'react';
import TaskTable, { TaskData } from './TaskTable';
import { EJobPerformer, ETaskStatus, useTasksByHomeIdQuery } from '../../../gql/generated';
import { useTranslation } from 'react-i18next';
import { chakra } from '@chakra-ui/react';
import { Row } from '@tanstack/react-table';
import { AppPaths } from '../../../routes/paths';
import { FILTER_TASKS_BY_JOB_PERFORMER_SELECT } from '../../../constants/presets';

type LocationState = { showDwelinkTasks?: boolean };

const TASKS_PER_PAGE = 40;

export default function Tasks() {
  const [currentPage, setCurrentPage] = useState(1);
  const [showIsArchived, setShowIsArchived] = useState(false);
  const [status, setStatus] = useState(ETaskStatus.ACTIVE);

  const location = useLocation();
  const state = location.state as LocationState;
  const [executor, setExecutor] = useState(state?.showDwelinkTasks ? EJobPerformer.DWELINK : undefined);

  const { id: homeId = '' } = useParams();
  const { t } = useTranslation();
  const toast = useToast();

  const handleExecutorChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setExecutor(() => {
      setCurrentPage(1);
      return e.target.value as EJobPerformer;
    });
  };

  const handleStatusChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setStatus(() => {
      setCurrentPage(1);
      return e.target.value as ETaskStatus;
    });
  };

  const handleIsArchivedChange = () => {
    setShowIsArchived((prev) => {
      setCurrentPage(1);
      return !prev;
    });
  };

  const jobPerformer = useMemo(
    () => (executor && Object.keys(EJobPerformer).includes(executor) ? [executor] : undefined),
    [executor],
  );

  const { data, loading } = useTasksByHomeIdQuery({
    variables: {
      filter: {
        homeId,
        status: [status],
        isArchived: showIsArchived,
        jobPerformer,
      },
      limit: TASKS_PER_PAGE,
      offset: (currentPage - 1) * TASKS_PER_PAGE,
    },
    onError: (e) => toast({ description: formatGraphQLErrors(e).message, status: 'error' }),
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });

  const handleGetNavigationPath = (row: Row<TaskData>) =>
    generatePath(AppPaths.EDIT_HOME_TASK, { id: homeId, taskId: row.original.id });

  const disableEdit = status === ETaskStatus.FINISHED || showIsArchived;

  return (
    <Flex width='100%' direction='column' gap='2' alignItems='flex-end'>
      <Flex gap={5} alignItems='center'>
        <Box>
          <FormControl display='flex' alignItems='center'>
            <FormLabel htmlFor='show_archived' mb='0' width='max-content' fontWeight='normal'>
              {t('task_show_archived')}
            </FormLabel>
            <Switch
              id='show_archived'
              colorScheme='orange'
              onChange={handleIsArchivedChange}
              isChecked={showIsArchived}
            />
          </FormControl>
        </Box>
        <Box>
          <Select value={executor} onChange={handleExecutorChange} variant='outlined'>
            {FILTER_TASKS_BY_JOB_PERFORMER_SELECT.map((key) => (
              <chakra.option value={key} key={key}>
                {t(`task_select_executor.${key.toLowerCase()}`)}
              </chakra.option>
            ))}
          </Select>
        </Box>
        <Box>
          <Select value={status} onChange={handleStatusChange} variant='outlined'>
            {Object.keys(ETaskStatus).map((key) => (
              <chakra.option value={key} key={key}>
                {t(`task_select_status.${key.toLowerCase()}`)}
              </chakra.option>
            ))}
          </Select>
        </Box>
      </Flex>
      <Box width='100%'>
        <TaskTable
          getNavigationPath={handleGetNavigationPath}
          tasks={(data?.tasksByHomeId.tasks || []).map(({ name, item, recommendedDate, jobPerformer, id }) => ({
            name,
            item: item.name,
            zone: item.zone.name,
            owner: item.home.owner,
            recommendedDate,
            jobPerformer,
            id,
          }))}
          disableEdit={disableEdit}
          isLoading={loading}
        />
        {data?.tasksByHomeId && (
          <Pagination
            total={data.tasksByHomeId.total}
            current={currentPage}
            defaultPageSize={TASKS_PER_PAGE}
            onChange={(page) => setCurrentPage(page)}
          />
        )}
      </Box>
    </Flex>
  );
}

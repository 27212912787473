import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import refreshTokenLink from './links/refreshTokenLink';
import errorLink from './links/errorLink';
import httpLink from './links/httpLink';
import authLink from './links/authLink';


const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([refreshTokenLink, authLink, errorLink, httpLink]),
});

export default client;

import ConfirmationModal from '../common/ConfirmationModal';
import { useNavigate } from 'react-router-dom';
import { AuthPaths } from '../../routes/paths';
import { useTranslation } from 'react-i18next';

type Props = {
  isOpen: boolean;
  email: string;
  onClose: () => void;
};

export default function SocialLoginAlert({ isOpen, onClose, email }: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <ConfirmationModal
      isOpen={isOpen}
      onClose={onClose}
      title={t('social_login_alert.title')}
      onSubmit={() => {
        navigate(AuthPaths.SET_PASSWORD, { state: { email } });
      }}
      description={t('social_login_alert.description')}
      submitButtonProps={{ title: t('social_login_alert.submit') }}
    />
  );
}

import {
  FlexProps,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Box,
  ButtonProps,
  IconProps,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import { useTranslation } from 'react-i18next';
import React, { LegacyRef, forwardRef, useMemo } from 'react';
import Button, { EButtonVariant } from '../Button';
import { AngleSmallDown } from '../../../icons';

interface IFormMenuPresetProps {
  Icon?: (props: IconProps) => JSX.Element;
  title: string;
  translate?: boolean;
  value: string | number;
  color?: string;
}

const FormMenuButton = forwardRef((props: ButtonProps, ref: LegacyRef<HTMLDivElement> | undefined) => (
  <Box ref={ref}>
    <Button styleVariant={EButtonVariant.grayOutline} w='full' fontWeight={400} textAlign='start' flex='1' {...props} />
  </Box>
));

interface IFormSelectProps {
  name: string;
  options: IFormMenuPresetProps[];
  placeholder?: string;
  containerProps?: FlexProps;
  disabled?: boolean;
  label: string;
  titleColor?: string;
  isRequired?: boolean;
  onClose?: () => void;
}

const ICON_SIZE = 4;

const FormMenu = (props: IFormSelectProps) => {
  const { name, options, placeholder, isRequired, titleColor, onClose, label, disabled, containerProps } = props;
  const { setValue, formState } = useFormContext();

  const defaultValue = options.find(({ value }) => formState?.defaultValues?.[name] === value);

  const [title, setTitle] = React.useState(defaultValue?.title || placeholder);
  const { t } = useTranslation();

  const error = formState.errors[name];

  const onClick = React.useCallback(
    (item: IFormMenuPresetProps) => {
      setValue(name, item.value);
      setTitle(item.title);
    },
    [name, setValue],
  );

  const color = useMemo(() => {
    if (!title) return 'gray';
    const color = options.find((item) => item.title === title)?.color;
    return color || titleColor;
  }, [options, title, titleColor]);

  return (
    <FormControl isRequired={isRequired} isInvalid={!!error}>
      <Flex {...containerProps} alignItems='center' py='2' flexDirection='row'>
        <FormLabel width='40%' fontWeight='500'>
          {t(label)}
        </FormLabel>
        <Flex flex='1' flexDirection='column'>
          <Menu onClose={onClose}>
            <MenuButton
              color={color || 'gray'}
              isDisabled={disabled}
              borderWidth='1'
              borderColor='gray.200'
              as={FormMenuButton}
              rightIcon={<AngleSmallDown color='black' />}
            >
              {t(title || '')}
            </MenuButton>
            <MenuList>
              {options.map((item, index) => (
                <MenuItem
                  key={index}
                  color={item.color}
                  onClick={() => onClick(item)}
                  justifyContent='space-between'
                  minH='48px'
                >
                  {t(item.title)}
                  <Box pl='3'> {item.Icon && <item.Icon width={ICON_SIZE} height={ICON_SIZE} />}</Box>
                </MenuItem>
              ))}
            </MenuList>
            <FormErrorMessage>{error?.message as string}</FormErrorMessage>
          </Menu>
        </Flex>
      </Flex>
    </FormControl>
  );
};

export default FormMenu;

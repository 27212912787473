import { Card, CardBody, CardHeader, Heading, Image } from '@chakra-ui/react';
import { ReactNode } from 'react';
import logo from '../../assets/logo-primary.svg';

type Props = {
  title: string;
  children: ReactNode;
};

export default function AuthCard({ title, children }: Props) {
  return (
    <Card px={{ base: '20px', sm: '50px' }} py={{ base: '20px', sm: '30px' }} shadow='lg'>
      <CardHeader textAlign='center' p={0}>
        <Image src={logo} mx='auto' h='60px' />
        <Heading size='lg' mt='12px'>
          {title}
        </Heading>
      </CardHeader>
      <CardBody p={0} mt='24px'>
        {children}
      </CardBody>
    </Card>
  );
}

import { isDate, isEqual as isDateEqual } from 'date-fns';
import { unstable_usePrompt } from 'react-router-dom';
import i18next from 'i18next';
import map from 'lodash/map';
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface IFormPromptProps {
  unwatchFields?: string[];
}

const FormPrompt = ({ unwatchFields = [] }: IFormPromptProps) => {
  const { formState, watch } = useFormContext();

  const formValues = watch();
  const { t } = useTranslation();

  const isShowAlert = useMemo(() => {
    const cleanFields = map(formValues, (value, key) => {
      if (unwatchFields.includes(key)) return true;
      if (isDate(value)) return isDateEqual(value as Date, formState?.defaultValues?.[key] as Date);
      return value === formState?.defaultValues?.[key];
    });
    const result = cleanFields.every((field) => field);
    return !result;
  }, [formState?.defaultValues, formValues, unwatchFields]);

  useEffect(() => {
    window.onbeforeunload = function () {
      if (isShowAlert) {
        const message = i18next.t('unsaved_changes_message');
        return message;
      }
    };
    return () => {
      window.onbeforeunload = null;
    };
  }, [isShowAlert]);
  unstable_usePrompt({ message: t('unsaved_changes_message'), when: isShowAlert });

  return null;
};

export default FormPrompt;

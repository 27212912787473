import { formatGraphQLErrors } from '../../gql/apollo/helpers';
import { useTranslation } from 'react-i18next';
import AccessTokenService from '../../services/tokens/AccessTokenService';
import RefreshTokenService from '../../services/tokens/RefreshTokenService';
import { useToast } from '@chakra-ui/react';
import { MeDocument, useLoginMutation } from '../../gql/generated';

const useLogin = (hideToast?: boolean) => {
  const { t } = useTranslation();
  const toast = useToast();
  return useLoginMutation({
    onError: (e) => {
      toast({
        description: formatGraphQLErrors(e).message,
        status: 'error',
      });
    },
    onCompleted: (data) => {
      const { accessToken, refreshToken, user } = data.login;
      if (!user) return;

      AccessTokenService.setToken(accessToken);
      RefreshTokenService.setToken(refreshToken);

      if (hideToast) return;
      const name = user?.profile ? `${user.profile.firstName} ${user.profile.lastName}` : user?.email;
      toast({
        description: t('welcome', { name }),
        status: 'success',
      });
    },

    update: (cache, { data }) => {
      cache.writeQuery({ query: MeDocument, data: { me: data?.login.user } });
    },
  });
};

export default useLogin;

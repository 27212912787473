import { AspectRatio, Box, IconButton, IconButtonProps, Image } from '@chakra-ui/react';
import { Carousel as RCarousel } from 'react-responsive-carousel';
import { AngleSmallLeft, AngleSmallRight } from '../../../../icons';
import SetAsMainButton from './SetAsMainButton';

const CarouselController = (props: IconButtonProps & { onClick: () => void }) => {
  return (
    <IconButton
      variant='ghost'
      position='absolute'
      top='0'
      bottom='0'
      margin='auto'
      bgColor='rgba(0,0,0,0.3)'
      _hover={{
        bgColor: 'rgba(0,0,0,0.5)',
      }}
      color='gray.300'
      rounded='full'
      zIndex='1'
      {...props}
    />
  );
};

type Props = {
  selectedItem: number;
  onChange: (ind: number) => void;
  onSetMainImage?: (fileId: string) => void;
  mainImage?: string;
  images: { id: string; url: string }[];
};

export default function Carousel({ selectedItem, mainImage, onSetMainImage, onChange, images }: Props) {
  const isShowChangeImageButtons = images.length > 1;
  return (
    <RCarousel
      selectedItem={selectedItem}
      onChange={onChange}
      showStatus={false}
      showThumbs={false}
      showIndicators={false}
      infiniteLoop={true}
      renderArrowPrev={
        isShowChangeImageButtons
          ? (clickHandler) => (
              <CarouselController aria-label='prev' icon={<AngleSmallLeft />} left='2' onClick={clickHandler} />
            )
          : undefined
      }
      renderArrowNext={
        isShowChangeImageButtons
          ? (clickHandler) => (
              <CarouselController aria-label='next' icon={<AngleSmallRight />} right='2' onClick={clickHandler} />
            )
          : undefined
      }
    >
      {images.map(({ id, url }) => (
        <Box position='relative' key={id}>
          <SetAsMainButton
            hidden={!onSetMainImage && mainImage !== id}
            onSetMainImage={() => onSetMainImage?.(id)}
            isMain={mainImage === id}
          />
          <AspectRatio ratio={16 / 9} backgroundSize='cover' backgroundImage={url}>
            <Image
              key={id}
              src={url}
              cursor='pointer'
              shadow='inner'
              backdropFilter='auto'
              backdropBlur='2xl'
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              fit='contain !important'
            />
          </AspectRatio>
        </Box>
      ))}
    </RCarousel>
  );
}

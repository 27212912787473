import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightAddon,
  Link,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Spinner,
  Text,
  useBoolean,
  useToast,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, useCallback, useState } from 'react';
import { Check, Cross } from '../../../icons';
import { useSetClientHubUrlMutation } from '../../../gql/generated';

const ClientHubUrl = ({ userId, jobberClientHubUri = '' }: { userId: string; jobberClientHubUri?: string | null }) => {
  const [isOpen, { toggle, off, on }] = useBoolean(false);
  const toast = useToast();
  const [setClientHubUrl, { reset, loading, error }] = useSetClientHubUrlMutation();
  const [newUrl, setNewUrl] = useState<string | null>(() => jobberClientHubUri);
  const { t } = useTranslation();
  const handleOnClose = useCallback(() => {
    setNewUrl(jobberClientHubUri);
    off();
    reset();
  }, [jobberClientHubUri, off, reset]);

  const handleOnChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setNewUrl(event.target.value);
  }, []);

  const handleSubmit = useCallback(() => {
    const url = newUrl || null;
    setClientHubUrl({
      variables: { input: { url, userId } },
      onCompleted: () => {
        toast({
          description: t('customer_profile_info.edit_client_hub_success_message'),
          status: 'success',
        });
        off();
      },
    });
  }, [newUrl, setClientHubUrl, t, toast, userId, off]);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line
  const errorMessage = error?.graphQLErrors[0]?.extensions?.originalError?.message || error?.message;

  return (
    <Box>
      <Popover isOpen={isOpen} onOpen={on} onClose={handleOnClose} placement='top'>
        <FormLabel>{t('customer_profile_info.client_hub_url')}</FormLabel>
        <PopoverTrigger>
          <Box display='inline-flex' alignItems='center'>
            <Link isExternal href={jobberClientHubUri || ''} mr='1'>
              <Text maxW='350px' noOfLines={1}>
                {jobberClientHubUri || 'N/A'}
              </Text>
            </Link>
            <IconCarbonEdit width='20px' height='20px' cursor='pointer' onClick={toggle} />
          </Box>
        </PopoverTrigger>
        <PopoverArrow />
        <PopoverContent h='90px' width='450px' alignItems='center' justifyContent='center' p='4'>
          <FormControl isInvalid={!!error}>
            <InputGroup size='sm'>
              <Input autoFocus value={newUrl || ''} onChange={handleOnChange} type='url' />
              {newUrl ? (
                <InputRightAddon mr='1px' onClick={() => setNewUrl('')} cursor='pointer' children={<Cross />} />
              ) : null}
              <InputRightAddon
                cursor='pointer'
                onClick={handleSubmit}
                children={loading ? <Spinner color='primary.main' size='sm' /> : <Check color='green' />}
              />
            </InputGroup>
            <FormErrorMessage>{errorMessage}</FormErrorMessage>
          </FormControl>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export default ClientHubUrl;

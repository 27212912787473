import { Flex, Link, LinkProps, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { GoLinkExternal } from '../../icons';

interface IJobberLinkProps extends LinkProps {
  link?: string | null;
}

const disabledProps = {
  _hover: { textDecorationLine: 'none' },
  color: 'gray.400',
  cursor: 'auto',
};

const defaultProps = {
  color: 'primary.main',
};

const JobberLink = ({ link, ...rest }: IJobberLinkProps) => {
  const { t } = useTranslation();
  const isDisabled = !link;
  return (
    <Link href={link ?? undefined} {...(isDisabled ? disabledProps : defaultProps)} isExternal {...rest}>
      <Flex alignItems='center' fontWeight='500'>
        <Text>{t('open_in_jobber')}</Text>
        <GoLinkExternal mx='2' fill={isDisabled ? 'gray.400' : 'primary.main'} w='4' h='4' />
      </Flex>
    </Link>
  );
};
export default JobberLink;

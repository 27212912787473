import { ButtonProps, Button as CButton } from '@chakra-ui/react';
import { EButtonVariant, buttonVariants } from '../../constants/buttonVariants';

export { EButtonVariant };

export interface IButtonProps extends ButtonProps {
  title?: string;
  styleVariant?: EButtonVariant;
}

const Button = ({ children, title, styleVariant = EButtonVariant.default, ...rest }: IButtonProps) => {
  return (
    <CButton {...buttonVariants[styleVariant]} {...rest}>
      {title || children}
    </CButton>
  );
};

export default Button;

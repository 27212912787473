import { Link as ReactRouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import { Link as ChakraLink, LinkProps as ChakraLinkProps } from '@chakra-ui/react';

type Props = ChakraLinkProps & RouterLinkProps;

export default function AppLink({ children, ...rest }: Props) {
  return (
    <ChakraLink as={ReactRouterLink} {...rest} textDecoration='none' _hover={{ textDecoration: 'none' }}>
      {children}
    </ChakraLink>
  );
}

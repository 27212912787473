import i18next from 'i18next';
import * as yup from 'yup';

const getInventoryItemSchema = () =>
  yup.object().shape({
    name: yup
      .string()
      .required(i18next.t('form_errors.field_required_error_message'))
      .max(20, i18next.t('form_errors.max_chars_error', { count: 20 })),
    condition: yup.string().required(i18next.t('form_errors.field_required_error_message')).nullable(),
    lastMaintenanceDate: yup.date().nullable(),
    purchasedFrom: yup
      .string()
      .max(40, i18next.t('form_errors.max_chars_error', { count: 40 }))
      .nullable(),
    installationYear: yup
      .number()
      .nullable()
      .transform((value: number) => (Number.isNaN(value) ? null : value))
      .min(1970, i18next.t('form_errors.min_error_year_installed'))
      .max(new Date().getFullYear(), i18next.t('form_errors.cannot_be_more_current_year')),
    brand: yup
      .string()
      .max(40, i18next.t('max_chars_error', { count: 40 }))
      .nullable(),
    model: yup
      .string()
      .max(40, i18next.t('max_chars_error', { count: 40 }))
      .nullable(),
    serial: yup
      .string()
      .max(40, i18next.t('max_chars_error', { count: 40 }))
      .nullable(),
    type: yup
      .string()
      .max(40, i18next.t('max_chars_error', { count: 40 }))
      .nullable(),
    color: yup
      .string()
      .max(40, i18next.t('max_chars_error', { count: 40 }))
      .nullable(),
    keyIssues: yup
      .string()
      .max(1000, i18next.t('max_chars_error', { count: 1000 }))
      .nullable(),
  });

export default getInventoryItemSchema;

import { Box, Flex, Select, useToast } from '@chakra-ui/react';
import { generatePath, useParams } from 'react-router-dom';
import { formatGraphQLErrors } from '../../../gql/apollo/helpers';
import { ChangeEvent, useMemo, useState } from 'react';
import { EDoItRequestStatus, EDoItRequestTiming, useDoitRequestsByHomeIdQuery } from '../../../gql/generated';
import { useTranslation } from 'react-i18next';
import { chakra } from '@chakra-ui/react';
import DoItRequestsTable, { DoItRequestsData } from './DoItRequestsTable';
import Pagination from '../../common/Pagination';
import { AppPaths } from '../../../routes/paths';
import { Row } from '@tanstack/react-table';
import { FILTER_DO_IT_REQUEST_BY_URGENCY_SELECT } from '../../../constants/presets';

const DOIT_REQUESTS_PER_PAGE = 40;

const HomeDoItRequests = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const { id: homeId } = useParams();
  const { t } = useTranslation();
  const toast = useToast();

  const [status, setStatus] = useState(EDoItRequestStatus.ACTIVE);
  const [urgency, setUrgency] = useState<EDoItRequestTiming | undefined>();

  const handleUrgencyChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setUrgency(() => {
      setCurrentPage(1);
      return e.target.value as EDoItRequestTiming;
    });
  };
  const handleStatusChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setStatus(() => {
      setCurrentPage(1);
      return e.target.value as EDoItRequestStatus;
    });
  };

  const filterStatus = useMemo(
    () =>
      Object.keys(EDoItRequestStatus).includes(status)
        ? [status]
        : [EDoItRequestStatus.ACTIVE, EDoItRequestStatus.FINISHED],
    [status],
  );

  const timing = useMemo(
    () => (urgency && Object.keys(EDoItRequestTiming).includes(urgency) ? [urgency] : undefined),
    [urgency],
  );

  const { data, loading } = useDoitRequestsByHomeIdQuery({
    variables: {
      filter: { homeId: homeId!, status: filterStatus, timing },
      limit: DOIT_REQUESTS_PER_PAGE,
      offset: (currentPage - 1) * DOIT_REQUESTS_PER_PAGE,
    },
    onError: (e) => toast({ description: formatGraphQLErrors(e).message, status: 'error' }),
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });

  const handleGetNavigationPath = (row: Row<DoItRequestsData>) => {
    return generatePath(AppPaths.HOME_DOIT_REQUEST_DETAILS, {
      id: homeId || '',
      doitRequestId: row.original.id,
    });
  };

  return (
    <Flex width='100%' direction='column' gap='2' alignItems='flex-end'>
      <Flex gap='2'>
        <Box>
          <Select value={urgency} onChange={handleUrgencyChange} variant='outlined'>
            {FILTER_DO_IT_REQUEST_BY_URGENCY_SELECT.map((key) => (
              <chakra.option value={key} key={key}>
                {t(`doit_request_select_urgency.${key.toLowerCase()}`)}
              </chakra.option>
            ))}
          </Select>
        </Box>
        <Box>
          <Select value={status} onChange={handleStatusChange} variant='outlined'>
            {['all_do_its'].concat(Object.keys(EDoItRequestStatus)).map((key) => (
              <chakra.option value={key} key={key}>
                {t(`doit_request_select_status.${key.toLowerCase()}`)}
              </chakra.option>
            ))}
          </Select>
        </Box>
      </Flex>
      <Box width='100%'>
        <DoItRequestsTable
          getNavigationPath={handleGetNavigationPath}
          doitRequests={(data?.doitRequestsByHomeId.doitRequests || []).map(
            ({ id, timing, description, sentDate, status, home: { location, owner } }) => ({
              timing: timing || '',
              status,
              owner,
              relatedHome: location,
              sentDate: sentDate || '',
              description: description || '',
              id,
            }),
          )}
          isLoading={loading}
        />
        {data?.doitRequestsByHomeId && (
          <Pagination
            total={data.doitRequestsByHomeId.total}
            current={currentPage}
            defaultPageSize={DOIT_REQUESTS_PER_PAGE}
            onChange={(page) => setCurrentPage(page)}
          />
        )}
      </Box>
    </Flex>
  );
};

export default HomeDoItRequests;

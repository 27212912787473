import { Button, Text, VStack } from '@chakra-ui/react';
import { addSeconds } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useTimer } from 'react-timer-hook';

const getExpiryTimestamp = () => {
  const waitSeconds = 30;
  return addSeconds(new Date(), waitSeconds);
};

type Props = {
  isLoading?: boolean;
  onResend: () => Promise<void>;
};

export default function ResendCode({ onResend, isLoading }: Props) {
  const { t } = useTranslation();

  const { totalSeconds, restart } = useTimer({ expiryTimestamp: getExpiryTimestamp() });

  const handleResend = () => {
    void onResend().then(() => restart(getExpiryTimestamp(), true));
  };

  return (
    <VStack gap={1}>
      <Text>{t('resend_code_message')}</Text>
      <Button variant='link' colorScheme='primary' onClick={handleResend} isDisabled={!!totalSeconds || isLoading}>
        {`${t('resend_button_title')}${totalSeconds ? ` - ${totalSeconds}${t('resend_time')}` : ''}`}
      </Button>
    </VStack>
  );
}

import React from 'react';
import { Box, Flex, Image, Link, Text } from '@chakra-ui/react';
import isEqual from 'lodash/isEqual';
import format from 'date-fns/format';
import { useTranslation } from 'react-i18next';
import { HomeWithStatsQuery } from '../../../gql/generated';
import { Areas } from '../../../icons/dwelink-icons';
import generateGooglePlaceUrl from '../../../utils/generateGooglePlaceUrl';
import JobberLink from '../../jobber/JobberLink';

interface IHeaderInfoProps {
  home?: HomeWithStatsQuery['home'];
}

const HeaderInfo = ({ home }: IHeaderInfoProps) => {
  const location = `${home?.location?.street1 || ''} ${home?.location.city || ''}, ${home?.location.state || ''}`;

  const { t } = useTranslation();

  const createdAt = format(new Date(home?.createdAt as string), 'MMM dd, yyyy');

  const placeUrl = home?.location ? generateGooglePlaceUrl(home?.location) : '';
  const image = home?.coverImage?.url;
  return (
    <Flex alignItems='center'>
      <Image
        src={image}
        borderRadius='full'
        boxSize='80px'
        isTruncated
        fallback={
          !image ? (
            <Flex
              borderWidth='1px'
              width='80px'
              height='80px'
              alignItems='center'
              justifyContent='center'
              borderRadius='full'
            >
              <Areas width='50px' height='50px' />
            </Flex>
          ) : undefined
        }
      />
      <Box ml={2} p='1.5'>
        <Link href={placeUrl} isExternal>
          <Text fontWeight='500'>{location}</Text>
        </Link>
        <Flex>
          <Text color='gray.400'>{t('customer_profile_info.email_label')}</Text>
          <Text color='gray.500' pl='1'>
            {home?.owner.email}
          </Text>
        </Flex>
        <Flex>
          <Text color='gray.400'>{t('customer_profile_info.phone_label')}</Text>
          <Text color='gray.500' pl='1'>
            {home?.owner.profile?.phone || 'N/A'}
          </Text>
        </Flex>
        <Flex>
          <Text color='gray.400'>{t('created_on')}</Text>
          <Text color='gray' pl='1'>
            {createdAt}
          </Text>
        </Flex>
        <JobberLink link={home?.jobberWebUri} />
      </Box>
    </Flex>
  );
};

export default React.memo(HeaderInfo, isEqual);

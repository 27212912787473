import { forwardRef, useImperativeHandle } from 'react';
import { FormProvider, UseFormReset, useForm } from 'react-hook-form';
import { Divider, useToast } from '@chakra-ui/react';
import isEqual from 'lodash/isEqual';
import { yupResolver } from '@hookform/resolvers/yup';
import { EItemCondition, ItemDocument, ItemFragment, useUpdateItemMutation } from '../../../gql/generated';
import { filterObject, mapObject } from '../../../constants/filterObject';
import getInventoryItemSchema from './getInventoryItemSchema';
import { CONDITION_SELECT_OPTIONS } from '../../../constants/presets';
import FormDatePicker from '../../common/form/FormDatePicker';
import { useTranslation } from 'react-i18next';
import FormInput from '../../common/form/FormInput';
import FormMenu from '../../common/form/FormMenu';
import FormNumberInput from '../../common/form/FormNumberInput';
import FormPrompt from '../../common/form/FormPrompt';

interface IEditItemForm {
  item?: ItemFragment;
}

interface IFormValues {
  name: string;
  condition: string | null;
  lastMaintenanceDate: Date | null;
  purchasedFrom: string | null;
  installationYear: number | null;
  brand: string | null;
  model: string | null;
  serial: string | null;
  type: string | null;
  color: string | null;
  keyIssues: string | null;
}

export interface IForwardRef {
  onSubmitForm: (() => unknown) | null | undefined;
  reset: UseFormReset<IFormValues>;
}

const EditItemForm = forwardRef<IForwardRef, IEditItemForm>(({ item }, ref) => {
  const initialValues: IFormValues = {
    name: item?.name || '',
    condition: item?.condition || null,
    lastMaintenanceDate: item?.lastMaintenanceDate ? new Date(item?.lastMaintenanceDate) : null,
    purchasedFrom: item?.purchasedFrom || null,
    installationYear: item?.installationYear ? parseInt(item?.installationYear, 10) : null,
    brand: item?.brand || null,
    model: item?.model || null,
    serial: item?.serial || null,
    type: item?.type || null,
    color: item?.color || null,
    keyIssues: item?.keyIssues || null,
  };

  const methods = useForm({
    resolver: yupResolver(getInventoryItemSchema()),
    defaultValues: initialValues,
    resetOptions: { keepDefaultValues: false, keepValues: false },
  });

  const toast = useToast();

  const [updateItem] = useUpdateItemMutation({
    onError: (error) => toast({ description: error.message, status: 'error' }),
  });

  const handleOnSubmit = methods.handleSubmit(async (data) => {
    if (!item?.id) return null;
    if (isEqual(initialValues, data)) return;

    const input = {
      ...data,
      id: item.id,
      installationYear: data.installationYear ? new Date(data.installationYear, 1, 1).toDateString() : undefined,
      condition: data.condition === undefined ? undefined : (data.condition as EItemCondition),
      lastMaintenanceDate: data.lastMaintenanceDate ? new Date(data.lastMaintenanceDate).toDateString() : undefined,
    };

    const filteredObject = filterObject(input, ([, value]) => value !== null);
    const filteredObjectInput = mapObject(filteredObject, ([key, value]) => [key, value || null]);

    await updateItem({
      variables: { input: filteredObjectInput },
      awaitRefetchQueries: true,
      refetchQueries: [{ query: ItemDocument, variables: { itemId: item.id } }],
      onCompleted: (data) => {
        methods.reset({
          name: data.updateItem.name,
          condition: data.updateItem.condition,
          lastMaintenanceDate: data.updateItem.lastMaintenanceDate
            ? new Date(data.updateItem?.lastMaintenanceDate)
            : null,
          purchasedFrom: data.updateItem.purchasedFrom,
          installationYear: data.updateItem?.installationYear ? parseInt(data.updateItem?.installationYear, 10) : null,
          brand: data.updateItem?.brand,
          model: data.updateItem?.model,
          serial: data.updateItem?.serial,
          type: data.updateItem?.type,
          color: data.updateItem?.color,
          keyIssues: data.updateItem?.keyIssues,
        });
        toast({ description: t('home_details.inventory_item_details.success_update_item_message'), status: 'success' });
      },
    });
  });

  useImperativeHandle(ref, () => ({
    onSubmitForm: handleOnSubmit,
    reset: methods.reset as UseFormReset<IFormValues>,
  }));

  const { t } = useTranslation();

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleOnSubmit}>
        <FormPrompt />
        <FormInput name='name' isRequired label='home_details.inventory_item_details.name' placeholder='undefined' />
        <Divider />
        <FormMenu
          isRequired
          options={CONDITION_SELECT_OPTIONS}
          placeholder='undefined'
          name='condition'
          label='home_details.inventory_item_details.condition'
        />
        <Divider />
        <FormInput
          name='keyIssues'
          isTextarea
          label='home_details.inventory_item_details.key_issue'
          placeholder='undefined'
        />
        <Divider />
        <FormDatePicker
          maxDate={new Date()}
          name='lastMaintenanceDate'
          label='home_details.inventory_item_details.last_performed_maintenance'
        />
        <Divider />
        <FormInput
          name='purchasedFrom'
          label='home_details.inventory_item_details.where_purchased'
          placeholder='undefined'
        />
        <Divider />
        <FormNumberInput
          max={new Date().getFullYear()}
          min={1970}
          name='installationYear'
          label='home_details.inventory_item_details.year_installed'
          placeholder='undefined'
        />
        <Divider />
        <FormInput name='brand' label='home_details.inventory_item_details.brand' placeholder='undefined' />
        <Divider />
        <FormInput name='model' label='home_details.inventory_item_details.model' placeholder='undefined' />
        <Divider />
        <FormInput name='serial' label='home_details.inventory_item_details.serial_number' placeholder='undefined' />
        <Divider />
        <FormInput name='type' label='home_details.inventory_item_details.type' placeholder='undefined' />
        <Divider />
        <FormInput name='color' label='home_details.inventory_item_details.color' placeholder='undefined' />
        <button type='submit' style={{ display: 'none' }} />
      </form>
    </FormProvider>
  );
});

export default EditItemForm;

import { generatePath, useParams } from 'react-router-dom';
import { useCallback, useState } from 'react';
import { Box } from '@chakra-ui/react';
import Pagination from '../../common/Pagination';
import InventoryItemsTable, { InventoryItemsData } from './InventoryItemsTable';
import { ItemFragment, useItemsByHomeIdQuery } from '../../../gql/generated';
import { CellContext, Row } from '@tanstack/react-table';
import { AppPaths } from '../../../routes/paths';

const ITEMS_PER_PAGE = 40;

const InventoryItems = () => {
  const { id: homeId } = useParams();
  const [currentPage, setCurrentPage] = useState(1);

  const { data, loading, refetch } = useItemsByHomeIdQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      filter: { homeId: homeId!, isArchived: false },
      offset: (currentPage - 1) * ITEMS_PER_PAGE,
      limit: ITEMS_PER_PAGE,
    },
  });

  const handlerGetNavigationPath = (row: Row<ItemFragment>) =>
    generatePath(AppPaths.ITEM_DETAILS, { id: homeId || '', itemId: row.original.id });

  const handlerGetTaskCountNavigationPath = useCallback(
    (info: CellContext<InventoryItemsData, number>) =>
      generatePath(AppPaths.ITEM_DETAILS_MAINTENANCE_PLAN, {
        id: info.row.original.homeId,
        itemId: info.row.original.id,
      }),
    [],
  );

  return (
    <Box>
      <InventoryItemsTable
        isLoading={loading}
        getNavigationPath={handlerGetNavigationPath}
        getTaskCountNavigationPath={handlerGetTaskCountNavigationPath}
        items={data?.itemsByHomeId.items}
        archiveCb={refetch}
      />
      {data?.itemsByHomeId && (
        <Pagination
          total={data.itemsByHomeId.total}
          current={currentPage}
          defaultPageSize={ITEMS_PER_PAGE}
          onChange={(page) => setCurrentPage(page)}
        />
      )}
    </Box>
  );
};

export default InventoryItems;

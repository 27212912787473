import { Grid, GridItem } from '@chakra-ui/react';
import JobberCard from '../../components/jobber/JobberCard';
import JobberHubCard from '../../components/jobber/JobberHubCard';

export default function Settings() {
  return (
    <Grid
      templateColumns={{
        base: 'repeat(2, 1fr)',
        xl: 'repeat(3, 1fr)',
      }}
      gap={4}
    >
      <GridItem colSpan={2}>
        <JobberCard />
      </GridItem>
      <GridItem>
        <JobberHubCard />
      </GridItem>
    </Grid>
  );
}
